import {Hosts, RequestState} from "../../constants";
import {authHeaders, post} from "../../utils/fetch";

const CREATE_OTP_SECRET = "CREATE_OTP_SECRET"
const VERIFY_2FA = "VERIFY_2FA"
const DISABLE_2FA = "DISABLE_2FA"
const ENABLE_LOGIN_2FA = "ENABLE_LOGIN_2FA"
const DISABLE_LOGIN_2FA = "DISABLE_LOGIN_2FA"

const TwoFA = {

  actions: {
    createOtpSecret: () => async (dispatch, getState) => {
      const result = await post({
        host: Hosts.EDGE_CLOUD_API,
        url: "/user/otp",
        headers: authHeaders(),
        dispatch,
        action: CREATE_OTP_SECRET,
      })

      return result.body.users[0]
    },

    verify2FA: (token) => async (dispatch, getState) => {
      const result = await post({
        host: Hosts.EDGE_CLOUD_API,
        url: "/user/otp/verify",
        body: {
          token
        },
        headers: authHeaders(),
        dispatch,
        action: VERIFY_2FA,
      })

      return result.body.users[0].otp_recovery_codes
    },

    disable2FA: (token) => async (dispatch, getState) => {
      const result = await post({
        host: Hosts.EDGE_CLOUD_API,
        url: "/user/otp/unverify",
        body: {
          token
        },
        headers: authHeaders(),
        dispatch,
        action: VERIFY_2FA,
      })

      return result.body.users[0]
    },

    enableEmail2FA: (token) => async (dispatch, getState) => {
      const result = await post({
        host: Hosts.EDGE_CLOUD_API,
        url: "/user/otp/enable",
        body: {
          token
        },
        headers: authHeaders(),
        dispatch,
        action: ENABLE_LOGIN_2FA,
      })

      return result.body.users[0]
    },


    disableEmail2FA: (token) => async (dispatch, getState) => {
      const result = await post({
        host: Hosts.EDGE_CLOUD_API,
        url: "/user/otp/disable",
        body: {
          token
        },
        headers: authHeaders(),
        dispatch,
        action: DISABLE_LOGIN_2FA,
      })

      return result.body.users[0]
    },

  },

  spec: {
  },

  modelReducer: (state, type, body, action) => {
    if (action.url && action.result !== RequestState.SUCCESS)
      return state;

    return state;
  }
}

export default TwoFA