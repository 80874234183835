import _ from 'lodash';
import {del, get, post, videoServiceAuthHeaders} from "../../utils/fetch";
import {Hosts, RequestState, Urls} from "../../constants";
import {pushTo} from "../history";


// ===========================
// HELPERS
// ===========================


// ===========================
// ACTIONS
// ===========================
export const FETCH_STREAMS = 'FETCH_STREAMS';
export const FETCH_STREAM = 'FETCH_STREAM';
export const CREATE_STREAM = 'CREATE_STREAM';
export const DELETE_STREAM = 'DELETE_STREAM';
export const CREATE_STREAM_UPLOAD_URL = 'CREATE_STREAM_UPLOAD_URL';


// ===========================
// SELECTORS
// ===========================
export const selectAllStreams = (state, serviceAccountId) => {
  return _.filter(Object.values(state.models.streams), (v) => {
    return v.service_account_id === serviceAccountId;
  });
};
export const selectStream = (state, streamId) => {
  const vid = state.models.streams[streamId];

  if (vid) {
    return {
      ...vid
    };
  }
  return null;
};
export const selectTotalStreamPages = (state, numPerPage) => {
  return Math.ceil(state.models.totalStreams / numPerPage);
}

// ===========================
// MODEL
// ===========================
const Stream = {
  actions: {
    fetchStreams: (serviceAccountId, page = 1, number = 10) => async (dispatch, getState) => {
      let result = await get({
        host: Hosts.API,
        url: `/service_account/${serviceAccountId}/streams`,
        headers: videoServiceAuthHeaders(getState(), serviceAccountId),
        params: {
          number,
          page
        },
        action: FETCH_STREAMS,
        dispatch,
      });
      return selectAllStreams(getState());
    },
    fetchStream: (streamId) => async (dispatch, getState) => {
      let result = await get({
        host: Hosts.API,
        url: `/stream/${streamId}`,
        headers: videoServiceAuthHeaders(getState()),
        action: FETCH_STREAM,
        dispatch,
      });
      return selectStream(getState(), streamId);
    },
    createStream: (body) => async (dispatch, getState) => {
      let result = await post({
        host: Hosts.API,
        url: "/stream",
        headers: videoServiceAuthHeaders(getState()),
        action: CREATE_STREAM,
        dispatch,
        body: body
      });
      let stream = _.get(result, 'body');
      let streamId = _.get(stream, 'id');

      pushTo(`/dashboard/video/livestreams/${streamId}`);

      return selectStream(getState(), streamId);
    },
    createStreamUploadUrl: (serviceAccountId) => async (dispatch, getState) => {
      let result = await post({
        host: Hosts.API,
        url: "/upload",
        headers: videoServiceAuthHeaders(getState(), serviceAccountId),
        action: CREATE_STREAM_UPLOAD_URL,
        dispatch
      });

      return _.get(result, 'body.uploads.0');
    },
    deleteStream: (serviceAccountId, streamId) => async (dispatch, getState) => {
      let result = await del({
        host: Hosts.API,
        url: `/stream/${streamId}`,
        headers: videoServiceAuthHeaders(getState(), serviceAccountId),
        action: DELETE_STREAM,
        dispatch,
      });


      pushTo(Urls.VIDEO_LIVESTREAMS);

      return selectAllStreams(getState());
    }
  },
  spec: {
    streams: {},
    totalStreams: 0
  },
  modelReducer: (state, type, body, action) => {
    if (action.url && action.result !== RequestState.SUCCESS)
      return state;

    if (type === FETCH_STREAMS) {
      return {
        ...state,
        streams: _.keyBy(body.streams, 'id'),
        totalStreams: _.get(body, 'total_count')
      }
    }

    if (type === FETCH_STREAM || type === CREATE_STREAM) {
      return {
        ...state,
        streams: {
          ...state.streams,
          [body.id]: body
        }
      }
    }

    if (type === DELETE_STREAM) {
      return {
        ...state
      }
    }

    return state;
  }
}
export default Stream;
