//a select input component that takes in a label, value, onChange, and options with dark background, right arrow on the right side and green checkmark on the selected option

import {CheckIcon, DownChevronIcon} from "../Svg";
import Select, {components} from "react-select";
import cx from "classnames";
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#131722',
    borderColor: state.isFocused ? '#18C99D' : '#3D4463',
    borderRadius: '6px',
    color: '#8A8FB5',
    outline: 'none',
    boxShadow: 'none',
    ':hover': {
      borderColor: '#18C99D', // Add your hover styles here
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? 'rgba(24, 201, 157, 0.1)' : '#131722',
    color: state.isFocused ? '#18C99D' : state.isSelected ? '#18C99D' : '#8A8FB5',
    fontFamily: 'Gilroy',
    paddingLeft: '10px',
    fontWeight: '500',
    fontSize: '14px',
    paddingTop: '11.5px',
    paddingBottom: '11.5px',
    position: 'relative',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    marginLeft: '7px',
    borderColor: state.isFocused ? '#18C99D' : '#3D4463',
    color: '#8A8FB5',
    fontFamily: 'Gilroy',
    fontWeight: '500',
    fontSize: '14px',
    height: '15px',
    outline: 'none',
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none'
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#8A8FB5',
    marginRight: '8px',
    ':hover': {
      color: '#8A8FB5', // Add your hover styles here
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: '#131722',
    border: '1px solid #3D4463',
    borderRadius: '6px',
    scrollbarColor: '#3D4463',
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: '500px',
  }),
}

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DownChevronIcon className={"dropdown-icon"} />
    </components.DropdownIndicator>
  );
};

const Option = ({ children, ...props }) => (
  <components.Option {...props}>
    {children}
    {props.isSelected && <CheckIcon className={"option-icon"} />}
  </components.Option>
);


export const SelectInput = ({label, value, onChange, options, styles, className, ...props}) => {
  return (
    <div className={cx("SelectInput", className)}>
      <label>{label}</label>
      <Select value={value}
              onChange={onChange}
              styles={styles ?? customStyles}
              options={options}
              components={{Option, DropdownIndicator}}
              {...props}>
      </Select>
    </div>
  );
}