import {Button} from "./Button";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router";
import Bill from "../store/models/Bill";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import User, {selectCurrentUser, selectCurrentUserId} from "../store/models/User";
import {pushTo} from "../store/history";
import {Link, NavLink} from "react-router-dom";
import {createSelector} from "reselect";
import {ModalTypes, ShowcaseURls, Urls, UserRoleIds as UserRoles} from "../constants";
import Project, {selectCurrentProject, selectCurrentProjectId} from "../store/models/Project";
import {DownChevronIcon} from "./Svg";
import UIState from "../store/UIState";
import {selectCurrentOrgId} from "../store/models/Organization";
import cx from "classnames";
import {DropdownMenu, DropdownMenuItem} from "./DropdownMenu";
import {OrgAndProjectDropdown} from "./OrgAndProjectDropdowns";

export const HeaderLink = ({to, target, onClick, name, active, className}) => {
  return (
    <NavLink className={cx('NavbarLink', {'NavbarLink--active': active}, className)}
             activeClassName={'NavbarLink--active'}
             to={to}
             onClick={onClick}
             target={target}
             rel="noopener noreferrer"
             exact
    >
      {name}
      {active && <div className={'NavbarLink__border'}/>}
    </NavLink>
  );
}

const selector = createSelector(
  selectCurrentUser,
  selectCurrentUserId,
  selectCurrentOrgId,
  selectCurrentProjectId,
  selectCurrentProject,
  (user, userId, currentOrgId, currentProjectId, currentProject) => {
    return {
      user,
      userId,
      currentOrgId,
      currentProjectId,
      currentProject,
    }
  }
);

export const Navbar = ({}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const {user, userId, currentOrgId, currentProjectId, currentProject}
    = useSelector(state => selector(state));

  const [loading, setLoading] = useState(false);
  const [billState, setBillState] = useState(Bill.PAID);

  const pathName = location.pathname;
  const inDashboard = pathName.includes('/dashboard');
  const isHome = pathName === '/';
  const isShowcase = _.find(ShowcaseURls, url => url === pathName);
  const userIsAdmin = user && user.role === UserRoles.ADMIN


  useEffect(() => {
    if (!_.isNil(currentProjectId) && _.isEmpty(currentProject)) {
      dispatch(Project.actions.fetchProject(currentProjectId));
    }
  }, [currentProjectId]);

  const onSelectProject = () => {
    dispatch(UIState.actions.showModal(ModalTypes.SELECT_PROJECT));
  }

  const onLogout = () => {
    dispatch(UIState.actions.showModal(ModalTypes.LOGOUT, {
      onConfirm: onConfirmLogout
    }))
  }

  const onConfirmLogout = () => {
    dispatch(User.actions.logout())
  }

  const onOpenSettings = () => {
    pushTo(Urls.SETTINGS_PROFILE)
  }

  return (
    <div className={cx(`Navbar`)}>

      <div className={cx(`Navbar__content`)}>
        <Link to={userId ? Urls.DASHBOARD : Urls.HOME}>
          <img src={"/images/edgecloud-logo.svg"}
               alt={'theta-video-api-logo'}
               className={'Navbar__logo'}
          />
        </Link>

        {currentOrgId && !_.isNil(userId) && inDashboard &&
          <OrgAndProjectDropdown />}

        <div className={'Navbar__user-content'}>
          <HeaderLink to={Urls.HOME}
                      name={'Home'}
                      active={pathName === Urls.HOME}/>

          <HeaderLink to={Urls.DASHBOARD}
                      name={'Dashboard'}
                      active={pathName.includes(Urls.DASHBOARD) && pathName !== Urls.SETTINGS_BILLING}/>


          <HeaderLink to={Urls.MARKETING_AI_SHOWCASES}
                      name={'AI Showcase'}
                      active={isShowcase}
          />


          <a href="https://docs.thetatoken.org/docs/theta-edgecloud-overview"
             target="_blank"
             rel="noopener noreferrer"
             className="NavbarLink"
          >Docs</a>

          {
            userIsAdmin &&
            <HeaderLink to={Urls.SETTINGS_BILLING}
                        name={'Billing'}
                        active={pathName === Urls.SETTINGS_BILLING}
            />
          }

          {
            _.isNil(userId) && <>
              <HeaderLink
                className={"login-btn"}
                to={`/login`}
                name={'Log in'}/>

              <div className={"vertical-divider"}/>

              <HeaderLink to={`/sign-up`}
                          className={"signup-btn"}
                          name={'Sign up'}/>
            </>
          }

          {
            !_.isNil(userId) &&
            <DropdownMenu label={"Me"} className={"NavbarLink"}>
              <DropdownMenuItem label={"Account"}
                                className={"NavbarLink NavbarLink--account"}
                                onClick={onOpenSettings}/>

              <DropdownMenuItem label={"Logout"}
                                className={"NavbarLink NavbarLink--logout"}
                                onClick={onLogout}/>
            </DropdownMenu>
          }

        </div>
      </div>
    </div>
  )
}