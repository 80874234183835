import { useHistory } from "react-router";
import Loader from "./Loader";
import { FaArrowLeft } from "react-icons/fa";
import { CheckmarkIcon, CopyIcon } from "./Svg";

const classNames = require('classnames');

export const Button = ({ title, color = 'green', size = 'medium', href, target, onClick, disabled, loading, className, style, role, icon }) => {
  const history = useHistory();

  const onClickLocal = e => {
    if (loading || disabled)
      return;

    if (href) {
      if (target === "_blank") {
        let link = document.createElement("a");
        link.href = href;
        link.target = '_blank'
        link.click();
      } else if (href.indexOf("http") !== -1) {
        window.location = href;
      } else {
        history.push(href);
      }
    }

    onClick?.(e);
  }

  return (
    <button className={classNames('Button', `Button--${color}`, `Button--${size}`, className)}
      style={style}
      onClick={onClickLocal}
      disabled={disabled}
      href={href}
      target={target}
      type={role}
    >
      {icon}
      <span style={{ opacity: (loading ? 0 : 1) }}>{title}</span>
      {
        loading &&
        <Loader size={'small'} />
      }
    </button>
  )
}

export const BackButton = ({ title, href, target, onClick, disabled, className, style }) => {
  const history = useHistory();

  const onClickLocal = e => {
    if (disabled)
      return;

    if (href) {
      if (target === "_blank") {
        let link = document.createElement("a");
        link.href = href;
        link.target = '_blank'
        link.click();
      } else if (href.indexOf("http") !== -1) {
        window.location = href;
      } else {
        history.push(href);
      }
    }

    onClick?.(e);
  }

  return (
    <button className={classNames('BackButton', className)}
      style={style}
      onClick={onClickLocal}
      disabled={disabled}
      href={href}
      target={target}
    >
      <FaArrowLeft />
      <span>{title}</span>
    </button>
  )
}

export const LargeButton = props => <Button {...props} size="l"></Button>;
export const SmallButton = props => <Button {...props} size="s"></Button>;
export const XSmallButton = props => <Button {...props} size="xs"></Button>;
export const LightButton = props => <Button {...props} color="sub"></Button>;