import _ from 'lodash';
import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { Button } from "../../components/Button";
import { useDispatch } from "react-redux";
import User from "../../store/models/User";
import PageTitleBar from "../../components/PageTitleBar";
import { pushTo } from "../../store/history";
import { Urls } from "../../constants";

function RequestResetPasswordForm({ onSubmit, loading, error, loaded }) {
  const { register, handleSubmit, formState } = useForm({
    mode: "onChange"
  });

  const onSubmitLocal = (e) => {
    e.preventDefault();

    handleSubmit(onSubmit)();
  }

  return (
    <form onSubmit={onSubmitLocal}
      className={'Form ResetPasswordForm'}
    >
      <input type="text" placeholder="Email" {...register("email", { required: true, maxLength: 83 })} />

      <div className={'FormError'}>{error}</div>

      {loaded ? <div className='ResetPasswordForm__success'>
        An email has been sent to you with instructions to reset your password.
      </div> : <Button title={'Reset Password'}
        role={'submit'}
        loading={loading}
        className='ResetPasswordForm__button'
        disabled={!formState.isValid}
      />}
    </form>
  );
}

function RequestResetPasswordPage() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = async ({ email }) => {
    setLoading(true);
    try {
      await dispatch(User.actions.requestResetPassword(email));
      setLoaded(true);
      setLoading(false);
      setTimeout(() => pushTo(Urls.DASHBOARD_HOME), 1500);
    } catch (e) {
      setLoading(false);
      setError(e.message);
    } finally {

    }
  };

  return <div className={'ResetPassword'}>
    <div className={'ResetPassword__content'}>
      <PageTitleBar title={'Reset Your Password'} />
      <RequestResetPasswordForm onSubmit={onSubmit}
        loading={loading}
        error={error}
        loaded={loaded}
      />
    </div>
  </div>
}

export default RequestResetPasswordPage;