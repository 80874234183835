import React from 'react';
import cx from 'classnames';


const Overlay = ({ children, className, type, ...props }) => (
  <div className={cx("g-overlay", className, type)} {...props}>
    <div className="overlay-shade"></div>
    { children }
  </div>);
export default Overlay;

