import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import Organization, {selectOrganizations} from "../../store/models/Organization";
import React, {useEffect, useState} from "react";
import UIState from "../../store/UIState";
import {ModalTypes, Urls} from "../../constants";
import _ from "lodash";
import Loader from "../../components/Loader";
import PageTitleBar from "../../components/PageTitleBar";
import {Button} from "../../components/Button";
import classNames from "classnames";
import cx from "classnames";
import {FaChevronRight} from "react-icons/fa";
import AppState from "../../store/AppState";
import {pushTo} from "../../store/history";

const selector = createSelector(
  selectOrganizations,
  (organizations) => (
    {organizations}
  )
)

export const SelectOrgPage = () => {

  const dispatch = useDispatch();
  const {organizations} = useSelector(state => selector(state));

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    fetchOrganizations()
  }, []);

  const fetchOrganizations = async () => {
    setLoading(true);
    setError(null);
    try {
      const orgsResponse = await dispatch(Organization.actions.fetchUserOrganizations());
      if (_.isEmpty(orgsResponse)) {
        // onCreateNewOrg()
      } else if (orgsResponse.length === 1) {
        onSelectOrg(orgsResponse[0])
      }
    } catch (e) {
      setError(e.message)
    }
    setLoading(false);
  }

  const onSelectOrg = async (org = false) => {
    await dispatch(AppState.actions.selectCurrentOrgAndProject(org.id))
    pushTo(Urls.DASHBOARD)
  }

  const onCreateNewOrg = () => {
    dispatch(UIState.actions.showModal(ModalTypes.CREATE_ORG))
  }

  const onLogout = () => {
    dispatch(AppState.actions.logout())
  }

  const createOrgBtn = <Button
    title={"Create organization"}
    onClick={onCreateNewOrg}
    color={"green-outline"}
  />

  return (
    <div className={"ServicePage"}>
      <div className={cx("ServicePage__content")}>
        {loading && <Loader/>}

        {!loading && error && <div className={"ServicePage__Error"}>
          {error}
          <Button onClick={fetchOrganizations}
                  color={"green-outline"}
                  title={"Retry"}/>
        </div>}

        {!loading && !error && <div className={"SelectOrgPage"}>
          <PageTitleBar alignTitleLeft={true} rightContent={createOrgBtn} title={"Select organization"}/>

          {_.isEmpty(organizations)
            ? <>
            <div className={"ServicePage__Empty"}>You don't have any organization.<br />
              Create one to continue or ask your organization's admin to invite you.</div>
              {/*<Button*/}
              {/*  className={"SelectOrgPage__Logout"}*/}
              {/*  onClick={onLogout}*/}
              {/*        color={"red-outline"}*/}
              {/*        title={"Logout"}/>*/}
            </>
            : <div className={"SelectOrgPage__List"}>
              <ul className={'ItemsList'}>
                {organizations.map(org => {
                  return (<li key={org.id}
                              onClick={() => onSelectOrg(org)}
                              className={classNames('ItemsListItem')}>

                    <div className={'ItemsListItem__name'}>
                      {org.name}
                    </div>
                    <div className={'ItemsListItem__url'}>
                      {org.email}
                    </div>
                    <FaChevronRight className={'ItemsListItem__caret'}/>

                  </li>);
                })}
              </ul>
            </div>}

          {/*<Button className={"SelectOrgPage__Skip"}*/}
          {/*        title={"Skip"} color={"grey"}*/}
          {/*        size={"medium"}*/}
          {/*        onClick={() => pushTo(Urls.DASHBOARD)}/>*/}

        </div>
        }
      </div>
    </div>)
}
