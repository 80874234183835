import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../components/Modal";
import { Button } from "../components/Button";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import _ from "lodash";
import Project from "../store/models/Project";
import { toast } from "react-toastify";


export const CreateApiKeyModal = ({ onRequestClose, projectId, setApiKeys }) => {
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    formState
  } = useForm();
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [newApiKey, setNewApiKey] = useState();
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (formState.errors.name) {
      setError('Please enter a name')
    }
  }, [formState]);

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(createApiKey)();
  }

  const createApiKey = async (body) => {
    setLoading(true);
    setError(null);

    try {
      let apiRes = await dispatch(Project.actions.createProjectApiKey(projectId, { name: body.name }));
      setNewApiKey(apiRes.api_key);
      let res = await dispatch(Project.actions.fetchProjectApiKeys(projectId));
      setApiKeys(res);
      toast.success('API Key created')
      // onRequestClose()
      // pushTo(Urls.DASHBOARD)
    } catch (e) {
      console.log('error:', e)
      setError(e.message)
    } finally {
      setLoading(false);
    }
  }

  const handleCopy = async () => {
    await navigator.clipboard.writeText(newApiKey);
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 2000)
    toast.success(`Api key: ${newApiKey} copied.`)
  }

  return (
    <Modal className={cx("CreateApiKeyModal")}
      onRequestClose={onRequestClose}>

      <ModalHeader>
        <ModalTitle>Create New Project API Key</ModalTitle>
      </ModalHeader>

      <ModalContent>

        <div className={cx("NewFormError")}>{error && error}</div>


        {newApiKey ? <div className='EmptyState api_key'>
          <div className='EmptyState__subtitle color-red'>Warning: Back up the api key below on paper and keep it somewhere secret and safe. It will not be shown again.</div>
          <div className='EmptyState__content'>
            <span className='EmptyState__content--key'>{newApiKey}</span>
          </div>
          <ModalActions>
            <Button color={"green-outline"} title={copied ? "Copied" : "Copy"} disabled={copied} onClick={handleCopy} />
          </ModalActions>
        </div> : <form onSubmit={onSubmit} className={"NewForm"}>

          <div className={"NewForm__Row center"}>

            <div className={"NewForm__InputTextWrapper NewForm__InputTextWrapper"}>
              <div className={"NewForm__InputLabel"}>API key name</div>
              <input name="name"
                type={"text"}
                autoFocus={true}
                placeholder={"Enter a name"}
                {...register("name", { required: true })} />
            </div>

          </div>

          <ModalActions>
            <Button color={"green"} role={'submit'} loading={loading} title={"Create New API Key"} />
            <Button color={"transparent"} onClick={onRequestClose} title={"Cancel"} />
          </ModalActions>

        </form>}
      </ModalContent>

    </Modal>
  );
}