import React, { useState, useRef } from "react";
import cx from 'classnames';
import { SlArrowDown } from "react-icons/sl";

export const Accordion = props => {
  const { className, header, body, headerClassName, bodyClassName } = props;
  const bodyRef = useRef();
  const handleOnClick = () => {
    let body = bodyRef.current;
    if (body.style.maxHeight) {
      body.style.maxHeight = null;
      body.style.overflow = 'hidden';
      body.style.border = 'none';
    } else {
      body.style.maxHeight = body.scrollHeight + "px";
      body.style.overflow = 'visible';
      body.style.border = "1px solid #3d4463";
    }
  }
  return (
    <div className={cx("accordion", className)}>
      <div className={cx("accordion__header", headerClassName)} onClick={handleOnClick}>
        {header}
        <span className="accordion__header--arrow">
          <svg height="20" width="20" viewBox="0 0 20 20">
            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" fill="#8A8FB5"></path>
          </svg>
        </span>
      </div>
      <div className={cx("accordion__body", bodyClassName)} ref={bodyRef}>
        <div className={cx("accordion__body--inner")}>
          {body}
        </div>
      </div>
    </div>)
}
export const AccordionHeader = props => {
  const { title, subTitle } = props;
  return <label>
    {title}
    {subTitle && <span>{subTitle}</span>}
  </label>
}
// export default Accordion;