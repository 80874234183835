import _ from 'lodash';
import { authHeaders, get, post, put, videoServiceAuthHeaders } from "../../utils/fetch";
import { BillType, Hosts, RequestState } from "../../constants";


// ===========================
// HELPERS
// ===========================
export const getBillType = (bill) => {
  if (bill.type === BillType.TFUEL) return "TFUEL"
  if (bill.type === BillType.STRIPE_AUTO || bill.type === BillType.AUTO) return "Stripe Auto"
  if (bill.type === BillType.STRIPE_MANUAL) return "Stripe Manual"
  if (bill.type === BillType.STRIPE_CRYPTO) return "Stripe Crypto"
}

export const getBillInvoiceUrl = (bill) => {
  if (bill.type === BillType.TFUEL) return `${Hosts.THETA_EXPLORER_URL}/txs/${bill.tfuel_tx_hash}`
  if ((bill.type === BillType.STRIPE_AUTO || bill.type === BillType.STRIPE_MANUAL || bill.type === BillType.STRIPE_CRYPTO || bill.type === BillType.AUTO) && bill.hosted_invoice_url) return bill.hosted_invoice_url
  return null;
}
// ===========================
// ACTIONS
// ===========================
export const FETCH_BALANCE = 'FETCH_BALANCE';
export const FETCH_AUTO_RECHARGE_SETTING = 'FETCH_AUTO_RECHARGE_SETTING';
export const UPDATE_AUTO_RECHARGE_SETTING = 'UPDATE_AUTO_RECHARGE_SETTING';
export const FETCH_STRIPE_URL = 'FETCH_STRIPE_URL';
export const FETCH_STRIPE_URL_FOR_USDC = 'FETCH_STRIPE_URL_FOR_USDC';
export const PURCHASE_CREDIT = 'PURCHASE_CREDIT';
export const APPLY_COUPON = 'APPLY_COUPON';
export const CANCEL_PLAN = 'CANCEL_PLAN';
export const FETCH_BILL_HISTORY = 'FETCH_BILL_HISTORY';


// ===========================
// SELECTORS
// ===========================
// const pendingBill = 'pendingBill';

export const selectBalanceInfo = (state, orgId) => {
  return state?.models.balance[orgId]
}
export const selectBalance = (state, orgId) => {
  const balanceInfo = state?.models.balance[orgId]
  return balanceInfo?.balance;
}
export const selectAutoRechargeSetting = (state, orgId) => {
  const balanceInfo = state?.models.balance[orgId]
  return balanceInfo?.auto_recharge_enabled;
}
export const selectStripeSub = (state, orgId) => {
  const balanceInfo = state?.models.balance[orgId]
  return balanceInfo?.stripe_subscription;
}
export const selectBills = (state, orgId) => {
  return state?.models.bills[orgId];
}
export const selectTotalBillPages = (state) => {
  return state?.models.totalBillPages;
}
// export const selectAllBills = (state) => {
//   return Object.values(state.models.bills);
// };
// export const selectBill = (state, billID) => {
//   const bill = state.models.bills[billID];
//   if (bill) {
//     return {
//       ...bill
//     };
//   }
//   return null;
// };

// export const selectPendingBill = (state) => {
//   const bills = state.models.bills;
//   if (!_.isNil(bills)) {
//     for (let billID in bills) {
//       let bill = bills[billID];
//       if (bill.state != 'paid') {
//         return bill;
//       }
//     }
//   }
//   return null;
// };

// export const selectTotalBillPages = (state, numPerPage) => {
//   return Math.ceil(state.models.totalBills / numPerPage);
// }
// export const selectTotalBillPages = (state) => {
//   return state.models.totalBillPages;
// }
// ===========================
// MODEL
// ===========================
const Bill = {
  DUE: 'due',
  PENDING: 'pending',
  OVERDUE: 'overdue',
  PAID: 'paid',
  ERROR: 'error',

  actions: {
    fetchBalance: (org_id) => async (dispatch, getState) => {
      let result = await get({
        host: Hosts.EDGE_CLOUD_API,
        url: `/balance`,
        params: { orgID: org_id },
        headers: authHeaders(),
        action: FETCH_BALANCE,
        dispatch,
      });
      return selectBalance(getState(), org_id);
    },
    updateAutoRechargeSetting: (org_id, body) => async (dispatch, getState) => {
      let result = await put({
        host: Hosts.EDGE_CLOUD_API,
        url: `/payment/AutoCharge`,
        params: { orgID: org_id },
        headers: authHeaders(),
        action: UPDATE_AUTO_RECHARGE_SETTING,
        dispatch,
        body: body
      });
      return selectAutoRechargeSetting(getState(), org_id);
    },
    fetchStripeUrl: (org_id, returnURL) => async (dispatch, getState) => {
      let result = await post({
        host: Hosts.EDGE_CLOUD_API,
        url: `/balance/StripePortalURL`,
        body: { orgID: org_id, returnURL },
        headers: authHeaders(),
        action: FETCH_STRIPE_URL,
        dispatch,
      });
      return _.get(result, 'body.balances.0.stripeURL')
    },
    fetchStripeUrlForUsdc: (org_id, returnURL, amount) => async (dispatch, getState) => {
      let result = await post({
        host: Hosts.EDGE_CLOUD_API,
        url: `/payment/StripeCryptoPaymentURL`,
        body: { orgID: org_id, returnURL, topUpType: amount },
        headers: authHeaders(),
        action: FETCH_STRIPE_URL_FOR_USDC,
        dispatch,
      });
      return _.get(result, 'body.url')
    },
    purchaseCredit: (org_id, amount) => async (dispatch, getState) => {
      let result = await post({
        host: Hosts.EDGE_CLOUD_API,
        url: `/payment/StripeTopup`,
        body: { orgID: org_id, topUpType: amount },
        headers: authHeaders(),
        action: PURCHASE_CREDIT,
        dispatch,
      });
      return;
    },
    applyCoupon: (org_id, couponId) => async (dispatch, getState) => {
      let result = await post({
        host: Hosts.EDGE_CLOUD_API,
        url: `/coupon/applyCoupon`,
        body: { orgID: org_id, couponID: couponId, forced: "FALSE" },
        headers: authHeaders(),
        action: APPLY_COUPON,
        dispatch,
      });
      return;
    },
    cancelPlan: (org_id) => async (dispatch, getState) => {
      let result = await post({
        host: Hosts.EDGE_CLOUD_API,
        url: `/balance/CancelPlan`,
        body: { orgID: org_id },
        headers: authHeaders(),
        action: CANCEL_PLAN,
        dispatch,
      });
      return;
    },
    fetchBillHistory: (org_id, page = 1, number = 20) => async (dispatch, getState) => {
      let result = await get({
        host: Hosts.EDGE_CLOUD_API,
        url: `/payment/topUpHistory`,
        params: { orgID: org_id, page, number },
        headers: authHeaders(),
        action: FETCH_BILL_HISTORY,
        dispatch,
      });
      return;
    },
  },
  spec: {
    bills: {},
    totalBillPages: 0,
    balance: {},
    autoRecharge: {}
  },
  modelReducer: (state, type, body, action) => {
    if (action.url && action.result !== RequestState.SUCCESS)
      return state;
    if (type === FETCH_BALANCE) {
      return {
        ...state,
        balance: _.keyBy(body.balances, 'org_id')
      }
    }

    if (type === UPDATE_AUTO_RECHARGE_SETTING) {
      return {
        ...state,
        balance: _.keyBy(body.balances, 'org_id')
      }
    }
    if (type === FETCH_BILL_HISTORY) {
      return {
        ...state,
        bills: {
          [_.get(action, 'params.orgID')]: _.get(body, 'topups')
        },
        totalBillPages: _.get(body, "pagination.pages")
      }
    }

    if (type === FETCH_STRIPE_URL) {
      return {
        ...state,
      }
    }

    if (type === PURCHASE_CREDIT) {
      return {
        ...state
      }
    }
    if (type === APPLY_COUPON) {
      return {
        ...state
      }
    }
    if (type === CANCEL_PLAN) {
      return {
        ...state
      }
    }
    return state;
  }
}
export default Bill;
