import React, {useEffect} from 'react';

function NotFoundPage() {

  useEffect(() => {
    (async () => {
    })();
  }, []);

  return (<div className={'ServiceContainer'}>
      <div className={'ServicePage'}>
        <div className={'ServicePage__content'}>
          <div className={'NotFoundPage'}>
            <div className={"NotFoundPage__title"}>Page not found</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFoundPage;
