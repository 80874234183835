import { get, videoServiceAuthHeaders } from "../../utils/fetch";
import { Hosts, LeftBarNames, RequestState } from "../../constants";


// ===========================
// HELPERS
// ===========================
function createRandomArr(range = 1) {
  let arr = [];
  for (let i = 0; i < 12; i++) {
    arr.push({
      "score": Math.ceil((Math.random() * 40 + 60) * range),
      "date": "2022-07-20",
      "hour": i
    })
  }
  return arr;
}

function getAverageScore(arr) {
  return Math.ceil(arr.reduce((sum, o) => o.score + sum, 0) / arr.length)
}

const views_arr = createRandomArr(5);
const viewer_experience_arr = createRandomArr();
const playback_success_arr = createRandomArr();
const startup_time_arr = createRandomArr();
const smoothness_arr = createRandomArr();
const video_quality_arr = createRandomArr();
const url_hits_arr = createRandomArr();
const url_volume_arr = createRandomArr();
// ===========================
// ACTIONS
// ===========================
export const FETCH_METRICS = 'FETCH_METRICS';
export const FETCH_METRIC = 'FETCH_METRIC';

// ===========================
// SELECTORS
// ===========================

export const selectMetric = (state, tvaID, filters) => {
  const data = state.models.metrics[tvaID || 'sum']
  if (data) {
    return {
      ...data
    };
  }

  return null;
};

// ===========================
// MODEL
// ===========================
const Metric = {
  actions: {
    fetchMetricsServiceAccountID: (serviceAccountID) => async (dispatch, getState) => {
      // console.log(`jlog162 url is ${Hosts.METRICS_API}`);
      let result1 = await get({
        host: Hosts.METRICS_API,
        url: `/query/akamaiHits`,
        params: { service_account_id: serviceAccountID, date_offset: 5 },
        headers: videoServiceAuthHeaders(getState(), serviceAccountID),
        action: FETCH_METRIC,
        dispatch,
      });
      let result2 = await get({
        host: Hosts.METRICS_API,
        url: `/query/akamaiVolume`,
        params: { service_account_id: serviceAccountID, date_offset: 5 },
        headers: videoServiceAuthHeaders(getState(), serviceAccountID),
        action: FETCH_METRIC,
        dispatch,
      });
      return selectMetric(getState(), serviceAccountID);
    },
    fetchMetricsTVAID: (tvaID, cardName, startDate, endDate) => async (dispatch, getState) => {
      if (cardName == LeftBarNames.url_viewers) {
        await get({
          host: Hosts.METRICS_API,
          url: `/query/URLViewer`,
          params: { tvaID: tvaID, start_date: startDate, end_date: endDate },
          headers: videoServiceAuthHeaders(getState(), tvaID),
          action: FETCH_METRIC,
          dispatch,
        });
      }
      if (cardName == LeftBarNames.akamai_volume) {
        await get({
          host: Hosts.METRICS_API,
          url: `/query/akamaiVolume`,
          params: { tvaID: tvaID, start_date: startDate, end_date: endDate },
          headers: videoServiceAuthHeaders(getState(), tvaID),
          action: FETCH_METRIC,
          dispatch,
        });
      }
      if (cardName == LeftBarNames.akamai_hits) {
        await get({
          host: Hosts.METRICS_API,
          url: `/query/akamaiHits`,
          params: { tvaID: tvaID, start_date: startDate, end_date: endDate },
          headers: videoServiceAuthHeaders(getState(), tvaID),
          action: FETCH_METRIC,
          dispatch,
        });
      }
      return selectMetric(getState(), tvaID);
    },
  },
  spec: {
    metrics: {
      'sum': {},
    },
  },
  modelReducer: (state, type, body, action) => {
    if (action.url && action.result !== RequestState.SUCCESS)
      return state;

    if (type === FETCH_METRIC || type == FETCH_METRICS) {
      // console.log(`jlog131 type ${type} body ${JSON.stringify(body)}`);
      // console.log(`jlog134 current metrics ${JSON.stringify(state.metrics)}`);
      let newState = {
        ...state,
        metrics: {
          ...state.metrics,
        }
      }
      for (let metric of body) {
        for (let id in metric) {

          // console.log(`jlog135 current metric id ${id} content ${JSON.stringify(metric[id])}`);
          newState.metrics.sum[`${id}`] = metric[id].sort((a, b) => new Date(a.date) - new Date(b.date));
          for (let item in metric[id]) {
            // console.log(`jlog137 ${JSON.stringify(item)}`);
          }
        }
      }
      // console.log(`jlog136 new state ${JSON.stringify(newState.metrics)}`);
      return newState;
    }

    return state;
  }
}
export default Metric;
