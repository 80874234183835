import React from 'react'
import { connect } from 'react-redux'
import ReactModal from 'react-modal';
import _ from 'lodash';

import { ModalTypes } from '../constants';
import UIState from '../store/UIState';
import { CreateProjectModal } from "../modals/CreateProjectModal";
import { CreateNotebookModal } from "../modals/CreateNotebookModal";
import { CreateDeploymentModal } from '../modals/CreateDeployment';
import { CreateOrgModal } from "../modals/CreateOrgModal";
import { SelectProjectModal } from "../modals/SelectProjectModal";
import { CreateCustomImageModal } from '../modals/CreateCustomImage';
import { DeleteModal } from '../modals/DeleteModal';
import ConfirmModal from "../modals/ConfirmModal";
import { InviteUserModal } from "../modals/InviteUserModal";
import { InviteTeamMembersToProjectModal } from "../modals/InviteTeamMembersToProjectModal";
import { EditProfileModal } from "../modals/EditProfileModal";
import { ChangePasswordModal } from "../modals/ChangePasswordModal";
import { AutoRechargeModal } from '../modals/AutoRechargeModal';
import { IncreaseQuotaModal } from "../modals/IncreaseQuotaModal";
import TwoFAModal from "../modals/TwoFAModal";
import { EditProjectModal } from "../modals/EditProjectModal";
import { EditOrgModal } from "../modals/EditOrgModal";
import { VerifyPhoneNumberModal } from "../modals/VerifyPhoneNumberModal";
import { ChangePhoneNumberModal } from "../modals/ChangePhoneNumberModal";
import LoginModal from '../modals/LoginModal';
import { LogoutModal } from "../modals/LogoutModal";
import AddCreditModal from '../modals/AddCreditModal';
import SignUpModal from '../modals/SignUpModal';
import { ShowcaseTextToImageDiscoverModal } from "../modals/ShowcaseTextToImageDiscoverModal";
import { CreateApiKeyModal } from '../modals/CreateApiKeyModal';
import { ManageGPUNodeModal } from '../modals/ManageGPUNodeModal';
import { CreateRAGChatbotModal } from '../modals/CreateRAGChatbotModal';
import { StorageModal } from '../modals/ManageStorageModal';
import { CreateLLMEndpointModal } from '../modals/CreateLLMEndpointModal';
import { UpdateLLMEndpointModal } from '../modals/UpdateLLMEndpointModal';
import { DepositTfuelModal } from '../modals/DepositTfuel';
import { AddUsdcCreditModal } from '../modals/AddUsdcCredit';

const ModalComponentByType = {
  [ModalTypes.CREATE_PROJECT]: CreateProjectModal,
  [ModalTypes.CREATE_NOTEBOOK]: CreateNotebookModal,
  [ModalTypes.CREATE_DEPLOYMENT]: CreateDeploymentModal,
  [ModalTypes.CREATE_CUSTOM_TEMPLATE]: CreateCustomImageModal,
  [ModalTypes.CREATE_ORG]: CreateOrgModal,
  [ModalTypes.SELECT_PROJECT]: SelectProjectModal,
  [ModalTypes.DELETE]: DeleteModal,
  [ModalTypes.CONFIRM]: ConfirmModal,
  [ModalTypes.INVITE_USER]: InviteUserModal,
  [ModalTypes.INVITE_TEAM_MEMBERS_TO_PROJECT]: InviteTeamMembersToProjectModal,
  [ModalTypes.EDIT_PROFILE]: EditProfileModal,
  [ModalTypes.EDIT_PROJECT]: EditProjectModal,
  [ModalTypes.EDIT_ORG]: EditOrgModal,
  [ModalTypes.CHANGE_PASSWORD]: ChangePasswordModal,
  [ModalTypes.AUTO_RECHARGE]: AutoRechargeModal,
  [ModalTypes.INCREASE_QUOTA]: IncreaseQuotaModal,
  [ModalTypes.TWO_FA]: TwoFAModal,
  [ModalTypes.VERIFY_PHONE_NUMBER]: VerifyPhoneNumberModal,
  [ModalTypes.CHANGE_PHONE_NUMBER]: ChangePhoneNumberModal,
  [ModalTypes.LOGIN]: LoginModal,
  [ModalTypes.SIGNUP]: SignUpModal,
  [ModalTypes.LOGOUT]: LogoutModal,
  [ModalTypes.ADD_CREDIT]: AddCreditModal,
  [ModalTypes.SHOWCASE_TEXT_TO_IMAGE_DISCOVER]: ShowcaseTextToImageDiscoverModal,
  [ModalTypes.CREATE_API_KEY]: CreateApiKeyModal,
  [ModalTypes.MANAGE_GPU_NODE]: ManageGPUNodeModal,
  [ModalTypes.CREATE_RAG_CHATBOT]: CreateRAGChatbotModal,
  [ModalTypes.MANAGE_STORAGE]: StorageModal,
  [ModalTypes.CREATE_LLM_ENDPOINT]: CreateLLMEndpointModal,
  [ModalTypes.UPDATE_LLM_ENDPOINT]: UpdateLLMEndpointModal,
  [ModalTypes.DEPOSIT_TFUEL]: DepositTfuelModal,
  [ModalTypes.ADD_USDC_CREDIT]: AddUsdcCreditModal,
};


// ===========================
// STORE
// ===========================
const mapStateToProps = state => ({
  modals: state.ui.modals
});

const actions = {
  hideModal: UIState.actions.hideModal
};
const withConnect = connect(mapStateToProps, actions);


// ===========================
// COMPONENT
// ===========================
class ModalContainer extends React.Component {
  onClick = () => this.props.onRequestClose()

  render() {
    let { modal, type, onRequestClose } = this.props;
    let ModalComponent = modal?.component;
    let onClose = () => {
      onRequestClose();
      modal.props?.onClose?.();
    }

    if (!ModalComponent)
      return null;

    return (
      <ReactModal
        isOpen={modal !== null}
        onRequestClose={onClose}
        ariaHideApp={false}
        overlayClassName="modal-overlay"
        className="modal-container">
        {ModalComponent &&
          <ModalComponent
            onRequestClose={onClose}
            modalType={modal?.type}
            {...modal?.props} />}
      </ReactModal>)
  }
}

class Modals extends React.Component {
  closeModal = type => this.props.hideModal?.(type)

  getModal(idx) {
    let modalData = _.get(this.props.modals, [idx], null);
    if (modalData) {
      modalData = {
        ...modalData,
        component: ModalComponentByType[modalData.type]
      }
    }
    return modalData;
  }

  render() {
    let modal1 = this.getModal(0);
    let modal2 = this.getModal(1);
    let modal3 = this.getModal(2);
    return <>
      <ModalContainer
        modal={modal1}
        onRequestClose={() => this.closeModal(modal1?.type)} />
      <ModalContainer
        modal={modal2}
        onRequestClose={() => this.closeModal(modal2?.type)} />
      <ModalContainer
        modal={modal3}
        onRequestClose={() => this.closeModal(modal3?.type)} />
    </>
  }
}

export default withConnect(Modals)
