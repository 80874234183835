import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {Button} from "../components/Button";
import {useDispatch, useSelector} from "react-redux";
import User, {selectCurrentUser, selectCurrentUserId} from "../store/models/User";
import {useIsMountedRef} from '../hooks/useIsMounted';
import {createSelector} from "reselect";
import cx from "classnames";
import Modal, {ModalActions, ModalContent, ModalHeader, ModalTitle} from "../components/Modal";
import {toast} from "react-toastify";


const selector = createSelector(
  selectCurrentUserId,
  (currentUserId) => (
    {currentUserId}
  )
);

export const ChangePasswordModal = ({onRequestClose}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const isMountedRef = useIsMountedRef();
  const {currentUserId} = useSelector(state => selector(state));
  const {register, handleSubmit, watch, setValue, formState: {errors}, getValues} = useForm();

  const onSubmit = async () => {
    const {oldPassword, newPassword, confirmNewPassword} = getValues()
    setLoading(true);

    if (newPassword !== confirmNewPassword) {
      setError('Passwords do not match');
      setLoading(false);
      return;
    }

    try {
      await dispatch(User.actions.changePassword(currentUserId, oldPassword, newPassword));
      toast.success('Your password has been updated');
      onRequestClose();
    } catch (e) {
      if (!isMountedRef.current) return;
      setError(e.message);
    } finally {
      if (!isMountedRef.current) return;
      setLoading(false);
    }
  };

  return (
    <Modal className={cx("ChangePasswordModal")}
           onRequestClose={onRequestClose}>

      <ModalHeader>
        <ModalTitle>Change my password</ModalTitle>
      </ModalHeader>

      <ModalContent>
        <div className={cx("NewFormError")}>{error && error}</div>

        <div className={"NewForm"}>

          <div className={"NewForm__Row NewForm__Row"}>

            <div className={"NewForm__InputTextWrapper NewForm__InputTextWrapper--half"}>
              <div className={"NewForm__InputLabel"}>Current password</div>
              <input type={"password"}
                     placeholder={"Current password"}
                     {...register("oldPassword", {required: true, maxLength: 1000})} />
            </div>
          </div>

          <div className={"NewForm__Row NewForm__Row--last"}>

            <div className={"NewForm__InputTextWrapper"}>
              <div className={"NewForm__InputLabel"}>New password</div>
              <input type={"password"}
                     placeholder={"New password"}
                     {...register("newPassword", {required: true, maxLength: 1000})} />
            </div>
            <div className={"NewForm__InputTextWrapper"}>
              <div className={"NewForm__InputLabel"}>Confirm new password</div>
              <input type={"password"}
                     placeholder={"Confirm new password"}
                     {...register("confirmNewPassword", {
                       required: true,
                       maxLength: 1000
                     })} />
            </div>
          </div>

          <ModalActions>
            <Button color={"green"} onClick={onSubmit} loading={loading} title={"Update"}/>
            <Button color={"transparent"} onClick={onRequestClose} title={"Cancel"}/>
          </ModalActions>

        </div>

      </ModalContent>

    </Modal>
  );
}