import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import PageTitleBar from "../../../components/PageTitleBar";
import { Button } from "../../../components/Button";
import { selectCurrentUser, selectCurrentUserId } from "../../../store/models/User";
import { ModalTypes, UserRoleIds, Urls, UserRoleIds as UserRoles } from "../../../constants";
import UIState from "../../../store/UIState";
import Organization, {
  selectCurrentOrgId,
  selectOrganizations,
  selectOrgQuota
} from "../../../store/models/Organization";
import { Tab, Tabs } from "../../../components/Tabs";
import Tooltip from "../../../components/Tooltip";
import { getHardwareDetail, getVmResourcesString } from "../../../utils";
import { replaceTo } from "../../../store/history";
import _ from "lodash";


export const SupportPage = () => {

  const dispatch = useDispatch();

  const userId = useSelector(state => selectCurrentUserId(state));
  const orgId = useSelector(state => selectCurrentOrgId(state));

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
  }, []);

  const onSendEmail = () => {
    window.open("mailto:support@thetaedgecloud.com" +
      "?subject=Support%20Ticket" +
      "&body=" +
      "%0A%0A%0A" +
      "User%20ID:%20" + userId +
      "%0A" +
      "Organization%20ID:%20" + orgId +
      "%0A%0A");
  }


  if (error)
    return (<div className={"ServicePage__Error"}>
      {error}
      {/* <Button onClick={fetchOrgQuota()}
        color={"green-outline"}
        title={"Retry"} /> */}
    </div>)

  return (<div className={"SupportPage"}>
    <PageTitleBar alignTitleLeft={true} title={"Support"} />

    <div className="SupportPage__content">
      <div className="SupportPage__content--title">
        Please email us if you have any questions or feedback.
      </div>
      <Button color={"green"} title={"Contact Support"} onClick={onSendEmail} />
    </div>
  </div>
  )
}
