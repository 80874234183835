import {toNumber} from './index';
import {DevEnv} from "../constants";

export const USER_ID = `${DevEnv}__USER_ID`;
export const USER_TOKEN = `${DevEnv}__USER_TOKEN`
export const ORG_ID = `${DevEnv}__ORG_ID`
export const PROJECT_ID = `${DevEnv}__PROJECT_ID`

const Storage = {
  getItem: key => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem(key)
    }
  },
  getItemBool: key => localStorage.getItem(key)
    ? localStorage.getItem(key) === 'true'
    : null,
  getItemNumber: key => localStorage.getItem(key)
    ? toNumber(localStorage.getItem(key))
    : null,
  setItem: (key, value) => {
    localStorage.setItem(key, value)
  },
  clearItem: (key, value) => localStorage.removeItem(key),
  clearUserAuth: () => {
    localStorage.removeItem(USER_ID);
    localStorage.removeItem(USER_TOKEN);
  },
  getUserAuth: () => {
    return {
      USER_ID: Storage.getItem(USER_ID),
      USER_TOKEN: Storage.getItem(USER_TOKEN),
    }
  },

  getUserToken: () => {
    return Storage.getItem(USER_TOKEN)
  },
  setUserToken: (value) => Storage.setItem(USER_TOKEN, value),

  getUserId: () => {
    return Storage.getItem(USER_ID);
  },
  setUserId: (value) => Storage.setItem(USER_ID, value),
  getOrgId: () => {
    return Storage.getItem(ORG_ID);
  },
  setOrgId: (value) => Storage.setItem(ORG_ID, value),
  getProjectId: () => {
    return Storage.getItem(PROJECT_ID);
  },
  setProjectId: (value) => Storage.setItem(PROJECT_ID, value),
  clearUserData: () => {
    localStorage.removeItem(USER_ID);
    localStorage.removeItem(USER_TOKEN);
    localStorage.removeItem(ORG_ID);
    localStorage.removeItem(PROJECT_ID);
  },
}
export default Storage;
