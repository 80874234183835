import React from "react";
import classNames from "classnames";

export default function Loader({size = 'medium', color = 'white', style}) {
  return (
    <div className={classNames('Loader', 'Loader--center', `Loader--${size}`, `Loader--${color}`)}
         style={style}
    />
  );
}

