import React, { useState, useRef } from 'react';
import cx from 'classnames';
import { useSelector, useDispatch } from "react-redux";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../components/Modal";
import { Button } from "../components/Button";
import Select from 'react-select'
import Ai, { selectAllVMs, selectNotebookTemplate } from '../store/models/Ai';
import { getVMOptions, formatPrice, getErrorMsg, generateRandomIdWithPrefix } from '../utils';
import { selectCurrentProjectId } from '../store/models/Project';
import { ModalTypes } from '../constants';
import { selectCurrentUser, selectCurrentUserId } from '../store/models/User';
import UIState from '../store/UIState';
import { toast } from "react-toastify";
import { selectCurrentOrgId } from '../store/models/Organization';
import Tooltip from '../components/Tooltip';
import { QuestionMarkIcon } from '../components/Svg';
import CustomSelect from '../components/CustomSelect';

const selectStyles = {
  container: (styles, { isDisabled }) => ({
    ...styles,
    flex: 1,
    height: 40,
    cursor: isDisabled ? 'not-allowed' : 'default',
  }),
  control: (styles, { isDisabled }) => ({
    ...styles,
    paddingLeft: 15,
    backgroundColor: '#191D29',
    borderColor: '#3D4463',
    borderRadius: 6,
    ':hover': {
      borderColor: 'white',
    },
    '.selected': {
      borderColor: 'white',
    },
  }),
  menu: (styles, state) => ({
    ...styles,
    border: '1px solid #3D4463',
    backgroundColor: '#191D29',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#191D29',
      borderColor: '#3D4463',
      color: isDisabled ? '#636B91' : (isFocused ? 'white' : (isSelected ? 'white' : '#636B91')),
      opacity: isDisabled ? 0.5 : 1,
    };
  },
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: '#8A8FB5',
  }),
  indicatorsContainer: (styles, { isDisabled }) => ({
    ...styles,
    display: isDisabled ? 'none' : 'flex',
  }),
  indicatorSeparator: (styles, state) => ({
    ...styles,
    color: '#636B91',
    backgroundColor: '#636B91',
    display: 'none'
  }),
  input: styles => ({ ...styles }),
  placeholder: styles => ({ ...styles, color: '#636B91' }),
  singleValue: (styles, { data }) => ({ ...styles, color: '#636B91', fontWeight: '500' }),
};

export const CreateNotebookModal = ({ onRequestClose }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const userId = useSelector(state => selectCurrentUserId(state));
  const curUser = useSelector(state => selectCurrentUser(state));
  const orgId = useSelector(state => selectCurrentOrgId(state));
  const projectId = useSelector(state => selectCurrentProjectId(state));
  const notebookTemplate = useSelector(state => selectNotebookTemplate(state));
  const imagesOptions = notebookTemplate ? notebookTemplate.container_images.map(img => ({ label: img, value: img })) : [];
  const VMs = useSelector(state => selectAllVMs(state));
  let [suggestedVM, setSuggestedVM] = useState(_.get(notebookTemplate, 'suggested_vms.0'));
  let [vmOptions, setVmOptions] = useState(getVMOptions(VMs, suggestedVM));
  let defaultVM = vmOptions.filter(o => o.value === suggestedVM)[0] || vmOptions[0];
  let [selectedVMOption, setSelectedVMOption] = useState(defaultVM);
  let [price, setPrice] = useState(formatPrice(VMs[suggestedVM || vmOptions[0].value].price_hour / 100));
  const defaultName = generateRandomIdWithPrefix('notebook', 20);

  const nameRef = useRef();
  const dnameRef = useRef();
  const imageRef = useRef();
  const portRef = useRef();
  const vmRef = useRef();
  const pwdRef = useRef();
  const authUserRef = useRef();
  const authPwdRef = useRef();

  const [enablePwd, setEnablePwd] = useState(false);
  const [enableAuth, setEnableAuth] = useState(false);

  const onSubmit = async () => {
    if (_.isNil(userId)) {
      dispatch(UIState.actions.showModal(ModalTypes.SIGNUP))
      return;
    }
    let name = nameRef.current.value;
    let image = imageRef.current.state.selectValue[0].value;
    let port = portRef.current.value;
    let vm = vmRef.current.state.selectValue[0].value;
    let pwd = enablePwd ? pwdRef.current.value : "";
    let dname = dnameRef.current.value;
    let authUser = enableAuth ? authUserRef.current.value : "";
    let authPwd = enableAuth ? authPwdRef.current.value : "";
    // console.log('name:', name, ' image:', image)
    // console.log('port:', port, ' vm:', vm)
    // console.log('projectId:', projectId)
    // console.log('pwd:', pwd)
    const imageId = notebookTemplate.id;
    const tags = notebookTemplate.tags;
    if (name.length) {
      if (!/^[A-Za-z0-9]+$/.test(name)) {
        const errorStr = 'Invalid ID. The ID should only contain letters and numbers.'
        setError(errorStr);
        toast.error('Error: ' + errorStr);
        return;
      }
      if (name.length > 20) {
        const errorStr = 'Invalid ID. The length of the ID should be less than or equal to 20.'
        setError(errorStr);
        toast.error('Error: ' + errorStr)
        return;
      }
    }
    setLoading(true);
    setError(null);
    try {
      const deployment = await dispatch(Ai.actions.createDeployment({
        name: name,
        project_id: projectId,
        deployment_image_id: imageId,
        container_image: image,
        min_replicas: 1,
        max_replicas: 1,
        vm_id: vm,
        password: pwd,
        annotations: {
          tags: JSON.stringify(tags),
          nickname: dname
        },
        auth_username: authUser,
        auth_password: authPwd
      }))
      // console.log('deployment:', deployment);
      await dispatch(Ai.actions.fetchJupyterNotebooks(projectId));
      onRequestClose();
    } catch (e) {
      setLoading(false);
      let error = getErrorMsg(e.message, onRequestClose, curUser, orgId, vm)
      setError(error);
      if (error === e.message) {
        toast.error(e.message)
      }
    }
  }

  const onVMChange = (v) => {
    setPrice(formatPrice(VMs[v.value].price_hour / 100));
    setSelectedVMOption(v)
  }

  const onImageChange = (v) => {
    const index = notebookTemplate.container_images.findIndex(img => img === v.value);
    let newSuggestedVM = _.get(notebookTemplate, `suggested_vms.${index}`)
    let newOptions = getVMOptions(VMs, newSuggestedVM)
    // console.log(index, v.value, newOptions)

    setVmOptions(newOptions);
    setSelectedVMOption(newOptions.filter(o => o.value === newSuggestedVM)[0] || newOptions[0]);
    setPrice(formatPrice(VMs[suggestedVM || newOptions[0].value].price_hour / 100));
    setSuggestedVM(newSuggestedVM);
  }

  return (
    <Modal className={cx("CreateNotebookModal")}
      onRequestClose={onRequestClose}>

      <ModalHeader>
        <ModalTitle align={'row'}>
          Create New Notebook
          <Tooltip tooltip={'Open documentation'}>
            <a href="https://docs.thetatoken.org/docs/ai-prototyping-with-jupyter-notebook" target='_blank'>
              <QuestionMarkIcon />
            </a>
          </Tooltip>
        </ModalTitle>
      </ModalHeader>

      <ModalContent>
        <div className='CreateNotebookModal__row'>
          <div className='CreateNotebookModal__select-wrap name'>
            <div className='CreateNotebookModal__select-label'>Notebook ID</div>
            <input className={"CreateNotebookModal__input"}
              placeholder={'Enter new notebook ID'}
              ref={nameRef}
              defaultValue={defaultName}
              disabled={true}
            />
          </div>
          <div className='CreateNotebookModal__select-wrap name'>
            <div className='CreateNotebookModal__select-label'>Notebook Name&nbsp;<div className='text-optional'>(optional)</div></div>
            <input className={"CreateNotebookModal__input"}
              placeholder={'Enter new notebook name'}
              ref={dnameRef}
            />
          </div>
        </div>
        <div className='CreateNotebookModal__row'>
          <div className='CreateNotebookModal__select-wrap image'>
            <div className='CreateNotebookModal__select-label'>Notebook Image</div>
            <Select options={imagesOptions}
              className={"CreateNotebookModal__selector"}
              defaultValue={imagesOptions[0]}
              styles={selectStyles}
              placeholder={'Select Notebook Image'}
              isDisabled={imagesOptions.length < 2}
              onChange={onImageChange}
              ref={imageRef}
            />
          </div>
        </div>
        <div className='CreateNotebookModal__row'>
          <div className='CreateNotebookModal__select-wrap port'>
            <div className='CreateNotebookModal__select-label'>Container Port</div>
            <input className={"CreateNotebookModal__input"}
              placeholder={'Enter new notebook container port'}
              defaultValue={notebookTemplate.container_port}
              ref={portRef}
            />
          </div>
          <div className='CreateNotebookModal__select-wrap pwd'>
            <div className='CreateNotebookModal__select-label'>Notebook password</div>
            <div className='CreateNotebookModal__select-content'>
              <label className='CreateNotebookModal__switch'>
                <input className='CreateNotebookModal__switch-input' type="checkbox" value={enablePwd} onClick={() => setEnablePwd(v => !v)} />
                <span className="CreateNotebookModal__switch-slider"></span>
              </label>
              <input className={"CreateNotebookModal__input pwd"}
                placeholder={enablePwd ? 'Enter notebook password' : 'Default password is empty'}
                disabled={!enablePwd}
                ref={pwdRef}
                type='password'
              />
            </div>
          </div>
        </div>
        {/* <div className='CreateNotebookModal__row'>
          <div className='CreateNotebookModal__select-wrap auth'>
            <div className='CreateNotebookModal__select-label'>Authentication&nbsp;<div className='text-optional'>(optional)</div></div>
            <div className='CreateNotebookModal__select-content'>
              <label className='CreateNotebookModal__switch'>
                <input className='CreateNotebookModal__switch-input' type="checkbox" value={enableAuth} onClick={() => setEnableAuth(v => !v)} />
                <span className="CreateNotebookModal__switch-slider"></span>
              </label>
              <input className={"CreateNotebookModal__input auth"}
                placeholder={enableAuth ? 'Enter authentication username' : 'Authentication disabled'}
                disabled={!enableAuth}
                ref={authUserRef}
                type='text'
              />
              <input className={"CreateNotebookModal__input auth"}
                placeholder={enableAuth ? 'Enter authentication password' : 'Authentication disabled'}
                disabled={!enableAuth}
                ref={authPwdRef}
                type='password'
              />
            </div>
          </div>
        </div> */}
        {/* <div className='CreateNotebookModal__row'>
          <div className='CreateNotebookModal__select-wrap cpu'>
            <div className='CreateNotebookModal__select-label'>CPU</div>
            <Select options={NotebookImages}
              className={"CreateNotebookModal__selector"}
              value={{}}
              styles={selectStyles}
              placeholder={'Select CPU'}
            />
          </div>
          <div className='CreateNotebookModal__select-wrap ram'>
            <div className='CreateNotebookModal__select-label'>RAM</div>
            <Select options={NotebookImages}
              className={"CreateNotebookModal__selector"}
              value={{}}
              styles={selectStyles}
              placeholder={'Select RAM'}
            />
          </div>
          <div className='CreateNotebookModal__select-wrap disk-size'>
            <div className='CreateNotebookModal__select-label'>Disk Size</div>
            <Select options={NotebookImages}
              className={"CreateNotebookModal__selector"}
              value={{}}
              styles={selectStyles}
              placeholder={'Select Disk Size'}
            />
          </div>
          <div className='CreateNotebookModal__select-wrap gpu-model'>
            <div className='CreateNotebookModal__select-label'>GPU Model</div>
            <Select options={NotebookGPUModels}
              className={"CreateNotebookModal__selector"}
              value={{
                label: 'NVidia A100',
                value: 'NVidia A100'
              }}
              styles={selectStyles}
              placeholder={'Select GPU Model'}
            />
          </div>
          <div className='CreateNotebookModal__select-wrap gpu-count'>
            <div className='CreateNotebookModal__select-label'>GPU Count</div>
            <Select options={NotebookGPUCounts}
              className={"CreateNotebookModal__selector"}
              value={{
                label: '4',
                value: '4'
              }}
              styles={selectStyles}
              placeholder={'Select GPU Count'}
            />
          </div>
        </div> */}
        <div className='CreateNotebookModal__row'>
          <div className='CreateNotebookModal__select-wrap vms'>
            <div className='CreateNotebookModal__select-label'>Machine type</div>
            <CustomSelect options={vmOptions}
              className={"CreateNotebookModal__selector"}
              value={selectedVMOption}
              styles={selectStyles}
              placeholder={'Select Machine type'}
              isDisabled={vmOptions.length < 2}
              onChange={onVMChange}
              selectRef={vmRef}
              isOptionDisabled={(option) => {
                return option.price < Number(VMs[suggestedVM || vmOptions[0].value].price_hour)
              }}
            />
          </div>
          {/* <div className='CreateNotebookModal__select-wrap duration'>
            <div className='CreateNotebookModal__select-label'>Reservation Duration</div>
            <Select options={NotebookDurationOptions}
              className={"CreateNotebookModal__selector"}
              value={NotebookDurationOptions[0]}
              styles={selectStyles}
              placeholder={'Select Notebook Image'}
            />
          </div> */}
        </div>
        <div className='CreateNotebookModal__price-info'>
          <div className='CreateNotebookModal__price-info--note'>
            Cost Estimation
          </div>
          <div className='CreateNotebookModal__price-info--price'>
            ~ ${price} per hour.
          </div>
        </div>
        {error && <div className={cx("CreateNotebookModal__error")}>{error}</div>}
      </ModalContent>

      <ModalActions>
        <Button color={"green"} onClick={onSubmit} title={"Create New Notebook"} loading={loading} />
        <Button color={"transparent"} onClick={onRequestClose} title={"Cancel"} />
      </ModalActions>

    </Modal>
  );
}