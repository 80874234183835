export const SDXL = {
  "name": "stable-diffusion-xl-turbo",
  "params": [
    {
      label: "Strength",
      name: "strength",
      desc: "Level of noise added to the initial image",
      min: 0.0,
      max: 1.0,
      step: 0.1,
      default: 0.8,
    },
    {
      label: "Guidance Scale",
      name: "guidanceScale",
      desc: "How closely your prompt should be followed. Use a lower value for more creativity, and a higher value for more precision.",
      min: 0.0,
      max: 20.0,
      step: 0.1,
      default: 7,
    },
    {
      label: "Steps",
      name: "steps",
      desc: "The number of steps to take in the diffusion process. More steps will generally produce better results but will be slower.",
      min: 1,
      max: 100,
      step: 1,
      default: 30,
    },
    {
      label: "Seed",
      name: "seed",
      desc: "The seed to use for the random number generator. Changing this will produce different results.",
      min: 0,
      max: 12013012031030,
      step: 1,
      default: 4775002255836,
      long: true,
    }
  ],
  messages: [
    {
      text: "multiple jellyfish floating in a colorful underwater world, depth of field",
      params: {
        "strength": 0.8,
        "guidanceScale": 7,
        "steps": 30,
        "seed": 4775002255836
      },
      sender: 'user'
    },
    {
      imageData: {
        image: "/images/showcase/jellyfish.png",
        prompt: "multiple jellyfish with bright, vibrant colors float underwater among the grass, depth of field, boca, jellyfish in foreground, focus, and background"
      },
      sender: 'bot'
    },
  ]
}

export const SDAutomatic = {
  "name": "stable-diffusion-turbo-vision",
  "params": [
    {
      label: "Guidance Scale",
      name: "guidanceScale",
      desc: "How closely your prompt should be followed. Use a lower value for more creativity, and a higher value for more precision.",
      min: 0.0,
      max: 30.0,
      step: 0.1,
      default: 8,
    },
    {
      label: "Steps",
      name: "steps",
      desc: "The number of steps to take in the diffusion process. More steps will generally produce better results but will be slower.",
      min: 1,
      max: 100,
      step: 1,
      default: 25,
    },
    {
      label: "Seed",
      name: "seed",
      desc: "The seed to use for the random number generator. Changing this will produce different results.",
      min: 0,
      max: 12013012031030,
      step: 1,
      default: 1067277458007,
      long: true,
    },
    {
      label: "Width",
      name: "width",
      desc: "The width of the image to generate.",
      min: 1,
      max: 768,
      step: 1,
      default: 512,
    },
    {
      label: "Height",
      name: "height",
      desc: "The height of the image to generate.",
      min: 1,
      max: 768,
      step: 1,
      default: 512,
    }
  ],
  messages: [
    {
      text: "multiple jellyfish with bright, vibrant colors float underwater among the grass, depth of field, boca, jellyfish in foreground, focus, and background",
      params: {
        "guidanceScale": 8,
        "steps": 25,
        "seed": 1067277458007,
        "width": 512,
        "height": 512
      },
      sender: 'user'
    },
    {
      imageData: {
        image: "/images/showcase/jellyfish.png",
        prompt: "multiple jellyfish with bright, vibrant colors float underwater among the grass, depth of field, boca, jellyfish in foreground, focus, and background"
      },
      sender: 'bot'
    },
  ]
}

export const SdImageToVideo = {
  "params": [
    {
      label: "Seed",
      name: "seed",
      desc: "The seed to use for the random number generator. Changing this will produce different results.",
      min: 0,
      max: 9223372036854776000,
      step: 1,
      default: 2023635357339779300,
      long: true,
    },
    {
      label: "Motion bucket ID",
      name: "motion_bucket_id",
      desc: "Controls how much motion to add/remove from the image",
      min: 1,
      max: 255,
      step: 1,
      default: 127,
    },
    {
      label: "Frames per second (FPS)",
      name: "frames_per_second",
      desc: "The length of your video in seconds will be 25/fps",
      min: 1,
      max: 30,
      step: 1,
      default: 16,
    }
  ]
}

export const StableViton = {
  "url": "ws://34.134.131.65:7860",
  "galleryUrl": "http://34.134.131.65:7860",
  "name": "StableVITON",
  "params": [
    {
      label: "Steps",
      name: "steps",
      desc: "The number of steps to take in the process. More steps will generally produce better results but will be slower.",
      min: 10,
      max: 50,
      step: 1,
      default: 20,
    }
  ]
}
