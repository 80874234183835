import {combineReducers} from 'redux';
import ModelState from './ModelState';
import AppState from './AppState';
import UIState from './UIState';

import modelTypes from './models';

ModelState.init(modelTypes);

const reducer = combineReducers({
  models: ModelState.reducer,
  app: AppState.reducer,
  ui: UIState.reducer,
});
export default reducer;
