import {useEffect, useRef, useState} from "react";
import {CopyIcon, DownBarIcon, DownChevronIcon, EraserIcon, InfoIcon, SendIcon, UpBarIcon} from "../Svg";
import {Button} from "../Button";
import _ from "lodash";
import {TextInput} from "../form/TextInput";
import cx from "classnames";
import Tooltip from "../Tooltip";
import {AIShowcase} from "../../store/models/AIShowcase";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {SelectInput} from "../form/SelectInput";
import {SDAutomatic, SDXL} from '../../constants/sd-models'
import UIState, {selectMobileLayout} from "../../store/UIState";
import {createSelector} from "reselect";
import {ModalTypes, Urls} from "../../constants";
import {ShowcaseImageItem} from "./ShowcaseImageItem";
import {ShowcaseSettingsPanel} from "./ShowcaseSettingsPanel";

const ChatMessage = ({message, index, onRegenerate, onLoaded, onCopy, isContest}) => {

  const [paramsOpened, setParamsOpened] = useState(false);

  const onToggleParams = () => {
    setParamsOpened(!paramsOpened);
  }

  if (message.sender === "bot") {
    return (
      <div className={cx("BotMessageContainer")}>
        <ShowcaseImageItem imageData={message.imageData}
                           index={index}
                           isEnhanced={false}
                           onRegenerate={onRegenerate}
                           onLoaded={onLoaded}/>

        {message.enhancedImageData &&
          <ShowcaseImageItem imageData={message.enhancedImageData}
                             index={index}
                             isEnhanced={true}
                             onRegenerate={onRegenerate}
                             onLoaded={onLoaded}/>}
      </div>
    )
  }

  return (
    <div className={cx("UserMessageContainer", {isContest: isContest})}>
      <div className="UserMessage">
        <div className={"UserMessage__PromptLabel"}>{message.enhanced
          ? <>
            AI Enhanced Prompt (Your prompt -> Meta Llama3 Chatbot -> Text-to-image)
            <Tooltip
              tooltip={"The following is an enhanced version of your original prompt, refined by an AI chatbot. This refined prompt is then fed into the text-to-image model to generate the final image."}><InfoIcon/></Tooltip>
          </>
          : 'Prompt'}
        </div>
        <div className={"UserMessage__PromptValue"}>{message.text}</div>
        <div className={"UserMessage__BottomWrapper"}>
          <div className={`UserMessage__ParamsButton ${paramsOpened && "open"}`}
               onClick={onToggleParams}>More parameters <DownChevronIcon/></div>
          {message.enhanced &&
            <Button title={"Try Llama3 ChatBot"} color={"grey"} size={"small"}
                    href={Urls.MARKETING_AI_SHOWCASE_CHATBOT} target={"_blank"}/>}
        </div>
        {paramsOpened &&
          <div className={"UserMessage__Params"}>
            <pre>{JSON.stringify({prompt: message.text, ...message.params}, null, 2)}</pre>
          </div>}

        {isContest && <div className={`UserMessage__actions`}>
          <Button title={"Copy"}
                  onClick={onCopy}
                  color={"grey-outline"}
                  icon={<CopyIcon/>}/>

        </div>}
      </div>

    </div>)
}

const selector = createSelector(
  selectMobileLayout,
  (isMobile) => ({
      isMobile
    }
  ))

export const ShowcaseTextToImage = ({defaultMessages, showcase}) => {

  let pageName = null;
  const currentUrl = window.location.href;
  if (currentUrl.includes(Urls.MARKETING_AI_CONTEST)) {
    pageName = "contest";
  } else {
    pageName = "showcase";
  }

  const dispatch = useDispatch();
  const {isMobile} = useSelector(state => selector(state));
  const [currentModel, setCurrentModel] = useState(SDAutomatic);


  const [textareaValue, setTextareaValue] = useState('')
  const [messages, setMessages] = useState(_.isNil(defaultMessages)
    ? SDAutomatic.messages : []);
  const chatboxRef = useRef(null);
  const [showSettings, setShowSettings] = useState(!isMobile)
  const [settings, setSettings] = useState({})
  const [changeSeed, setChangeSeed] = useState(null)

  useEffect(() => {
    if (_.isNil(defaultMessages)) {
      setMessages(currentModel.messages)
    }
  }, [currentModel]);

  const sendMessage = async (event) => {
    event.preventDefault();

    const newMessages = [...messages,
      {text: textareaValue, params: settings, sender: 'user'},
      {text: '... enhancing your prompt', params: settings, sender: 'user', enhanced: true},
      {imageData: {}, enhancedImageData: {}, sender: 'bot'}
    ]
    setMessages(newMessages)
    const message = {prompt: textareaValue, ...settings};

    generateImage(message, false, newMessages.length - 1)
    generateEnhancedImage(message, newMessages.length - 1)

    setTimeout(() => {
      if (!_.isNil(chatboxRef) && !_.isNil(chatboxRef.current)) {
        chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
      }
    }, 1000)
  };

  const generateImage = async (message, isEnhanced, index, regenerating) => {
    try {
      const result = await dispatch(AIShowcase.actions.generateImage(pageName, currentModel, message, regenerating, showcase));
      setMessages(oldMessages => {
        const newMessages = [...oldMessages];
        if (isEnhanced) {
          newMessages[index].enhancedImageData = {prompt: message.prompt, image: result};
        } else {
          newMessages[index].imageData = {prompt: message.prompt, image: result};
        }
        return newMessages
      });
    } catch (e) {
      toast.error(e.message)
      console.error(e)
    }
  }

  const generateEnhancedImage = async (message, index, regenerating) => {

    const enhancedPromptResponse = await dispatch(AIShowcase.actions.generateImprovedImagePrompt(message.prompt))
    setMessages(oldMessages => {
      const newMessages = [...oldMessages];
      newMessages[newMessages.length - 2].text = enhancedPromptResponse;
      return newMessages
    });

    generateImage({...message, prompt: enhancedPromptResponse}, true, index, regenerating)
  }

  const regenerateMessage = async (prompt, isEnhanced, index) => {
    try {
      const newSeed = Math.floor(Math.random() * currentModel.params.find(p => p.name === 'seed').max);

      const settingsWithNewRandomSeed = {
        ...settings,
        seed: newSeed
      }
      setChangeSeed(newSeed)

      let newMessages = [...messages];
      if (index === 1) {
        newMessages[index - 1].params = settingsWithNewRandomSeed;
        newMessages[index].imageData.image = null;
      } else if (isEnhanced) {
        newMessages[index - 1].params = settingsWithNewRandomSeed;
        newMessages[index - 1].text = "enhancing your prompt..."
        newMessages[index].enhancedImageData.image = null;
      } else {
        newMessages[index - 2].params = settingsWithNewRandomSeed;
        newMessages[index].imageData.image = null;
      }
      setMessages(newMessages);

      if (isEnhanced) {
        generateEnhancedImage({prompt, ...settingsWithNewRandomSeed}, index, true)
      } else {
        generateImage({prompt, ...settingsWithNewRandomSeed}, false, index, true)
      }

    } catch (e) {
      toast.error(e.message)
      console.log(e)
    }
  }

  const onTextareaChange = (event) => {
    setTextareaValue(event.target.value)
  }

  const onCopy = (message) => {
    navigator.clipboard.writeText(JSON.stringify({prompt: message.text, ...message.params}, null, 2))
    toast.success("Copied to clipboard")

  }

  const onScrollToBottom = () => {
    chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
  }

  const onScrollToTop = () => {
    chatboxRef.current.scrollTop = 0;
  }

  const onClearMessages = () => {
    setMessages([]);
  }

  const onToggleSettings = () => {
    setShowSettings(!showSettings);
  }

  const onSettingsChanged = (settings) => {
    setSettings(settings)
  }

  const onImageLoaded = (imageIndex) => {
    if (imageIndex === messages.length - 1) {
      onScrollToBottom()
    }
  }

  return (
    <div className="ShowcaseTextToImage">
      <div className="ShowcaseTextToImage__container">

        <div className={"ShowcaseTextToImage__SidePanels"}>
          <div className={"SidePanel"}>
            {!isMobile && <div className={"ShowcaseTextToImage__LeftInfo"}>
              <div className={"SidePanel__Title"}>Model</div>
              <SelectInput className={"SidePanel__Select"}
                           options={[{label: SDXL.name, value: SDXL}, {label: SDAutomatic.name, value: SDAutomatic}]}
                           value={{label: currentModel.name, value: currentModel}}
                           onChange={(model) => setCurrentModel(model.value)}/>
            </div>}

            <div className={"SidePanel__Header"}>
              <div className={"SidePanel__Header__Prompt"}>Prompt</div>
              <div className={"SidePanel__Header__InputActions"}>
                <Tooltip tooltip={"Scroll to bottom"}>
                  <div onClick={onScrollToBottom}><DownBarIcon/></div>
                </Tooltip>
                <Tooltip tooltip={"Scroll to top"}>
                  <div onClick={onScrollToTop}><UpBarIcon/></div>
                </Tooltip>
                <Tooltip tooltip={"Clear all messages"}>
                  <div onClick={onClearMessages}><EraserIcon/></div>
                </Tooltip>
              </div>
            </div>
            <form className="SidePanel__form" onSubmit={sendMessage}>
            <textarea name="message"
                      className="SidePanel__input"
                      value={textareaValue}
                      onChange={onTextareaChange}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          sendMessage(event);
                        }
                      }}
                      placeholder="Eg. multiple jellyfish floating in a colorful underwater world, depth of field"/>

              <Button
                title={"Send"}
                type="submit"
                icon={<SendIcon/>}
                className="ShowcaseTextToImage__button"
                color={"green"}
                size={"small"}
                disabled={_.isEmpty(textareaValue)}/>
            </form>

            <div className={`SidePanel__OutputOptions ${showSettings && "open"}`}
                 onClick={onToggleSettings}>Output options <DownChevronIcon/></div>

            {showSettings && <>
              {isMobile && <div className={"ShowcaseTextToImage__LeftInfo"}>
                <div className={"SettingsPanel__Title"}>Model</div>
                <SelectInput className={"SettingsPanel__Select"}
                             options={[{label: SDXL.name, value: SDXL}, {label: SDAutomatic.name, value: SDAutomatic}]}
                             value={{label: currentModel.name, value: currentModel}}
                             onChange={(model) => setCurrentModel(model.value)}/>
              </div>}
              <ShowcaseSettingsPanel model={currentModel} onParamsChanged={onSettingsChanged} changeSeed={changeSeed}/>

              {/*<SettingsPanel model={currentModel} onParamsChanged={onSettingsChanged} changeSeed={changeSeed}/>*/}
            </>}

          </div>
        </div>

        <div className={cx("ShowcaseTextToImage__chatbox")}>

          <div ref={chatboxRef} className="ShowcaseTextToImage__messages">
            {!_.isNil(defaultMessages) && defaultMessages}
            {messages.map((message, index) => {
                return (
                  <ChatMessage key={index}
                               index={index}
                               message={message}
                               onLoaded={onImageLoaded}
                               onCopy={() => onCopy(message)}
                               isContest={pageName === "contest"}
                               onRegenerate={regenerateMessage}/>)
              }
            )}
          </div>

        </div>
      </div>

    </div>
  );
}