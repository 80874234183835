import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import _ from "lodash";
import React, { useEffect } from "react";
import { selectCurrentUser, selectCurrentUserId } from "../store/models/User";
import { Link, NavLink } from "react-router-dom";
import { createSelector } from "reselect";
import { ModalTypes, ShowcaseURls, Urls, UserRoleIds } from "../constants";
import Project, { selectCurrentProject, selectCurrentProjectId } from "../store/models/Project";
import { MenuIcon } from "./Svg";
import UIState from "../store/UIState";
import { selectCurrentOrgId } from "../store/models/Organization";
import cx from "classnames";
import { DropdownMenu, DropdownMenuItem } from "./DropdownMenu";
import { pushTo } from "../store/history";
import { UserAvatar } from "./UserAvatar";

export const HeaderLink = ({ to, target, onClick, name, active, className }) => {
  return (
    <NavLink className={cx('NavbarLink', { 'NavbarLink--active': active }, className)}
      activeClassName={'NavbarLink--active'}
      to={to}
      onClick={onClick}
      target={target}
      rel="noopener noreferrer"
      exact
    >
      {name}
      {active && <div className={'NavbarLink__border'} />}
    </NavLink>
  );
}

const selector = createSelector(
  selectCurrentUser,
  selectCurrentUserId,
  selectCurrentOrgId,
  selectCurrentProjectId,
  selectCurrentProject,
  (user, userId, currentOrgId, currentProjectId, currentProject) => {
    return {
      user,
      userId,
      currentOrgId,
      currentProjectId,
      currentProject,
    }
  }
);

export const MobileNavbar = ({ }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { user, userId, currentOrgId, currentProjectId, currentProject }
    = useSelector(state => selector(state));

  const pathName = location.pathname;
  const userIsAdmin = user && user.role === UserRoleIds.ADMIN;

  const onOpenSettings = () => {
    pushTo(Urls.SETTINGS_PROFILE);
  }

  const onLogout = () => {
    dispatch(UIState.actions.showModal(ModalTypes.LOGOUT, {
      onConfirm: onConfirmLogout
    }))
  }

  const onConfirmLogout = () => {
    dispatch(User.actions.logout())
  }

  const onOpenBilling = () => {
    pushTo(Urls.SETTINGS_BILLING);
  }

  useEffect(() => {
    if (!_.isNil(currentProjectId) && _.isEmpty(currentProject)) {
      dispatch(Project.actions.fetchProject(currentProjectId));
    }
  }, [currentProjectId]);

  const onToggleSideMenu = () => {
    dispatch(UIState.actions.toggleSideMenu())
  }

  return (
    <div className={cx(`Navbar`)}>

      <div className={cx(`Navbar__content`)}>
        <div className="Navbar__side-menu" onClick={onToggleSideMenu}>
          <MenuIcon color="sub" className="hamburger" />
        </div>

        <div onClick={onToggleSideMenu}>
          <img src={"/images/edgecloud-logo-mobile.svg"}
            alt={'theta-video-api-logo'}
            className={'Navbar__logo Navbar__logo--mobile'}
          />
        </div>

        <div className={'Navbar__user-content'}>

          {_.isNil(userId) &&
            <HeaderLink to={`/sign-up`}
              className={"signup-btn"}
              name={'Come in'} />}

          {!_.isNil(userId) &&
            <DropdownMenu label={<UserAvatar user={user} />} className={"NavbarLink"}>
              <DropdownMenuItem label={"Account"}
                className={"NavbarLink NavbarLink--account"}
                onClick={onOpenSettings} />

              {userIsAdmin && <DropdownMenuItem label={"Billing"}
                className={"NavbarLink NavbarLink--account"}
                onClick={onOpenBilling} />}

              <DropdownMenuItem label={"Logout"}
                className={"NavbarLink NavbarLink--logout"}
                onClick={onLogout} />
            </DropdownMenu>}


        </div>
      </div>
    </div>
  )
}