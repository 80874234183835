import React, {useEffect, useState} from "react";
import _ from "lodash";
import Tooltip from "../Tooltip";
import {InfoIcon} from "../Svg";
import {TextInput} from "../form/TextInput";

export const ShowcaseSettingsPanel = ({model, onParamsChanged, changeSeed}) => {

  const [params, setParams] = useState({});

  useEffect(() => {
    if (changeSeed) {
      onParamChanged('seed', changeSeed, true)
    }
  }, [changeSeed]);

  useEffect(() => {
    setParams(_.map(model.params, p => {
      return {
        name: p.name,
        value: p.default
      }
    }));

    const ranges = _.map(model.params, (param) => {
      const range = document.getElementById(param.name);
      range.addEventListener('input', updateGradient);
      setTimeout(() => {
        updateGradient({target: range})
      }, 100)
      return range;
    });

    return () => {
      ranges.forEach(range => {
        range.removeEventListener('input', updateGradient);
      });
    };
  }, [model]);

  useEffect(() => {
    if (!_.isEmpty(params)) {
      const paramsObject = params?.reduce((obj, param) => {
        obj[param.name] = param.value;
        return obj;
      }, {});

      onParamsChanged(paramsObject)
    }
  }, [params]);

  function updateGradient(e) {
    const range = e.target;
    const value = ((range.value - range.min) / (range.max - range.min)) * 100;
    const color = `linear-gradient(to right, #129978 ${value}%, #3D4463 ${value}%)`;

    // Create a new style element
    const style = document.createElement('style');

    style.textContent = `
    #${range.id}::-moz-range-track {
      background: ${color};
    }
    #${range.id}::-webkit-slider-runnable-track {
      background: ${color};
    }
  `;

    document.head.appendChild(style);
  }

  const onParamChanged = (name, value, needGradientUpdate = false) => {
    const modelParam = model.params.find(p => p.name === name);
    value = parseFloat(value);

    if (_.isNil(value) || _.isNaN(value)) {
      value = 0;
    } else if (value < modelParam.min) {
      value = modelParam.min
    } else if (value > modelParam.max) {
      value = modelParam.max
    }

    setParams(params.map(param => {
      if (param.name === name) {
        return {
          ...param,
          value: value
        }
      }
      return param;
    }))


    if (needGradientUpdate) {
      setTimeout(() => {
        updateGradient({target: document.getElementById(name)})
      }, 50)
    }
  }

  return (
    <div className="ShowcaseSettingsPanel">

      <div className={"ShowcaseSettingsPanel__InputsWrapper"}>

        {_.map(model.params, (param, index) => {
          return (
            <div key={index} className="ShowcaseSettingsPanel__field">
              <div className={"ShowcaseSettingsPanel__field--top"}>
                <Tooltip longText={true}
                         tooltip={param.desc}>
                  <div className={"label-wrapper"}>
                    <label htmlFor={param.name}>{param.label}</label>
                    <InfoIcon/>
                  </div>
                </Tooltip>
                <TextInput max={param.max} min={param.min} className={param.long ? "large" : ""} type={"number"}
                           value={params?.[index]?.value} name={param.name}
                           onChange={(e) => onParamChanged(param.name, e.target.value, true)}/>
              </div>
              <input type="range" id={param.name} step={param.step} max={param.max} min={param.min}
                     value={params?.[index]?.value}
                     onChange={(e) => onParamChanged(param.name, e.target.value)}/>
            </div>
          )
        })}
      </div>
    </div>)
};