import classNames from "classnames";
import {Link} from "react-router-dom";
import cx from "classnames";

export const Tab = ({href, isSelected, children, onClick}) => {
  return (
    <li className={classNames('Tab', {
      'Tab--selected': isSelected
    })}>
      {
        onClick &&
        <a onClick={onClick}>
          {children}
        </a>
      }
      {
        href &&
        <Link to={href}>{children}</Link>
      }
    </li>
  )
}

export const Tabs = ({children, leftAlign = false, className}) => {
  return (
    <ul className={cx('Tabs', {leftAlign}, className)}>
      {children}
    </ul>
  )
}
