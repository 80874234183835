import {FloatingUITooltip, TooltipContent, TooltipTrigger} from "./FloatingUITooltip";
import React from "react";

const Tooltip = ({children, tooltip, hidden = false, asChild, placement = 'top', longText = false}) => {

  if (hidden) {
    return children
  }

  const style = {};
  if (longText) {
    style.maxWidth = '280px';
    style.textAlign = 'left';
  }

  return (
    <FloatingUITooltip placement={placement}>
      <TooltipTrigger asChild={asChild}>{children}</TooltipTrigger>
      <TooltipContent style={style}>{tooltip}</TooltipContent>
    </FloatingUITooltip>
  )
}

export default Tooltip