import React, { useState, useRef } from 'react';
import cx from 'classnames';
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../components/Modal";
import { Button } from "../components/Button";
import { toast } from "react-toastify";

export const DeleteModal = ({ onRequestClose, onConfirm, title }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = async () => {
    setLoading(true);
    setError(null);
    try {
      await onConfirm();
      onRequestClose();
    } catch (e) {
      setLoading(false);
      setError(e.message)
      toast.error('Error: ' + e.message)
    }
  }

  return (
    <Modal className={cx("DeleteModal")}
      onRequestClose={onRequestClose}>

      <ModalHeader>
        <ModalTitle>Do you want to delete {title}</ModalTitle>
      </ModalHeader>

      <ModalContent>
        {error && <div className={cx("DeleteModal__error")}>Error: {error}</div>}
      </ModalContent>

      <ModalActions>
        <Button color={"red"} onClick={onSubmit} title={"Delete"} loading={loading} />
        <Button color={"transparent"} onClick={onRequestClose} title={"Cancel"} />
      </ModalActions>

    </Modal>
  );
}