import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { Button } from "../../components/Button";
import { useDispatch } from "react-redux";
import User from "../../store/models/User";
import PageTitleBar from "../../components/PageTitleBar";
import { history, pushTo } from "../../store/history";
import { Urls } from "../../constants";
import {useQuery} from "../../utils/url";

function PasswordResetPageForm({ onSubmit, loading, error, loaded }) {
  const { register, handleSubmit, formState } = useForm({
    mode: "onChange"
  });

  const onSubmitLocal = (e) => {
    e.preventDefault();

    handleSubmit(onSubmit)();
  }

  return (
    <form onSubmit={onSubmitLocal}
      className={'Form ResetPasswordForm'}
    >
      <input hidden type='text' name='email' autoComplete='email' />
      <input type="password" placeholder="New Password" autoComplete='new-password' {...register("password", { required: true, maxLength: 83 })} />
      <input type="password" placeholder="Confirm New Password" autoComplete='new-password' {...register("confirm_password", { required: true, maxLength: 83 })} />

      <div className={'FormError'}>{error}</div>

      {loaded ? <div className='ResetPasswordForm__success'>
        Your new password has been set
      </div> : <Button title={'Reset Password'}
        role={'submit'}
        loading={loading}
        className='ResetPasswordForm__button'
        disabled={!formState.isValid}
      />}
    </form>
  );
}

function PasswordResetPage() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);
  const {user_id: userId, token} = useQuery();

  const onSubmit = async ({ password, confirm_password }) => {
    if (password !== confirm_password) {
      setError("Password and Confirm Password need to be the same");
      return;
    }
    setLoading(true);
    try {
      await dispatch(User.actions.resetPassword(userId, token, password));
      setLoaded(true);
      setLoading(false);
      setTimeout(() => pushTo(Urls.LOGIN), 1500);
    } catch (e) {
      setLoading(false);
      setError(e.message);
    } finally {

    }
  };

  return <div className={'ResetPassword'}>
    <div className={'ResetPassword__content'}>
      <PageTitleBar title={'Reset Password'} />
      <PasswordResetPageForm onSubmit={onSubmit}
        loading={loading}
        error={error}
        loaded={loaded} />
    </div>
  </div>
}

export default PasswordResetPage;