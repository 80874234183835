import {Button} from "../Button";
import {InfoIcon, RefreshIcon} from "../Svg";
import Loader from "../Loader";
import Tooltip from "../Tooltip";

export const ShowcaseImageItem = ({imageData, index, isEnhanced, onRegenerate, onLoaded}) => {

  return (
    <div className={`ShowcaseImageItem`}>

      {imageData.image
        ? <img className={"image"}
               src={imageData.image}
               alt={`Generated image : ${imageData.prompt}`}
               onLoad={() => {
                 onLoaded(index);
               }}
        />
        : <div className={"ShowcaseImageItem__loader"}><Loader /></div>}

      <div className={`ShowcaseImageItem__label`}>
        {isEnhanced
          ? <>
            Image from AI enhanced prompt
            <Tooltip tooltip={"This image is generated from the AI enhanced prompt shown above."}><InfoIcon /></Tooltip>
          </>
          : "Image from your prompt"}
      </div>

      <div className={`ShowcaseImageItem__actions`}>
        <Button title={"Regenerate"}
                onClick={() => onRegenerate(imageData.prompt, isEnhanced, index)}
                color={"grey-outline"}
                icon={<RefreshIcon/>}/>

      </div>

    </div>
  )
}