export const ShowcaseGallery = ({images, title, onClick, name}) => {

  const onImageSelected = (index) => {
        onClick(index, name)
  }

  return (
    <div className={"ShowcaseGallery"}>
      {title &&<div className={"ShowcaseGallery__Title"}>{title}</div>}
      <div className={"ShowcaseGallery__Images"}>
        {images.map((image, index) => (
          <div key={index} className={"ShowcaseGallery__Image"} onClick={(e) => onImageSelected(index)}>
            <img src={image} alt={name}/>
          </div>
        ))}
      </div>
    </div>
  )
}