import _ from 'lodash';
import {createSelector} from 'reselect';

import {authHeaders, del, get, post, put} from "../../utils/fetch";
import {Hosts, RequestState, Urls, UserRoleIds} from "../../constants";
import Storage from '../../utils/storage';

import AppState from "../AppState";
import {pushTo} from "../history";
import {selectCurrentOrgId} from "./Organization";
import {selectCurrentProjectId} from "./Project";


// ===========================
// HELPERS
// ===========================
export const getUserColor = (firstName, lastName, email) => {
  const hash = (firstName && lastName)
    ? (firstName + lastName).split('').reduce((acc, char) => char.charCodeAt(0) + acc, 0)
    : firstName?.split('')?.reduce((acc, char) => char.charCodeAt(0) + acc, 0)
  return `hsl(${hash % 360}, 100%, 65%)`;
}

export const getUserFullName = (user) => {
  if (user?.first_name)
    return `${user.first_name} ${user.last_name ?? ''}`;
  else return null;
}

// ACTIONS
// ===========================
export const FETCH_USER = 'FETCH_USER';
export const FETCH_ORGANIZATION_USERS = 'FETCH_ORGANIZATION_USERS';
export const FETCH_PROJECT_USERS = 'FETCH_PROJECT_USERS';
export const REMOVE_USER_FROM_ORG = 'REMOVE_USER_FROM_ORG';
export const ENABLE_USER_IN_ORG = 'ENABLE_USER_IN_ORG';
export const REMOVE_USER_FROM_PROJECT = 'REMOVE_USER_FROM_PROJECT';
export const ENABLE_USER_IN_PROJECT = 'ENABLE_USER_IN_PROJECT';
export const INVITE_USER_TO_PROJECT = 'INVITE_USER_TO_PROJECT';
export const ACCEPT_INVITE = 'ACCEPT_INVITE';
export const CHANGE_USER_ROLE_IN_ORG = 'CHANGE_USER_ROLE_IN_ORG';
export const CHANGE_USER_ROLE_IN_PROJECT = 'CHANGE_USER_ROLE_IN_PROJECT';
export const ADD_USER_TO_PROJECT = 'ADD_USER_TO_PROJECT';

export const SIGNUP = 'SIGNUP';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const SEND_VERIFICATION_EMAIL = 'SEND_VERIFICATION_EMAIL';
export const VERIFY_PHONE_NUMBER = 'VERIFY_PHONE_NUMBER';
export const OAUTH2 = 'OAUTH2';
export const FETCH_SESSION = 'FETCH_SESSION';
export const DELETE_SESSION = 'DELETE_SESSION';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_EMAIL = 'CHANGE_EMAIL';
export const CHANGE_PHONE_NUMBER = 'CHANGE_PHONE_NUMBER';
export const FETCH_USER_ROLE_IN_ORG = 'FETCH_USER_ROLE_IN_ORG';
export const VISIT_PROJECT = 'VISIT_PROJECT';


// ===========================
// SELECTORS
// ===========================
export const selectAllUsers = state => state.models.users;
export const selectUser = (state, userId) => state.models.users[userId];
export const selectUsers = createSelector(
  selectAllUsers,
  (_, userIds) => userIds,
  (users, userIds) => _.pick(users, userIds)
)

export const selectCurrentUserId = state => state?.app.userId;
export const selectCurrentUser = state => selectUser(state, selectCurrentUserId(state));
export const selectCurrentUserRole = state => selectUser(state, selectCurrentUserId(state))?.role;
export const selectUsersInOrg = (state) => _.filter(state.models.users, user => user.organization_id === selectCurrentOrgId(state));
export const selectUsersInProject = (state, projectId) => {
  const projectIdSelect = projectId ?? selectCurrentProjectId(state)
  return _.filter(state.models.users, user => user.project_id === projectIdSelect);
}

// ===========================
// MODEL
// ===========================
const User = {
  actions: {
    fetchUser: (userId) => async dispatch =>
      get({
        host: Hosts.EDGE_CLOUD_API,
        url: "/user/:userId",
        params: {userId},
        headers: authHeaders(),
        dispatch,
        action: FETCH_USER,
      })
        .then(results => results?.body?.users?.[0]),

    signup: (email, password, firstname, lastName) => async (dispatch, getState) => {
      let result = await post({
        host: Hosts.EDGE_CLOUD_API,
        url: "/user",
        body: {
          email,
          password,
          first_name: firstname,
          last_name: lastName
        },
        action: SIGNUP,
      });

      await dispatch(User.actions.authenticateUser(result.body.users[0]));

      await dispatch(AppState.actions.startApp());

      return selectCurrentUserId(getState());
    },

    login: (email, password, twoFA, whitelistIp) => async (dispatch, getState) => {
      let body = {
        email: email,
        password: password,
      }
      if (!_.isEmpty(twoFA)) {
        body.token = twoFA
        body.whitelist_ip = whitelistIp
      }

      let result = await post({
        host: Hosts.EDGE_CLOUD_API,
        url: "/user/login",
        action: LOGIN,
        params: {
          expand: "redirect_project_id.org_id"
        },
        dispatch,
        body,
      });

      await dispatch(User.actions.authenticateUser(result.body.users[0]));

      await dispatch(AppState.actions.startApp());

      return result;
    },

    authenticateUser: (credentials) => async (dispatch, getState) => {
      return await dispatch(User.actions.setUserCredentials(credentials));
    },

    setUserCredentials: (userSession) => (dispatch, getState) => {
      let userId = userSession.id
      let userToken = userSession.auth_token
      dispatch(AppState.actions.setAuth(userId, userToken));
    },

    logout: (withRedirect = true, redirectUrl = null) => async (dispatch) => {
        await Storage.clearUserData();
        if (withRedirect) {
          pushTo(redirectUrl ?? Urls.HOME)
          window.location.reload();
        }
    },

    editProfile: (userId, body) => async (dispatch, getState) => {
      let result = await put({
        host: Hosts.EDGE_CLOUD_API,
        url: "/user/:userId",
        headers: authHeaders(),
        params: {userId},
        body,
        dispatch,
        action: EDIT_PROFILE,
      });

      return result?.body?.users?.[0];
    },

    changePassword: (userId, oldPassword, newPassword) => async (dispatch, getState) => {
      let result = await put({
        host: Hosts.EDGE_CLOUD_API,
        url: "/user/:userId/change_password",
        headers: authHeaders(),
        params: {userId},
        body: {
          old_password: oldPassword,
          password: newPassword,
        },
        dispatch,
        action: CHANGE_PASSWORD,
      });

      return result?.body?.users?.[0];
    },

    requestChangeEmail: () => async (dispatch, getState) => {
      const userId = selectCurrentUserId(getState());
      let result = await post({
        host: Hosts.EDGE_CLOUD_API,
        url: "/user/:userId/request_change_email",
        headers: authHeaders(),
        params: {userId},
        dispatch,
      });

      return result?.body?.users?.[0];
    },

    changeEmail: (userId, email, token) => async (dispatch, getState) => {
      let result = await put({
        host: Hosts.EDGE_CLOUD_API,
        url: "/user/:userId/change_email",
        headers: authHeaders(),
        params: {userId},
        body: {
          email,
          token
        },
        dispatch,
        action: CHANGE_EMAIL,
      });

      return result?.body?.users?.[0];
    },

changePhoneNumber: (userId, phoneNumber) => async (dispatch, getState) => {
      let result = await put({
        host: Hosts.EDGE_CLOUD_API,
        url: "/user/:userId/change_phone_number",
        headers: authHeaders(),
        params: {userId},
        body: {
          phone_number: phoneNumber,
        },
        dispatch,
        action: CHANGE_PHONE_NUMBER,
      });

      return result?.body?.users?.[0];
    },

    sendVerificationEmail: () => async (dispatch, getState) => {
      const userId = selectCurrentUserId(getState());
      return post({
        host: Hosts.EDGE_CLOUD_API,
        url: "/user/:userId/resend_verification_email",
        headers: authHeaders(),
        params: {userId},
        actions: SEND_VERIFICATION_EMAIL,
      })
    },

    sendVerifyPhoneNumber: () => async (dispatch, getState) => {
      const userId = selectCurrentUserId(getState());
      return post({
        host: Hosts.EDGE_CLOUD_API,
        url: "/user/:userId/resend_verification_phone_number",
        headers: authHeaders(),
        params: {userId},
        actions: VERIFY_PHONE_NUMBER,
      })
    },


    fetchOrganizationUsers: (orgId) => async (dispatch, getState) => {
      const queryOrgId = orgId ?? selectCurrentOrgId(getState())
      const result = await get({
        host: Hosts.EDGE_CLOUD_API,
        url: `/organization/:orgId/users`,
        params: {
          orgId: queryOrgId
        },
        headers: authHeaders(),
        action: FETCH_ORGANIZATION_USERS,
        data: {
          orgId: queryOrgId
        },
        dispatch,
      });
      return _.get(result, 'body.users');
    },

    fetchProjectUsers: (projectId) => async (dispatch, getState) => {
      const result = await get({
        host: Hosts.EDGE_CLOUD_API,
        url: `/project/:projectId/users`,
        params: {
          projectId
        },
        headers: authHeaders(),
        action: FETCH_PROJECT_USERS,
        data: {
          projectId
        },
        dispatch,
      });
      return _.get(result, 'body.users');
    },

    removeUserFromOrg: (userId, orgId) => async (dispatch, getState) => {
      const queryOrgId = orgId ?? selectCurrentOrgId(getState());

      const result = await del({
        host: Hosts.EDGE_CLOUD_API,
        url: `/organization/:orgId/user/:userId`,
        params: {
          orgId: queryOrgId,
          userId
        },
        headers: authHeaders(),
        dispatch,
        action: REMOVE_USER_FROM_ORG,
      });
      return result;
    },

    enableUserInOrg: (userId, orgId) => async (dispatch, getState) => {
      const queryOrgId = orgId ?? selectCurrentOrgId(getState());
      const result = await put({
        host: Hosts.EDGE_CLOUD_API,
        url: `/organization/:orgId/user/:userId/enable`,
        params: {
          orgId: queryOrgId,
          userId
        },
        headers: authHeaders(),
        dispatch,
        action: ENABLE_USER_IN_ORG,
      });
      return result;
    },

    addUserToProject: (projectId, userId, role = UserRoleIds.EDITOR) => async (dispatch, getState) => {
      const result = await post({
        host: Hosts.EDGE_CLOUD_API,
        url: `/project/:projectId/user`,
        params: {
          projectId
        },
        headers: authHeaders(),
        dispatch,
        body: {
          user_id: userId,
          role
        },
        action: ADD_USER_TO_PROJECT,
      });
      return result;
    },

    inviteUserToProject: (body, projectId) => async (dispatch, getState) => {
      const result = await post({
        host: Hosts.EDGE_CLOUD_API,
        url: `/project/:projectId/invite_user`,
        params: {
          projectId
        },
        headers: authHeaders(),
        dispatch,
        body,
        action: INVITE_USER_TO_PROJECT,
      });
      return result;
    },

    acceptProjectInvite: (params) => async (dispatch, getState) => {
      const result = await post({
        host: Hosts.EDGE_CLOUD_API,
        url: `/project/:projectId/add_invitee`,
        params: {
          projectId: params.projectId
        },
        headers: authHeaders(),
        dispatch,
        body: {
          email: params.email,
          user_id: params.userId,
          token: params.token
        },
        action: ACCEPT_INVITE,
      });
      return result;
    },

    changeUserRoleInOrg: (userId, role, orgId) => async (dispatch, getState) => {
      const queryOrgId = orgId ?? selectCurrentOrgId(getState());

      const result = await put({
        host: Hosts.EDGE_CLOUD_API,
        url: `/organization/:orgId/user/:userId`,
        params: {
          orgId: queryOrgId,
          userId
        },
        headers: authHeaders(),
        dispatch,
        data: {orgId},
        body: {role},
        action: CHANGE_USER_ROLE_IN_ORG,
      });
      return result;
    },

    removeUserFromProject: (userId, projectId) => async (dispatch, getState) => {
      const result = await del({
        host: Hosts.EDGE_CLOUD_API,
        url: `/project/:projectId/user/:userId`,
        params: {
          projectId,
          userId
        },
        headers: authHeaders(),
        action: REMOVE_USER_FROM_PROJECT,
        dispatch,
      });
      return result;
    },

    enableUserInProject: (userId, projectId) => async (dispatch, getState) => {
      const result = await put({
        host: Hosts.EDGE_CLOUD_API,
        url: `/project/:projectId/user/:userId/enable`,
        params: {
          projectId,
          userId
        },
        headers: authHeaders(),
        dispatch,
        action: ENABLE_USER_IN_PROJECT,
      });
      return result;
    },

    changeUserRoleInProject: (userId, projectId, role) => async (dispatch, getState) => {
      const result = await put({
        host: Hosts.EDGE_CLOUD_API,
        url: `/project/:projectId/user/:userId`,
        params: {
          projectId,
          userId
        },
        headers: authHeaders(),
        dispatch,
        action: CHANGE_USER_ROLE_IN_PROJECT,
        body: {role},
        data: {projectId},
      });
      return result;
    },

    getUserRoleInOrg: (userId, orgId) => async (dispatch, getState) => {
      const queryOrgId = orgId ?? selectCurrentOrgId(getState());
      const result = await get({
        host: Hosts.EDGE_CLOUD_API,
        url: `/organization/:orgId/user/:userId`,
        params: {
          orgId: queryOrgId,
          userId
        },
        headers: authHeaders(),
        action: FETCH_USER_ROLE_IN_ORG,
        dispatch,
      });
      return result?.body?.users?.[0];
    },

    // LEGACY APIs, maybe used in the future?
    // oauth2: (authCode) => async (dispatch, getState) => {
    //   return post({
    //     url: "/user/oauth2",
    //     params: {
    //       expand: "tps_id"
    //     },
    //     dispatch,
    //     action: OAUTH2,
    //     body: {
    //       authorization_code: authCode,
    //     },
    //   })
    // },
    //
    verifyEmail: (userId, code) => async (dispatch, getState) => {
      let result = await put({
        host: Hosts.EDGE_CLOUD_API,
        url: "/user/:userId/verify_email",
        params: {userId},
        body: {code},
        headers: authHeaders(),
      });
      pushTo(Urls.DASHBOARD);
    },

    visitProject: (projectId) => async (dispatch, getState) => {
      const userId = selectCurrentUserId(getState());

      let result = await put({
        host: Hosts.EDGE_CLOUD_API,
        url: "/user/:userId/visit_project/:projectId",
        params: {userId, projectId},
        dispatch,
        action: 'VISIT_PROJECT',
        headers: authHeaders(),
      });
    },

    requestResetPassword: (email) => async (dispatch, getState) => {
      let result = await post({
        host: Hosts.EDGE_CLOUD_API,
        url: `/user/request_password_reset`,
        body: { email },
        headers: authHeaders(),
      });
    },

    resetPassword: (userId, token, password) => async (dispatch, getState) => {
      let result = await put({
        host: Hosts.EDGE_CLOUD_API,
        url: "/user/:userId/reset_password",
        params: {userId},
        body: {
          token,
          password
        },
        headers: authHeaders(),
        action: CHANGE_PASSWORD
      });
    },
    // invalidSessionLogout: () => async (dispatch) => {
    //   await dispatch(User.actions.logout(false))
    // },

  },

  spec: {
    users: {},
  },

  modelReducer: (state, type, body, action) => {
    if (action.url && action.result !== RequestState.SUCCESS)
      return state;

    if (!_.isEmpty(body?.users)) {
      let users = _.map(body?.users, user => {
        if (user.user_id) {
          return {
            ...user,
            id: user.user_id,
            user_id: undefined
          }
        } else {
          return user;
        }
      })

      users = (action?.data?.orgId)
        ? _.map(users, user => ({...user, organization_id: action.data.orgId}))
        : users;
      users = (action?.data?.projectId)
        ? _.map(users, user => ({...user, project_id: action.data.projectId}))
        : users;

      state = {
        ...state,
        users: _.merge(state.users, _.keyBy(users, 'id')),
      }
    }

    if (type === REMOVE_USER_FROM_ORG) {
      const userId = action.params.userId;
      const user = state.users[userId];
      if (user) {
        state = {
          ...state,
          users: {
            ...state.users,
            [userId]: {
              ...user,
              disabled: true
            }
          }
        }
      }
    }

    if (type === ENABLE_USER_IN_ORG) {
      const userId = action.params.userId;
      const user = state.users[userId];
      if (user) {
        state = {
          ...state,
          users: {
            ...state.users,
            [userId]: {
              ...user,
              disabled: false
            }
          }
        }
      }
    }

    if (type === REMOVE_USER_FROM_PROJECT) {
      const userId = action.params.userId;
      const user = state.users[userId];
      if (user) {
        state = {
          ...state,
          users: {
            ...state.users,
            [userId]: {
              ...user,
              disabled: true
            }
          }
        }
      }
    }

    if (type === ENABLE_USER_IN_PROJECT) {
      const userId = action.params.userId;
      const user = state.users[userId];
      if (user) {
        state = {
          ...state,
          users: {
            ...state.users,
            [userId]: {
              ...user,
              disabled: false
            }
          }
        }
      }
    }

    return state;
  }


}
export default User;
