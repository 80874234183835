import { Link } from "react-router-dom";
import classNames from "classnames";
import React from "react";

export const ServiceSidebarItem = ({ title, active, icon, link, disabled = false, className }) => {
  return (
    disabled ? <div
      className={classNames('ServiceSidebar__item disabled', className, { 'ServiceSidebar__item--active': active, })}>
      <div className={classNames("ServiceSidebar__item__icon-wrapper")}>{icon}</div>
      <span>{title}</span>
    </div> : <Link to={link} disabled={disabled}>
      <div
        className={classNames('ServiceSidebar__item', className, { 'ServiceSidebar__item--active': active })}>
        <div className={classNames("ServiceSidebar__item__icon-wrapper")}>{icon}</div>
        <span>{title}</span>
      </div>
    </Link>
  )
}