import React from "react";
import cx from "classnames";

export default function PageTitleBar({leftContent, title, alignTitleLeft = false, rightContent, style, className}) {
  const hasSides = (leftContent || rightContent);

  return (
    <div className={cx('PageTitleBar', className)}
         style={style}>
      {
        !alignTitleLeft && hasSides &&
        <div className={'PageTitleBar__left'}>
          {leftContent}
        </div>
      }
      <div className={cx('PageTitleBar__title', {alignLeft: alignTitleLeft})}>
        {title}
      </div>
      {
        hasSides &&
        <div className={'PageTitleBar__right'}>
          {rightContent}
        </div>
      }
    </div>
  );
}

