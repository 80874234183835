import React, {useEffect, useRef, useState} from "react";
import {Button} from "../../components/Button";
import {pushTo} from "../../store/history";
import {Urls} from "../../constants";
import _ from "lodash";
import {createSelector} from "reselect";
import {selectCurrentUserId} from "../../store/models/User";
import {useSelector} from "react-redux";
import {mergeUrl} from "../../utils/url";
import {selectCurrentProjectId} from "../../store/models/Project";
import {selectMobileLayout} from "../../store/UIState";
import {selectCurrentOrg} from "../../store/models/Organization";


const Badges = (projectId) => [
  {
    name: "Llama 3",
    url: mergeUrl(Urls.AI_MODEL_EXPLORER, {projectId, templateId: "img_rrdau7uikg8rhurf7cbei8j77nbp"}),
    redirectUrl: mergeUrl(Urls.AI_MODEL_EXPLORER, {projectId})
  },
  {
    name: "Stable Diffusion",
    url: mergeUrl(Urls.AI_MODEL_EXPLORER, {projectId, templateId: "img_ks064vaw39a5r0873kjh6wagjhju"}),
    redirectUrl: mergeUrl(Urls.AI_MODEL_EXPLORER, {projectId})
  },
  {
    name: "Whisper",
    url: mergeUrl(Urls.AI_MODEL_EXPLORER, {projectId, templateId: "img_xeup0vzqnhra7dm4tpchwr3q9kdu"}),
    redirectUrl: mergeUrl(Urls.AI_MODEL_EXPLORER, {projectId})
  },
  {
    name: "Jupyter Notebook",
    url: mergeUrl(Urls.AI_JUPYTER_NOTEBOOK, {projectId, openCreateModal: true}),
    redirectUrl: mergeUrl(Urls.AI_JUPYTER_NOTEBOOK, {projectId})
  },
  {
    name: "Video NFT DRMs",
    url: Urls.VIDEO_CREATE_VIDEO,
    redirectUrl: mergeUrl(Urls.VIDEO_VIDEOS, {projectId})
  }
]

const selector = createSelector(
  (state) => selectCurrentUserId(state),
  (state) => selectCurrentProjectId(state),
  (state) => selectCurrentOrg(state),
  (state) => selectMobileLayout(state),
  (currentUserId, currentProjectId, curOrg, isMobile) => ({
    currentUserId, currentProjectId, curOrg, isMobile
  })
)

export const LandingPage = () => {
  const bannerRefs = [useRef(null), useRef(null)]
  const {currentUserId, currentProjectId, curOrg, isMobile} = useSelector(state => selector(state))
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0)
  const [currentImageIndex, setCurrentImageIndex] = useState(2)
  const [imageInitialized, setImageInitialized] = useState(false)
  const projectId = currentProjectId ?? 'service'
  const badges = Badges(projectId)

  useEffect(() => {
    if (!imageInitialized && !_.isNil(bannerRefs[0])) {
      bannerRefs[1].current.style.backgroundImage = `url('/images/dashboard/homepage_01.jpg')`
      bannerRefs[0].current.style.backgroundImage = `url('/images/dashboard/homepage_02.jpg')`
      setImageInitialized(true)
    }
  }, [bannerRefs]);

  useEffect(() => {
    if (!_.isNil(bannerRefs[0])) {
      if (currentBannerIndex === 0) {
        swapBanners(0, 1)
        setCurrentBannerIndex(1)
      } else {
        swapBanners(1, 0)
        setCurrentBannerIndex(0)
      }
    }
  }, [currentImageIndex]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentImageIndex === 6)
        setCurrentImageIndex(1)
      else
        setCurrentImageIndex(currentImageIndex + 1)
    }, 6000)

    // Cleanup the timer when component unmounts or currentImageIndex changes
    return () => clearTimeout(timer)
  }, [currentImageIndex])

  const swapBanners = (index1, index2) => {
    const banner1 = bannerRefs[index1].current

    const banner2 = bannerRefs[index2].current
    banner1.style.opacity = 0;
    banner2.style.opacity = isMobile ? 0.30 : 1;

    setTimeout(() => {
      banner1.style.backgroundImage = `url('/images/dashboard/homepage_0${currentImageIndex}.jpg')`
    }, 2000)
  }

  const onSignUp = () => {
    pushTo(Urls.SIGN_UP)
  }
  const onJoinDashboard = () => {
    pushTo(Urls.DASHBOARD)
  }
  const onBadgeClick = (badge) => {
    if (curOrg && (curOrg.suspended || curOrg.disabled))
      pushTo(badge.redirectUrl)
    else
      pushTo(badge.url)
  }

  return (
    <div className={"LandingPage"}>
      <div className={"main-wrapper"}>

        <div ref={bannerRefs[0]} className={"bg-image"}/>
        <div ref={bannerRefs[1]} className={"bg-image"}/>

        {!isMobile && <div className={"badges-list"}>{_.map(badges, badge => {
          return (
            <div className={"badge"} key={badge.name} onClick={() => onBadgeClick(badge)}>
              <div className={"badge-name"}>{badge.name}</div>
            </div>
          )
        })}</div>}

        <div className={"landing-texts"}>
          <div className={"landing-title"}>Empowering AI teams<br/>with unrivaled GPU<br/>price-to-performance</div>
          <div className={"landing-desc"}>The First Hybrid Cloud-Edge Computing Platform<br/>for AI, Video, Rendering,
            Gaming and more.
          </div>
        </div>

        {isMobile
          ? <>
            <Button title={"Try AI Showcase"} href={Urls.MARKETING_AI_SHOWCASES} className={"sign-up-btn"} size={"l"}/>
            <Button title={"Access Dashboard"} href={Urls.DASHBOARD} className={"sign-up-btn"} size={"l"}/>
          </>
          : <Button title={"Start now"} onClick={onJoinDashboard} className={"sign-up-btn"} size={"l"}/>
        }
      </div>
    </div>
  )
}