export const ImageToImageModels = [
  {
    fn_index: 11,
    trigger_id: 15,
    label: "Art style transfer",
    name: "art_style_transfer",
    galleries: [
      {
        label: "Image",
        name: "image",
        galleryLabel: "Image examples",
        paths: [
          `/file=/home/gradio_demos/tmp/1133af6a787b5035c77c3487f28d1a1e3de3de5e/belfry-cropped.jpg`,
          `/file=/home/gradio_demos/tmp/eef33e99bb3e850d1f84e74243a59221c81ab818/puppy-cropped.jpg`,
          `/file=/home/gradio_demos/tmp/b0d64b91221fff2d8774d7541f34965af2043aec/statue-of-liberty.jpg`,
          `/file=/home/gradio_demos/tmp/98e8c9fee517c9c3728337f64e81b31e99cef763/m_content.png`,
          `/file=/home/gradio_demos/tmp/b8a04e4c9c28bef8484e42e687beceaa7d7ffa84/bridge.jpg`,
          `/file=/home/gradio_demos/tmp/3e6fa39dc813c11a98dcfa8ccac395c60c2e2dea/streets.jpg`,
        ]
      },
      {
        label: "Art style image",
        name: "art_style_image",
        galleryLabel: "Art style examples",
        paths: [
          `/file=/home/gradio_demos/tmp/3aad5fd42f361d8a69ab783a759e459b1781e49e/tom_and_jerry.jpg`,
          `/file=/home/gradio_demos/tmp/f14e4a311450e5ba0b9402adde59eb3c8b61400e/m_style.png`,
          `/file=/home/gradio_demos/tmp/4b15fe5fcf1b7a67de7c5d2dee9ed17fad1d0403/starry_night.jpg`,
          `/file=/home/gradio_demos/tmp/d2b40fc841fca6ca937c3cb81e5628288e84af60/style23.jpg`,
          `/file=/home/gradio_demos/tmp/a8cfecdeb94bdd2e865edaad86c51fdefbe86229/style4.jpg`,
          `/file=/home/gradio_demos/tmp/499ee94133c32568901b22cb1dd2f4167837704d/the_scream.jpg`,
        ]
      }
    ]
  },
  {
    fn_index: 12,
    trigger_id: 82,
    label: "Background removal",
    name: "background_removal",
    galleries: [
      {
        label: "Image",
        name: "background",
        galleryLabel: null,
        paths: [
          `/file=/home/gradio_demos/tmp/5de4d9d59a4581c1b23f96144ba93fe92ed2fdea/Dubai_Marina_Skyline.jpg`,
          `/file=/home/gradio_demos/tmp/d3eca8c94d61fee2df5636121dab5310500c7070/hamburger.jpg`,
          `/file=/home/gradio_demos/tmp/96a0e5038e812ba94dcfaeaf20969a28d3d65d20/spongebob.webp`,
          `/file=/home/gradio_demos/tmp/bbecbe259ac40f4be2c355e3c05bf84b4bde7251/chipmunk.jpg`,
          `/file=/home/gradio_demos/tmp/5a493cd2ae7220ada37c5be29076250ed9fe7f9b/McLaren.jpeg`,
        ]
      }
    ]
  },
  {
    instructions: "Upload an image and fill in the area you want to erase.",
    fn_index: 13,
    trigger_id: 25,
    label: "Object erasing",
    name: "object_erasing",
    drawing_enabled: true,
    galleries: [
      {
        label: "Image",
        name: "background",
        galleryLabel: null,
        isLocal: true,
        paths: [
          `/images/showcase/image-to-image/baloon.jpg`,
          `/images/showcase/image-to-image/bird.jpg`,
          `/images/showcase/image-to-image/beach.jpg`,
          `/images/showcase/image-to-image/office.png`,
          `/images/showcase/image-to-image/snow_mountain.jpg`,
        ]
      },
    ]
  },
  {
    instructions: "Upload an image, fill the area you want painted, and describe your request using the prompts",
    fn_index: 8,
    trigger_id: 72,
    label: "In painting",
    name: "in_painting",
    drawing_enabled: true,
    galleries: [
      {
        label: "Image",
        name: "background",
        galleryLabel: null,
        isLocal: true,
        paths: [
          `/images/showcase/image-to-image/baloon.jpg`,
          `/images/showcase/image-to-image/bird.jpg`,
          `/images/showcase/image-to-image/beach.jpg`,
          `/images/showcase/image-to-image/office.png`,
          `/images/showcase/image-to-image/snow_mountain.jpg`,
        ]
      },
    ],
    prompts: [
      {
        name: "prompt",
        label: "Prompt"
      }, {
        name: "negative_prompt",
        label: "Negative prompt"
      }
    ],
    rangeInputs: [
      {
        name: "seed",
        label: "Seed",
        min: 0,
        max: 2147483647,
        step: 1,
        default: 300000000,
        long: true,
        desc: "The seed to use for the random number generator. Changing this will produce different results.",
      }
    ]
  },
  {
    fn_index: 14,
    trigger_id: 92,
    label: "Image upscaling",
    name: "image_upscaling",
    galleries: [
      {
        label: "Image",
        name: "image_to_upscale",
        galleryLabel: null,
        paths: [
          `/file=/home/gradio_demos/tmp/53985aa890ac74a9a9c156b0956f1b7948019424/flower.jpg`,
          `/file=/home/gradio_demos/tmp/20ba11a2514c70ee8acf03af87b1967342db1678/cat.png`,
          `/file=/home/gradio_demos/tmp/bc1da0831a870c562bd7bd11f46e2c5f76add769/grandpa.png`,
          `/file=/home/gradio_demos/tmp/5590b0af3cfe6c6bf67172c39554d6664f6242b1/camel.jpg`,
        ]
      }
    ]
  }

]