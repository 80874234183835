import _ from 'lodash';
import { cssTransition } from "react-toastify";

// ===========================
// HELPERS
// ===========================
export const MOBILE_BREAKPOINT = 768;
export const TABLET_BREAKPOINT = 1024;

// ===========================
// ACTIONS
// ===========================
export const RESIZE_WINDOW = 'RESIZE_WINDOW';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const HIDE_ALL_MODALS = 'HIDE_ALL_MODALS';
export const TOGGLE_SIDEMENU = 'TOGGLE_SIDEMENU';


// ===========================
// SELECTORS
// ===========================
export const selectUI = state => state.ui;
export const selectModals = state => state.ui.modals;
export const selectOpenModal = state => {
  return _.first(selectModals(state))
};
export const selectMobileLayout = state => state.ui.mobileLayout;
export const selectSideMenu = state => state.ui.sidemenu;

// ===========================
// MODEL
// ===========================
const UIState = {

  actions: {

    updateLayout: (width, height) => dispatch => dispatch({
      type: RESIZE_WINDOW,
      width,
      height,
    }),

    showModal: (modal, props) => (dispatch, getState) => {
      if (_.isString(modal))
        modal = { type: modal, props };

      return dispatch({
        type: SHOW_MODAL,
        modal,
      });
    },

    hideModal: (modalType) => (dispatch, getState) => {
      return dispatch({
        type: HIDE_MODAL,
        modalType,
      });
    },

    hideAllModals: () => (dispatch, getState) => {
      return dispatch({ type: HIDE_ALL_MODALS })
    },

    toggleSideMenu: () => (dispatch, getState) =>
      dispatch({
        type: TOGGLE_SIDEMENU,
        show: !selectSideMenu(getState())
      }),

  },

  spec: {
    modals: [],
    window: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    mobileLayout: window?.innerWidth <= MOBILE_BREAKPOINT,
    sidemenu: false,
  },

  reducer: (state = UIState.spec, action) => {
    const { type } = action;

    switch (type) {

      case RESIZE_WINDOW: {
        if(action.width <= MOBILE_BREAKPOINT !== state.mobileLayout)
          return {
            ...state,
            mobileLayout: action.width <= MOBILE_BREAKPOINT,
          }
        if(action.width !== state.window.width || action.height !== state.window.height)
          return {
            ...state,
            window: {
              width: action.width,
              height: action.height,
            }
          }
        return state;
      }

      case SHOW_MODAL: {
        let { type, props } = action.modal;
        return {
          ...state,
          modals: [...state.modals, { type, props }]
        };
      }

      case HIDE_MODAL: {
        return {
          ...state,
          modals: action.modalType
            ? _.reject(state.modals, { type: action.modalType })
            : state.modals.slice(0, -1)
        };
      }

      case HIDE_ALL_MODALS: {
        return {
          ...state,
          modals: []
        }
      }

      case TOGGLE_SIDEMENU:
        return { ...state, sidemenu: action.show }

    }

    return state;
  },
};
export default UIState;
