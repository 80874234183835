import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../components/Modal";
import { Button } from "../components/Button";
import { selectCurrentOrg } from "../store/models/Organization";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { selectCurrentProject, selectProject } from "../store/models/Project";
import { createSelector } from "reselect";
import User, { getUserFullName, selectCurrentUserId, selectUsersInOrg, selectUsersInProject } from "../store/models/User";
import { toast } from "react-toastify";
import { MemberItemModes, MemberItemsList } from "../components/MemberItem";
import Loader from "../components/Loader";
import { InviteIcon } from "../components/Svg";
import { pushTo } from "../store/history";
import { Urls, UserRoleIds, UserRoleLabels } from "../constants";
import { SelectInput } from "../components/form/SelectInput";

const selector = createSelector(
  selectCurrentOrg,
  (state, projectId) => projectId ? selectProject(state, projectId) : selectCurrentProject(state),
  selectUsersInOrg,
  (state, projectId) => projectId ? selectUsersInProject(state, projectId) : [],
  selectCurrentUserId,
  (currentOrg, project, orgUsers, projectUsers, currentUserId) => {
    return { currentOrg, project, orgUsers: _.filter(orgUsers, user => !user.disabled), projectUsers, currentUserId }
  }
)

export const InviteTeamMembersToProjectModal = ({ onRequestClose, onDone, projectId }) => {
  const dispatch = useDispatch()
  const { orgUsers, projectUsers, currentOrg, project, currentUserId } = useSelector(state => selector(state, projectId))

  const [addedUserIds, setAddedUserIds] = useState([])
  const [invitedUsers, setInvitedUsers] = useState([])
  const [emailInput, setEmailInput] = useState('')

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const [selectedRole, setSelectedRole] = useState(UserRoleIds.VIEWER)
  const roleOptions = _.map(UserRoleLabels, (label, key) =>
    ({ label: label, value: key }))

  let filteredUsers = orgUsers
  filteredUsers = _.filter(filteredUsers, user => {
    return user.id !== currentUserId && _.find(projectUsers, { id: user.id }) === undefined
  })
  filteredUsers = _.map(filteredUsers, user => {
    return {
      ...user,
      invited: _.includes(addedUserIds, user.id)
    }
  })
  filteredUsers = [..._.map(invitedUsers, user => {
    return {
      email: user.email,
      role: user.role,
      invited: true
    }
  }), ...filteredUsers];

  useEffect(() => {
    fetchUsers()
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault(); // Prevent form submission
        onCloseModal();
      }
    }

    // Add event listener to window
    window.addEventListener('keydown', handleKeyDown);

    // Remove event listener from window when component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      await dispatch(User.actions.fetchOrganizationUsers(currentOrg.id));
      if (projectId) {
        await dispatch(User.actions.fetchProjectUsers(projectId));
      }
    } catch (e) {
      setError(e.message)
    }
    setLoading(false)
  }

  const inviteUser = async (email, role) => {
    try {
      await dispatch(User.actions.inviteUserToProject({ email, role }, project.id))
      toast.success(`Invitation sent to ${email}`)
      setInvitedUsers([{ email, role }, ...invitedUsers])
      setEmailInput('')
    } catch (e) {
      toast.error(e.message)
    }
  }

  const addUserToProject = async (user, role) => {
    try {
      await dispatch(User.actions.addUserToProject(project.id, user.id, role))
      setAddedUserIds([...addedUserIds, user.id])
      toast.success(`${getUserFullName(user)} has been added to the project`)
    } catch (e) {
      toast.error(e.message)
    }
  }

  const onCloseModal = () => {
    onRequestClose()
    onDone?.()
    if (!projectId) {
      pushTo(Urls.DASHBOARD)
    }
  }

  const onSelectRole = (role) => {
    setSelectedRole(role.value)
  }

  return (
    <Modal className={cx("InviteTeamMembersToProjectModal")}
      onRequestClose={onCloseModal}>

      <ModalHeader>
        <ModalTitle>{"Invite team members to this project"}</ModalTitle>
      </ModalHeader>

      <ModalContent>
        {loading && <Loader />}
        {!loading && error && <div className={"ServicePage__Error"}>
          {error}
          <Button onClick={fetchUsers}
            color={"green-outline"}
            title={"Retry"} />
        </div>}

        {!loading && !error && <>
          <div className={"InviteTeamMembersToProjectModal__InviteRow"}>
              <div className={"NewForm__InputTextWrapper"}>
                <input className={"NewForm__InputLabel"}
                  placeholder={"Invite by email"}
                  value={emailInput}
                  onChange={(e) => setEmailInput(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') inviteUser(e.target.value, selectedRole)
                  }} />
              </div>

              <div className="InviteTeamMembersToProjectModal__InviteRow__Sub">
              <div className={"NewForm__InputWrapper NewForm__InputWrapper--floating"}>
                <SelectInput
                  className={"InviteTeamMembersToProjectModal__InviteRow__RoleSelect"}
                  options={roleOptions}
                  value={{ label: UserRoleLabels[selectedRole], value: selectedRole }}
                  onChange={onSelectRole} />
              </div>

            <Button color={"green-outline"}
              icon={<InviteIcon />}
              onClick={() => inviteUser(emailInput, selectedRole)}
              title={"Invite"} />
                   </div>
                   </div>

          <MemberItemsList users={filteredUsers}
            mode={MemberItemModes.INVITE}
            onInvite={addUserToProject} />

          <ModalActions>
            <Button color={"green"}
              onClick={onCloseModal}
              title={"Done"} />
          </ModalActions>
        </>}

      </ModalContent>
    </Modal>
  );
}