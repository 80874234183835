import _ from 'lodash';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Controller, FormProvider, useFieldArray, useForm, useFormContext} from "react-hook-form";
import {BackButton, Button} from "../../../components/Button";
import {useDispatch, useSelector} from "react-redux";
import {selectServiceAccount} from "../../../store/models/DApp";
import Video from "../../../store/models/Video";
import {useDropzone} from 'react-dropzone';
import {fileNameFromUrl, isValidUrl, isVideoPlatformUrl, mergeUrl} from "../../../utils/url";
import {ModalTypes, SupportedNetworks, Urls} from '../../../constants';
import PageTitleBar from "../../../components/PageTitleBar";
import Select from 'react-select'
import {CgClose, CgInfo} from "react-icons/cg";
import cx from 'classnames';
import EnterpriseBadge from '../../../assets/icons/badge-enterprise.svg';
import Tooltip from "../../../components/Tooltip";
import iconEthereum from '../../../assets/icons/blockchains/icon-ethereum.png';
import iconPolygon from '../../../assets/icons/blockchains/icon-polygon.png';
import iconTheta from '../../../assets/icons/blockchains/icon-theta.png';
import iconPog from '../../../assets/icons/blockchains/icon-pogs.png';
import iconGrove from '../../../assets/icons/blockchains/icon-grove.png';
import iconPassaways from '../../../assets/icons/blockchains/icon-plasm.png';
import {selectCurrentProject} from "../../../store/models/Project";
import {selectCurrentUserId} from '../../../store/models/User';
import UIState from '../../../store/UIState';


const FileInput = props => {
  const {name, label = name} = props
  const {register, unregister, setValue, watch} = useFormContext()
  const files = watch(name)
  const onDrop = useCallback(
    droppedFiles => {
      setValue(name, droppedFiles, {shouldValidate: true})
    },
    [setValue, name]
  )
  const {getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles} = useDropzone({
    onDrop,
    accept: props.accept,
  });
  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);
  const acceptedFileItems = acceptedFiles.map(file => (
    <div key={file.path}
         style={{
           color: '#18C99D'
         }}
    >
      {file.path}
    </div>
  ));

  useEffect(() => {
    register(name)
    return () => {
      unregister(name)
    }
  }, [register, unregister, name])
  return (
    <>
      <div className="container">
        <div {...getRootProps({style})}>
          <input {...props} {...getInputProps()} />
          {!_.isEmpty(acceptedFileItems)
            ? acceptedFileItems
            : <p>Drag & drop or <span className={"choose-file-text"}>Choose a file</span> to upload</p>
          }
        </div>
      </div>
    </>
  )
}

async function uploadFile(url, file) {
  return fetch(url, {
    method: 'PUT',
    body: file,
    headers: {
      "Content-Type": 'application/octet-stream'
    }
  });
}

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '30px',
  borderWidth: 2,
  fontWeight: 600,
  fontSize: 15,
  borderRadius: 14,
  cursor: 'pointer',
  borderColor: '#282B3B',
  borderStyle: 'dashed',
  backgroundColor: 'transparent',
  color: '#636B91',
  marginBottom: 45,
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#18C99D'
};

const acceptStyle = {
  borderColor: '#18C99D'
};

const rejectStyle = {
  borderColor: '#ff1744'
};


function CreateVideoForm({onSubmit, loading, isEnterpriseAccount}) {
  const formMethods = useForm({
    defaultValues: {
      resolutions: resolutionOptions.map(o => o.value),
      video_type: 0,
      use_studio_drm: false,
      chain_id: 361,
      drm_rules: [{nft_collection: "", chain_id: 361}]
    }
  });
  const {register, handleSubmit, control, getValues, setValue, formState: {error, touchedFields}} = formMethods;
  const {fields, append, remove} = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "drm_rules", // unique name for your Field Array
  });

  const [rerenderFlag, setRerenderFlag] = useState(false);
  const [hasNftDrm, setHasNftDrm] = useState(false);
  const videoType = formMethods.getValues().video_type;
  const useStudioDrm = formMethods.getValues().use_studio_drm;

  const onChangeValue = (name, value) => {
    setValue(name, value);
    setRerenderFlag(prevFlag => !prevFlag);
  }

  return (
    <FormProvider {...formMethods}>
      <form className={'Form CreateVideoForm'}>
        <div className={'CreateVideoForm__label'}>Video source</div>
        <input type="text"
               className='CreateVideoForm__default-input'
               placeholder="Enter video url"
               {...register("source_uri", {required: false})} />
        <input type="hidden" {...register("playback_policy", {required: true, value: 'public'})} />

        <div className={'CreateVideoForm__or'}>OR</div>

        <FileInput accept="video/*"
                   name={'file_to_upload'}
                   refs={register("file_to_upload", {required: false})}
        />

        <div className={'CreateVideoForm__label'}>Video name</div>
        <input className='CreateVideoForm__default-input' type="text"
               placeholder="Enter video name" {...register("metadata.filename", {required: false})} />

        <div className='CreateVideoForm__selects'>
          <div className='CreateVideoForm__select'>
            <div className='CreateVideoForm__label'>Resolutions *</div>
            <div className='CreateVideoForm__select--wrap'>
              <Controller
                name="resolutions"
                control={control}
                render={({field: {onChange}}) => (
                  <Select
                    className={'CreateVideoForm__select--resolution'}
                    defaultValue={resolutionOptions}
                    onChange={(options) =>
                      onChange(options?.map((option) => option.value))
                    }
                    styles={{...selectStyles}}
                    options={resolutionOptions}
                    isMulti
                    isClearable={false}
                    placeholder={'Select resolutions'}
                    isSearchable={false}
                  />
                )}
              />
            </div>
          </div>
          <div className='CreateVideoForm__select'>
            <div className='CreateVideoForm__label'>
              Elite Edge Node Workers *
              <Tooltip tooltip={"By default, videos are processed by community ran Elite Edge Nodes."} asChild={true}>
                <div className={"icon-info"}><CgInfo/></div>
              </Tooltip>
            </div>

            <input type="hidden" {...register('video_type')} />
            <div className='CreateVideoForm__encode-options'>
              <div className={cx('CreateVideoForm__encode-option left', {active: videoType === 1})}
                   onClick={() => onChangeValue('video_type', encodeOptions[0].value)}>{encodeOptions[0].label}</div>
              <div className={cx('CreateVideoForm__encode-option right', {active: videoType === 0})}
                   onClick={() => onChangeValue('video_type', encodeOptions[1].value)}>{encodeOptions[1].label}</div>
            </div>

            <div className='CreateVideoForm__select--note'>Internal workers are recommended<br/>for videos longer than 5
              minutes
            </div>
          </div>
        </div>

        <div className={'CreateVideoForm__drm'}>
          <div className={'CreateVideoForm__drm--enable-drm'}>
            <div className={'CreateVideoForm__drm--enable-drm--toggle-wrapper'}>
              <input className={"CreateVideoForm__drm--enable-drm--toggle"} type="checkbox" readOnly
                     checked={hasNftDrm}/>
              <div className={"CreateVideoForm__drm--enable-drm--slider"} onClick={() => setHasNftDrm(!hasNftDrm)}/>

            </div>
            <div className={'CreateVideoForm__drm--enable-drm--texts'}>
              <div className={'CreateVideoForm__drm--enable-drm--title'}>Enable NFT based DRM</div>
              <div className={'CreateVideoForm__drm--enable-drm--description'}>{hasNftDrm
                ? "Select the NFTs that will allow access to your video"
                : "Protect your videos by requiring users to have specific NFT(s)"}
              </div>
            </div>
          </div>

          <div className={cx("drm-details", {hidden: !hasNftDrm})}>
            <input type="hidden" {...register('video_type')} />
            <div className='CreateVideoForm__use-studio-drm'>
              <div className={cx('CreateVideoForm__use-studio-drm--option left', {active: !useStudioDrm})}
                   onClick={() => onChangeValue('use_studio_drm', false)}>
                <div className={"CreateVideoForm__use-studio-drm--option--title"}>Standard DRM</div>
                <div className={"CreateVideoForm__use-studio-drm--option--desc"}>Essential protection for digital
                  content,<br/>perfect for creators and small businesses.
                </div>
              </div>
              <Tooltip tooltip={"Enterprise Plan Required"} hidden={isEnterpriseAccount}>
                <div className={cx('CreateVideoForm__use-studio-drm--option right', {
                  active: useStudioDrm && isEnterpriseAccount,
                  disabled: !isEnterpriseAccount
                })}
                     onClick={() => isEnterpriseAccount && onChangeValue('use_studio_drm', true)}>
                  <div className={"CreateVideoForm__use-studio-drm--option--badge"}><img src={EnterpriseBadge}
                                                                                         alt={"enterprise-badge"}/>
                  </div>
                  <div className={"CreateVideoForm__use-studio-drm--option--title"}>Studio-Level DRM</div>
                  <div className={"CreateVideoForm__use-studio-drm--option--desc"}>Advanced security for high-value
                    content<br/>like movies and TV.
                  </div>
                </div>
              </Tooltip>
            </div>

            <div className={'CreateVideoForm__nft-collection-title'}>NFT Collections</div>
            <div className={'CreateVideoForm__nft-collection-desc'}>Select the NFT collections that will allow access to
              your video
            </div>

            {fields.map((field, index) => {
              return <section key={field.id} className="CreateVideoForm__section">
                <div className='CreateVideoForm__drm-left'>
                  <div className='CreateVideoForm__select--wrap'>
                    <Controller
                      name={`drm_rules.${index}.chain_id`}
                      control={control}
                      render={({field: {onChange}}) => (
                        <Select
                          className='CreateVideoForm__select--resolution'
                          defaultValue={SupportedNetworks[0]}
                          onChange={(option) => onChange(option.value)}
                          styles={selectStyles}
                          options={SupportedNetworks}
                          getOptionLabel={(option) => <CustomOption data={option}/>}
                          placeholder={'Select NFT network'}
                          isSearchable={false}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className={'CreateVideoForm__drm-right'}>
                  <div className='CreateVideoForm__drm-address'>
                    <input type="text"
                           className='CreateVideoForm__drm-address--input '
                           placeholder="Collection Address"
                           {...register(`drm_rules.${index}.nft_collection`, {required: false})} />

                    <CgClose className='CreateVideoForm__drm-address--close-btn' onClick={() => remove(index)}
                             color='grey'/>
                  </div>
                  <div className='CreateVideoForm__drm-address'>
                    <input type="text"
                           className='CreateVideoForm__drm-address--input '
                           placeholder="Collection Name"
                           {...register(`drm_rules.${index}.name`, {required: false})} />
                  </div>

                  <div className='CreateVideoForm__drm-address'>
                    <input type="text"
                           className='CreateVideoForm__drm-address--input '
                           placeholder="Collection Thumbnail URL"
                           {...register(`drm_rules.${index}.image`, {required: false})} />
                  </div>

                  <div className='CreateVideoForm__drm-address'>
                    <input type="text"
                           className='CreateVideoForm__drm-address--input '
                           placeholder="Collection Link"
                           {...register(`drm_rules.${index}.link`, {required: false})} />
                  </div>
                </div>

              </section>
            })}
            <Button role={'button'}
                    className={"CreateVideoForm__nft-collection--add"}
                    onClick={() => append({nft_collection: "", chain_id: 361})}
                    title='Add another collection'/>
          </div>
        </div>

        <Button title={'Save'}
                role={'submit'}
                loading={loading}
                style={{
                  marginTop: 30,
                  width: 200,
                  height: 40,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
                onClick={formMethods.handleSubmit(onSubmit)}
        />
      </form>
    </FormProvider>
  );
}

function CreateVideoPage() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(null);
  const [warning, setWarning] = useState(null);
  const [error, setError] = useState(null);
  const project = useSelector(state => selectCurrentProject(state));
  const userId = useSelector(state => selectCurrentUserId(state));
  const videoApiId = project?.tva_id
  const serviceAccount = useSelector(state => selectServiceAccount(state, videoApiId));
  const isEnterpriseAccount = serviceAccount?.max_studio_drm_streams > 0;

  const onSubmit = async (data) => {
    if (_.isNil(userId)) {
      dispatch(UIState.actions.showModal(ModalTypes.SIGNUP));
      return;
    }
    let videoData = data;

    if (_.isNil(data.file_to_upload)) {
      if (!isValidUrl(videoData.source_uri)) {
        setError('Invalid video URL. Please fix and then try again.');
        return;
      }
      if (isVideoPlatformUrl(videoData.source_uri)) {
        setError('Invalid video URL. You must use the raw video URL (i.e. a downloadable video)');
        return;
      }
    }

    setLoading(true);
    setError(null);

    try {
      if (data.file_to_upload) {
        setState('Uploading video...');
        setWarning('Do not close this tab or browser');
        const file = data.file_to_upload[0];
        const upload = await dispatch(Video.actions.createVideoUploadUrl(videoApiId));
        await uploadFile(upload.presigned_url, file);


        videoData = _.pick(data, ['playback_policy', 'resolutions', 'video_type', 'metadata', 'use_studio_drm']);
        videoData = {
          ...videoData,
          source_upload_id: upload.id,
          file_name: file.name
        }
      } else {
        videoData = _.pick(data, ['source_uri', 'playback_policy', 'resolutions', 'video_type', 'metadata', 'use_studio_drm']);
        videoData = {
          ...videoData,
          file_name: fileNameFromUrl(data.source_uri)
        }
      }

      if (data.drm_rules) {
        let collections = data.drm_rules
          .filter(obj => obj.nft_collection !== '')
          .map(obj => ({
            nft_collection: obj.nft_collection.trim(),
            chain_id: parseInt(obj.chain_id) || 361,
            title: obj.name,
            image: obj.image,
            link: obj.link
          }))
        if (collections.length > 0) {
          videoData['drm_rules'] = collections;
          videoData['use_drm'] = true;
        }
      }

      setState('Creating video...');
      setWarning(null);

      const video = await dispatch(Video.actions.createVideo(videoData));
    } catch (e) {
      setLoading(false);
      setState(null);
      setError(e.message || 'Ooops');
    }
  };

  return (
    <div className={'CreateVideoPage'}>
      <div className={'CreateVideoPage__content'}>
        <PageTitleBar title={'New Video'}
                      leftContent={<BackButton title={'Videos'}
                                               href={mergeUrl(Urls.DASHBOARD_VIDEO)}/>}
        />
        <CreateVideoForm onSubmit={onSubmit}
                         loading={loading}
                         videoApiId={videoApiId}
                         isEnterpriseAccount={isEnterpriseAccount}
        />
        {
          state &&
          <div className={'CreateVideoPage__state'}>
            {state}
          </div>
        }
        {
          warning &&
          <div className={'CreateVideoPage__warning'}>
            {warning}
          </div>
        }
        {
          error &&
          <div className={'CreateVideoPage__error'}>
            {error}
          </div>
        }
      </div>
    </div>
  );
}

const selectStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: 'transparent',
    borderColor: '#191D29',
    height: 40,
    borderRadius: 6,

  }),
  menu: (styles, state) => ({
    ...styles,
    border: 'none',
    backgroundColor: '#191D29',
  }),
  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
    return {
      ...styles,
      backgroundColor: '#191D29',
      borderColor: '#2C2F40',
      color: ((isFocused || isSelected) ? '#18C99D' : '#8A8FB5'),
      cursor: 'pointer',
      fontSize: 14
    };
  },
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: '#636B91',
  }),
  indicatorSeparator: (styles, state) => ({
    ...styles,
    color: '#8A8FB5',
    backgroundColor: '#8A8FB5',
    display: 'none'
  }),
  input: styles => ({fontSize: 14, ...styles}),
  placeholder: styles => ({...styles, color: '#636B91'}),
  singleValue: (styles, {data}) => ({...styles, fontWeight: 500, fontSize: 14, color: '#636B91'}),
  multiValue: styles => ({
    ...styles, padding: 1, borderRadius: 4, backgroundColor: "rgba(24, 201, 157, 0.10)", color: '#18C99D',
    ':hover': {
      backgroundColor: 'rgba(24, 201, 157, 0.25)',
    }
  }),
  multiValueLabel: styles => ({...styles, color: '#18C99D', fontWeight: 600}),
  multiValueRemove: (styles, {isFocused}) => ({
    ...styles,
    ':hover': {
      backgroundColor: 'rgba(24, 201, 157, 0)',
    },
  }),
  valueContainer: styles => ({...styles, paddingRight: 0, border: 'none'})
};

export const CustomOption = ({data}) => (
  <div className={"select-custom-option"}>
    <img src={getBlockchainLogo(data.value)} alt={data.label} style={{width: 24, marginRight: 10}}/>
    {data.label}
  </div>
);

const encodeOptions = [{
  label: 'Internal',
  value: 1
},
  {
    label: 'External',
    value: 0
  }]
const resolutionOptions = [
  {label: '2160P', value: 2160},
  {label: '1080P', value: 1080},
  {label: '720P', value: 720},
  {label: '360P', value: 360},
]

export const getBlockchainLogo = (chainId) => {
  switch (parseInt(chainId)) {
    case 1:
    case 5:
      return iconEthereum
    case 9065:
      return iconPog
    case 137:
      return iconPolygon
    case 47683:
      return iconGrove
    case 7734:
      return iconPassaways
    default:
      return iconTheta
  }
}

export default CreateVideoPage;
