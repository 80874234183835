import React, { useEffect } from 'react';
import cx from 'classnames';
import { TextInput } from './form/TextInput';

const eventListenersMap = new WeakMap();

const Slider = ({ className, ...props }) => {
  const { step, id, max, min, value, onChange } = props;

  useEffect(() => {
    const rangeElement = document.getElementById(id);
    if (!rangeElement) return;
    updateGradient({ target: rangeElement });

    function updateGradient(e) {
      const range = e.target;
      const val = ((value - range.min) / (range.max - range.min)) * 100;
      const color = `linear-gradient(to right, #129978 ${val}%, #3D4463 ${val}%)`;

      // Create a new style element
      const style = document.createElement('style');

      style.textContent = `
      #${range.id}::-moz-range-track {
        background: ${color};
      }
      #${range.id}::-webkit-slider-runnable-track {
        background: ${color};
      }
    `;

      document.head.appendChild(style);
    }
  }, [value]);


  return <div className={cx(className)}>
    <input step={step} type="range" id={id} max={max} min={min} value={value}
      onChange={onChange} />
    <TextInput step={step} max={max} min={min} type={"number"} value={value}
      onChange={onChange} />
  </div>
}
export default Slider;


