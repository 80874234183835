import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { BackArrowIcon } from "../../../components/Svg";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Urls } from "../../../constants";
import { mergeUrl } from "../../../utils/url";
import { AiTabs, AiTab } from '../../../components/AiTabs';
import Ai, { selectDeployment, selectAllVMs } from '../../../store/models/Ai';
import { getLinkByDeployment, getStatus, getHardwareDetail, getPodId, getStatusSum, formatDate, checkActivePod } from '../../../utils';
import classNames from 'classnames';
import Loader from '../../../components/Loader';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Button } from '../../../components/Button';
import { authHeaders } from "../../../utils/fetch";
import { Hosts } from "../../../constants";


function ModelDeploymentDetailPage() {
  const dispatch = useDispatch();
  const { projectId, shard, suffix } = useParams();
  const logRef = useRef("")
  const [logs, setLogs] = useState("")
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const deployment = useSelector(state => selectDeployment(state, projectId, suffix));
  console.log('deployment:', deployment)

  const link = deployment ? getLinkByDeployment(deployment) : '';
  const vms = useSelector(state => selectAllVMs(state));

  useEffect(() => {
    let loop;
    let retry;

    const streamLogs = async () => {
      let response;
      let url = Hosts.EDGE_CLOUD_CONTROLLER_API + `/deployment/${shard}/${suffix}/logs?follow=true&project_id=${projectId}`
      try {
        response = await fetch(url, { headers: authHeaders() });
        if (response.status !== 200) {
          retry = setTimeout(() => streamLogs(), 10 * 1000);
          return;
        }
        const reader = response.body.getReader();

        const read = async () => {
          const { done, value } = await reader.read();
          logRef.current = logRef.current.concat(new TextDecoder().decode(value))
          if (done) {
            setLogs(logRef.current)
            clearInterval(loop)
            streamLogs()
            return;
          }
          setLogs(logRef.current)
          loop = setTimeout(() => {
              read().then().catch(err => {
                logRef.current = ""
                setLogs(logRef.current)
                retry = setTimeout(() => streamLogs(), 10);
              })
          }, 10)
        }

        read().then().catch(err => {
          console.log(err)
          logRef.current = ""
          setLogs(logRef.current)
          retry = setTimeout(() => streamLogs(), 10);
        })
        
      } catch (err) {
        if (typeof err === 'string') {
          console.log("URL is unreachable: ", url, err);
          return;
        }
      }
    }

    (async () => {
      await fetchData();
      streamLogs();
    })();

    return () => {
      clearTimeout(loop);
      clearTimeout(retry);
    };

  }, [projectId, shard, suffix]);

  const fetchData = async () => {
    setLoading(true);
    if (error) setError();
    try {
      await dispatch(Ai.actions.fetchDeploymentDetail(projectId, shard, suffix));
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }


  const [selectedSubTab, setSelectedSubTab] = useState('details')

  return (<div className={'AiServicePage AiServicePage--with-back-button'}>
    <Link to={mergeUrl(Urls.AI_MODEL_DEPLOYMENTS, { projectId })}>
      <div className={'AiServicePage__back-button'}>
        <BackArrowIcon />
        <div className={'AiServicePage__back-button--label'}>
          Model Deployments
        </div>
      </div>
    </Link>
    {!loading && deployment && <div className={'AiServicePage__header detail'}>
      <div className={'AiServicePage__header--title'}>{deployment.Name}</div>
      <div className={classNames(`AiServicePage__header--button detail`, { 'disabled': !checkActivePod(deployment) })}>
        {checkActivePod(deployment) ? <a href={link} target='_blank' >
          Inference Endpoint
        </a> : <>
          <Loader size='small' color='grey' />
          Inference Endpoint
        </>}
      </div>
    </div>}
    {!loading && deployment && <div className={'AiServicePage__content new-deployment'}>
      <AiTabs>
        <AiTab isSelected={(selectedSubTab === 'details')}
          onClick={() => {
            setSelectedSubTab('details')
          }}>
          Details
        </AiTab>
        <AiTab isSelected={(selectedSubTab === 'nodes')}
          onClick={() => {
            setSelectedSubTab('nodes')
          }}>
          Nodes
        </AiTab>
        {/* <AiTab isSelected={(selectedSubTab === 'stats')}
          onClick={() => {
            setSelectedSubTab('stats')
          }}>
          Stats
        </AiTab> */}
        <AiTab isSelected={(selectedSubTab === 'logs')}
          onClick={() => {
            setSelectedSubTab('logs')
          }}>
          Logs
        </AiTab>
      </AiTabs>
      {
        (selectedSubTab === 'details') && deployment &&
        <div className='AiServicePage__tab-content'>
          <div className='ModelDeploymentDetailPage__details'>
            <div className='ModelDeploymentDetailPage__details--row section'>
              <div className='ModelDeploymentDetailPage__details--section-title'>
                STATUS
              </div>
            </div>
            <div className='ModelDeploymentDetailPage__details--row'>
              <div className='ModelDeploymentDetailPage__details--title'>
                Deployment Key
              </div>
              <div className='ModelDeploymentDetailPage__details--value'>
                {deployment.Suffix}
              </div>
            </div>
            <div className='ModelDeploymentDetailPage__details--row'>
              <div className='ModelDeploymentDetailPage__details--title'>
                Endpoint
              </div>
              <div className={classNames('ModelDeploymentDetailPage__details--value green',
                { disabled: !checkActivePod(deployment) })}>
                {checkActivePod(deployment) ? <a href={link} target='_blank' >
                  {link}
                </a> : link}
              </div>
            </div>
            <div className='ModelDeploymentDetailPage__details--row'>
              <div className='ModelDeploymentDetailPage__details--title'>
                Status
              </div>
              <div className='ModelDeploymentDetailPage__details--value status'>
                {getStatusSum(deployment.PodPhase)}
              </div>
            </div>
            <div className='ModelDeploymentDetailPage__details--row'>
              <div className='ModelDeploymentDetailPage__details--title'>
                Replicas
              </div>
              <div className='ModelDeploymentDetailPage__details--value'>
                {deployment.Replicas}
              </div>
            </div>
            <div className='ModelDeploymentDetailPage__details--row'>
              <div className='ModelDeploymentDetailPage__details--title'>
                Available Replicas
              </div>
              <div className='ModelDeploymentDetailPage__details--value'>
                {deployment.Replicas}
              </div>
            </div>
            <div className='ModelDeploymentDetailPage__details--row'>
              <div className='ModelDeploymentDetailPage__details--title'>
                Created
              </div>
              <div className='ModelDeploymentDetailPage__details--value'>
                {formatDate(deployment.CreatedAt)}
              </div>
            </div>
            <div className='ModelDeploymentDetailPage__details--row section'>
              <div className='ModelDeploymentDetailPage__details--section-title'>
                METADATA
              </div>
            </div>
            <div className='ModelDeploymentDetailPage__details--row'>
              <div className='ModelDeploymentDetailPage__details--title'>
                Name
              </div>
              <div className='ModelDeploymentDetailPage__details--value'>
                {deployment.TemplateName}
              </div>
            </div>
            <div className='ModelDeploymentDetailPage__details--row'>
              <div className='ModelDeploymentDetailPage__details--title'>
                Image
              </div>
              <div className='ModelDeploymentDetailPage__details--value'>
                {deployment.ImageURL}
              </div>
            </div>
            <div className='ModelDeploymentDetailPage__details--row'>
              <div className='ModelDeploymentDetailPage__details--title'>
                Hardware
              </div>
              <div className='ModelDeploymentDetailPage__details--value'>
                {getHardwareDetail(vms, deployment.MachineType)}
              </div>
            </div>
          </div>
        </div>
      }
      {
        (selectedSubTab === 'nodes') && deployment &&
        <div className='AiServicePage__tab-content'>
          {deployment.PodPhase.split('; ').map((podStr, i) => {
            return (< div className='ModelDeploymentDetailPage__nodes' key={i}>
              <div className='ModelDeploymentDetailPage__nodes--row'>
                <div className='ModelDeploymentDetailPage__nodes--cell id'>ID</div>
                {/* <div className='ModelDeploymentDetailPage__nodes--cell specs'>Specs</div> */}
                <div className='ModelDeploymentDetailPage__nodes--cell status'>Status</div>
              </div>
              <div className='ModelDeploymentDetailPage__nodes--row'>
                <div className='ModelDeploymentDetailPage__nodes--cell id'>{getPodId(podStr)}</div>
                {/* <div className='ModelDeploymentDetailPage__nodes--cell specs'>2398472938742</div> */}
                <div className='ModelDeploymentDetailPage__nodes--cell status'>{getStatus(podStr)}</div>
              </div>
            </div>)
          })}
        </div>
      }
      {
        (selectedSubTab === 'stats') &&
        <div className='AiServicePage__tab-content'>
          stats
        </div>
      }
      {
        (selectedSubTab === 'logs') &&
        <div className='AiServicePage__tab-content'>
          {(logs && logs.length > 0) ? <div className='AiServicePage__code-container'>
            <SyntaxHighlighter
              lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
              language="javascript"
              style={vscDarkPlus}>
              {logs}
            </SyntaxHighlighter>
          </div> : <div className='EmptyState model-detail'>
            <div className={'EmptyState__title'}>No logs available yet.</div>
          </div>}
        </div>
      }
    </div>}
    {loading && <div className={'EmptyState'}>
      <Loader size='large' color='grey' />
    </div>}
    {!loading && error && !deployment && <div className={"AiServicePage__error"}>
      {error}
      <Button onClick={fetchData}
        color={"green-outline"}
        title={"Retry"} />
    </div>}
  </div >
  );
}

export default ModelDeploymentDetailPage;
