import React, {useState} from 'react';
import {BackButton, Button} from "../../../components/Button";
import {useDispatch, useSelector} from "react-redux";
import PageTitleBar from "../../../components/PageTitleBar";
import Stream from "../../../store/models/Stream";
import {useParams} from "react-router";
import {pushTo} from "../../../store/history";
import {useIsMountedRef} from '../../../hooks/useIsMounted';
import {selectCurrentProject} from "../../../store/models/Project";
import {Urls} from "../../../constants";


function DeleteSteamPage() {
  const dispatch = useDispatch();
  const {streamId} = useParams();
  const project = useSelector(state => selectCurrentProject(state));
  const videoApiId = project?.tva_id
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false);
  const isMountedRef = useIsMountedRef();

  const handleDelete = async () => {
    setLoading(true);

    try {
      await dispatch(Stream.actions.deleteStream(videoApiId, streamId));
    } catch (e) {
      if (e.code === 404) {
        pushTo(Urls.VIDEO_LIVESTREAMS);
        return;
      }
      setError(e.message || 'Ooops');
    } finally {
      if (!isMountedRef.current) return;
      setLoading(false);
    }
  };

  const leftContent = isDeleted ? <BackButton title={'Livestreams'}
                                              href={`/dashboard/video/livestreams`}
  /> : <BackButton title={'Livestream'}
                   href={`/dashboard/video/livestreams/${streamId}`}
  />

  return (
    <div className={'VideoPage'}>
      <div className={'VideoPage__content'}>
        <PageTitleBar title={'Delete Stream'}
                      leftContent={leftContent}
        />
        <div className={'VideoPage__delete'}>
          Do you want to delete livestream {streamId}?
        </div>
        {error && <div className={'CreateVideoPage__error'}>{error}</div>}
        <Button title={'Delete'}
                role={'submit'}
                loading={loading}
                color={'red'}
                style={{
                  marginTop: 30,
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
                onClick={handleDelete}
        />

      </div>
    </div>
  );
}

export default DeleteSteamPage;
