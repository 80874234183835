import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { selectBalance } from "../../../store/models/DApp";
import { useParams } from "react-router";
import PageTitleBar from "../../../components/PageTitleBar";
import { selectCurrentOrg } from '../../../store/models/Organization';
import { Button } from '../../../components/Button';
import { mergeUrl } from '../../../utils/url';
import { Urls } from '../../../constants';
import { Overlay } from '../../../components/Overlay';
import { selectCurrentUserId } from '../../../store/models/User';

function RenderingServicesPage() {
  const dispatch = useDispatch();
  const currentOrg = useSelector(state => selectCurrentOrg(state));
  const userId = useSelector(state => selectCurrentUserId(state));

  useEffect(() => {
    (async () => {
    })();
  }, []);

  return (<div className={'ServiceContainer'}>
    {(!userId || (currentOrg && !currentOrg.disabled)) &&
      <div className={'ServicePage'}>
        <div className={'ServicePage__content'}>
          <div className={'ComingSoonPage'}>
            <div className={"ComingSoonPage__title"}>Rendering/Gaming Services</div>
            <div className={"ComingSoonPage__desc"}>Coming soon!</div>
          </div>
        </div>
      </div>}
    {currentOrg && currentOrg.suspended && <Overlay type={'suspended'} />}
    {currentOrg && currentOrg.disabled && <Overlay type={'disabled'} />}
  </div>
  );
}

export default RenderingServicesPage;
