import React, {useEffect, useState} from "react";
import {DownloadIcon, ImageIcon, ObjectIcon} from "../Svg";
import {Button} from "../Button";
import _ from "lodash";
import cx from "classnames";
import {getUrlFromPath, ShowcaseSketchApi} from "../../store/models/Showcases/ShowcaseSketchApi";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {ModalTypes} from "../../constants";
import Loader from "../Loader";
import UIState, {selectMobileLayout} from "../../store/UIState";
import {createSelector} from "reselect";
import {ImageInput} from "../form/ImageInput";
import {TextInput} from "../form/TextInput";
import {ObjViewer} from "../ObjViewer";
import Tooltip from "../Tooltip";

const LoadingItem = ({loadingData, elapsedTime}) => {
  const status = !loadingData || loadingData?.msg === 'estimation' ? 'scheduled' : 'processing'
  const bottomWrapper = loadingData?.msg === 'estimation'
    ? <div className={"ResultBox__Loading__BottomWrapper"}>Number of jobs ahead of you: <span
      style={{color: '#ffffff'}}>{loadingData.rank + 1}</span></div>
    : loadingData?.msg === 'process_starts'
      ? <div className={"ResultBox__Loading__BottomWrapper"}>Generation in progress...</div>
      : <></>

  return (<div className={cx("ResultBox")}>
    <div className={"ResultBox__Loading"}>
      <div className={"ResultBox__Loading__TopWrapper"}>
        {status} | {elapsedTime}s
      </div>

      <div className={"ResultBox__Loading__CenterWrapper"}>
        <Loader color={"green"}/>
      </div>

      {bottomWrapper}
    </div>
  </div>)

}

const ResultImageItem = ({image, loading, loadingData, elapsedTime}) => {
  if (loading && loadingData) {
    return <LoadingItem loadingData={loadingData} elapsedTime={elapsedTime}/>
  }

  if (!image) return (
    <div className={cx("ResultBox")}>
      <div className={"ResultBox__Idle"}>
        <ImageIcon/>
      </div>
    </div>
  )
  return (
    <div className={cx("ResultBox")}>
      <div className={"ResultBox__Image"}>
        <a href={image} target={"_blank"}>
          <img src={image} alt={"result"}/>
        </a>
      </div>

      <div className={"ResultBox__DownloadWrapper"}>
        <Tooltip tooltip={"Download"}>
          <a href={image} download target={"_blank"}><DownloadIcon/></a>
        </Tooltip>
      </div>
    </div>
  )
}

const ResultObjectItem = ({object, loadingData, loading, elapsedTime}) => {
  if (loading && loadingData) {
    return <LoadingItem loadingData={loadingData} elapsedTime={elapsedTime}/>
  }
  if (!object) return (
    <div className={cx("ResultBox")}>
      <div className={"ResultBox__Idle"}>
        <ObjectIcon/>
      </div>
    </div>
  )
  return (
    <div className={cx("ResultBox")}>
      <ObjViewer url={object}/>

      <div className={"ResultBox__DownloadWrapper"}>
        <Tooltip tooltip={"Download"}>
          <a href={object} download target={"_blank"}><DownloadIcon/></a>
        </Tooltip>
      </div>
    </div>
  )
}

const ImageInputInstructions = ({}) => {
  return (<>
    <div><span className={"ImageInput__Label--Green"}>Upload a sketch</span></div>
    <div>or</div>
    <div>drag image here</div>
  </>)
}


const selector = createSelector(
  selectMobileLayout,
  (isMobile) => ({
      isMobile
    }
  ))

let intervalId = null;

export const ShowcaseSketch = ({showcase}) => {

  const dispatch = useDispatch();

  const url = showcase?.models?.[0]?.urls
  const {isMobile} = useSelector(state => selector(state));
  const [imageGenerating, setImageGenerating] = useState(false)
  const [objGenerating, setObjGenerating] = useState(false)
  const [currentPrompt, setCurrentPrompt] = useState("")
  const [currentNegativePrompt, setCurrentNegativePrompt] = useState("")
  const [loadingData, setLoadingData] = useState(null)
  const [error, setError] = useState(null)
  const [elapsedTime, setElapsedTime] = useState(0)
  const [currentImage, setCurrentImage] = useState(null)
  const [currentImageFile, setCurrentImageFile] = useState(null)
  const [imageGeneratedPath, setImageGeneratedPath] = useState(null)
  const [objectGenerated, setObjectGenerated] = useState(null)
  const imageGenerated = imageGeneratedPath ? getUrlFromPath(imageGeneratedPath, url) : null

  useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
      dispatch(ShowcaseSketchApi.actions.stopEventStreams())
    }
  }, []);

  const onGenerate2d = async (event) => {
    setError(null)
    event.preventDefault();
    setImageGenerating(true)
    try {
      startStopwatch()
      const image2dPath = await dispatch(ShowcaseSketchApi.actions.generate2d(currentImageFile, url, currentPrompt, currentNegativePrompt, onPredictEvent2d))
      setLoadingData(null)
      stopInterval()
      setImageGenerating(false)
      setImageGeneratedPath(image2dPath)
    } catch (e) {
      toast.error(e.message)
      console.error(e)
      setImageGenerating(false)
    }
  };

  const onGenerate3d = async (event) => {
    setError(null)
    event.preventDefault();
    setObjGenerating(true)
    try {
      const object3dPath = await dispatch(ShowcaseSketchApi.actions.generate3d(imageGeneratedPath, url, onPredictEvent3d))
      setLoadingData(null)
      stopInterval()
      setObjGenerating(false)
      setObjectGenerated(`${url}/file=${object3dPath}`)
    } catch (e) {
      toast.error(e.message)
      console.error(e)
      setObjGenerating(false)
    }
  };

  const onImageSelected = async (file, name) => {
    if (!file) return;

    setCurrentImageFile(file)

    try {
      setImageGenerating(true)
      let fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = function () {
        setCurrentImage(fileReader.result)
        setImageGenerating(false)
      }
      fileReader.onerror = function (error) {
        setImageGenerating(false)
        toast.error("Failed to upload image")
      }
    } catch (e) {
      setImageGenerating(false)
      toast.error("Failed to upload image")
    }
  }

  const onPromptChanged = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    switch (name) {
      case 'prompt':
        setCurrentPrompt(value)
        break;
      case 'negative_prompt':
        setCurrentNegativePrompt(value)
        break;
    }
  }

  const onPredictEvent2d = (event) => {
    switch (event.msg) {
      case 'estimation':
        setLoadingData(event)
        break;
      case 'process_starts':
        setLoadingData(event)
        break;
      case 'error':
      case 'unexpected_error':
        onError(event.error)
        break;
    }
  }

  const onPredictEvent3d = (event) => {
    switch (event.msg) {
      case 'estimation':
        startStopwatch()
        setLoadingData(event)
        break;
      case 'process_starts':
        setLoadingData(loadingData => ({...loadingData, msg: 'process_starts'}))
        break;
      case 'error':
      case 'unexpected_error':
        onError(event.error)
        break;
    }
  }

  const onError = (error) => {
    setLoadingData(null)
    setImageGenerating(false)
    setObjGenerating(false)
    stopInterval()
    setError(error ?? "Failed to generate. Try again later")
    toast.error(error ?? "Failed to generate. Try again later")
  }

  const startStopwatch = () => {

    if (intervalId) {
      stopInterval()
    }

    const startTime = new Date().getTime()

    intervalId = setInterval(() => {
      const currentTime = new Date().getTime()
      setElapsedTime(((currentTime - startTime) / 1000).toFixed(0))
    }, 1000)

  }

  const stopInterval = () => {
    clearInterval(intervalId)
    intervalId = null
    setElapsedTime(0)
  }

  const onStopGenerating = (event) => {
    event.preventDefault();

    dispatch(UIState.actions.showModal(ModalTypes.CONFIRM, {
      title: "Stop generating",
      message: "Are you sure you want to stop the ongoing generation?",
      onConfirm: onConfirmStopGenerating,
      confirmLabel: "Yes",
      cancelLabel: "No",
      size: 's'
    }))
  }

  const onConfirmStopGenerating = () => {
    stopInterval()
    setLoadingData(null)
    setImageGenerating(false)
    setObjGenerating(false)
    dispatch(ShowcaseSketchApi.actions.stopEventStreams())
  }

  const onRemoveImage = (name) => {
    setCurrentImage(null)
    setCurrentImageFile(null)
  }

  const onClear2d = (event) => {
    event.preventDefault();
    setImageGeneratedPath(null)
  }

  const onClear3d = (event) => {
    event.preventDefault();
    setObjectGenerated(null)
  }

  return (
    <div className="ShowcaseSketch">
      <div className="ShowcaseSketch__container">
        <div className={"ShowcaseSketch__LeftSide"}>

          <div className={"ShowcaseSketch__ImageInputWrapper"}>
            <form className="SidePanel__form">

              <ImageInput value={currentImage}
                          name={"image"}
                          label={"Image"}
                          onChange={onImageSelected}
                          onRemove={onRemoveImage}
                          formats={"image/jpeg, image/jpg, image/png"}
                          instructions={<ImageInputInstructions/>}/>

            </form>

          </div>
        </div>

        <div className={cx("ShowcaseSketch__RightSide")}>
          {error && <div className={"ErrorMessage"}>{error}</div>}

          <div className={"ShowcaseSketch__Prompts"}>
            <TextInput name={"prompt"} value={currentPrompt} onChange={onPromptChanged} placeholder={"Prompt"}/>
            <TextInput name={"negative_prompt"} value={currentNegativePrompt} onChange={onPromptChanged}
                       placeholder={"Negative Prompt"}/>
          </div>

          <div className={"ShowcaseSketch__ResultBoxes"}>
            <div className={"ShowcaseSketch__ResultWrapper"}>
              <ResultImageItem image={imageGenerated} loading={imageGenerating}
                               loadingData={loadingData} elapsedTime={elapsedTime}/>

              <div className={"ShowcaseSketch__ResultWrapper__LeftBadge"}>2D image</div>

              {imageGenerating
                ? <Button
                  title={"Stop generating"}
                  onClick={onStopGenerating}
                  className="SidePanel__ActionButtons__Button--Primary"
                  color={"red"}
                />
                : imageGenerated
                  ? <Button
                    title={"Clear"}
                    onClick={onClear2d}
                    className="SidePanel__ActionButtons__Button"
                    color={"grey"}/>
                  : <Button
                    title={"Generate 2D"}
                    onClick={onGenerate2d}
                    loading={imageGenerating}
                    className="SidePanel__ActionButtons__Button SidePanel__ActionButtons__Button--Primary"
                    color={"green"}
                    disabled={!_.isNil(loadingData) || _.isNil(currentImageFile)}/>}
            </div>

            <div className={"ShowcaseSketch__ResultWrapper"}>
              <ResultObjectItem object={objectGenerated} loading={objGenerating}
                                loadingData={loadingData} elapsedTime={elapsedTime}/>

              <div className={"ShowcaseSketch__ResultWrapper__LeftBadge"}>Output Model (OBJ Format)</div>

              {objGenerating
                ? <Button
                  title={"Stop generating"}
                  onClick={onStopGenerating}
                  className="SidePanel__ActionButtons__Button--Primary"
                  color={"red"}
                />
                : objectGenerated
                  ? <Button
                    title={"Clear"}
                    onClick={onClear3d}
                    className="SidePanel__ActionButtons__Button"
                    color={"grey"}/>
                  : <Button
                    title={"Generate 3D"}
                    onClick={onGenerate3d}
                    className="SidePanel__ActionButtons__Button SidePanel__ActionButtons__Button--Primary"
                    color={"green"}
                    disabled={_.isNil(imageGenerated)}/>}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}