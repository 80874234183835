import {getUserColor} from "../store/models/User";
import {memo} from "react";

export const UserAvatar = memo(function UserAvatar({ user }) {
  if (_.isEmpty(user)) return <></>

  return (
    <div className={"UserAvatar"}
         style={{backgroundColor: getUserColor(user.first_name, user.last_name, user.email)}}
         >{(user.first_name && user.last_name)
      ? `${user.first_name.slice(0, 1)}${user.last_name.slice(0, 1)}`
      : user?.first_name?.slice(0, 2)}
    </div>
  )
});