import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../components/Modal";
import { Button } from "../components/Button";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import _ from "lodash"
import { generateRandomIdWithPrefix } from '../utils';


export const CreateLLMEndpointModal = ({ onRequestClose, setValue, setCustomEndpoints }) => {
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    formState
  } = useForm();
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    // if (formState.errors.name) {
    //   setError('Please enter a name')
    // }
  }, [formState]);

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(createEndpoint)();
  }

  const createEndpoint = async (body) => {
    body.id = generateRandomIdWithPrefix('c_llm_', 32);
    body.endpoint = body.endpoint.endsWith('/chat/completions') ? body.endpoint.slice(0, -17) : body.endpoint;
    console.log('body:', body);
    setCustomEndpoints(ceps => [body].concat(ceps));
    setValue({ label: body.name, value: body.id })
    onRequestClose();
  }

  return (
    <Modal className={cx("CreateLLMEndpointModal")}
      onRequestClose={onRequestClose}>

      <ModalHeader>
        <ModalTitle>Create New Custom LLM Endpoint</ModalTitle>
      </ModalHeader>

      <ModalContent>

        <div className={cx("NewFormError")}>{error && error}</div>


        <form onSubmit={onSubmit} className={"NewForm"}>
          <div className={"NewForm__Row"}>
            <div className={"NewForm__InputTextWrapper NewForm__InputTextWrapper--half"}>
              <div className={"NewForm__InputLabel"}>LLM Endpoint</div>
              <input name="name"
                type={"text"}
                autoFocus={true}
                placeholder={"Your own OpenAI compatible LLM endpoint, e.g. https://api.openai.com/v1/chat/completions"}
                {...register("endpoint", { required: true })} />
            </div>
            <div className={"NewForm__InputTextWrapper NewForm__InputTextWrapper--half"}>
              <div className={"NewForm__InputLabel"}>LLM Model</div>
              <input name="name"
                type={"text"}
                placeholder={"e.g. gpt-4o-mini"}
                {...register("model", { required: true })} />
            </div>
          </div>
          <div className={"NewForm__Row"}>
            <div className={"NewForm__InputTextWrapper NewForm__InputTextWrapper--half"}>
              <div className={"NewForm__InputLabel"}>API Key (optional)</div>
              <input name="name"
                type={"text"}
                placeholder={"The API key to use LLM endpoint"}
                {...register("access_token")} />
            </div>
            <div className={"NewForm__InputTextWrapper NewForm__InputTextWrapper--half"}>
              <div className={"NewForm__InputLabel"}>Endpoint Name (optional)</div>
              <input name="name"
                type={"text"}
                placeholder={"e.g. My awesome LLM"}
                {...register("name")} />
            </div>
          </div>

          <ModalActions>
            <Button color={"green"} role={'submit'} loading={loading} title={"Create New LLM Endpoint"} />
            <Button color={"transparent"} onClick={onRequestClose} title={"Cancel"} />
          </ModalActions>

        </form>
      </ModalContent>

    </Modal>
  );
}