import _ from 'lodash';
import {del, get, post, put, videoServiceAuthHeaders} from "../../utils/fetch";
import {Hosts, RequestState} from "../../constants";
import {pushTo} from "../history";


// ===========================
// HELPERS
// ===========================


// ===========================
// ACTIONS
// ===========================
export const REGISTER_WEBHOOK = 'REGISTER_WEBHOOK';
export const FETCH_WEBHOOKS_REGISTRATIONS = 'FETCH_WEBHOOKS_REGISTRATIONS';
export const FETCH_WEBHOOKS_REGISTRATION = 'FETCH_WEBHOOKS_REGISTRATION';
export const DELETE_WEBHOOK_REGISTRATION = 'DELETE_WEBHOOK_REGISTRATION';
export const UPDATE_WEBHOOK_REGISTRATION = 'UPDATE_WEBHOOK_REGISTRATION';

// ===========================
// SELECTORS
// ===========================
export const selectAllWebhookRegistrations = (state, serviceAccountId) => {
  return _.filter(Object.values(state.models.webhooks.registrations), (v) => {
    return v.service_account_id === serviceAccountId;
  });
};
export const selectWebhookRegistration = (state, webhookRegId) => {
  const whid = state.models.webhooks.registrations[webhookRegId];

  if (whid) {
    return {
      ...whid
    };
  }
  return null;

};
export const selectTotalWebhookRegistrationPages = (state) => {
  return state.models.totalWebhookRegistrationPages;
}
// ===========================
// MODEL
// ===========================
const Webhook = {
  actions: {
    fetchWebhookRegistrations: (serviceAccountId, page = 1, number = 10) => async (dispatch, getState) => {
      let result = await get({
        host: Hosts.API,
        url: `/webhook/registration/list`,
        headers: videoServiceAuthHeaders(getState(), serviceAccountId),
        params: {
          number,
          page
        },
        action: FETCH_WEBHOOKS_REGISTRATIONS,
        dispatch,
      });
      return selectAllWebhookRegistrations(getState());
    },
    fetchWebhookRegistration: (webhookRegId) => async (dispatch, getState) => {
      let result = await get({
        host: Hosts.API,
        url: `/webhook/registration/${webhookRegId}`,
        headers: videoServiceAuthHeaders(getState()),
        action: FETCH_WEBHOOKS_REGISTRATION,
        dispatch,
      });
      return selectWebhookRegistration(getState(), webhookRegId);
    },
    registerWebhook: (body) => async (dispatch, getState) => {
      let result = await post({
        host: Hosts.API,
        url: "/webhook/registration",
        headers: videoServiceAuthHeaders(getState()),
        action: REGISTER_WEBHOOK,
        dispatch,
        body: body
      });
      let webhook = _.get(result, 'body.webhook_registrations.0');
      let webhookRegId = _.get(webhook, 'id');

      pushTo(`/dashboard/video/webhooks/registrations/${webhookRegId}`);

      return selectWebhookRegistration(getState(), webhookRegId);
    },
    deleteWebhookRegistration: (webhookRegId) => async (dispatch, getState) => {
      let result = await del({
        host: Hosts.API,
        url: `/webhook/registration/${webhookRegId}`,
        headers: videoServiceAuthHeaders(getState()),
        action: DELETE_WEBHOOK_REGISTRATION,
        dispatch,
      });

      pushTo(`/dashboard/video/webhooks`);

      return selectWebhookRegistration(getState(), webhookRegId);
    },
    updateWebhookRegistrationUrl: (webhookRegId, body) => async (dispatch, getState) => {
      let result = await put({
        host: Hosts.API,
        url: `/webhook/registration/${webhookRegId}`,
        headers: videoServiceAuthHeaders(getState()),
        action: UPDATE_WEBHOOK_REGISTRATION,
        dispatch,
        body: body
      });
      return selectWebhookRegistration(getState(), webhookRegId);
    },
  },
  spec: {
    webhooks: {
      registrations: {
        "wbhk_reg_sxk63e6rfqr5rkwv0uw1ysn": {
          "id": "wbhk_reg_sxk63e6rfqr5rkwv0uw1ysn",
          "service_account_id": "srvacc_fvk2n68cp44wuz3qse9gff9j6",
          "name": "ThetaDrop",
          "events": [
            "video.created",
            "video.updated",
            "video.finished",
            "video.errored",
            "video.deleted"
          ],
          "url": "https://api.thetadrop.com/webhooks/tva",
          "create_time": "2023-06-06T22:17:46.619Z",
          "update_time": "2023-06-06T22:17:46.619Z"
        }
      },
      events: {},
      attempts: {}
    },
    totalWebhookRegistrationPages: 1
  },
  modelReducer: (state, type, body, action) => {
    if (action.url && action.result !== RequestState.SUCCESS)
      return state;

    if (type === FETCH_WEBHOOKS_REGISTRATIONS) {
      return {
        ...state,
        webhooks: {
          ...state.webhooks,
          registrations: _.keyBy(body.webhook_registrations, 'id'),
        },
        totalWebhookRegistrtionPages: _.get(body, 'pagination.pages')
      }
    }

    if (type === FETCH_WEBHOOKS_REGISTRATION || type === UPDATE_WEBHOOK_REGISTRATION
      || type === REGISTER_WEBHOOK) {
      return {
        ...state,
        webhooks: {
          ...state.webhooks,
          registrations: _.keyBy(body.webhook_registrations, 'id'),
        }
      }
    }

    return state;
  }
}
export default Webhook;
