import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cx from 'classnames';
import _ from "lodash";
import { Button, CopiedButton, CopyButton } from "../components/Button";
import Modal from "../components/Modal";
import Wallet, { selectTfuelPrice, selectWalletMapping } from "../store/models/Wallet";
import { selectCurrentOrg, selectCurrentOrgId } from '../store/models/Organization';
import Loader from '../components/Loader';
import { CheckmarkIcon, CopyIcon } from '../components/Svg';
import { TFuelPrice } from '../components/Price';
import { formatNumber } from '../utils/number';
import { toast } from 'react-toastify';

const selectors = createSelector(
  (state) => selectCurrentOrg(state),
  (state) => selectWalletMapping(state, selectCurrentOrgId(state)),
  (state) => selectTfuelPrice(state),
  (org, walletMapping, tfuelPrice) => ({ org, walletMapping, tfuelPrice }));

export const DepositTfuelModal = ({ onRequestClose }) => {
  const dispatch = useDispatch();
  const { org, walletMapping, tfuelPrice } = useSelector((state) => selectors(state))
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const address = _.get(walletMapping, 'address');
  const qrCodeURL = `https://api.qrserver.com/v1/create-qr-code/?size=160x160&data=${address}`;
  const [addressCopied, setAddressCopied] = useState(false)
  const [usdAmount, setUsdAmount] = useState('20');
  const tfuelAmount = parseFloat(usdAmount) / tfuelPrice

  useEffect(() => {
    if (!_.isEmpty(org)) {
      fetchAddress();
    }
  }, [org])

  useEffect(() => {
    fetchTfuelPrice();
  }, []);

  const fetchAddress = async () => {
    try {
      setLoading(true);
      setError(null);
      await dispatch(Wallet.actions.fetchWalletMapping(org.id));
    } catch (e) {
      setError(e.message);
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  const fetchTfuelPrice = async () => {
    try {
      await dispatch(Wallet.actions.fetchTfuelPrice());
    } catch (error) {
      toast.error(`Error fetching TFUEL price: ${error.message}`);
      console.error('Error fetching TFUEL price:', error);
    }
  };

  const calculateTfuelValue = () => {
    if (!tfuelPrice || !usdAmount) return { regular: 0, discounted: 0 };
    const regularTfuel = parseFloat(usdAmount) / tfuelPrice;
    const discountedTfuel = regularTfuel * 0.95; // 5% less TFUEL needed
    return {
      regular: regularTfuel.toFixed(2),
      discounted: discountedTfuel.toFixed(2)
    };
  };

  const onAddressCopied = () => {
    navigator.clipboard.writeText(address);
    if (!addressCopied) {
      setAddressCopied(true)
      setTimeout(() => {
        setAddressCopied(false)
      }, 2000)
    }
  }

  const onTryAgain = () => {
    fetchAddress();
  }

  return (
    <Modal
      className={cx("DepositTfuelModal")}
      onRequestClose={onRequestClose}>

      <div className="payment-title">Pay with TFUEL</div>

      <div className={'allow-time-text'}>
        TFUEL deposited to this address will be converted to dollars and available in your organization's account within a few minutes.
      </div>

      {error ? <>
        <div className="error">{error}</div>
        <Button color="green-outline" size="l" title="Try again" onClick={onTryAgain} />
      </> : loading
        ? <Loader />
        : <>
          <div className={'section-title'}>Scan QR code</div>

          <img src={qrCodeURL} className="address-qr" alt="address-qr-code" />

          <div className={'deposit-address-label'}>Deposit Address:</div>
          <div className={'deposit-address-value'}>{address}</div>

          <div onClick={onAddressCopied}>
            {addressCopied
              ? <Button className="copy-btn copied" icon={<CheckmarkIcon />} title="Copied!" />
              : <Button className="copy-btn" icon={<CopyIcon />} title="Copy Address" />}
          </div>

          {tfuelPrice && <div className="tfuel-converter">
            <div className="tfuel-converter-title">TFUEL CONVERTER</div>
            <div className="input-wrapper">
              <div className="input-title">USD credit: </div>
              <span className="currency-symbol">$</span>
              <input
                type="number"
                value={usdAmount}
                onChange={(e) => setUsdAmount(e.target.value)}
                placeholder="Enter USD amount"
                className="usd-input"
              />
            </div>

            <div className="conversion-result">
              <div className="result-title">TFUEL required: </div>
              <TFuelPrice
                amount={tfuelAmount}
                discount={0.05}
                size="m"
                format={v => formatNumber(v, 2, 0, false, true)}>
                <span className="asterisk">*</span>
              </TFuelPrice>
            </div>

            <div className="conversion-note">* Actual TFUEL price may fluctuate with average market price.</div>
          </div>}

          <div className={'disclaimers'}>
            <div className={'disclaimer-title'}>SEND ONLY MAINNET TFUEL TO THIS ADDRESS.</div>
            <div className={'disclaimer-desc'}>Sending any other cryptocurrency to this address may result in loss of your deposit.</div>
          </div>
        </>}

      <div className={'footer'}>
        <Button color="grey" title="Done" onClick={onRequestClose} />
      </div>
    </Modal>
  );
}
