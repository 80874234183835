export function dataURLtoBlob(dataUrl) {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export const blobToFile = (blob, fileName, type) => {
  const file = new File([blob], fileName, { type });
  return file;
};

export const dataURLtoFile = (dataURL, fileName, type) => {
  return blobToFile(dataURLtoBlob(dataURL), fileName, type);
}

export async function urlToFile(url, filename) {
  const response = await fetch(url);
  const blob = await response.blob();
  return blobToFile(blob, filename, 'application/octet-stream');
}