import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import React, {useEffect, useState} from "react";
import {Urls} from "../../constants";
import _ from "lodash";
import Loader from "../../components/Loader";
import PageTitleBar from "../../components/PageTitleBar";
import {Button} from "../../components/Button";
import cx from "classnames";
import AppState, {selectProjectInvite} from "../../store/AppState";
import {pushTo} from "../../store/history";
import User, {selectCurrentUser, selectCurrentUserId} from "../../store/models/User";
import {mergeUrl, useQuery} from "../../utils/url";
import Project, {selectProject} from "../../store/models/Project";
import {toast} from "react-toastify";

const selector = createSelector(
  selectCurrentUserId,
  selectCurrentUser,
  (state, projectId) => selectProject(state, projectId),
  selectProjectInvite,
  (currentUserId, currentUser, project, projectInvite) => (
    {currentUserId, currentUser, project, projectInvite}
  )
)

export const AcceptProjectInvite = () => {

  const dispatch = useDispatch();
  const {project_id: projectId, email, token, user_id: userId} = useQuery();

  const {currentUserId, currentUser, project, projectInvite} = useSelector(state => selector(state, projectId));

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const inviteParams = token ? {projectId, email, token, userId} : projectInvite;

  useEffect(() => {
    if (inviteParams) {
      const {projectId, email, userId, token} = inviteParams;
      dispatch(AppState.actions.saveProjectInvite(projectId, email, token, userId))
    }

    if (_.isEmpty(currentUserId)) {
      if (userId) {
        pushTo(Urls.LOGIN)
      } else {
        pushTo(Urls.SIGN_UP)
      }
    } else if (!error) {
      acceptInvite()
    }
  }, [currentUserId]);

  const acceptInvite = async () => {
    console.log('acceptInvite')
    console.log(inviteParams)
    const inlineInviteParams = inviteParams
    setLoading(true);
    setError(null);
    try {
      await dispatch(User.actions.acceptProjectInvite(inlineInviteParams));
      const project = await dispatch(Project.actions.fetchProject(inlineInviteParams?.projectId, {expand: 'org_id'}));
      await dispatch(AppState.actions.selectCurrentOrgAndProject(project?.org_id, project?.id));
      dispatch(AppState.actions.clearProjectInvite())
      toast.success(`You have successfully joined ${project?.name ?? 'a new organization'}`)
      pushTo(Urls.DASHBOARD)
    } catch (e) {
      setError(e.message)
      setLoading(false);
    }
  }

  const onLogout = async () => {
    if (userId) {
      await dispatch(User.actions.logout(true, mergeUrl(Urls.LOGIN, inviteParams)));
    } else {
      await dispatch(User.actions.logout(true, mergeUrl(Urls.SIGN_UP, inviteParams)));
    }
  }

  return (
    <div className={"ServicePage"}>
      <div className={cx("ServicePage__content")}>
        <div className={"AcceptProjectInvite"}>
          <PageTitleBar title={`Joining a new organization`}/>
          <div className={"AcceptProjectInvite__desc"}>{`You have been invited to join a project`}</div>
        </div>

        {loading && <Loader/>}

        {!loading && error && <div className={"ServicePage__Error ServicePage__Error--red"}>
          {error}

          <div className={"ServicePage__Error__Buttons"}>
            <Button onClick={acceptInvite}
                    color={"green-outline"}
                    title={"Retry"}/>

            <Button onClick={onLogout}
                    color={"red-outline"}
                    title={"Logout"}/>

          </div>

        </div>}

      </div>
    </div>)
}
