import _ from 'lodash';

const ModelState = {
  init: modelTypes => {
    // Gather actions from all model objects
    ModelState.actions = _.assign({}, _.map(modelTypes, e => [e.actions]));

    // Create initial model spec from model objects
    ModelState.spec = _.assign({}, ..._.map(modelTypes, e => e.spec));

    // Gather state reducers from all model objects
    let reducers = _.chain(modelTypes)
      .map('modelReducer')
      .compact()
      .value();

    ModelState.reducer = function (state = ModelState.spec, action) {
      var {json, type} = action;
      var body = _.get(json, 'body');

      // Go through model reducer functions
      return _.reduce(reducers, (s, f) => f(s, type, body, action, json), state);
    }

  },
  spec: {},
  actions: {},
  reducer: null,
};

export default ModelState;
