import _ from 'lodash';
import React, {useState, useEffect, useContext} from 'react';
import plusSVG from '../../assets/icons/plus.svg';
import equalsSVG from '../../assets/icons/equals-vertical.svg';
import tfuelIcon from '../../assets/icons/tokens/tfuel-small@2x.png';
import {Step} from "../../components/Step";
import {Button} from "../../components/Button";
import {useVideoJS} from "../../hooks/useVideoJS";
import {Hosts, Urls} from "../../constants";
import {useInterval} from "../../hooks/useInterval";
import Video from "../../store/models/Video";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {selectCurrentUserId} from "../../store/models/User";

const sampleVideoUrl = 'https://tva-demo.s3.amazonaws.com/world.mp4';
const sampleCreateVideoBody = {"source_uri": sampleVideoUrl, "playback_policy": "public"};


function SamplePricingCard({title, subtitle, price}) {
  return (
    <div className={'SamplePricingCard'}>
      <div className={'SamplePricingCard__title'}>
        {title}
      </div>
      <div className={'SamplePricingCard__subtitle'}>
        {subtitle}
      </div>
      <div className={'SamplePricingCard__price'}>
        {price}
      </div>
    </div>
  )
}

function SamplePricingSection({userId}) {
  return (
    <div className={'SamplePricingSection'}>
      <div className={'SamplePricingSection__title'}>
        Monthly Cost Example
      </div>
      <div className={'SamplePricingSection__subtitle'}>
      </div>
      <div className={'SamplePricingSection__cards'}>
        <SamplePricingCard title={'Video length'}
                           subtitle={'5 MINUTES AVERAGE'}
                           price={`$75/mo`}
        />
        <img src={plusSVG}
             className={'SamplePricingSection__plus-icon'}/>
        <SamplePricingCard title={'Videos uploaded'}
                           subtitle={'500 VIDEOS PER MONTH'}
                           price={`$5/month`}
        />
        <img src={plusSVG}
             className={'SamplePricingSection__plus-icon'}/>
        <SamplePricingCard title={'View count'}
                           subtitle={'50,000 VIEWS PER MONTH'}
                           price={`$150/month`}
        />
      </div>

      <img src={equalsSVG}
           className={'SamplePricingSection__equals-icon'}/>

      <div className={'SamplePricingSection__estimated-title'}>
        Estimated cost with existing solutions:
      </div>
      <div className={'SamplePricingSection__estimated-cost'}>
        $230/month
      </div>

      <div className={'SamplePricingSection__enable-edge-title'}>
        <span>Enable Theta edge network and pay in</span>
        <img src={tfuelIcon}/>
        <span>TFUEL</span>
      </div>
      <div className={'SamplePricingSection__enable-edge-cost'}>
        <span className={'usd'}>$97.65/month</span>
        <span className={'tfuel'}>(~376.54 TFUEL)</span>
      </div>
      <div className={'SamplePricingSection__enable-edge-savings'}>
        (57% savings)
      </div>

      <Button title={'Get Started'}
              color={'green'}
              size={'xlarge'}
              className={'SamplePricingSection__cta-button'}
              href={userId ? Urls.VIDEO_VIDEOS : Urls.SIGN_UP}
      />

      <Button title={'Get Started'}
              color={'green'}
              size={'large'}
              className={'SamplePricingSection__cta-button mobile-only'}
              href={userId ? Urls.VIDEO_VIDEOS : Urls.SIGN_UP}
      />
    </div>
  )
}

export function PricingCard({title, price, tfuelPrice, priceSubtitle, product, savings, hideCTA, userId}) {
  return (
    <div className={'PricingCard'}>
      <div className={'PricingCard__title'}>
        {title}
      </div>
      <div className={'PricingCard__price'}>
        <span className={'usd'}>{price}</span>
        {/* {
          tfuelPrice &&
          <span className={'tfuel'}>{`(~${tfuelPrice} TFUEL)`}</span>
        } */}
      </div>
      <div className={'PricingCard__price-subtitle'}>
        {priceSubtitle}
      </div>
      {
        product &&
        <div className={'PricingCard__product'}>
          <div>Enable Theta</div>
          <div>
            <span>{`edge node`}</span>
            <span style={{
              marginLeft: 6,
              textDecoration: 'underline'
            }}>{product}</span>
          </div>
        </div>
      }
      {
        savings &&
        <div className={'PricingCard__savings'}>
          {`SAVE up to ${savings}`}
        </div>
      }

      {
        hideCTA !== true &&
        <Button title={'Try it!'}
                color={'grey'}
                size={'large'}
                href={userId ? Urls.VIDEO_VIDEOS : Urls.SIGN_UP}
        />
      }


      {/*<div className={'PricingCard__tfuel-discount'}>*/}
      {/*  <img src={tfuelIcon}/>*/}
      {/*  <span>Pay in TFUEL (10% discount)</span>*/}
      {/*</div>*/}
    </div>
  )
}

export function PricingSection({userId}) {
  return (
    <div className={'PricingSection'}>
      <div className={'PricingSection__title'}>
        Our Pricing
      </div>
      {/* <div className={'PricingSection__subtitle'}>
        <span>(Accepting </span>
        <img src={tfuelIcon}/>
        <span> TFUEL only)</span>
      </div> */}
      <div className={'PricingSection__cards'}>
        <PricingCard title={'Video ingest'}
                     price={`$0.015/min`}
                     tfuelPrice={0.12}
                     priceSubtitle={'(based on video file length)'}
                     product={'encoding'}
                     savings={'40%'}
                     userId={userId}
        />
        <PricingCard title={'Video transcode'}
                     price={`$0.015/min`}
                     tfuelPrice={0.002}
                     priceSubtitle={'(based on compute time)'}
                     product={'storage'}
                     savings={'30%'}
                     userId={userId}
        />
        <PricingCard title={'Video delivery'}
                     price={`$0.024`}
                     tfuelPrice={0.0023}
                     priceSubtitle={'(based on video data delivered)'}
                     product={'delivery'}
                     savings={'60%'}
                     userId={userId}
        />
      </div>
      {/*<div className={'PricingSection__asterisks'}>*/}
      {/*  *Available in 2022*/}
      {/*</div>*/}
    </div>
  )
}

export function LiveDemoCardStep({number, title, description, children}) {
  return (
    <div className={'LiveDemoCardStep'}>
      {
        number &&
        <div className={'LiveDemoCardStep__number'}>
          {`Step ${number}`}
        </div>
      }
      <div className={'LiveDemoCardStep__title'}>
        {title}
      </div>
      <div className={'LiveDemoCardStep__description'}>
        {description}
      </div>
      {
        children
      }
    </div>
  )
}

function LiveDemoPlaybackStep({playbackUrl}) {
  const {Video, player, ready} = useVideoJS(
    {
      fluid: true,
      controls: true,
      sources: [{
        src: playbackUrl
      }]
    }
  );

  return (
    <LiveDemoCardStep number={2}
                      title={'Play anywhere'}
                      description={'Get the streaming URL with ready prepared video assets, fully transcoded and optimized for all your devices and bandwidth. Simplified.'}
    >
      <div className={'URLCard URLCard--green'}>
        {playbackUrl}
      </div>
      <Video muted autoPlay loop/>
    </LiveDemoCardStep>
  );
}

function LiveDemoCard() {
  const [startDate, setStartDate] = useState(null);
  const [isDemoRunning, setDemoRunning] = useState(false);
  const [createVideoResponse, setCreateVideoResponse] = useState(null);
  const playbackUrl = _.get(createVideoResponse, 'body.videos.0.playback_uri', null);
  const progress = _.get(createVideoResponse, 'body.videos.0.progress', 0);
  const [timeElapsed, setTimeElapsed] = useState(null);
  const [updateIntervalId, setUpdateIntervalId] = useState(null);

  useInterval(() => {
    if (isDemoRunning && _.isNil(playbackUrl)) {
      setTimeElapsed(((Date.now() - startDate) / 1000).toFixed(1));
    }

    if(!_.isNil(playbackUrl) && updateIntervalId){
      clearInterval(updateIntervalId);
    }
  }, 100);

  const getVideo = async (videoId) => {
    const response = await fetch(
      `${Hosts.API}/video/${videoId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    const jsonResponse = await response.json();

    if (response.ok) {
      setCreateVideoResponse(jsonResponse);
    } else {
      const error = _.get(jsonResponse, ['error', 'message']);
    }
  };

  const startDemo = async () => {
    setDemoRunning(true);
    setStartDate(Date.now());

    const response = await fetch(
      `${Hosts.API}/video/demo`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(sampleCreateVideoBody)
      }
    );
    const jsonResponse = await response.json();

    if (response.ok) {
      const videoId = _.get(jsonResponse, 'body.videos.0.id', null);
      setCreateVideoResponse(jsonResponse);
      const id = setInterval(() => {
        getVideo(videoId);
      }, 1000);
      setUpdateIntervalId(id);
    } else {
      const error = _.get(jsonResponse, ['error', 'message']);
    }
  };

  return (
    <div className={'LiveDemoCard'}>
      <LiveDemoCardStep number={1}
                        title={'Upload video'}
                        description={'Upload a video file via Theta video API. Theta will automatically encode and process it for streaming. No work on your end.'}
      >
        <div className={'URLCard'}>
          POST https://api.thetavideoapi.com/video
        </div>

        <pre className="LiveDemoCardStep__code">
                    <span>{`{`}</span><br/>
                    <span>{`  "input": "`}</span>
                    <span
                      // TODO if we want users to add their own...
                      // contentEditable="true"
                      // role="textbox"
                      // tabIndex="0"
                    >
                        {sampleVideoUrl}
                    </span><span>{`",`}</span>
                    <br/>
                    <span>{`  "playback_policy": "public"`}</span><br/>
                    <span>{`}`}</span>
                </pre>

        <Button title={'Run'}
                onClick={startDemo}
                loading={isDemoRunning}
                disabled={!_.isNil(createVideoResponse)}
        />
        {
          isDemoRunning && _.isNil(createVideoResponse) &&
          <div className="LiveDemoCardStep__status">{`Creating video...`}</div>
        }
        {
          isDemoRunning && _.isNil(playbackUrl) && progress === 0 &&
          <div className="LiveDemoCardStep__status">{`Encoding video: ${timeElapsed}s`}</div>
        }
        {
          isDemoRunning && _.isNil(playbackUrl) && progress > 0 &&
          <div className="LiveDemoCardStep__status">{`Encoding video: ${progress}%`}</div>
        }
      </LiveDemoCardStep>
      {
        playbackUrl &&
        <LiveDemoPlaybackStep playbackUrl={playbackUrl}/>
      }
      {
        playbackUrl &&
        <LiveDemoCardStep number={3}
                          title={'Optimize with Theta P2P'}
                          description={'Save up to 60% by integrating the Theta P2P in a few lines of code.'}
        >
          <Button title={'View Documentation'}
                  href={'https://docs.thetatoken.org/docs/theta-video-api-overview'}
                  target={'_blank'}
          />
        </LiveDemoCardStep>
      }
    </div>
  )
}

function LiveDemoSection() {
  return (
    <div className={'LiveDemoSection'}>
      <div className={'LiveDemoSection__title'}>
        Try the live demo
      </div>
      <div className={'LiveDemoSection__subtitle'}>
        Theta Video API. Decentralized.
      </div>

      <LiveDemoCard/>
    </div>
  )
}

const selector = createSelector(
  (state) => selectCurrentUserId(state),
  (userId) => ({
    userId
  })
)

function LandingPageTVA() {
  const {userId} = useSelector(state => selector(state));
  return (
    <div className={'LandingPageTVA'}>
      <div className={'LandingPageTVA__content'}>
        <div className={'LandingPageTVA__pitch'}>
          Simple Video API for developers. Decentralized. Web 3.0
        </div>
        <img className={'LandingPageTVA__powered-by'}
             src={"/images/powered-by-theta.svg"}
        />
        <div className={'LandingPageTVA__what'}>
          Build video into any application with a few lines of code. No hassle and video stack development needed. Simple. Cost-effective. Powered by Theta.
        </div>
        <div className={'LandingPageTVA__steps'}>
          <Step number={1}
                title={'Upload video'}
          />
          <Step number={2}
                title={'Play anywhere'}
          />
          <Step number={3}
                title={'Optimize with Theta P2P'}
          />
        </div>
        <LiveDemoSection/>
        <PricingSection userId={userId}/>
        <SamplePricingSection userId={userId}/>
      </div>
    </div>
  );
}

export default LandingPageTVA;
