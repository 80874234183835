import {Base64} from 'js-base64';

export const getShortId = (id) => {
  //first three characters + 3 dots + last three characters
  return id.slice(0, 10) + '...' + id.slice(-6);
}


export const encodeData = (data) => {
  const salt = 'theta-edge-cloud';
  
  try {
      // Convert data to JSON string
      const jsonString = JSON.stringify(data);
      
      // Convert string to Uint8Array
      const dataArray = new TextEncoder().encode(jsonString);
      const saltArray = new TextEncoder().encode(salt);
      
      // Create result array with space for salt and data
      const resultArray = new Uint8Array(saltArray.length + dataArray.length);
      
      // Copy salt to beginning
      resultArray.set(saltArray);
      // Copy data after salt
      resultArray.set(dataArray, saltArray.length);
      
      // XOR with salt
      for (let i = 0; i < resultArray.length; i++) {
          resultArray[i] = resultArray[i] ^ saltArray[i % saltArray.length];
      }
      
      // Convert to base64 and then to base64url
      const base64 = Base64.encode(String.fromCharCode.apply(null, resultArray));
      const base64url = base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
      
      return base64url;
  } catch (error) {
      console.error('Encoding error:', error);
      console.log('Input data:', data);
      throw error;
  }
}

export const decodeData = (protectedString) => {
  const salt = 'theta-edge-cloud'; // Same unique salt used for protection
  const decoder = new TextDecoder();
  const base64String = protectedString.replace(/-/g, '+').replace(/_/g, '/');
  const decodedArray = new Uint8Array(atob(base64String).split('').map(char => char.charCodeAt(0)));
  const saltArray = new TextEncoder().encode(salt);
  for (let i = 0; i < decodedArray.length; i++) {
    decodedArray[i] = decodedArray[i] ^ saltArray[i % saltArray.length];
  }
  const dataArray = decodedArray.slice(saltArray.length);
  const jsonString = decoder.decode(dataArray);
  return JSON.parse(jsonString);
}