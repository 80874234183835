import React, { useState, useRef } from 'react';
import cx from 'classnames';
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../components/Modal";
import { Controller, FormProvider, useFieldArray, useForm } from "react-hook-form";
import { Button } from "../components/Button";
import Select from 'react-select'
import classNames from 'classnames';
import { selectCurrentProjectId } from '../store/models/Project';
import { useSelector, useDispatch } from "react-redux";
import Ai, { } from '../store/models/Ai';
import { ModalTypes } from '../constants';
import { selectCurrentUserId } from '../store/models/User';
import UIState from '../store/UIState';
import { toast } from "react-toastify";
import Tooltip from '../components/Tooltip';
import { QuestionMarkIcon } from '../components/Svg';

const selectStyles = {
  container: styles => ({
    ...styles,
    flex: 1,
    height: 40,
  }),
  control: (styles, { isFocused }) => ({
    ...styles,
    paddingLeft: 15,
    backgroundColor: '#191D29',
    borderColor: '#3D4463',
    borderRadius: 6,
    boxShadow: isFocused ? '0 0 0 1px #18c99d' : 'none',
    ':hover': {
      borderColor: '#18c99d',
      boxShadow: '0 0 0 1px #18c99d',
    },
    '.selected': {
      borderColor: '#18c99d',
      boxShadow: '0 0 0 1px #18c99d',
    }
  }),
  menu: (styles, state) => ({
    ...styles,
    border: '1px solid #3D4463',
    backgroundColor: '#191D29',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#191D29',
      borderColor: '#3D4463',
      color: (isFocused ? 'white' : (isSelected ? 'white' : '#636B91')),
    };
  },
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: '#8A8FB5',
  }),
  indicatorSeparator: (styles, state) => ({
    ...styles,
    color: '#636B91',
    backgroundColor: '#636B91',
    display: 'none'
  }),
  input: styles => ({ ...styles }),
  placeholder: styles => ({ ...styles, color: '#636B91' }),
  singleValue: (styles, { data }) => ({ ...styles, color: '#636B91', fontWeight: '500' }),
  multiValue: (styles, { data }) => {
    let bgColor = '#636B91';
    switch (data.value) {
      case 'Text2image':
        bgColor = 'rgba(24, 201, 198, .1)';
        break;
      case 'Gradio':
        bgColor = 'rgba(255, 66, 220, .1)';
        break;
      case 'API':
        bgColor = 'rgba(0, 157, 255, .1)';
        break;
      case 'WebUI':
        bgColor = 'rgba(252, 238, 33, 0.1)';
        break;
      case 'LLM':
        bgColor = 'rgba(66, 113, 252, .1)';
        break;
      case 'MoE':
        bgColor = 'rgba(161, 69, 255, .1)';
        break;
      case 'CodeGen':
        bgColor = 'rgba(255, 125, 38, .1)';
        break;
      case 'ImageGen':
        bgColor = 'rgba(255, 48, 48, .1)';
        break;
      case 'VideoGen':
        bgColor = 'rgba(224, 110, 205, .1)';
        break;
      case '3DGen':
        bgColor = 'rgba(155, 230, 34, .1)';
        break;
      case 'SpeechRec':
        bgColor = 'rgba(20, 181, 42, .1)';
        break;
      default:
        break;
    }
    return {
      ...styles,
      fontSize: '16px',
      backgroundColor: bgColor
    }
  },
  multiValueLabel: (styles, { data }) => {
    let color = '#ffffff';
    switch (data.value) {
      case 'Text2image':
        color = '#18C9C6';
        break;
      case 'Gradio':
        color = '#FF42DC';
        break;
      case 'API':
        color = '#009DFF';
        break;
      case 'WebUI':
        color = '#fcee21';
        break;
      case 'LLM':
        color = '#4271FC';
        break;
      case 'MoE':
        color = '#A145FF';
        break;
      case 'CodeGen':
        color = '#FF7D26';
        break;
      case 'ImageGen':
        color = '#FF3030';
        break;
      case 'VideoGen':
        color = '#E06ECD';
        break;
      case '3DGen':
        color = '#9be622';
        break;
      case 'SpeechRec':
        color = '#14B52A';
        break;
      default:
        break;
    }
    return {
      ...styles,
      color: color
    }
  }
};

export const CreateCustomImageModal = ({ onRequestClose, type, template }) => {
  // console.log('CreateCustomImageModal template:', template)
  const dispatch = useDispatch()
  const userId = useSelector(state => selectCurrentUserId(state));
  const projectId = useSelector(state => selectCurrentProjectId(state));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedFramework, setSelectedFramework] = useState('gradio');
  const tagOptions = [{ label: 'ImageGen', value: 'ImageGen' }, { label: 'CodeGen', value: 'CodeGen' },
  { label: 'VideoGen', value: 'VideoGen' }, { label: '3DGen', value: '3DGen' },
  { label: 'LLM', value: 'LLM' }, { label: 'MoE', value: 'MoE' },
  { label: 'WebUI', value: 'WebUI' }, { label: 'API', value: 'API' },
  { label: 'SpeechRec', value: 'SpeechRec' },
  ]

  const nameRef = useRef();
  const imageRef = useRef();
  const portRef = useRef();
  const tagsRef = useRef();
  const descriptionRef = useRef();
  const argsRef = useRef();
  const varsRef = useRef();

  const onSubmit = async () => {
    if (_.isNil(userId)) {
      dispatch(UIState.actions.showModal(ModalTypes.SIGNUP))
      return;
    }
    let name = nameRef.current.value;
    let image = imageRef.current.value.replace(/^https:\/\//, '');;
    let port = portRef.current.value;
    let description = descriptionRef.current.value;
    let tags = tagsRef.current.state.selectValue.map(option => option.value)
    // console.log('name:', name, ' image:', image)
    // console.log('port:', port, ' description:', description)
    // console.log('tags:', tags, ' projectId:', projectId)
    let args;
    let vars;
    try {
      if (argsRef.current.value.trim() !== '') {
        args = JSON.parse(argsRef.current.value);
      } else {
        args = null;
      }
    } catch (error) {
      args = null;
    }
    // console.log('args:', args,)

    try {
      if (varsRef.current.value.trim() !== '') {
        vars = JSON.parse(varsRef.current.value);
      } else {
        vars = null;
      }
    } catch (error) {
      vars = null;
    }
    // console.log('vars:', vars,)

    if (name === '') {
      setError('Custom template name is required');
      nameRef.current.focus();
      return;
    }
    if (image === '') {
      setError('Custom template image is required');
      imageRef.current.focus();
      return;
    }
    if (port === '') {
      setError('Custom template port is required');
      portRef.current.focus();
      return;
    }



    setLoading(true);
    setError(null);

    if (type === 'update') {
      try {
        const tmp = await dispatch(Ai.actions.updateCustomTemplate(template.id, {
          name: name,
          project_id: projectId,
          description: description,
          container_image: [image],
          tags: tags,
          container_port: port,
          container_args: args,
          env_vars: vars,
          icon_url: "",
        }))
        // console.log('update tmp:', tmp)
        await dispatch(Ai.actions.fetchCustomTemplates(projectId, 1, 8));
        onRequestClose();
      } catch (e) {
        setLoading(false);
        setError(e.message);
        toast.error('Error: ' + e.message);
      }
    } else {
      try {
        const tmp = await dispatch(Ai.actions.createCustomTemplate({
          name: name,
          project_id: projectId,
          description: description,
          container_image: [image],
          tags: tags,
          container_port: port,
          container_args: args,
          env_vars: vars,
          icon_url: "",
        }))
        // console.log('create tmp:', tmp)
        await dispatch(Ai.actions.fetchCustomTemplates(projectId, 1, 8));
        onRequestClose();
      } catch (e) {
        setLoading(false);
        setError(e.message);
        toast.error('Error: ' + e.message);
      }
    }
  }

  return (
    <Modal className={cx("CreateCustomImageModal")}
      onRequestClose={onRequestClose}>

      <ModalHeader>
        <ModalTitle align={'row'}>
          {type === 'update' ? 'Update' : 'Create New'} Custom Template
          {type !== 'update' && <Tooltip tooltip={'Open documentation'}>
            <a href="https://docs.thetatoken.org/docs/running-generic-containerized-workload" target='_blank'>
              <QuestionMarkIcon />
            </a>
          </Tooltip>}
        </ModalTitle>
      </ModalHeader>
        <ModalContent>
          <div className='CreateCustomImageModal__row'>
            <div className='CreateCustomImageModal__section-wrap name'>
              <div className='CreateCustomImageModal__select-label'>Name</div>
              <input className={"CreateCustomImageModal__input"}
                placeholder={'Enter custom template name'}
                ref={nameRef}
                defaultValue={type === 'update' ? template.name : ''}
              />
            </div>
          </div>
          <div className='CreateCustomImageModal__row'>
            <div className='CreateCustomImageModal__section-wrap image'>
              <div className='CreateCustomImageModal__select-label'>Container Image URL</div>
              <input className={"CreateCustomImageModal__input"}
                placeholder={'Enter custom template container image url'}
                ref={imageRef}
                defaultValue={type === 'update' ? template.container_images[0] : ''}
              />
            </div>
            <div className='CreateCustomImageModal__section-wrap port'>
              <div className='CreateCustomImageModal__select-label'>Container Port</div>
              <input className={"CreateCustomImageModal__input"}
                placeholder={'Enter default container port'}
                ref={portRef}
                defaultValue={type === 'update' ? template.container_port : ''}
              />
            </div>
          </div>
          <div className='CreateCustomImageModal__row'>
            <div className='CreateCustomImageModal__section-wrap tags'>
              <div className='CreateCustomImageModal__select-label'>Tags&nbsp;<div className='text-optional'>(optional)</div></div>
              <Select options={tagOptions}
                isMulti
                className={"CreateDeploymentModal__selector"}
                defaultValue={type === 'update' ? template.tags.map(v => ({ label: v, value: v })) : null}
                styles={selectStyles}
                placeholder={'Select Custom Template Tags'}
                isDisabled={tagOptions.length < 2}
                ref={tagsRef}
              />
            </div>
          </div>
          <div className='CreateCustomImageModal__row'>
            <div className='CreateCustomImageModal__section-wrap args'>
              <div className='CreateCustomImageModal__select-label'>Container Arguments&nbsp;<div className='text-optional'>(optional)</div></div>
              <input className={"CreateCustomImageModal__input"}
                placeholder={'Enter custom template arguments. Ex: ["arg1", "arg2", ...]'}
                defaultValue={type === 'update' && template.container_args !== null ? JSON.stringify(template.container_args) : ''}
                ref={argsRef}
              />
            </div>
          </div>
          <div className='CreateCustomImageModal__row'>
            <div className='CreateCustomImageModal__section-wrap args'>
              <div className='CreateCustomImageModal__select-label'>Container Environment Variables&nbsp;<div className='text-optional'>(optional)</div></div>
              <input className={"CreateCustomImageModal__input"}
                placeholder={'Enter custom template environment variables. Ex: {"key1": "value1", "key2": "value2", ...}'}
                defaultValue={type === 'update' && template.env_vars ? JSON.stringify(template.env_vars) : ''}
                ref={varsRef}
              />
            </div>
          </div>
          <div className='CreateCustomImageModal__row'>
            <div className='CreateCustomImageModal__section-wrap description'>
              <div className='CreateCustomImageModal__select-label'>Description&nbsp;<div className='text-optional'>(optional)</div></div>
              <textarea className={"CreateCustomImageModal__textarea"}
                placeholder={'Enter custom template description'}
                ref={descriptionRef}
                defaultValue={type === 'update' ? template.description : ''}
              />
            </div>
          </div>
        </ModalContent>

      <ModalActions>
        {error && <div className={cx("CreateCustomImageModal__error")}>Error: {error}</div>}
        <Button color={"green"} onClick={onSubmit} title={`${type === 'update' ? 'Update' : 'Create New'} Custom Template`} />
        <Button color={"transparent"} onClick={onRequestClose} title={"Cancel"} />
      </ModalActions>

    </Modal>
  );
}


function NumberInputSection(props) {
  const { className, label, defaultValue = 0, vRef } = props;
  const [value, setValue] = useState(defaultValue);

  const decreaseValue = () => {
    if (value > 0) {
      setValue(value - 1);
    }
  };

  const increaseValue = () => {
    setValue(value + 1);
  };

  return (
    <div className={classNames("number-input", className)}>
      <label htmlFor="number-input" className='number-input__label'>{label}</label>
      <div className="number-input__group">
        <div className="number-input__button" onClick={decreaseValue}>-</div>
        <input
          type="number"
          id="number-input"
          className='number-input__input'
          inputMode="numeric"
          value={value}
          onChange={(e) => setValue(parseInt(e.target.value))}
        />
        <div className="number-input__button" onClick={increaseValue}>+</div>
      </div>
    </div>
  );
}

export default NumberInputSection;
