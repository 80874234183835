import React, { useEffect, useState } from "react";
import { Urls } from "../constants";
import { Button } from "../components/Button";
import { selectCurrentUser } from "../store/models/User";
import { UserRoleIds as UserRoles } from "../constants";
import { mergeUrl } from "../utils/url";
import { useSelector } from "react-redux";
import { selectCurrentOrg, selectCurrentOrgId } from "../store/models/Organization";
import { selectBalance } from "../store/models/Bill";
import { IoAlertCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import { CloseIcon } from "./Svg";

export const Banner = () => {
  const org = useSelector(state => selectCurrentOrg(state));
  const orgId = useSelector(state => selectCurrentOrgId(state));
  const balance = useSelector(state => selectBalance(state, orgId));
  const user = useSelector(state => selectCurrentUser(state));
  const userIsAdmin = user && user.role === UserRoles.ADMIN
  const [showBanner, setShowBanner] = useState(balance > 0 && balance < 100 && !org.disabled);

  useEffect(() => {
    setShowBanner(balance > 0 && balance < 100 && !org.disabled);
  }, [orgId, balance, org])
  return showBanner ? (
    <div className={'Banner'}>
      <div className={'Banner__content'}>
        {`Your account balance is below $1. ${userIsAdmin ? `Please add credit to avoid interruption of your services.` : `Please contact your admin to add credit to avoid interruption of your services.`}`}
        {userIsAdmin && <Button size={'small'}
          title={'Go To Billing'}
          href={mergeUrl(Urls.SETTINGS_BILLING)}
          color={'white-outline'}
        />}
      </div>
      <div className={'Banner__close'} onClick={() => setShowBanner(false)}>
        <CloseIcon />
      </div>
    </div>
  ) : <></>
}