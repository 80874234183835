import React, {useEffect, useState} from "react";
import {CameraIcon, CloseIcon, DownChevronIcon, DownloadIcon, InfoIcon, SendIcon} from "../Svg";
import {Button} from "../Button";
import _ from "lodash";
import {TextInput} from "../form/TextInput";
import cx from "classnames";
import Tooltip from "../Tooltip";
import {AIShowcase} from "../../store/models/AIShowcase";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {ModalTypes, Urls} from "../../constants";
import {SdImageToVideo} from "../../constants/sd-models";
import Loader from "../Loader";
import UIState, {selectMobileLayout} from "../../store/UIState";
import {createSelector} from "reselect";
import {ImageInput} from "../form/ImageInput";
import {ShowcaseSettingsPanel} from "./ShowcaseSettingsPanel";


const ChatMessage = ({message, showcaseModelUrl}) => {

  const imageUrl = `${showcaseModelUrl}/file=${message.image.path}`
  const [paramsOpened, setParamsOpened] = useState(false);

  const onToggleParams = () => {
    setParamsOpened(!paramsOpened);
  }

  return (
    <div className={cx("UserMessageContainer")}>
      <div className="UserMessage">
        <div className={"UserMessage__PromptLabel"}>Source image</div>
        <div className={"UserMessage__PromptValue"}>
          <a href={imageUrl} target={"_blank"}>
            <img src={imageUrl}/>
          </a>
        </div>
        <div className={"UserMessage__BottomWrapper"}>
          <div className={`UserMessage__ParamsButton ${paramsOpened && "open"}`}
               onClick={onToggleParams}>More parameters <DownChevronIcon/></div>
        </div>
        {paramsOpened &&
          <div className={"UserMessage__Params"}>
            <pre>{JSON.stringify(message.params, null, 2)}</pre>
          </div>}
      </div>

    </div>)
}

const VideoItem = ({item}) => {
  return (
    <div className={cx("ResultBox")}>
      <div className={"ResultBox__Video"}>
        <video className={"ResultBox__Video__Player"} controls>
          <source src={item.video} type="video/mp4"/>
          Your browser does not support the video tag.
        </video>
        <div className={"ResultBox__Video__DownloadWrapper"}>
          <Tooltip tooltip={"Download"}>
            <a href={item.video} download target={"_blank"}><DownloadIcon /></a>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

const VideoLoadingItem = ({loadingData, elapsedTime}) => {
  if (!loadingData) return <></>

  if (loadingData.msg === 'estimation') {
    return (
      <div className={cx("ResultBox")}>
        <div className={"ResultBox__Loading"}>
          <div className={"ResultBox__Loading__TopWrapper"}>
            Scheduled | {elapsedTime}s / {loadingData.rank_eta?.toFixed(0) ?? '?'}s
          </div>

          <div className={"ResultBox__Loading__CenterWrapper"}>
            <Loader size={"small"} color={"green"}/> Waiting for available slot...
          </div>

          <div className={"ResultBox__Loading__BottomWrapper"}>Number of jobs ahead of you: <span
            style={{color: '#ffffff'}}>{loadingData.rank + 1}</span></div>
        </div>
      </div>)
  }

  if (loadingData.msg === 'process_starts') {
    return (<div className={cx("ResultBox")}>

      <div className={"ResultBox__Loading"}>

        <div className={"ResultBox__Loading__TopWrapper"}>
          Processing | {elapsedTime}s / {loadingData.eta?.toFixed(0) ?? '?'}s
        </div>

        <div className={"ResultBox__Loading__CenterWrapper"}>
          <Loader color={"green"}/>
        </div>

        <div className={"ResultBox__Loading__BottomWrapper"}>Video generation in progress...</div>
      </div>
    </div>)
  }
}


const selector = createSelector(
  selectMobileLayout,
  (isMobile) => ({
      isMobile
    }
  ))

let showcaseImageToVideoIntervalId = null;

export const ShowcaseImageToVideo = ({showcase}) => {

  const dispatch = useDispatch();

  const {isMobile} = useSelector(state => selector(state));
  const [loadingData, setLoadingData] = useState(null)
  const [imageUploading, setImageUploading] = useState(false)
  const [error, setError] = useState(null)
  const [elapsedTime, setElapsedTime] = useState(0)
  const [currentImage, setCurrentImage] = useState(null)
  const [messages, setMessages] = useState([])
  const [settings, setSettings] = useState(_.reduce(SdImageToVideo.params, (obj, param) => {
    obj[param.name] = param.default
    return obj
  }, {}))
  const [dragging, setDragging] = useState(false);
  const chatboxRef = React.createRef();
  const [showSettings, setShowSettings] = useState(!isMobile)
  const showcaseModel = showcase?.models?.[0]
  const showcaseModelUrl = showcaseModel?.urls
  const imageUrl = currentImage ? `${showcaseModelUrl}/file=${currentImage.path}` : null

  useEffect(() => {
    return () => {
      if (showcaseImageToVideoIntervalId) {
        clearInterval(showcaseImageToVideoIntervalId)
      }
      dispatch(AIShowcase.actions.stopImageToVideoGeneration())
    }
  }, []);

  const onGenerateVideo = async (event) => {
    setError(null)
    event.preventDefault();

    try {
      setMessages([...messages, {type: 'prompt', image: currentImage, params: settings}, {type: 'loading'}])
      onScrollToBottom()
      dispatch(AIShowcase.actions.imageToVideoPredict({image: currentImage, ...settings}, onPredictEvent, showcaseModel));
    } catch (e) {
      toast.error(e.message)
      console.error(e)
    }
  };

  const onImageSelected = async (file) => {
    setDragging(false)
    setImageUploading(true)
    const imagePath = await dispatch(AIShowcase.actions.uploadImageForImageToVideo(file, showcaseModelUrl))
    setImageUploading(false)
    const imageData = {
      "meta": {
        "_type": "gradio.FileData"
      },
      "mime_type": file.type,
      "orig_name": file.name,
      "path": imagePath,
      "size": file.size,
    }

    setCurrentImage(imageData)
  }

  const onRemoveImage = () => {
    setCurrentImage(null)
  }

  const onSettingsChanged = (settings) => {
    setSettings(settings)
  }

  const onPredictEvent = (event) => {
    switch (event.msg) {
      case 'estimation':
      case 'process_starts':
        startStopwatch()
        setLoadingData(event)
        break;
      case 'process_completed':
        try {
          if (event.success) {
            setLoadingData(null)
            stopInterval()
            setMessages((messages) => [...messages.slice(0, -1), {type: 'video', video: event.output.data[0].video.url}])
            onScrollToBottom()
          } else {
            onError("Failed to generate video")
          }
        } catch (e) {
          onError("Failed to generate video: " + e.message)
        }
        break;
      case 'error':
      case 'unexpected_error':
        onError(event.error)
        break;
    }
  }

  const onError = (error) => {
    setLoadingData(null)
    setMessages((messages) => messages.slice(0, -2))
    stopInterval()
    setError(error ?? "Failed to generate video. Try again later")
    toast.error(error ?? "Failed to generate video. Try again later")
  }

  const startStopwatch = () => {

    if (showcaseImageToVideoIntervalId) {
      stopInterval()
    }

    const startTime = new Date().getTime()

    showcaseImageToVideoIntervalId = setInterval(() => {
      const currentTime = new Date().getTime()
      setElapsedTime(((currentTime - startTime) / 1000).toFixed(0))
    }, 1000)

  }

  const stopInterval = () => {
    clearInterval(showcaseImageToVideoIntervalId)
    showcaseImageToVideoIntervalId = null
    setElapsedTime(0)
  }

  const onStopGenerating = (event) => {
    event.preventDefault();

    dispatch(UIState.actions.showModal(ModalTypes.CONFIRM, {
      title: "Stop generating",
      message: "Are you sure you want to stop the ongoing video generation?",
      onConfirm: onConfirmStopGenerating,
      confirmLabel: "Yes",
      cancelLabel: "No",
      size: 's'
    }))
  }

  const onConfirmStopGenerating = () => {
    stopInterval()
    setLoadingData(null)
    setMessages((messages) => messages.slice(0, -2))
    dispatch(AIShowcase.actions.stopImageToVideoGeneration())
  }

  const onScrollToBottom = () => {
    if (!isMobile) {
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
        if (!_.isNil(chatboxRef) && !_.isNil(chatboxRef.current)) {
          chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
        }
      }, 200)
    }
  }

  const onToggleSettings = () => {
    setShowSettings(!showSettings);
  }

  return (
    <div className="ShowcaseImageToVideo">
      <div className="ShowcaseImageToVideo__container">
        <div className={"ShowcaseImageToVideo__LeftSide"}>
          {!isMobile && <div className={"ShowcaseImageToVideo__Title"}>{"Model: Stable Video Diffusion"}</div>}

          <div className={"SidePanel"}>

            <form className="SidePanel__form">

              <ImageInput
                name={"image"}
                value={imageUrl}
                onChange={onImageSelected}
                onRemove={onRemoveImage}
              />

              <div className={"SidePanel__GenerateImageLink"}>
                Have text only? <a href={Urls.MARKETING_AI_SHOWCASE_TEXT_TO_IMAGE} target={"_blank"}>Generate an image
                here</a>
              </div>

              <div className={"SidePanel__ActionButtons"}>
                <Button
                  title={"Clear"}
                  color={"grey"}
                  className="SidePanel__ActionButtons__Button"
                  disabled={_.isNil(currentImage)}
                  onClick={onRemoveImage}
                />

                {loadingData
                  ? <Button
                    title={"Stop generating"}
                    onClick={onStopGenerating}
                    className="SidePanel__ActionButtons__Button"
                    color={"red"}
                  />
                  : <Button
                    title={"Send"}
                    onClick={onGenerateVideo}
                    icon={imageUploading ? null : <SendIcon/>}
                    className="SidePanel__ActionButtons__Button"
                    color={"green"}
                    loading={imageUploading}
                    disabled={!_.isNil(loadingData) || _.isNil(currentImage)}/>}
              </div>

              <div className={`SidePanel__OutputOptions ${showSettings && "open"}`}
                   onClick={onToggleSettings}>Output options <DownChevronIcon/></div>

              {showSettings &&
                <>
                  {isMobile && <div className={"ShowcaseImageToVideo__Title"}>{"Model: Stable Video Diffusion"}</div>}
                  <ShowcaseSettingsPanel model={SdImageToVideo} onParamsChanged={onSettingsChanged}/>
              </>}
            </form>

          </div>
        </div>

        <div className={cx("ShowcaseImageToVideo__RightSide")}>
          {error && <div className={"ErrorMessage"}>{error}</div>}

          {_.isEmpty(messages) && <div className={cx("ResultBox")}>
            <div className={"ResultBox__Idle"}>
              <CameraIcon/>
              Generated Video
            </div>
          </div>}

          {!_.isEmpty(messages) && <div className={"VideosWrapper"} ref={chatboxRef}>
            {messages.map((item, index) => {
              switch (item.type) {
                case 'prompt':
                  return (<ChatMessage key={index} message={item} showcaseModelUrl={showcaseModelUrl}/>)
                case 'loading':
                  return (<VideoLoadingItem key={index} loadingData={loadingData} elapsedTime={elapsedTime}/>)
                case 'video':
                  return (<VideoItem key={index} item={item}/>)
              }
            })}
          </div>
          }

        </div>
      </div>

    </div>
  );
}