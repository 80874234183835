import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {Button} from "../components/Button";
import {useDispatch, useSelector} from "react-redux";
import User, {selectCurrentUser, selectCurrentUserId} from "../store/models/User";
import {useIsMountedRef} from '../hooks/useIsMounted';
import {createSelector} from "reselect";
import cx from "classnames";
import Modal, {ModalActions, ModalContent, ModalHeader, ModalTitle} from "../components/Modal";
import {toast} from "react-toastify";


const selector = createSelector(
  selectCurrentUserId,
  selectCurrentUser,
  (currentUserId, currentUser) => (
    {currentUserId, currentUser}
  )
);

export const ChangePhoneNumberModal = ({onRequestClose}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const isMountedRef = useIsMountedRef();
  const {currentUserId, currentUser} = useSelector(state => selector(state));
  const {register, handleSubmit, watch, setValue, formState: {errors}, getValues} = useForm();

  useEffect(() => {
    if (!_.isEmpty(currentUser)) {
      setValue('phone_number', currentUser.phone_number);
    }
  }, [currentUser]);

  const onSubmit = async () => {
    const {phone_number} = getValues()
    setLoading(true);

    try {
      await dispatch(User.actions.editProfile(currentUserId, {phone_number}));
      toast.success('Your phone number has been updated');
      onRequestClose();
    } catch (e) {
      if (!isMountedRef.current) return;
      setError(e.message);
    } finally {
      if (!isMountedRef.current) return;
      setLoading(false);
    }
  };

  return (
    <Modal className={cx("ChangePhoneNumberModal")}
           onRequestClose={onRequestClose}>

      <ModalHeader>
        <ModalTitle>Change my phone number</ModalTitle>
      </ModalHeader>

      <ModalContent>
        <div className={cx("NewFormError")}>{error && error}</div>

        <div className={"NewForm"}>

          <div className={"NewForm__Row NewForm__Row"}>

            <div className={"NewForm__InputTextWrapper NewForm__InputTextWrapper"}>
              <div className={"NewForm__InputLabel"}>Phone number</div>
              <input type={"text"}
                     placeholder={"New phone number"}
                     {...register("phone_number", {required: true, maxLength: 1000})} />
            </div>
          </div>

          <ModalActions>
            <Button color={"green"} onClick={onSubmit} loading={loading} title={"Update"}/>
            <Button color={"transparent"} onClick={onRequestClose} title={"Cancel"}/>
          </ModalActions>

        </div>

      </ModalContent>

    </Modal>
  );
}