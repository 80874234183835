import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { mergeUrl } from "../utils/url";
import { selectCurrentProjectId } from "../store/models/Project";
import { Urls } from "../constants";

export default function ApiKeyNote({ type }) {
  const history = useHistory();
  const projectId = useSelector(state => selectCurrentProjectId(state));

  const handleToCreate = () => {
    history.push(mergeUrl(Urls.SETTINGS_PROJECT, { projectId }));
  }
  return (
    <div className={'AiServicePage__header--note'}>
      <span className="AiServicePage__header--note-row">
        Want to manage your {type} through API? First create an API Key&nbsp;
        <span onClick={handleToCreate} className='AiServicePage__header--link'>here</span>, and then check out these&nbsp;
        <a
          href="https://docs.thetatoken.org/docs/use-edgecloud-api-keys-to-manage-deployments"
          target="_blank" rel="noreferrer" className='learn-more'>
          docs
        </a>.
      </span>
    </div>
  );
}

