import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {Button} from "../components/Button";
import {useDispatch, useSelector} from "react-redux";
import User, {selectCurrentUser} from "../store/models/User";
import {useIsMountedRef} from '../hooks/useIsMounted';
import {createSelector} from "reselect";
import cx from "classnames";
import Modal, {ModalActions, ModalContent, ModalHeader, ModalTitle} from "../components/Modal";
import {toast} from "react-toastify";


const selector = createSelector(
  selectCurrentUser,
  (currentUser) => (
    {currentUser}
  )
);

export const EditProfileModal = ({onRequestClose}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const isMountedRef = useIsMountedRef();
  const {currentUser} = useSelector(state => selector(state));
  const {register, handleSubmit, watch, setValue, formState: {errors}, getValues} = useForm();

  useEffect(() => {
    if (!_.isEmpty(currentUser)) {
      setValue('firstname', currentUser.first_name);
      setValue('lastname', currentUser.last_name);
    }
  }, [currentUser]);

  const onSubmit = async () => {
    const {firstname, lastname} = getValues()
    setLoading(true);
    try {
      await dispatch(User.actions.editProfile(currentUser.id, {first_name: firstname, last_name: lastname}));
      toast.success('Your profile has been updated');
      onRequestClose();
    } catch (e) {
      if (!isMountedRef.current) return;
      setError(e.message);
    } finally {
      if (!isMountedRef.current) return;
      setLoading(false);
    }
  };

  const onChangeEmail = async() => {
    // dispatch(UIState.actions.showModal(ModalTypes.CHANGE_EMAIL))
    try {
      await dispatch(User.actions.requestChangeEmail())
      toast.success("An email has been sent to you, with a link to change your email address")
    } catch (e) {
      toast.error(e.message)
    }
  }

  return (
    <Modal className={cx("EditProfileModal")}
           onRequestClose={onRequestClose}>

      <ModalHeader>
        <ModalTitle>Update my profile</ModalTitle>
      </ModalHeader>

      <ModalContent>
        <div className={cx("NewFormError")}>{error && error}</div>

        <div className={"NewForm"}>

          <div className={"NewForm__Row NewForm__Row"}>

            <div className={"NewForm__InputTextWrapper"}>
              <div className={"NewForm__InputLabel"}>First name</div>
              <input type={"text"}
                     placeholder={"First name"}
                     {...register("firstname", {required: true, maxLength: 83})} />
            </div>
            <div className={"NewForm__InputTextWrapper"}>
              <div className={"NewForm__InputLabel"}>Last name</div>
              <input type={"text"}
                     placeholder={"Last name"}
                     {...register("lastname", {required: true, maxLength: 83})} />
            </div>
          </div>

          <div className={"NewForm__Row NewForm__Row--last"}>
            <div className={"NewForm__InputTextWrapper NewForm__InputTextWrapper--auto"}>
              <div className={"NewForm__InputLabel"}>Email</div>
              <Button onClick={onChangeEmail}
                      color={"green-outline"}
                      title={"Change email"}/>
            </div>
          </div>

          <ModalActions>
            <Button color={"green"} onClick={onSubmit} loading={loading} title={"Update"}/>
              <Button color={"transparent"} onClick={onRequestClose} title={"Cancel"}/>
            </ModalActions>

          </div>

      </ModalContent>

    </Modal>
);
}