import cx from "classnames";
import { Tab, Tabs } from "../../components/Tabs";
import { useEffect, useState } from "react";
import { ShowcaseTextToImage } from "../../components/ai-showcases/ShowcaseTextToImage";
import { ShowcaseChatbot } from "../../components/ai-showcases/ShowcaseChatbot";
import { ModalTypes, Urls } from "../../constants";
import { pushTo } from "../../store/history";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import UIState, { selectMobileLayout } from "../../store/UIState";
import { Button } from "../../components/Button";
import { DownChevronIcon } from "../../components/Svg";
import { useLocation } from "react-router";
import { ShowcaseImageToVideo } from "../../components/ai-showcases/ShowcaseImageToVideo";
import { ShowcaseViton } from "../../components/ai-showcases/ShowcaseViton";
import { SelectInput } from "../../components/form/SelectInput";
import _ from "lodash";
import { AIShowcase, selectShowcases } from "../../store/models/AIShowcase";
import { ShowcaseMaintenance } from "../../components/ai-showcases/ShowcaseMaintenance";
import { useInterval } from "../../hooks/useInterval";
import { ShowcaseSketch } from "../../components/ai-showcases/ShowcaseSketch";
import Loader from "../../components/Loader";
import { ShowcaseImageToImage } from "../../components/ai-showcases/ShowcaseImageToImage";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const emptyShowcases = [
  { name: "Text to image", rank: 1 },
  { name: "Chatbot", rank: 2 },
  { name: "Image to video", rank: 3 },
  { name: "Sketch to 3D", rank: 4 },
  { name: "Virtual try-on", rank: 5 },
  { name: "Image to image", rank: 6 },
];

const getTabIndex = (path) => {
  switch (path) {
    case Urls.MARKETING_AI_SHOWCASE_CHATBOT:
      return 1;
    case Urls.MARKETING_AI_SHOWCASE_IMAGE_TO_VIDEO:
      return 2;
    case Urls.MARKETING_AI_SHOWCASE_SKETCH_TO_3D:
      return 3;
    case Urls.MARKETING_AI_SHOWCASE_VITON:
      return 4;
    case Urls.MARKETING_AI_SHOWCASE_IMAGE_TO_IMAGE_TABS:
      return 5;
    default:
      return 0;
  }
}

const selector = createSelector(
  (state) => selectMobileLayout(state),
  (state) => selectShowcases(state),
  (isMobile, showcases) => ({ isMobile, apiShowcases: showcases }
  ));

export const AiShowcasesPage = ({ match }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isMobile, apiShowcases } = useSelector(selector);
  const [loading, setLoading] = useState(true)
  const showcases = apiShowcases
    ? _.filter(apiShowcases, showcase =>
      _.find(emptyShowcases, emptyShowcase => emptyShowcase.name === showcase.name))
    : emptyShowcases

  let currentTabIndex = getTabIndex(match.path)
  let currentShowcase = _.find(showcases, showcase => (showcase.rank - 1 === currentTabIndex))


  const [tabIndex, setTabIndex] = useState(currentTabIndex)

  useEffect(() => {
    (async () => {
      const openExplore = location.pathname?.split('/')?.pop() === 'explore'
      if (openExplore) {
        dispatch(UIState.actions.showModal(ModalTypes.SHOWCASE_TEXT_TO_IMAGE_DISCOVER))
      }

      if (!apiShowcases) {
        setLoading(true)
        await dispatch(AIShowcase.actions.fetchShowcases())
        setLoading(false)
      } else {
        setLoading(false)
      }
    })()
  }, []);

  useInterval(() => {
    dispatch(AIShowcase.actions.fetchShowcases())
  }, 60000);

  const onTabChanged = (showcase) => {
    switch (showcase.name) {
      case "Chatbot":
        pushTo(Urls.MARKETING_AI_SHOWCASE_CHATBOT)
        break;
      case "Image to video":
        pushTo(Urls.MARKETING_AI_SHOWCASE_IMAGE_TO_VIDEO)
        break;
      case "Virtual try-on":
        pushTo(Urls.MARKETING_AI_SHOWCASE_VITON)
        break;
      case "Sketch to 3D":
        pushTo(Urls.MARKETING_AI_SHOWCASE_SKETCH_TO_3D)
        break;
      case "Image to image":
        pushTo(Urls.MARKETING_AI_SHOWCASE_ART_STYLE_TRANSFER)
        break;
      default:
        pushTo(Urls.MARKETING_AI_SHOWCASE_TEXT_TO_IMAGE)
        break;
    }
    setTabIndex(showcase.rank - 1)
  }

  const onExploreCommunityFeed = () => {
    dispatch(UIState.actions.showModal(ModalTypes.SHOWCASE_TEXT_TO_IMAGE_DISCOVER))
  }

  return (
    <div className={"AiShowcasesPage"}>

      <div className={cx("AiShowcasesPage__Header")}>

        <div className={"AiShowcasesPage__Header__Left"}>
          <div className={cx("AiShowcasesPage__Title")}>Generative AI Showcase</div>
          <div className={cx("AiShowcasesPage__Subtitle")}>Experience the best of genAI text-to-image, text-to-video,
            chatbots and much more.<br />
            Learn how to develop novel AI applications built on EdgeCloud.<br />
            Server is under high load, please be patient…
            <div className={"AiShowcasesPage__Terms"}><Link to={Urls.MARKETING_AI_SHOWCASE_TERMS} target="_blank">Terms of Use</Link> | <Link to={Urls.MARKETING_AI_SHOWCASE_PRIVACY_POLICY} target="_blank">Privacy Policy</Link></div>
          </div>
        </div>

        {currentTabIndex === 0 && <div className={"Discover"}>
          <div className={"Discover__Left"}>
            <div className={"Discover__Title"}>Community Feed</div>
            <div className={"Discover__Description"}>Explore what the community has created.</div>
          </div>
          <Button
            className={"Discover__ExploreButton"}
            title={"Explore"}
            icon={<DownChevronIcon />}
            color={"green-outline"}
            size={"medium"}
            onClick={onExploreCommunityFeed}
          />
        </div>}
      </div>

      {loading && <Loader />}


      {isMobile && <SelectInput
        className={"AiShowcasesPage__MobileTabs"}
        value={{ value: tabIndex, label: currentShowcase.name }}
        options={_.map(showcases, (showcase, index) => {
          return ({ value: (showcase.rank - 1), label: showcase.name })
        }
        )}
        onChange={(option) => onTabChanged(showcases[option.value])}
      />}

      {!isMobile && <Tabs leftAlign={true} className={"AiShowcasesPage__Tabs"}>
        {_.map(showcases, (showcase, index) => (
          <Tab key={showcase.name} onClick={() => onTabChanged(showcase)}
            isSelected={tabIndex === showcase.rank - 1}>
            {showcase.name}
          </Tab>
        ))}
      </Tabs>}

      {!loading && <>
        {currentShowcase?.maintenance ? <ShowcaseMaintenance /> : <>
          {currentShowcase?.name === 'Text to image' && <ShowcaseTextToImage showcase={currentShowcase} />}
          {currentShowcase?.name === 'Chatbot' && <ShowcaseChatbot showcase={currentShowcase} />}
          {currentShowcase?.name === 'Image to video' && <ShowcaseImageToVideo showcase={currentShowcase} />}
          {currentShowcase?.name === 'Virtual try-on' && <ShowcaseViton showcase={currentShowcase} />}
          {currentShowcase?.name === 'Sketch to 3D' && <ShowcaseSketch showcase={currentShowcase} />}
          {currentShowcase?.name === 'Image to image' && <ShowcaseImageToImage match={match} showcase={currentShowcase} />}
        </>}
      </>}

    </div>
  )
}