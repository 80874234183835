import React, {useEffect, useRef, useState} from 'react';
import {Image, Layer, Line, Stage} from 'react-konva';
import useImage from 'use-image';
import {BackIcon, DrawIcon, PaletteIcon, RulerIcon} from "./Svg";
import cx from 'classnames';

const DrawingCanvas = ({imageUrl, backgroundRef, layerRef, combineRef}) => {
  const [image] = useImage(imageUrl, 'Anonymous');
  const [stageSize, setStageSize] = useState({width: 0, height: 0});
  const [lines, setLines] = useState([]);
  const isDrawing = useRef(false);
  const [strokeColor, setStrokeColor] = useState('#00FF00');
  const [strokeWidth, setStrokeWidth] = useState(30);
  const [drawMenuOpen, setDrawMenuOpen] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    setLines([])
    const updateSize = () => {
      if (containerRef.current) {
        const {clientWidth, clientHeight} = containerRef.current;
        setStageSize({width: clientWidth, height: clientHeight});
      }
    };

    updateSize();
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [imageUrl]);

  useEffect(() => {
    if (image) {
      const {width, height} = image;
      const aspectRatio = width / height;
      let newWidth = stageSize.width;
      let newHeight = stageSize.height;

      if (newWidth / aspectRatio > newHeight) {
        newWidth = newHeight * aspectRatio;
      } else {
        newHeight = newWidth / aspectRatio;
      }

      setStageSize({width: newWidth, height: newHeight});
    }
  }, [image, stageSize.width, stageSize.height]);


  useEffect(() => {

    const range = document.getElementById("strokeWidth");

    if (range) {
      range.addEventListener('input', updateGradient);
      setTimeout(() => {
        updateGradient({target: range})
      }, 100)
    }

    return () => {
      range?.removeEventListener('input', updateGradient);
    };
  }, [drawMenuOpen]);

  function updateGradient(e) {
    const range = e.target;
    const value = ((range.value - range.min) / (range.max - range.min)) * 100;
    const color = `linear-gradient(to right, #129978 ${value}%, #3D4463 ${value}%)`;

    // Create a new style element
    const style = document.createElement('style');

    style.textContent = `
    #${range.id}::-moz-range-track {
      background: ${color};
    }
    #${range.id}::-webkit-slider-runnable-track {
      background: ${color};
    }
  `;

    document.head.appendChild(style);
  }

  const handleMouseDown = () => {
    isDrawing.current = true;
    const pos = combineRef.current.getPointerPosition();
    setLines([...lines, {points: [pos.x, pos.y], color: strokeColor, width: strokeWidth}]);
    setDrawMenuOpen(false);
  };

  const handleMouseMove = () => {
    if (!isDrawing.current) return;
    const stage = combineRef.current;
    const point = stage.getPointerPosition();
    let lastLine = lines[lines.length - 1];
    lastLine.points = lastLine.points.concat([point.x, point.y]);
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  const onRemoveLastLine = () => {
    if (lines.length > 0) {
      lines.splice(lines.length - 1, 1);
      setLines(lines.concat());
    }
  };

  const getDataURL = (e) => {
    e.preventDefault();
    const drawingDataUrl = layerRef.current.toDataURL();
    const dataURL = combineRef.current.toDataURL();
    window.open(drawingDataUrl);
    window.open(dataURL);
  };

  return (
    <div className={"DrawingCanvas"}>
      <div ref={containerRef} className={"DrawingCanvas__Stage"}>
        <Stage
          width={stageSize.width}
          height={stageSize.height}
          onMouseDown={handleMouseDown}
          onMousemove={handleMouseMove}
          onMouseup={handleMouseUp}
          onTouchStart={handleMouseDown}
          onTouchMove={handleMouseMove}
          onTouchEnd={handleMouseUp}
          ref={combineRef}
        >
          <Layer ref={backgroundRef}>
            {image && <Image image={image} width={stageSize.width} height={stageSize.height} />}
          </Layer>
          <Layer ref={layerRef}>
            {lines.map((line, i) => (
              <Line
                key={i}
                points={line.points}
                stroke={line.color}
                strokeWidth={line.width}
                tension={0.5}
                lineCap="round"
                globalCompositeOperation={
                  line.tool === 'eraser' ? 'destination-out' : 'source-over'
                }
              />
            ))}
          </Layer>
        </Stage>
      </div>
      <div className={"DrawingCanvas__Menu"}>
        <div className={cx("DrawingCanvas__Menu__IconWrapper",
          {"DrawingCanvas__Menu__IconWrapper--active": drawMenuOpen})}
             onClick={() => setDrawMenuOpen(!drawMenuOpen)}>
          <DrawIcon size={"xl"}/>
        </div>
        <div className={cx("DrawingCanvas__Menu__IconWrapper",
          {"DrawingCanvas__Menu__IconWrapper--disabled": lines.length === 0})}
             onClick={onRemoveLastLine}>
          <BackIcon size={"xl"}/>
        </div>
        {drawMenuOpen && <div className={"DrawingCanvas__Controls"}>
          <div className={"DrawingCanvas__Controls__Item"}>
            <RulerIcon size={"xl"}/>
            <div className="ShowcaseSettingsPanel__field">
              <input type="range" step={1} max={50} min={1}
                     value={strokeWidth}
                     name={"strokeWidth"}
                     id={"strokeWidth"}
                     onChange={(e) => setStrokeWidth(parseInt(e.target.value, 10))}/>
            </div>
          </div>
          <div className={"DrawingCanvas__Controls__Item"}>
            <PaletteIcon size={"xl"}/>
            <input
              type="color"
              value={strokeColor}
              onChange={(e) => setStrokeColor(e.target.value)}
            />
          </div>
        </div>}
      </div>
    </div>
  );
};

export default DrawingCanvas;