import _ from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import { Button } from "../../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FaChevronRight, FaCommentDots, FaEllipsisV, FaCheckCircle, FaEdit, FaTimesCircle } from "react-icons/fa";
import classNames from "classnames";
import PageTitleBar from "../../../../components/PageTitleBar";
import { Pagination } from '../../../../components/Pagination';
import { BetaIcon, VerticalDotsIcon, VideoIcon } from "../../../../components/Svg";
import { mergeUrl } from "../../../../utils/url";
import { ModalTypes, Urls } from "../../../../constants";
import { selectCurrentProject } from "../../../../store/models/Project";
import { createSelector } from "reselect";
import { selectCurrentUser, selectCurrentUserId } from "../../../../store/models/User";
import { selectCurrentOrg } from '../../../../store/models/Organization';
import { Overlay } from '../../../../components/Overlay';
import AiStorage, { convertSizeLabel, getFormateStorageSize, selectAllStorages, selectTotalStoragePages, StorageLocationOptions } from '../../../../store/models/AiStorage';
import { DropdownMenu, DropdownMenuItem } from '../../../../components/DropdownMenu';
import UIState from '../../../../store/UIState';
import { pushTo } from '../../../../store/history';
import dayjs from 'dayjs';
import { formatDuration } from '../../../../utils/time';
import Loader from '../../../../components/Loader';
import { toast } from 'react-toastify';

const NUM_PER_PAGE = 10;

const selector = createSelector(
  selectCurrentProject,
  selectCurrentUser,
  selectCurrentOrg,
  selectAllStorages,
  selectTotalStoragePages,
  (project, currentUser, currentOrg, storages, totalStoragePages) => ({ project, currentUser, currentOrg, storages, totalStoragePages })
);

const AiStorageListPage = () => {

  const dispatch = useDispatch();
  const { project, currentUser, currentOrg, storages, totalPages } = useSelector(state => selector(state));
  const userId = useSelector(state => selectCurrentUserId(state));
  const hasNoStorages = (!_.isNil(storages) && _.isEmpty(storages));
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (project) {
        setIsLoading(true);
        try {
          await dispatch(AiStorage.actions.fetchStorages(project?.id));
        } catch (e) {
          console.error(e);
          toast.error('Failed to fetch storages');
        }
        setIsLoading(false);
      }
    })();
  }, [project]);

  // const handlePageChange = pageNumber => {
  //   setCurrentPage(pageNumber);
  //   dispatch(AiStorage.actions.fetchStorages(project?.id, pageNumber, NUM_PER_PAGE));
  // }

  const reconfigStorage = (storage, e) => {
    e.stopPropagation();
    dispatch(UIState.actions.showModal(ModalTypes.MANAGE_STORAGE, {
      existingVolume: storage
    }));
  }

  const deleteStorage = (storage, e) => {
    e.stopPropagation();
    dispatch(UIState.actions.showModal(ModalTypes.CONFIRM, {
      title: 'Delete Storage',
      message: 'Are you sure you want to delete this storage?',
      onConfirm: () => onConfirmDeleteStorage(storage),
    }));
  }

  const onConfirmDeleteStorage = async (storage) => {
    try {
      await dispatch(AiStorage.actions.deleteStorage(project?.id, storage.Suffix));
    } catch (e) {
      console.error(e);
      toast.dismiss();
      toast.error(e.message, { autoClose: false });
    }
  }

  const navigateToStorageDetails = (storageId) => {
    const url = mergeUrl(Urls.AI_STORAGE_DETAILS, { selectedTab: 'storage-detail', projectId: project.id, storageId });
    pushTo(url);
  }

  const handleOpenNewStorage = () => {
    dispatch(UIState.actions.showModal(ModalTypes.MANAGE_STORAGE));
  }

  return (<div className={'AiServicePage'}>
    <div className={'AiServicePage__header'}>
      <div className={'AiServicePage__header--title'}>
        <div className={'PageTitleBar__titleWithIcon'}>Persistent Storage <BetaIcon /></div>
      </div>
      <div className={'AiServicePage__header--button'} onClick={handleOpenNewStorage}>
        <div className={'AiServiceHeaderButtonIcon empty'} />
        New Persistent Volume
      </div>
    </div>
    <div className={'AiServicePage__content'}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {!hasNoStorages && <ul className={'ItemsList'}>
            <li className={'ItemsListHeader'}>
              <div className={'ItemsListHeader__name'}>Name</div>
              <div className={'ItemsListHeader__id'}>ID</div>
              {/* <div className={'ItemsListHeader__status'}>Status</div> */}
              <div className={'ItemsListHeader__location'}>Region</div>
              <div className={'ItemsListHeader__size'}>Size</div>
              <div className={'ItemsListHeader__duration'}>Duration</div>
            </li>
            {storages.map(storage => (
              <li key={storage.Suffix}
                className={classNames('ItemsListItem', `ItemsListItem--${storage.status?.toLowerCase()}`)}
                onClick={() => navigateToStorageDetails(storage.Suffix)}
              >
                <div className={'ItemsListItem__name editable'}>
                  <NameSection storage={storage} projectId={project.id} />
                </div>
                <div className={'ItemsListItem__id'}>{storage.Name}</div>
                {/* <div className={'ItemsListItem__status'}>{storage.Status ?? '-'}</div> */}
                <div className={'ItemsListItem__location'}>{storage.Region ?? '-'}</div>
                <div className={'ItemsListItem__size'}>{getFormateStorageSize(storage)}</div>
                <div className={'ItemsListItem__duration'}>
                  {storage.CreatedAt ? formatDuration(storage.CreatedAt) : '-'}
                </div>
                <div className={'ItemsListItem__ellipsis'} onClick={(e) => e.stopPropagation()}>
                  <DropdownMenu icon={<VerticalDotsIcon />}>
                    <DropdownMenuItem
                      label={"Reconfig"}
                      onClick={(e) => reconfigStorage(storage, e)}
                    />
                    <DropdownMenuItem
                      label={"Delete"}
                      onClick={(e) => deleteStorage(storage, e)}
                    />
                  </DropdownMenu>
                </div>
              </li>
            ))}
          </ul>}

          {!hasNoStorages && totalPages &&
            <Pagination
              size={'lg'}
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange} />
          }

          {hasNoStorages &&
            <div className={'EmptyState'}>
              <div className={'EmptyState__title'}>No Storages</div>
              <div className={'EmptyState__subtitle'}>Create your first persistent volume to get started</div>
              <Button size={'large'}
                title={'New Persistent Volume'}
                onClick={() => dispatch(UIState.actions.showModal(ModalTypes.MANAGE_STORAGE))}
              />
            </div>}
        </>
      )}
    </div>
  </div>
  );
}

const NameSection = ({ storage, projectId }) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const nameRef = useRef();

  const handleEdit = () => {
    setIsEditing(true);
  }

  const handleSubmit = async () => {
    let nickname = nameRef.current.value;
    try {
      await dispatch(AiStorage.actions.updateStorage(projectId, storage.Suffix, storage.StorageCapacity, storage.DisplayName, {
        displayName: nickname
      }));
      setIsEditing(false);
    } catch (e) {
      console.error(e);
      toast.error('Failed to update storage name');
    }
  }

  const handleCancel = () => {
    setIsEditing(false);
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }

  return (
    <div onClick={e => e.stopPropagation()} className='AiServicePage__name-warp'>
      {!isEditing ? storage.DisplayName || `\u00A0` :
        <input type='text' ref={nameRef} defaultValue={storage.DisplayName} onKeyDown={handleKeyDown} />}
      {isEditing ?
        <div className='AiServicePage__icon-warp'>
          <div className='PageTitleBar__right--icon cancel' onClick={handleCancel} title="Cancel"><FaTimesCircle /></div>
          <div className='PageTitleBar__right--icon submit' onClick={handleSubmit} title="Submit"><FaCheckCircle /></div>
        </div> :
        <div className='AiServicePage__icon-warp'>
          <div className='PageTitleBar__right--icon edit' onClick={handleEdit}><FaEdit /></div>
        </div>}
    </div>
  );
}

export default AiStorageListPage;
