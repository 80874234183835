import React, {useEffect, useState} from 'react';
import cx from 'classnames';
import Modal, {ModalActions, ModalContent, ModalHeader, ModalTitle} from "../components/Modal";
import {Button} from "../components/Button";
import Organization, {selectCurrentOrg, selectCurrentOrgId, selectOrganizations} from "../store/models/Organization";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import _ from "lodash";
import AppState from "../store/AppState";
import {pushTo} from "../store/history";
import {Urls} from "../constants";
import {selectCurrentUser} from "../store/models/User";
import {createSelector} from "reselect";

const selector = createSelector(
  selectCurrentUser,
  (user) => (
    {user}
  )
)

export const CreateOrgModal = ({onRequestClose}) => {
  const dispatch = useDispatch()
  const {user} = useSelector(state => selector(state));
  const {
    register,
    handleSubmit,
    formState,
    setValue
  } = useForm();
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (formState.errors.email) {
      setError('Please enter a valid email address')
    } else if (formState.errors.name) {
      if (formState.errors.name.type === 'required') {
        setError('Please enter an organization name')
      } else if (formState.errors.name.type === 'maxLength') {
        setError('Organization name must be less than 32 characters')
      }
    }
  }, [formState]);

  useEffect(() => {
    setValue('email', user.email)
  }, [user]);

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(createOrg)();
  }

  const createOrg = async (body) => {
    setLoading(true);
    setError(null);

    try {
      const org = await dispatch(Organization.actions.createOrganization(body))
      await dispatch(AppState.actions.selectCurrentOrgAndProject(org.id))
      onRequestClose()
      pushTo(Urls.DASHBOARD)
    } catch (e) {
      setLoading(false);
      setError(e.message)
    }
  }

  return (
    <Modal className={cx("CreateProjectModal")}
           onRequestClose={onRequestClose}>

      <ModalHeader>
        <ModalTitle>Create New Organization</ModalTitle>
      </ModalHeader>

      <ModalContent>
        <div className={cx("NewFormError")}>{error && error}</div>

        <form onSubmit={onSubmit} className={"NewForm"}>

          <div className={"NewForm__Row NewForm__Row--last"}>

            <div className={"NewForm__InputTextWrapper"}>
              <div className={"NewForm__InputLabel"}>Organization name</div>
              <input name="name"
                     type={"text"}
                     placeholder={"Enter a name"}
                     {...register("name", {required: true, maxLength: 32})} />
            </div>

            <div className={"NewForm__InputTextWrapper"}>
              <div className={"NewForm__InputLabel"}>Organization email</div>
              <input name="email"
                     type={"email"}
                     placeholder={"Enter an email address"}
                     {...register("email", {required: true})} />
            </div>

          </div>

          <ModalActions>
            <Button color={"green"} role={'submit'} loading={loading} title={"Create New Organization"}/>
            <Button color={"transparent"} onClick={onRequestClose} title={"Cancel"}/>
          </ModalActions>

        </form>

      </ModalContent>

    </Modal>
  );
}