import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import React, {useEffect, useState} from "react";
import Loader from "../../../components/Loader";
import PageTitleBar from "../../../components/PageTitleBar";
import {Button} from "../../../components/Button";
import User, {selectCurrentUser, selectCurrentUserId} from "../../../store/models/User";
import {ModalTypes} from "../../../constants";
import UIState from "../../../store/UIState";
import {toast} from "react-toastify";
import {parsePhoneNumber} from "libphonenumber-js";
import _ from "lodash";

const selector = createSelector(
  selectCurrentUserId,
  selectCurrentUser,
  (currentUserId, currentUser) => {
    return {
      currentUserId,
      currentUser,
    }
  }
)

export const SecurityPage = () => {

  const dispatch = useDispatch();
  const {currentUserId, currentUser} = useSelector(state => selector(state));

  const [loading, setLoading] = useState(false)
  const [loadingSendVerificationEmail, setLoadingSendVerificationEmail] = useState(false)
  const [loadingSendVerificationText, setLoadingSendVerificationText] = useState(false)
  const [error, setError] = useState(null)

  const twoFaEnabled = currentUser?.otp_2fa_enabled

  const hasPhoneNumber = !_.isNil(currentUser?.phone_number)
  const parsedPhoneNumber = (hasPhoneNumber)
    ? parsePhoneNumber(currentUser?.phone_number, 'US').formatNational()
    : '-'

  useEffect(() => {
    fetchData()
  }, []);

  const fetchData = async () => {
    // setLoading(true);
    // setError(null);
    // try {
    //   await Promise.all([
    //   ])
    // } catch (e) {
    //   setError(e.message)
    // }
    // setLoading(false);
  }

  const onChangeEmail = async() => {
    // dispatch(UIState.actions.showModal(ModalTypes.CHANGE_EMAIL))
    try {
      await dispatch(User.actions.requestChangeEmail())
      toast.success("An email has been sent to you, with a link to change your email address")
    } catch (e) {
      toast.error(e.message)
    }
  }

  const onChangePhoneNumber = () => {
    dispatch(UIState.actions.showModal(ModalTypes.CHANGE_PHONE_NUMBER))
  }

  const onChangePassword = () => {
    dispatch(UIState.actions.showModal(ModalTypes.CHANGE_PASSWORD))
  }

  const onEnableTwoFA = () => {
    dispatch(UIState.actions.showModal(ModalTypes.TWO_FA))
  }

  const onSendVerificationEmail = async () => {
    setLoadingSendVerificationEmail(true)
    try {
      await dispatch(User.actions.sendVerificationEmail());
      toast.success("A new verification email has been sent to your email address")
    } catch (e) {
      toast.error(e.message)
    }
    setLoadingSendVerificationEmail(false)
  }

  const onSendVerificationPhoneNumber = async () => {
    setLoadingSendVerificationText(true)
    try {
      await dispatch(User.actions.sendVerifyPhoneNumber());
      toast.success("A new verification code has been sent to your phone number")
      dispatch(UIState.actions.showModal(ModalTypes.VERIFY_PHONE_NUMBER))
    } catch (e) {
      toast.error(e.message)
    }
    setLoadingSendVerificationText(false)
  }

  if (loading)
    return <Loader/>

  if (error)
    return (<div className={"ServicePage__Error"}>
      {error}
      <Button onClick={fetchData()}
              color={"green-outline"}
              title={"Retry"}/>
    </div>)

  return (<div className={"SecurityPage"}>
      <PageTitleBar alignTitleLeft={true} title={"Security"}/>

      {!currentUser?.email_verified &&
        <div className="g-settings-box top">
          <div className="box-left">
            <div className="box-content">
              <div className="box-value red">Your email is not verified.<br/>
                Click on the button to send a new verification email.
              </div>
            </div>
          </div>
          <Button
            className={"send-verification-btn"}
            size={"xm"}
            loading={loadingSendVerificationEmail}
            onClick={onSendVerificationEmail}
            title={"Send email"}/>
        </div>}

      {hasPhoneNumber && !currentUser?.phone_number_verified &&
        <div className="g-settings-box top">
          <div className="box-left">
            <div className="box-content">
              <div className="box-value red">Your phone number is not verified.<br/>
                Click on the button below to send a new verification code to your phone number.
              </div>
            </div>
          </div>

          <Button
            className={"send-verification-btn"}
            size={"xm"}
            loading={loadingSendVerificationText}
            onClick={onSendVerificationPhoneNumber}
            title={"Send code"}/>
        </div>}

      <div className={"SecurityPage__Block"}>

        <div className={"SecurityPage__Block__Row"}>
          <div className={"SecurityPage__Block__Row__Title"}>Email</div>
          <div className={"SecurityPage__Block__Row__Bottom"}>
            <div className={"SecurityPage__Block__Row__Bottom__Left"}>{currentUser?.email}</div>
            <div className={"SecurityPage__Block__Row__Bottom__Right"}>
              <Button onClick={onChangeEmail}
                      color={"transparent"}
                      title={"Change email >"}/>
            </div>
          </div>
        </div>

        <div className={"SecurityPage__Block__Row"}>
          <div className={"SecurityPage__Block__Row__Title"}>Phone number</div>
          <div className={"SecurityPage__Block__Row__Bottom"}>
            <div className={"SecurityPage__Block__Row__Bottom__Left"}>{parsedPhoneNumber}</div>
            <div className={"SecurityPage__Block__Row__Bottom__Right"}>
              <Button onClick={onChangePhoneNumber}
                      color={"transparent"}
                      title={"Change phone number >"}/>
            </div>
          </div>
        </div>
      </div>

      <div className={"SecurityPage__Block"}>
        <div className={"SecurityPage__Block__Row"}>
          <div className={"SecurityPage__Block__Row__Title"}>Password</div>
          <div className={"SecurityPage__Block__Row__Bottom"}>
            <div className={"SecurityPage__Block__Row__Bottom__Left"}>Change your account password</div>
            <div className={"SecurityPage__Block__Row__Bottom__Right"}>
              <Button onClick={onChangePassword}
                      color={"transparent"}
                      title={"Change password >"}/>
            </div>
          </div>
        </div>

        <div className={"SecurityPage__Block__Row SecurityPage__Block__Row--last"}>
          <div className={"SecurityPage__Block__Row__Title"}>Two-factor authentication</div>
          <div className={"SecurityPage__Block__Row__Bottom"}>
            <div className={"SecurityPage__Block__Row__Bottom__Left"}>Require an authentication code when you log in
              with email and password
            </div>
            <div className={"SecurityPage__Block__Row__Bottom__Right"}>
              <Button onClick={onEnableTwoFA}
                      color={"transparent"}
                      disabled={twoFaEnabled}
                      title={twoFaEnabled ? "2FA Enabled" : "Enable 2FA >"}/>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
