import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../components/Modal";
import { Button } from "../components/Button";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import _, { isNil } from "lodash"
import { generateRandomIdWithPrefix } from '../utils';
import Ai from '../store/models/Ai';


export const UpdateLLMEndpointModal = ({ onRequestClose, type, endpoint, projectId, chatbotId }) => {
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    formState
  } = useForm();
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    // if (formState.errors.name) {
    //   setError('Please enter a name')
    // }
  }, [formState]);

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(createEndpoint)();
  }

  const createEndpoint = async (body) => {
    body.endpoint = body.endpoint.endsWith('/chat/completions') ? body.endpoint.slice(0, -17) : body.endpoint;
    body.project_id = projectId;
    body.chatbot_id = chatbotId;
    try {
      if (type === 'edit') {
        body.id = endpoint.id;
        await dispatch(Ai.actions.updateLlmEndpoint(body.id, body))
      } else {
        body.id = generateRandomIdWithPrefix('c_llm_', 32);
        await dispatch(Ai.actions.createLlmEndpoint(body))
      }
      await dispatch(Ai.actions.fetchRagChatbot(projectId, chatbotId));
      onRequestClose();
    } catch (e) {
      setError(e.message);
      console.log('e:', e.message)
    }
  }
  // console.log('version:', endpoint.access_token_version, !isNil(endpoint.access_token_version))
  return (
    <Modal className={cx("CreateLLMEndpointModal")}
      onRequestClose={onRequestClose}>

      <ModalHeader>
        <ModalTitle>{type === 'edit' ? 'Update' : 'Create'} New Custom LLM Endpoint</ModalTitle>
      </ModalHeader>

      <ModalContent>

        <div className={cx("NewFormError")}>{error && error}</div>


        <form onSubmit={onSubmit} className={"NewForm"}>
          <div className={"NewForm__Row"}>
            <div className={"NewForm__InputTextWrapper NewForm__InputTextWrapper--half"}>
              <div className={"NewForm__InputLabel"}>LLM Endpoint</div>
              <input name="name"
                type={"text"}
                autoFocus={true}
                placeholder={"Your own OpenAI compatible LLM endpoint, e.g. https://api.openai.com/v1/chat/completions"}
                defaultValue={type === 'edit' ? endpoint.endpoint : ''}
                {...register("endpoint", { required: true })} />
            </div>
            <div className={"NewForm__InputTextWrapper NewForm__InputTextWrapper--half"}>
              <div className={"NewForm__InputLabel"}>LLM Model</div>
              <input name="name"
                type={"text"}
                placeholder={"e.g. gpt-4o-mini"}
                defaultValue={type === 'edit' ? endpoint.model : ''}
                {...register("model", { required: true })} />
            </div>
          </div>
          <div className={"NewForm__Row"}>
            <div className={"NewForm__InputTextWrapper NewForm__InputTextWrapper--half"}>
              <div className={"NewForm__InputLabel"}>API Key (optional)</div>
              <input name="name"
                type={"text"}
                placeholder={`${type === 'edit' ? 'Updating the API key is not allowed.' : 'The API key to use LLM endpoint'}`}
                defaultValue={(type === 'edit' && !isNil(endpoint.access_token_version)) ? '************' : ''}
                {...register("access_token")}
                disabled={type === 'edit'} />
            </div>
            <div className={"NewForm__InputTextWrapper NewForm__InputTextWrapper--half"}>
              <div className={"NewForm__InputLabel"}>Endpoint Name (optional)</div>
              <input name="name"
                type={"text"}
                placeholder={"e.g. My awesome LLM"}
                defaultValue={type === 'edit' ? endpoint.name : ''}
                {...register("name")} />
            </div>
          </div>

          <ModalActions>
            <Button color={"green"} role={'submit'} loading={loading} title={`${type === 'edit' ? "Update" : "Create"} New LLM Endpoint`} />
            <Button color={"transparent"} onClick={onRequestClose} title={"Cancel"} />
          </ModalActions>

        </form>
      </ModalContent>

    </Modal>
  );
}