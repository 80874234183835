import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ServingIcon, EllipsisIcon } from "../../../components/Svg";
import { Link, useHistory } from 'react-router-dom';
import { Urls } from "../../../constants";
import { mergeUrl } from "../../../utils/url";
import Ai, { selectAllDeployments, selectAllVMs } from '../../../store/models/Ai';
import {
  getStatusSum, age, checkActivePod, getLinkByDeployment,
  checkDeploymentsReady, getVmResourcesString
} from '../../../utils';
import UIState from '../../../store/UIState';
import { ModalTypes } from '../../../constants';
import { Button } from '../../../components/Button';
import classNames from 'classnames';
import { selectCurrentUserId } from '../../../store/models/User';
import { selectCurrentProjectId } from '../../../store/models/Project';
import Loader from '../../../components/Loader';
import { selectAppStarted } from '../../../store/AppState';
import { FaCheckCircle, FaEdit, FaTimesCircle } from "react-icons/fa";
import _ from 'lodash';
import ApiKeyNote from '../../../components/ApiKeyNote';
import Tooltip from "../../../components/Tooltip";

function ModelDeploymentsPage() {
  const dispatch = useDispatch();
  const projectId = useSelector(state => selectCurrentProjectId(state));
  const userId = useSelector(state => selectCurrentUserId(state));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const deployments = useSelector(state => selectAllDeployments(state, projectId));
  const hasNoDeployments = (!_.isNil(deployments) && _.isEmpty(deployments));
  const appStarted = useSelector(state => selectAppStarted(state));

  const vms = useSelector(state => selectAllVMs(state));
  const [activePopup, setActivePopup] = useState(-1);
  const history = useHistory();
  let timeoutId;

  async function fetchData() {
    try {
      setError();
      const deployments = await dispatch(Ai.actions.fetchDeployments(projectId));
      setLoading(false);
      if (!checkDeploymentsReady(deployments)) {
        timeoutId = setTimeout(fetchData, 1000 * 5)
      }
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!appStarted || _.isNil(userId)) return;
    setLoading(true);
    fetchData();
    return () => { clearTimeout(timeoutId) };
  }, [projectId, Object.keys(deployments).length]);

  const handlePopup = (e, i) => {
    e.stopPropagation();
    e.preventDefault();
    if (i === -1) {
      setActivePopup(i)
      return;
    }
    setActivePopup(v => {
      if (v === i) return -1;
      return i;
    })
  }

  const handleLink = (e) => {
    e.stopPropagation();
  }

  const handleDelete = (e, deployment) => {
    const onConfirm = async () => {
      await dispatch(Ai.actions.deleteDeployment(projectId, deployment.Shard, deployment.Suffix));
      await dispatch(Ai.actions.fetchDeployments(projectId));
    }
    e.stopPropagation();
    e.preventDefault();
    setActivePopup(-1);
    dispatch(UIState.actions.showModal(ModalTypes.DELETE, { title: deployment.Name, onConfirm }))
  }

  const navToDetail = (suffix, shard) => {
    history.push(mergeUrl(Urls.AI_MODEL_DEPLOYMENT_DETAIL, { projectId, suffix, shard }));
  }

  return (<div className={'AiServicePage'}>
    <div className={'AiServicePage__header'}>
      <div className={'AiServicePage__header--title'}>
        Model Deployments
        <ApiKeyNote type={'model deployments'} />
      </div>
      <Link to={mergeUrl(Urls.AI_MODEL_EXPLORER, { projectId })}>
        <div className={'AiServicePage__header--button'}>
          <div className={'AiServiceHeaderButtonIcon'}>
            <ServingIcon />
          </div>
          New Deployment
        </div>
      </Link>
    </div>
    {loading && <div className={'EmptyState'}>
      <Loader size='large' color='green' />
    </div>}
    {!loading && !error && <div className={'AiServicePage__content'}>
      {!hasNoDeployments && <div className={'AiServicePage__table'}>
        <div className={'AiServicePage__table--header'}>
          <div className={'AiServicePage__table--header-row'}>
            <div className={'AiServicePage__table--cell title'}>Name</div>
            <div className={'AiServicePage__table--cell title'}>ID</div>
            <div className={'AiServicePage__table--cell inference-endpoint'}></div>
            <div className={'AiServicePage__table--cell status'}>Status</div>
            <div className={'AiServicePage__table--cell serving-duration'}>Duration</div>
            <div className={'AiServicePage__table--cell status'}>Machine Type</div>
            <div className={'AiServicePage__table--cell ellipsis'}></div>
          </div>
        </div>
        <div className={'AiServicePage__table--body'}>
          {Object.keys(deployments).map((k, i) => {
            const deployment = deployments[k];
            return (
              <div key={i} className={'AiServicePage__table--row clickable'} onClick={() => navToDetail(deployment.Suffix, deployment.Shard)}>
                <div className={'AiServicePage__table--cell title'}>
                  <NameSection deployment={deployment} />
                </div>
                <div className={'AiServicePage__table--cell title'}>
                  <ServingIcon />
                  {deployment.Name}
                </div>
                {checkActivePod(deployment) ? <a className={classNames('AiServicePage__table--cell inference-endpoint')}
                  href={getLinkByDeployment(deployment)} target='_blank' onClick={handleLink} >
                  {/* <div className={classNames('AiServicePage__table--cell inference-endpoint')}> */}
                  <div className={classNames('AiServicePageViewButton active')}>
                    Inference Endpoint
                  </div>
                  {/* </div> */}
                </a> : <div className='AiServicePage__table--cell inference-endpoint' onClick={handleLink}>
                  <div className={classNames('AiServicePageViewButton')}>
                    <Loader size='xsmall' color='grey' />
                    Inference Endpoint
                  </div>
                </div>}

                <div className={'AiServicePage__table--cell status'}>{getStatusSum(deployment.PodPhase)}</div>
                <div className={'AiServicePage__table--cell serving-duration'}>
                  {age(deployment.CreatedAt)}
                </div>
                <div className={'AiServicePage__table--cell status'}>
                  <Tooltip tooltip={<div className={"AiServicePage__tooltip"}>
                    {getVmResourcesString(vms[deployment.MachineType].resources)}
                  </div>}>{vms[deployment.MachineType].name}</Tooltip>
                </div>
                <div className={'AiServicePage__table--cell ellipsis'}>
                  <div className={'AiServicePageEllipsis'} onClick={(e) => handlePopup(e, i)}>
                    <EllipsisIcon />
                  </div>
                  {activePopup === i && <div className={'AiServicePageEllipsis__popup'} onClick={e => e.stopPropagation()}>
                    <div className={'AiServicePageEllipsis__popup--row'} onClick={(e) => handleDelete(e, deployment)}>Delete</div>
                  </div>}
                </div>
              </div>
            )
          })}
        </div>
      </div>}
      {
        hasNoDeployments &&
        <div className={'EmptyState'}>
          <div className={'EmptyState__title'}>No Deployments</div>
          <div className={'EmptyState__subtitle'}>Deploy your first Model to get started</div>
          <Button size={'large'}
            title={'New Deployment'}
            href={mergeUrl(Urls.AI_MODEL_EXPLORER, { projectId })}
          />
        </div>
      }
    </div >}
    {!loading && error && <div className={"AiServicePage__error"}>
      {error}
      <Button onClick={async () => {
        await fetchData();
        setLoading(false);
      }}
        color={"green-outline"}
        title={"Retry"} />
    </div>}
  </div >
  );
}

const NameSection = ({ deployment }) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const nameRef = useRef();
  const handleEdit = () => {
    setIsEditing(true);
  }
  const handleSubmit = async () => {
    let nickname = nameRef.current.value;
    let projectId = deployment.ProjectID;
    await dispatch(Ai.actions.updateDeployment(deployment.Shard, deployment.Suffix, {
      project_id: projectId,
      annotations: {
        nickname: nickname,
        tags: _.get(deployment, 'Annotations.tags') || '[]'
      }
    }))
    await dispatch(Ai.actions.fetchDeployments(projectId));
    setIsEditing(false);
  }
  const handleCancel = () => {
    setIsEditing(false);
  }
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }
  return <div onClick={e => e.stopPropagation()} className='AiServicePage__name-warp'>
    {!isEditing ? _.get(deployment, 'Annotations.nickname') || <span className='AiServicePage__name-warp--empty'>name empty</span> :
      <input type='text' ref={nameRef} defaultValue={_.get(deployment, 'Annotations.nickname')} onKeyDown={handleKeyDown} />}
    {isEditing ?
      <div className='AiServicePage__icon-warp'>
        <div className='PageTitleBar__right--icon cancel' onClick={handleCancel} title="Cancel"><FaTimesCircle /></div>
        <div className='PageTitleBar__right--icon submit' onClick={handleSubmit} title="Submit"><FaCheckCircle /></div>
      </div> :
      <div className='AiServicePage__icon-warp'>
        <div className='PageTitleBar__right--icon edit' onClick={handleEdit}><FaEdit /></div>
      </div>}
  </div>
}

export default ModelDeploymentsPage;
