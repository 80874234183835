import React, {useEffect, useState} from 'react';
import {BackButton, Button} from "../../../components/Button";
import {useDispatch, useSelector} from "react-redux";
import PageTitleBar from "../../../components/PageTitleBar";
import {useParams} from "react-router";
import {pushTo} from "../../../store/history";
import {useIsMountedRef} from '../../../hooks/useIsMounted';
import Webhook, {selectWebhookRegistration} from '../../../store/models/Webhook';
import {selectCurrentProject} from "../../../store/models/Project";


function DeleteWebhookRegistrationPage() {
  const dispatch = useDispatch();
  const {webhookRegId} = useParams();
  const project = useSelector(state => selectCurrentProject(state));
  const videoApiId = project?.tva_id;
  const webhookRegistration = useSelector(state => selectWebhookRegistration(state, webhookRegId));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false);
  const isMountedRef = useIsMountedRef();

  console.log('isDeleted:', isDeleted)

  useEffect(() => {
    dispatch(Webhook.actions.fetchWebhookRegistration(webhookRegId));
  }, []);

  const handleDelete = async () => {
    setLoading(true);

    try {
      await dispatch(Webhook.actions.deleteWebhookRegistration(webhookRegId));
    } catch (e) {
      if (e.code === 204) {
        pushTo(`/dashboard/video/webhooks/registrations`);
        return;
      }
      setError(e.message || 'Ooops');
    } finally {
      if (!isMountedRef.current) return;
      setLoading(false);
    }
  };

  const leftContent = isDeleted ? <BackButton title={'Webhooks'}
                                              href={`/dashboard/video/webhooks/registrations`}
  /> : <BackButton title={'Webhook'}
                   href={`/dashboard/video/webhooks/registrations/${webhookRegId}`}
  />

  return (
    <div className={'VideoPage'}>
      <div className={'VideoPage__content'}>
        <PageTitleBar title={'Delete Registration'}
                      leftContent={leftContent}
        />
        <div className={'VideoPage__delete'}>
          <span>Do you want to delete webhook registration for {webhookRegistration?.name || ''}</span>
          <span>(id: {webhookRegId})?</span>
        </div>
        {error && <div className={'CreateVideoPage__error'}>{error}</div>}
        <Button title={'Delete'}
                role={'submit'}
                loading={loading}
                color={'red'}
                style={{
                  marginTop: 30,
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
                onClick={handleDelete}
        />

      </div>
    </div>
  );
}

export default DeleteWebhookRegistrationPage;