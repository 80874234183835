import React from 'react';
import cx from 'classnames';

import Modal, {ModalContent, ModalHeader, ModalTitle} from "../components/Modal";
import EnableTwoFA from "../components/EnableTwoFA";


const TwoFAModal = ({onRequestClose, ...props}) => {
  const onDone = () => {
    onRequestClose()
  }

  return (
    <Modal className={cx("two-fa-modal")} onRequestClose={onRequestClose}>
      <ModalHeader>
        <ModalTitle align="row">Manage 2-Step Authentication</ModalTitle>
      </ModalHeader>

      <ModalContent>
        <EnableTwoFA onDone={onDone} />
      </ModalContent>
    </Modal>)
}
export default TwoFAModal;