import _ from 'lodash';
import React, { useRef, useState, useEffect, useCallback } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

export const useVideoJS = (videoJsOptions, classNames = "") => {
  const videoNode = useRef(null);
  const [ready, setReady] = useState(false);
  const changedKey = JSON.stringify(videoJsOptions);
  const player = useRef(null);
  useEffect(() => {
    let intervalId;
    player.current = videojs(videoNode.current, videoJsOptions);
    player.current.ready(() => {
      setReady(true);
    });

    player.current.on('error', (e) => {
      console.log('err:', e);
      checkAndReload();
    })

    checkAndReload();

    function checkAndReload() {
      if (videoJsOptions.type === 'livestream') {
        intervalId = setInterval(() => {
          const _player = player.current;

          if (_player) {
            if (_player.currentTime()) {
              clearInterval(intervalId);
            } else {
              _player.autoplay(true);
              _player.src(videoJsOptions.sources);
            }
          }
          console.log('in interval');
        }, 5 * 1000)
      }
    }


    return () => {
      player.current.dispose();
      clearInterval(intervalId);
    };
  }, [changedKey]);

  const Video = useCallback(
    ({ children, ...props }) => (
      <div data-vjs-player key={changedKey}>
        <video ref={videoNode} className={`video-js ${classNames}`} {...props}>
          {children}
        </video>
      </div>
    ),
    [changedKey]
  );
  return { Video, ready, player: player.current };
};
