import React, {useEffect, useState} from 'react';
import cx from 'classnames';
import Modal, {ModalActions, ModalContent, ModalHeader, ModalTitle} from "../components/Modal";
import {Button} from "../components/Button";
import Organization, {selectCurrentOrg, selectOrganizations} from "../store/models/Organization";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import _ from "lodash";
import Project, {selectAllProjects, selectCurrentProject, selectOrgProjects} from "../store/models/Project";
import {createSelector} from "reselect";
import {SelectInput} from "../components/form/SelectInput";
import User from "../store/models/User";
import {toast} from "react-toastify";
import {UserRoleIds, UserRoleLabels} from "../constants";

const selector = createSelector(
  selectOrganizations,
  selectAllProjects,
  selectCurrentOrg,
  selectCurrentProject,
  (organizations, projects, currentOrg, currentProject) => {
    return {organizations, projects, currentOrg, currentProject}
  }
)

export const InviteUserModal = ({onRequestClose}) => {
  const dispatch = useDispatch()
  const {organizations, projects, currentOrg, currentProject} = useSelector(state => selector(state))

  const {
    register,
    handleSubmit,
    formState
  } = useForm();
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const [selectedOrg, setSelectedOrg] = useState(currentOrg)
  const [selectedProject, setSelectedProject] = useState(currentProject)
  const [selectedRole, setSelectedRole] = useState(UserRoleIds.VIEWER)

  const orgOptions = _.map(organizations, (org) =>
    ({label: org.name, value: org.id}))

  const orgProjects = _.filter(projects, project => project.org_id === selectedOrg.id)

  const projectOptions = _.map(orgProjects, project =>
    ({label: project.name, value: project.id}))

  const roleOptions = _.map(UserRoleLabels, (label, key) =>
    ({label: label, value: key}))

  useEffect(() => {
    dispatch(Organization.actions.fetchUserOrganizations());
  }, []);

  useEffect(() => {
    (async () => {
      if (selectedOrg) {
        const projects = await dispatch(Project.actions.fetchUserProjectsInOrg(selectedOrg?.id));
        if (selectedProject?.org_id !== selectedOrg?.id && projects?.length > 0) {
          setSelectedProject(projects[0])
        }
      }
    })()
  }, [selectedOrg]);

  useEffect(() => {
    if (formState.errors.name) {
      setError('Please enter a name')
    } else if (_.isEmpty(formState.errors)) {
      setError(null)
    }
  }, [formState]);

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(createProject)();
  }

  const createProject = async (body) => {
    setLoading(true);
    setError(null);

    body.role = selectedRole

    try {
      const invite = await dispatch(User.actions.inviteUserToProject(body, selectedProject.id))
      toast.success('User invited')
      onRequestClose()
    } catch (e) {
      setLoading(false);
      setError(e.message)
    }
  }

  const onSelectOrg = (org) => {
    setSelectedOrg(_.find(organizations, {id: org.value}))
  }

  const onSelectProject = (project) => {
    setSelectedProject(_.find(projects, {id: project.value}))
  }

  const onSelectRole = (role) => {
    setSelectedRole(role.value)
  }

  return (
    <Modal className={cx("InviteUserModal")}
           onRequestClose={onRequestClose}>

      <ModalHeader>
        <ModalTitle>Invite a new member</ModalTitle>
      </ModalHeader>

      <ModalContent>
        <div className={cx("NewFormError")}>{error && error}</div>

        <form onSubmit={onSubmit} className={"NewForm"}>

          <div className={"NewForm__Row NewForm__Row"}>

            <div className={"NewForm__InputWrapper"}>
              <div className={"NewForm__InputLabel"}>Organization</div>
              <SelectInput options={orgOptions}
                           value={{label: selectedOrg.name, value: selectedOrg.id}}
                           onChange={onSelectOrg}/>
            </div>

            <div className={"NewForm__InputWrapper"}>
              <div className={"NewForm__InputLabel"}>Project</div>
              <SelectInput options={projectOptions}
                           value={{label: selectedProject?.name, value: selectedProject?.id}}
                           onChange={onSelectProject}/>
            </div>

          </div>

          <div className={"NewForm__Row NewForm__Row--last"}>
            <div className={"NewForm__InputTextWrapper"}>
              <div className={"NewForm__InputLabel"}>Email</div>
              <input name="email"
                     type={"email"}
                     placeholder={"Enter an email"}
                     {...register("email", {required: true})} />
            </div>
            <div className={"NewForm__InputWrapper"}>
              <div className={"NewForm__InputLabel"}>Role</div>
              <SelectInput options={roleOptions}
                           value={{label: UserRoleLabels[selectedRole], value: selectedRole}}
                           onChange={onSelectRole}/>
            </div>

          </div>

          <ModalActions>
            <Button color={"green"} disabled={!selectedProject} role={'submit'} loading={loading}
                    title={"Invite new member"}/>
            <Button color={"transparent"} onClick={onRequestClose} title={"Cancel"}/>
          </ModalActions>

        </form>

      </ModalContent>

    </Modal>
  );
}