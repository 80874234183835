import React, {useEffect, useState} from "react";
import {DownChevronIcon, GarmentIcon, ModelIcon, SendIcon} from "../Svg";
import {Button} from "../Button";
import _ from "lodash";
import cx from "classnames";
import {AIShowcase} from "../../store/models/AIShowcase";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {ModalTypes} from "../../constants";
import {StableViton} from "../../constants/sd-models";
import Loader from "../Loader";
import UIState, {selectMobileLayout} from "../../store/UIState";
import {createSelector} from "reselect";
import {ImageInput} from "../form/ImageInput";
import {ShowcaseSettingsPanel} from "./ShowcaseSettingsPanel";
import {ShowcaseGallery} from "./ShowcaseGallery";


const LoadingItem = ({loadingData, elapsedTime}) => {
  if (!loadingData) return <></>

  if (loadingData.msg === 'estimation') {
    return (
      <div className={cx("ResultBox")}>
        <div className={"ResultBox__Loading"}>
          <div className={"ResultBox__Loading__TopWrapper"}>
            Scheduled | {elapsedTime}s / {loadingData.rank_eta?.toFixed(0) ?? '?'}s
          </div>

          <div className={"ResultBox__Loading__CenterWrapper"}>
            <Loader size={"small"} color={"green"}/> Waiting for available slot...
          </div>

          <div className={"ResultBox__Loading__BottomWrapper"}>Number of jobs ahead of you: <span
            style={{color: '#ffffff'}}>{loadingData.rank + 1}</span></div>
        </div>
      </div>)
  }

  if (loadingData.msg === 'process_starts') {
    return (<div className={cx("ResultBox")}>

      <div className={"ResultBox__Loading"}>

        <div className={"ResultBox__Loading__TopWrapper"}>
          Processing | {elapsedTime}s / {loadingData.rank_eta?.toFixed(0) ?? '?'}s
        </div>

        <div className={"ResultBox__Loading__CenterWrapper"}>
          <Loader color={"green"}/>
        </div>

        <div className={"ResultBox__Loading__BottomWrapper"}>Generation in progress...</div>
      </div>
    </div>)
  }
}

const PromptItem = ({message}) => {
  const [paramsOpened, setParamsOpened] = useState(false);

  const onToggleParams = () => {
    setParamsOpened(!paramsOpened);
  }

  return (
    <div className={cx("PromptContainerRoot")}>
      <div className={"PromptContainer"}>
        <div className={"PromptContainer__Visible"}>
          <div className="PromptContainer__Left">
            <div className={"PromptContainer__PromptLabel"}>Prompt</div>
            <div className={`PromptContainer__ParamsButton ${paramsOpened && "open"}`}
                 onClick={onToggleParams}>More parameters <DownChevronIcon/></div>
          </div>
          <div className={"PromptContainer__Right"}>
            <a href={message.model} target={"_blank"}>
              <img src={message.model}/>
            </a>
            <a href={message.garment} target={"_blank"}>
              <img src={message.garment}/>
            </a>
          </div>
        </div>
        {paramsOpened &&
          <div className={"PromptContainer__Params"}>
            <pre>{JSON.stringify(message.params, null, 2)}</pre>
          </div>}
      </div>
    </div>)

}

const ResultItem = ({item}) => {
  return (
    <div className={cx("ResultBox")}>
      <div className={"ResultBox__Image"}>
        <a href={item.image} target={"_blank"}>
          <img src={item.image} alt={"result"}/>
        </a>
      </div>
    </div>
  )
}

const ImageInputInstructions = ({type}) => {
  const icon = type === 'garment' ? <GarmentIcon/> : <ModelIcon/>

  return (<>
    <div>{icon}</div>
    <div><span className={"ImageInput__Label--Green"}>Upload {type} image</span></div>
    <div>or</div>
    <div>drag image here (jpg)</div>
  </>)
}


const selector = createSelector(
  selectMobileLayout,
  (isMobile) => ({
      isMobile
    }
  ))

let vitonIntervalId = null;

export const ShowcaseViton = ({showcase}) => {

  const dispatch = useDispatch();

  const {isMobile} = useSelector(state => selector(state));
  const [imageUploading, setImageUploading] = useState(false)
  const [loadingData, setLoadingData] = useState(null)
  const [error, setError] = useState(null)
  const [elapsedTime, setElapsedTime] = useState(0)
  const [currentModel, setCurrentModel] = useState(null)
  const [currentGarment, setCurrentGarment] = useState(null)
  const [messages, setMessages] = useState([])
  const [settings, setSettings] = useState(_.reduce(StableViton.params, (obj, param) => {
    obj[param.name] = param.default
    return obj
  }, {}))
  const chatboxRef = React.createRef();
  const [showSettings, setShowSettings] = useState(!isMobile)
  const showcaseUrl = showcase.models[0].urls

  useEffect(() => {
    return () => {
      if (vitonIntervalId) {
        clearInterval(vitonIntervalId)
      }
      dispatch(AIShowcase.actions.stopVitonGeneration())
    }
  }, []);

  const onGenerate = async (event) => {
    setError(null)
    event.preventDefault();

    try {
      setMessages([...messages, {
        type: 'prompt',
        garment: currentGarment,
        model: currentModel,
        params: settings
      }, {type: 'loading'}])
      onScrollToBottom()
      dispatch(AIShowcase.actions.vitonPredict(showcaseUrl, {
        garment: currentGarment,
        model: currentModel,
        ...settings
      }, onPredictEvent));
    } catch (e) {
      toast.error(e.message)
      console.error(e)
    }
  };

  const onSelectGalleryImage = async (itemIndex, name) => {
    setImageUploading(true)
    try {
      const imageData = await dispatch(AIShowcase.actions.vitonGetGalleryImage(showcaseUrl, name === 'garment' ? 1 : 0, itemIndex))

      if (name === 'garment') {
        setCurrentGarment(imageData)
      } else if (name === 'model') {
        setCurrentModel(imageData)
      }
    } catch (e) {
      toast.error(e.message)
    }
    setImageUploading(false)
  }

  const onImageSelected = async (file, name) => {
    if (!file) return;

    try {
      setImageUploading(true)
      let fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = function () {
        if (name === 'garment') {
          setCurrentGarment(fileReader.result)
        } else if (name === 'model') {
          setCurrentModel(fileReader.result)
        }
        setImageUploading(false)
      }
      fileReader.onerror = function (error) {
        setImageUploading(false)
        toast.error("Failed to upload image")
      }
    } catch (e) {
      setImageUploading(false)
      toast.error("Failed to upload image")
    }
  }

  const onSettingsChanged = (settings) => {
    setSettings(settings)
  }

  const onPredictEvent = (event) => {
    switch (event.msg) {
      case 'estimation':
        startStopwatch()
        setLoadingData(event)
        break;
      case 'process_starts':
        setLoadingData(loadingData => ({...loadingData, msg: 'process_starts'}))
        break;
      case 'process_completed':
        try {
          if (event.success) {
            setLoadingData(null)
            stopInterval()
            setMessages((messages) => [...messages.slice(0, -1), {type: 'result', image: event?.output?.data[0]}])
            onScrollToBottom()
          } else {
            onError("Failed to generate")
          }
        } catch (e) {
          onError("Failed to generate: " + e.message)
        }
        break;
      case 'error':
      case 'unexpected_error':
        onError(event.error)
        break;
    }
  }

  const onError = (error) => {
    setLoadingData(null)
    setMessages((messages) => messages.slice(0, -2))
    stopInterval()
    setError(error ?? "Failed to generate. Try again later")
    toast.error(error ?? "Failed to generate. Try again later")
  }

  const startStopwatch = () => {

    if (vitonIntervalId) {
      stopInterval()
    }

    const startTime = new Date().getTime()

    vitonIntervalId = setInterval(() => {
      const currentTime = new Date().getTime()
      setElapsedTime(((currentTime - startTime) / 1000).toFixed(0))
    }, 1000)

  }

  const stopInterval = () => {
    clearInterval(vitonIntervalId)
    vitonIntervalId = null
    setElapsedTime(0)
  }

  const onStopGenerating = (event) => {
    event.preventDefault();

    dispatch(UIState.actions.showModal(ModalTypes.CONFIRM, {
      title: "Stop generating",
      message: "Are you sure you want to stop the ongoing generation?",
      onConfirm: onConfirmStopGenerating,
      confirmLabel: "Yes",
      cancelLabel: "No",
      size: 's'
    }))
  }

  const onConfirmStopGenerating = () => {
    stopInterval()
    setLoadingData(null)
    setMessages((messages) => messages.slice(0, -2))
    dispatch(AIShowcase.actions.stopVitonGeneration())
  }

  const onRemoveImages = () => {
    setCurrentModel(null)
    setCurrentGarment(null)
  }

  const onRemoveImage = (name) => {
    if (name === 'model') {
      setCurrentModel(null)
    } else if (name === 'garment') {
      setCurrentGarment(null)
    }
  }

  const onScrollToBottom = () => {
    if (!isMobile) {
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
        if (!_.isNil(chatboxRef) && !_.isNil(chatboxRef.current)) {
          chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
        }
      }, 200)
    }
  }

  const onToggleSettings = () => {
    setShowSettings(!showSettings);
  }

  return (
    <div className="ShowcaseViton">
      <div className="ShowcaseViton__container">
        <div className={"ShowcaseViton__LeftSide"}>

          <div className={"SidePanel"}>

            <form className="SidePanel__form">

              <div className={"SidePanel__ImageInputs"}>
                <ImageInput value={currentModel}
                            name={"model"}
                            label={"Model Image"}
                            onChange={onImageSelected}
                            onRemove={onRemoveImage}
                            formats={"image/jpeg, image/jpg, image/png"}
                            instructions={<ImageInputInstructions type={"model"}/>}>
                  <div className={"ImageInput__LeftBadge"}>Model</div>
                </ImageInput>

                {isMobile && <ShowcaseGallery images={existingModels(showcaseUrl)}
                                              title={"Model examples"}
                                              onClick={onSelectGalleryImage}
                                              name={"model"}/>}

                <ImageInput value={currentGarment}
                            name={"garment"}
                            label={"Garment Image"}
                            formats={"image/jpeg, image/jpg, image/png"}
                            onRemove={onRemoveImage}
                            onChange={onImageSelected}
                            instructions={<ImageInputInstructions type={"garment"}/>}>
                  <div className={"ImageInput__LeftBadge"}>Garment</div>
                </ImageInput>

                {isMobile && <ShowcaseGallery images={existingGarments(showcaseUrl)}
                                              title={"Garment examples"}
                                              onClick={onSelectGalleryImage}
                                              name={"garment"}/>}
              </div>
              <div className={"SidePanel__Examples"}>
                {!isMobile && <ShowcaseGallery images={existingModels(showcaseUrl)}
                                               title={"Model examples"}
                                               onClick={onSelectGalleryImage}
                                               name={"model"}/>}
                {!isMobile && <ShowcaseGallery images={existingGarments(showcaseUrl)}
                                               title={"Garment examples"}
                                               onClick={onSelectGalleryImage}
                                               name={"garment"}/>}
              </div>

              <div className={"SidePanel__ActionButtons"}>
                <Button
                  title={"Clear"}
                  color={"grey"}
                  className="SidePanel__ActionButtons__Button SidePanel__ActionButtons__Button"
                  disabled={_.isNil(currentModel)}
                  onClick={onRemoveImages}
                />

                {loadingData
                  ? <Button
                    title={"Stop generating"}
                    onClick={onStopGenerating}
                    className="SidePanel__ActionButtons__Button--Primary"
                    color={"red"}
                  />
                  : <Button
                    title={"Generate"}
                    onClick={onGenerate}
                    loading={imageUploading}
                    icon={imageUploading ? null : <SendIcon/>}
                    className="SidePanel__ActionButtons__Button SidePanel__ActionButtons__Button--Primary"
                    color={"green"}
                    disabled={!_.isNil(loadingData) || _.isNil(currentModel) || _.isNil(currentGarment)}/>}
              </div>

              <div className={`SidePanel__OutputOptions ${showSettings && "open"}`}
                   onClick={onToggleSettings}>Output options <DownChevronIcon/></div>

              {showSettings &&
                <>
                  <ShowcaseSettingsPanel model={StableViton} onParamsChanged={onSettingsChanged}/>
                </>}
            </form>

          </div>
        </div>

        <div className={cx("ShowcaseViton__RightSide")}>
          {error && <div className={"ErrorMessage"}>{error}</div>}

          <div className={"MessagesWrapper"} ref={chatboxRef}>
            {!_.isEmpty(messages) && messages.map((item, index) => {
              switch (item.type) {
                case 'prompt':
                  return (<PromptItem key={index} message={item}/>)
                case 'loading':
                  return (<LoadingItem key={index} loadingData={loadingData} elapsedTime={elapsedTime}/>)
                case 'result':
                  return (<ResultItem key={index} item={item}/>)
              }
            })}
          </div>

        </div>
      </div>

    </div>
  );
}

const existingModels = (url) => [
  `${url}/file=/app/examples_eternal/model/1.jpg`,
  `${url}/file=/app/examples_eternal/model/2.jpg`,
  `${url}/file=/app/examples_eternal/model/3.jpg`,
  `${url}/file=/app/examples_eternal/model/4.jpg`,
  `${url}/file=/app/examples_eternal/model/6.jpg`,
]

const existingGarments = (url) => [
  `${url}/file=/app/examples_eternal/garment/1.jpg`,
  `${url}/file=/app/examples_eternal/garment/2.jpg`,
  `${url}/file=/app/examples_eternal/garment/3.jpg`,
  `${url}/file=/app/examples_eternal/garment/4.jpg`,
  `${url}/file=/app/examples_eternal/garment/5.jpg`,
  `${url}/file=/app/examples_eternal/garment/6.jpg`,
]