import React, { forwardRef } from 'react';
import _ from 'lodash';
import cx from 'classnames';

import { formatNumber, formatTFuel } from '../utils/number';

import {DollarIcon, TFuelIcon} from './Svg'


export const defaultPriceFormat = v => formatNumber(v, 2);
export const floorTfuelPriceFormat = v => formatNumber(v, 1, 0, true)
export const floorDollarPriceFormat = v => formatNumber(v, 2, 0, true)
const Price = forwardRef(({ amount, token, discount, format = defaultPriceFormat, className, size = 'm', color, children, withIcon = false, noIcon = false, onClick, ...props }, ref) => {
  let icon;
  let hasDiscount = !_.isNil(discount);

  amount = _.isNaN(amount)
    ? 0
    : amount;

  let discountedAmount = hasDiscount
    ? parseFloat(amount) - (parseFloat(amount) * discount)
    : amount;

  if (token === 'tfuel') {
    icon = <TFuelIcon size={size} />
  } else if (withIcon && token === 'usd') {
    icon = <DollarIcon size={size} />
  }
  
  return (
    <div 
      ref={ref}
      className={cx("g-price", className, size, color, token, { 'has-click': _.isFunction(onClick), discounted: hasDiscount })}
      onClick={onClick}
      {...props}>
      <span className="price-amount">
        { !noIcon && icon }
        <strong className={cx("price-value", {tfuel: (token === 'tfuel')})}>{ format ? format(amount) : amount }</strong>
        { hasDiscount && 
        <div className="price-slash" />}
      </span>
      { hasDiscount && 
      <div className="discount-amount">
        { icon }
        <strong className="price-value">{ format ? format(discountedAmount) : discountedAmount }</strong>
      </div>}
      { children }
    </div>);
})

export default Price;


export const TFuelPrice = forwardRef(({positive = false, noIcon = false, withTag = false, children, ...props}, ref) =>
  <Price {...props}
    ref={ref}
    token='tfuel'
         noIcon={noIcon}
    format={props.format ?? formatTFuel}>
    { withTag ? <span className="price-tag">TFUEL</span> : children}
  </Price>);

export const UsdPrice = forwardRef(({floor, withIcon = false, withTag = false, negative = false, positive = false, children, ...props}, ref) =>
    <Price
        {...props}
        ref={ref}
        token='usd'
        withIcon={withIcon}
        format={v => `${(negative) ? '-' : (positive) ? '+' : ''} $${formatNumber(v, 2, 0, floor)}`}>
      { withTag ? <span className="price-tag">USD</span> : children}
    </Price>);
