import { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty, isNil, get } from "lodash";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import UIState from "../../store/UIState";
import Ai from "../../store/models/Ai";
import { DeleteIcon, OverviewIcon } from "../Svg";
import KnowledgeBase from "./KnowledgeBase";
import { Button } from "../Button";
import Loader from "../Loader";
import { Pagination } from "../Pagination";
import { FaCheckCircle, FaEdit, FaTimesCircle } from "react-icons/fa";
import { ModalTypes } from "../../constants";

export const KnowledgeTab = (props) => {
    const dispatch = useDispatch();
    const { chatbot } = props;
    const { projectId, id } = useParams();
    const [error, setError] = useState();
    const [crtPage, setCrtPage] = useState(1);
    const [documents, setDocuments] = useState();
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
  
    useEffect(() => {
      fetchData()
    }, [chatbot.id])
  
    const fetchData = async (page = 0) => {
      try {
        setError()
        setLoading(true);
        let res = await dispatch(Ai.actions.fetchChatbotDocuments(id, projectId, page));
        setDocuments(_.get(res, 'documents'));
        setTotalPages(_.get(res, 'total_pages'));
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    }
  
    const handlePageChange = pageNumber => {
      setCrtPage(pageNumber);
      fetchData(pageNumber - 1);
    }
  
    return <div className='AiServicePage__tab-content'>
      <div className='RagChatbotDetailPage knowledge-base-tab'>
        <NewDocument fetchData={fetchData} />
        {loading && <div className={'EmptyState'}>
          <Loader size='large' color='grey' />
        </div>}
        {!loading && documents && documents.length > 0 && <>
          <div className='RagChatbotDetailPage__documents'>
            {documents.map(doc => <DocumentCard key={doc.id} doc={doc} fetchData={fetchData} crtPage={crtPage} />)}
          </div>
          <div className='RagChatbotDetailPage__pagination'>
            <Pagination
              size={'lg'}
              currentPage={crtPage}
              totalPages={totalPages}
              onPageChange={handlePageChange} />
          </div>
        </>}
        {!loading && documents && documents.length === 0 && <div className={'RagChatbotDetailPage__empty-state'}>
          No Documents
        </div>
        }
        {/* <div className='RagChatbotDetailPage__tabs'>
          <div className={cx("RagChatbotDetailPage__tab", { active: tab === 'file' })} onClick={() => setTab('file')}>
            File
          </div>
          <div className={cx("RagChatbotDetailPage__tab", { active: tab === 'text' })} onClick={() => setTab('text')}>
            Text
          </div>
        </div>
        <div className='RagChatbotDetailPage__knowledge-base-content'>
          {tab === 'file' && fileDocs.map(file => {
            return <div className='RagChatbotDetailPage____knowledge-base--wrap'>
              <div className='RagChatbotDetailPage____knowledge-base--label'>
                Knowledge Base {file.id}
              </div>
              <div className='RagChatbotDetailPage____knowledge-base--file'>
                <FileInput accept=".doc,.pdf,.docx,.txt" file={file}
                  setFile={() => { }} seterror={setError} error={error}
                  emptyContent={<p>Drag & drop or <span className={"choose-file-text"}>Choose a file</span> to upload<br />
                    Supported File Types: pdf, .doc., docx., .txt
                  </p>} />
              </div>
              <div className='RagChatbotDetailPage____knowledge-base--file-preview'>
                {file.content}
              </div>
            </div>
          })}
          {tab === 'text' && textDocs.map(text => {
            return <div className='RagChatbotDetailPage____knowledge-base--wrap'>
              <div className='RagChatbotDetailPage____knowledge-base--label'>
                Knowledge Base {text.id}
              </div>
              <div className='RagChatbotDetailPage____knowledge-base--text'>
                <textarea type="text" placeholder='Enter text here...' className='RagChatbotDetailPage__textarea'
                  onChange={() => { }} defaultValue={text.content} />
              </div>
            </div>
          })}
        </div> */}
      </div>
    </div>
  }
  
  const NewDocument = (props) => {
    const { fetchData } = props;
    const dispatch = useDispatch();
    const { projectId, id } = useParams();
    const [tab, setTab] = useState('file');
    const [newData, setNewData] = useState({});
    const [error, setError] = useState();
    const [loading, setLoading] = useState();
  
    const handleAdd = async () => {
      setError();
      let text = _.get(newData, 'text');
      let file = _.get(newData, 'file');
      let metadata = _.get(newData, 'metadata');
      if ((isNil(text) || isEmpty(text)) && isNil(file)) {
        setError('Either file or text must be provided');
        return;
      }
      setLoading(true);
      let count = 0;
      if (!isNil(text) && !isEmpty(text)) {
        try {
          await dispatch(Ai.actions.createChatbotDocument(id, {
            project_id: projectId,
            text,
            description: metadata ?? ''
          }))
          setNewData(d => ({ ...d, text: '', metadata: '' }));
          count++;
        } catch (e) {
          setError(e.message);
          setLoading(false);
          return;
        }
      }
      if (!isNil(file)) {
        try {
          await dispatch(Ai.actions.createChatbotDocument(id, {
            project_id: projectId,
            file,
            description: metadata ?? ''
          }))
          setNewData(d => ({ ...d, file: null, metadata: '' }));
          count++;
        } catch (e) {
          setError(e.message);
          setLoading(false);
          return;
        }
      }
      toast.success(`The new document${count > 1 ? 's have' : ' has'} been added.`);
      await fetchData();
      setLoading(false);
    }
  
    const AddButton = () => {
      let text = _.get(newData, 'text');
      let file = _.get(newData, 'file');
      const disabled = (isNil(text) || isEmpty(text)) && isNil(file);
  
      return <Button className={"RagChatbotDetailPage__new-document--button"} title={loading ? "Adding" : "Add"}
        color='green-outline' onClick={handleAdd} loading={loading} disabled={disabled} />
    }
    return <div className='RagChatbotDetailPage__new-document'>
      <div className='RagChatbotDetailPage__new-document--label'>
        Add a new document
      </div>
      <KnowledgeBase className={'large'} tab={tab} settab={setTab}
        data={newData} setdata={setNewData}
        seterror={setError} error={error} enableReset={true} addButton={<AddButton />} />
      {/* <Button className={"RagChatbotDetailPage__new-document--button"} title={loading ? "Adding" : "Add"}
        color='green-outline' onClick={handleAdd} loading={loading} /> */}
      {error && <div className={"RagChatbotDetailPage__document--error"}>{error}</div>}
    </div>
  }
  const DocumentCard = (props) => {
    const { doc, fetchData, crtPage } = props;
    const { projectId, id } = useParams();
    const type = _.get(doc, 'metadata.type');
    const dispatch = useDispatch();
    const [currentDoc, setCurrentDoc] = useState(doc);
    const [isEditing, setIsEditing] = useState(false);
    const [editedMetadata, setEditedMetadata] = useState(doc.metadata.description || '');
    const [isOpening, setIsOpening] = useState(false);

    const handleDelete = () => {
      const onConfirm = async () => {
        await dispatch(Ai.actions.deleteChatbotDocument(projectId, id, doc.id));
        await fetchData(crtPage - 1);
      }
      dispatch(UIState.actions.showModal(ModalTypes.DELETE, { title: doc.id, onConfirm }));
    }

    const handleOpen = async () => {
      setIsOpening(true);
      try {
        const document = await dispatch(Ai.actions.fetchChatbotDocument(id, projectId, doc.id));
        const content = document.content;
        const fileName = type === 'file' ? _.get(doc, 'metadata.filename', 'document.txt') : 'document.txt';
        const blob = new Blob([content], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const newWindow = window.open(url, '_blank');
        newWindow.document.title = fileName;
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Failed to open document:', error);
        // Optionally, show an error message to the user
      } finally {
        setIsOpening(false);
      }
    }

    const handleEdit = () => {
      setIsEditing(true);
    }

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        await dispatch(Ai.actions.updateChatbotDocument(id, doc.id, {
          project_id: projectId,
          description: editedMetadata
        }));
        setCurrentDoc(d => ({ ...d, metadata: { ...d.metadata, description: editedMetadata } }));
      } catch (e) {
        toast.error('Failed to update metadata')
        setEditedMetadata(currentDoc.metadata.description || '');
      }
      setIsEditing(false);
    }

    const handleCancel = () => {
      setIsEditing(false);
      setEditedMetadata(currentDoc.metadata.description || '');
    }

    const handleKeyDown = (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSubmit();
      }
    }

    return <div className='RagChatbotDetailPage__document' key={doc.id}>
      <div className='RagChatbotDetailPage__document--label'>
        <span className='RagChatbotDetailPage__document--label-cell id'>Document ID: {doc.id}</span>
        <span className='RagChatbotDetailPage__document--label-cell'>Type: {type}</span>
        
      </div>
      {type === 'file' && <div className='RagChatbotDetailPage__document--label'>
        <span className='RagChatbotDetailPage__document--label-cell'>
          File name: {_.get(doc, 'metadata.filename')}
        </span>
      </div>}
      <div className='RagChatbotDetailPage__document--hr'></div>
      {/* <div className='RagChatbotDetailPage__document--label'>
        Document Content
      </div> */}
      <div className='RagChatbotDetailPage__document--metadata'>
        <div className='RagChatbotDetailPage__document--metadata-label'>
          Metadata
          <div className='RagChatbotDetailPage__document--metadata-actions'>
            {isEditing ? (
              <>
                <div className='RagChatbotDetailPage__document--icon cancel' onClick={handleCancel} title="Cancel">
                  <FaTimesCircle />
                </div>
                <div className='RagChatbotDetailPage__document--icon submit' onClick={handleSubmit} title="Submit">
                  <FaCheckCircle />
                </div>
              </>
            ) : (
              <div className='RagChatbotDetailPage__document--icon edit' onClick={handleEdit}>
                <FaEdit />
              </div>
            )}
          </div>
        </div>
        <div className='RagChatbotDetailPage__document--metadata-input'>
          {isEditing ? (
            <textarea
              value={editedMetadata}
              onChange={(e) => setEditedMetadata(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          ) : (
            currentDoc.metadata.description || ''
          )}
        </div>
      </div>
      <span className='RagChatbotDetailPage__document--label-cell options'>
          <Button
            className="RagChatbotDetailPage__document--small-button delete"
            onClick={handleDelete}
            color="red-outline"
            size="small"
            icon={<DeleteIcon />}
            title="Delete"
          >
            Delete
          </Button>
          <Button
            className="RagChatbotDetailPage__document--small-button open"
            onClick={handleOpen}
            color="green-outline"
            title={isOpening ? "" : "Open Document"}
            size="small"
            icon={isOpening ? <Loader size="small" color="green" /> : <OverviewIcon />}
            disabled={isOpening}
          >
          </Button>
        </span>
    </div>
  }
  const FileInput = props => {
    const { setFile, seterror, error, file, baseStyle = BaseStyle, emptyContent, icon } = props;
  
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
      accept: props.accept,
    });
    useEffect(() => {
      if (file) {
        setFile(file);
      }
    }, [])
  
    useEffect(() => {
      seterror();
      const newFile = acceptedFiles[0];
      if (!newFile) return;
      setFile(newFile);
    }, [acceptedFiles])
  
    const style = useMemo(() => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }), [
      isDragActive,
      isDragReject,
      isDragAccept
    ]);
  
    return (
      <>
        <div className="container">
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            {icon ? icon : ''}
            {file && !error
              ? <div key={file.path} style={{ color: '#18C99D' }}>
                {file.path}
              </div>
              : emptyContent
            }
          </div>
        </div>
      </>
    )
  }





const BaseStyle = {
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 2,
    fontWeight: 600,
    fontSize: 15,
    borderRadius: 14,
    cursor: 'pointer',
    borderColor: '#282B3B',
    borderStyle: 'dashed',
    backgroundColor: 'transparent',
    color: '#636B91',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };
  
  const activeStyle = {
    borderColor: '#18C99D'
  };
  
  const acceptStyle = {
    borderColor: '#18C99D'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };
