import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import classNames from 'classnames';
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../components/Modal";
import { Button } from "../components/Button";
import Loader from "../components/Loader";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Organization, { selectCurrentOrg, selectCurrentOrgId, selectOrganizations } from "../store/models/Organization";
import AppState from "../store/AppState";
import { ModalTypes } from "../constants";
import UIState from "../store/UIState";
import Project, {
  selectCurrentProjectId,
  selectOrgProjects,
  selectUsersForProject,
  selectUsersForProjectsInOrg
} from "../store/models/Project";
import { createSelector } from "reselect";
import { UserListRowItem } from "../components/UserListRowItem";
import { ProjectIcon } from "../components/Svg";
import dayjs from "dayjs";
import { SelectInput } from "../components/form/SelectInput";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { updateAiProjectId } from '../utils';
import Bill from '../store/models/Bill';
import {getShortId} from "../utils/strings";

const selector = createSelector(
  selectCurrentOrgId,
  selectCurrentOrg,
  selectCurrentProjectId,
  (state, orgId) => selectOrgProjects(state, orgId),
  selectOrganizations,
  (state, orgId) => selectUsersForProjectsInOrg(state, orgId),
  (currentOrgId, currentOrg, currentProjectId, projects, organizations, users) => (
    { currentOrgId, currentOrg, currentProjectId, projects, organizations, users }
  )
)

const SelectOption = ({ org }) => {
  const myOrg = org.user_join_time === org.create_time
  const joined = myOrg ? 'Created' : 'Joined'
  return (
    <div className={"SelectProjectModal__SelectOption"}>
      <div className={"SelectProjectModal__SelectOption__name"}>{org.name}</div>
      <div className={"SelectProjectModal__SelectOption__id"}>Owned by {org.email}</div>
      <div className={"SelectProjectModal__SelectOption__id"}>{joined} {dayjs(org.user_join_time).format('MMM D, YYYY')}</div>
    </div>
  )
}

export const SelectProjectModal = ({onRequestClose}) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const [orgIdSelected, setOrgIdSelected] = useState(useSelector(selectCurrentOrgId))
  const { currentOrgId, currentOrg, currentProjectId, organizations, projects, users }
    = useSelector(state => selector(state, orgIdSelected));
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const orgSelected = _.find(organizations, { id: orgIdSelected })
  const [prevOrgIdSelected, setPrevOrgIdSelected] = useState(orgIdSelected)

  useEffect(() => {
    fetchData()
  }, []);

  useEffect(() => {
    if (prevOrgIdSelected !== orgIdSelected) {
      fetchData(false)
      setPrevOrgIdSelected(orgIdSelected)
    }
  }, [orgIdSelected]);

  const fetchData = async (fetchOrgs = true) => {
    setLoading(true);
    setError(null);
    try {
      await dispatch(Project.actions.fetchUserProjectsInOrg(orgIdSelected, {
        expand: 'user_ids'
      }))
      if (fetchOrgs)
        dispatch(Organization.actions.fetchUserOrganizations());
    } catch (e) {
      setError(e.message)
    }
    setLoading(false);
  }

  const onSelectProject = async (project) => {
    if (currentOrgId !== orgIdSelected) {
      dispatch(AppState.actions.selectCurrentOrgAndProject(orgIdSelected, project.id))
      dispatch(Bill.actions.fetchBalance(orgIdSelected));
    } else {
      dispatch(AppState.actions.selectProject(project.id))
    }
    toast(`Project selected: ${project.name}`)
    updateAiProjectId(history, project.id)
    onRequestClose()
  }

  const onCreateNewProject = () => {
    onRequestClose()
    dispatch(UIState.actions.showModal(ModalTypes.CREATE_PROJECT, {}))
  }

  const onSelectOrg = async (e) => {
    if (e.value === null) {
      onRequestClose()
      dispatch(UIState.actions.showModal(ModalTypes.CREATE_ORG))
      return
    }

    setOrgIdSelected(e.value)
  }

  return (
    <Modal className={cx("SelectProjectModal")}
      size={"xl"}
      onRequestClose={onRequestClose}>

      <ModalHeader>
        <SelectInput value={{ label: orgSelected?.name, value: orgSelected?.id }}
                     options={[...organizations.map(org => ({ label: <SelectOption org={org} />, value: org.id })),
          { label: "Create new organization", value: null }]}
          onChange={onSelectOrg} />

        <ModalTitle>Projects</ModalTitle>
      </ModalHeader>

      <ModalContent>
        {loading && <Loader />}

        {!loading && error && <div className={"ServicePage__Error"}>
          {error}
          <Button onClick={fetchData}
            color={"green-outline"}
            title={"Retry"} />
        </div>}

        {!loading && !error && <>
          <div className={"SelectProjectModal__Container"}>
            {_.isEmpty(projects)
              ? <div className={"ServicePage__Empty"}>You don't have any projects. Create one to continue.</div>
              : <div className={"SelectProjectModal__Container__List"}>
                <ul className={'ItemsList'}>
                  {projects.map(project => {
                    const projectUsers = _.filter(users, user => project?.user_ids?.includes(user.id))
                    return (<li key={project.id}
                      onClick={() => onSelectProject(project)}
                      className={classNames('ItemsListItem', (currentProjectId === project.id) && 'ItemsListItem--current')}>

                      <div className={'ItemsListItem__name ItemsListItem__name--l ItemsListItem__33'}>
                        <ProjectIcon className={'ItemsListItem__name__icon'} /> {project.name}
                      </div>
                      <div className={'ItemsListItem__url ItemsListItem__33'}>
                        Created {dayjs(project.create_time).format('MMM D, YYYY')}
                      </div>
                      <div className={'ItemsListItem__users ItemsListItem__33'}>
                        <UserListRowItem users={projectUsers} />
                      </div>
                    </li>)
                  })}
                </ul>
              </div>}
          </div>


          <ModalActions>
            <Button color={"green"} onClick={onCreateNewProject} title={"Create New Project"} />
            <Button color={"transparent"} onClick={onRequestClose} title={"Cancel"} />
          </ModalActions>
        </>}

      </ModalContent>

    </Modal>
  );
}