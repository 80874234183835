import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {Button} from "../components/Button";
import {useDispatch, useSelector} from "react-redux";
import User, {selectCurrentUser} from "../store/models/User";
import {useIsMountedRef} from '../hooks/useIsMounted';
import {createSelector} from "reselect";
import cx from "classnames";
import Modal, {ModalActions, ModalContent, ModalHeader, ModalTitle} from "../components/Modal";
import {toast} from "react-toastify";
import Project from "../store/models/Project";


const selector = createSelector(
  selectCurrentUser,
  (currentUser) => (
    {currentUser}
  )
);

export const EditProjectModal = ({onRequestClose, project}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const isMountedRef = useIsMountedRef();
  const {register, handleSubmit, watch, setValue, formState: {errors}, getValues} = useForm();

  useEffect(() => {
    if (!_.isEmpty(project)) {
      setValue('name', project?.name);
    }
  }, [project]);

  const onSubmit = async () => {
    const {name} = getValues()
    setLoading(true);
    try {
      await dispatch(Project.actions.editProject(project.id, {name}));
      toast.success(`${project.name} has been updated`);
      onRequestClose();
    } catch (e) {
      if (!isMountedRef.current) return;
      setError(e.message);
    } finally {
      if (!isMountedRef.current) return;
      setLoading(false);
    }
  };

  return (
    <Modal className={cx("EditProjectModal")}
           onRequestClose={onRequestClose}>

      <ModalHeader>
        <ModalTitle>Update project</ModalTitle>
      </ModalHeader>

      <ModalContent>
        <div className={cx("NewFormError")}>{error && error}</div>

        <div className={"NewForm"}>

          <div className={"NewForm__Row NewForm__Row"}>

            <div className={"NewForm__InputTextWrapper"}>
              <div className={"NewForm__InputLabel"}>Project name</div>
              <input type={"text"}
                     placeholder={"Project name"}
                     {...register("name", {required: true})} />
            </div>
          </div>

          <ModalActions>
            <Button color={"green"} onClick={onSubmit} loading={loading} title={"Update"}/>
            <Button color={"transparent"} onClick={onRequestClose} title={"Cancel"}/>
          </ModalActions>

        </div>

      </ModalContent>

    </Modal>
  );
}