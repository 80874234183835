import React, { useEffect, useState } from 'react';
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Ai, { selectDeployment } from '../../../store/models/Ai';
import { Link } from 'react-router-dom';
import { Urls } from '../../../constants';
import { selectCurrentProjectId } from '../../../store/models/Project';
import { mergeUrl } from '../../../utils/url';
import { BackArrowIcon } from "../../../components/Svg";
import { getLinkByDeployment, getModelByDeployment } from '../../../utils';
import { AiTab, AiTabs } from '../../../components/AiTabs';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Button } from '../../../components/Button';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';

function LlmDetailPage() {
  const dispatch = useDispatch();
  const { projectId, shard, suffix } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const deployment = useSelector(state => selectDeployment(state, projectId, suffix)) || {};
  const link = deployment ? getLinkByDeployment(deployment, true) : '';
  const model = deployment ? getModelByDeployment(deployment) : '';
  const [copied, setCopied] = useState(false);
  const [selectedTab, setSelectedTab] = useState('curl');
  const [code, setCode] = useState(`curl -X POST ${link}/v1/chat/completions \\
  -H 'Content-Type: application/json' \\
  -d '{
    "model": "${model}",
    "messages": [
        {"role": "system", "content": "You are a helpful assistant."},
        {"role": "user", "content": "Hello, how are you?"}
    ],
    "max_tokens": 100
  }'`);

  // console.log('deployment:', deployment)
  const fetchData = async () => {
    setLoading(true);
    if (error) setError();
    try {
      await dispatch(Ai.actions.fetchDeploymentDetail(projectId, shard, suffix));
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  useEffect(() => {
    const curlCode = `curl -X POST ${link}/v1/chat/completions \\
    -H 'Content-Type: application/json' \\
    -d '{
      "model": "${model}",
      "messages": [
          {"role": "system", "content": "You are a helpful assistant."},
          {"role": "user", "content": "Hello, how are you?"}
      ],
      "max_tokens": 100
    }'`;
    const jsonCode = `{
  "model": "${model}",
  "messages": [
      {"role": "system", "content": "You are a helpful assistant."},
      {"role": "user", "content": "Hello, how are you?"}
  ],
  "max_tokens": 100
}`;
    const jsCode = `import OpenAI from "openai";

// Set OpenAI's API key to use vLLM's API server.
const openai_api_key = "YOUR_OPENAI_API_KEY"
const openai_api_base = "${link}/v1"

const openai = new OpenAI(
  api_key=openai_api_key,
  base_url=openai_api_base,
);

async function main() {
  const completion = await openai.completions.create({
    "model": "${model}",
    "messages": [
      {"role": "system", "content": "You are a helpful assistant."},
      {"role": "user", "content": "Hello, how are you?"}
    ],
    "max_tokens": 100
  });
    
  // console.log(completion);
}
main();`;

    const pythonCode = `from openai import OpenAI

# Set OpenAI's API key to use vLLM's API server.
openai_api_key = "YOUR_OPENAI_API_KEY"
openai_api_base = "${link}/v1"

client = OpenAI(
  api_key=openai_api_key,
  base_url=openai_api_base,
)

chat_response = client.chat.completions.create({
  "model": "${model}",
  "messages": [
    {"role": "system", "content": "You are a helpful assistant."},
    {"role": "user", "content": "Hello, how are you?"}
  ],
  "max_tokens": 100
})
print("Chat response:", chat_response)`;
    switch (selectedTab) {
      case 'curl':
        setCode(curlCode);
        break;
      case 'json':
        setCode(jsonCode);
        break;
      case 'javascript':
        setCode(jsCode);
        break;
      case 'python':
        setCode(pythonCode);
        break;
      default:
        break;
    }
    // console.log('code:', code)
  }, [selectedTab, deployment])

  const handleCopy = () => {
    if (copied) return;
    navigator.clipboard.writeText(code)
      .then(() => {
        setCopied(true);
        toast.success('Code Copied!')
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => {
        console.error('Failed to copy:', err);
      });
  }

  return (<div className={'AiServicePage'}>
    <Link to={mergeUrl(Urls.AI_MODEL_DEPLOYMENT_DETAIL, { projectId, suffix, shard })}>
      <div className={'AiServicePage__back-button long'}>
        <BackArrowIcon />
        <div className={'AiServicePage__back-button--label'}>
          Model Deployment Detail
        </div>
      </div>
    </Link>
    <div className={'AiServicePage__header'}>
      <div className={'AiServicePage__header--title'}>LLM detail</div>
    </div>
    {!loading && !error && <div className={'AiServicePage__content'}>
      <div className="URLCard URLCard--green">{link}</div>
      <AiTabs>
        <AiTab isSelected={(selectedTab === 'curl')}
          onClick={() => {
            setSelectedTab('curl')
          }}>
          cURL
        </AiTab>
        <AiTab isSelected={(selectedTab === 'json')}
          onClick={() => {
            setSelectedTab('json')
          }}>
          JSON
        </AiTab>
        <AiTab isSelected={(selectedTab === 'javascript')}
          onClick={() => {
            setSelectedTab('javascript')
          }}>
          JavaScript
        </AiTab>
        <AiTab isSelected={(selectedTab === 'python')}
          onClick={() => {
            setSelectedTab('python')
          }}>
          Python
        </AiTab>
        <div className='AiServicePage__copy-wrap'>
          <Button onClick={handleCopy} title={copied ? "Code Copied!" : "Copy Code"} color={'green-outline'} size={'small'} />
        </div>
      </AiTabs>
      {
        (selectedTab === 'curl') && deployment &&
        <div className='AiServicePage__tab-content'>
          <SyntaxHighlighter
            lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
            language="javascript"
            style={vscDarkPlus}>
            {code}
          </SyntaxHighlighter>
        </div>}
      {
        (selectedTab === 'json') && deployment &&
        <div className='AiServicePage__tab-content'>
          <SyntaxHighlighter
            lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
            language="javascript"
            style={vscDarkPlus}>
            {code}
          </SyntaxHighlighter>
        </div>}
      {
        (selectedTab === 'javascript') && deployment &&
        <div className='AiServicePage__tab-content'>
          <SyntaxHighlighter
            lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
            language="javascript"
            style={vscDarkPlus}>
            {code}
          </SyntaxHighlighter>
        </div>}
      {
        (selectedTab === 'python') && deployment &&
        <div className='AiServicePage__tab-content'>
          <SyntaxHighlighter
            lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
            language="python"
            style={vscDarkPlus}>
            {code}
          </SyntaxHighlighter>
        </div>}
    </div>}
    {loading && <div className={'EmptyState'}>
      <Loader size='large' color='grey' />
    </div>}
    {!loading && error && <div className={"AiServicePage__error"}>
      {error}
      <Button onClick={fetchData}
        color={"green-outline"}
        title={"Retry"} />
    </div>}
  </div>)
}

export default LlmDetailPage;
