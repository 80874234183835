import React, {useEffect, useRef, useState} from "react";
import {DownChevronIcon, InfoIcon, SendIcon} from "../Svg";
import {Button} from "../Button";
import _ from "lodash";
import cx from "classnames";
import {AIShowcase} from "../../store/models/AIShowcase";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {ModalTypes} from "../../constants";
import Loader from "../Loader";
import UIState, {selectMobileLayout} from "../../store/UIState";
import {createSelector} from "reselect";
import {ImageInput} from "../form/ImageInput";
import {ShowcaseGallery} from "./ShowcaseGallery";
import DrawingCanvas from "../DrawingCanvas";
import {dataURLtoFile} from "../../utils/blob";
import {TextInput} from "../form/TextInput";
import Tooltip from "../Tooltip";
import {getUrlFromPath} from "../../store/models/Showcases/ShowcaseSketchApi";

const LoadingItem = ({loadingData, elapsedTime}) => {
  const status = !loadingData || loadingData?.msg === 'estimation' ? 'scheduled' : 'processing'
  const bottomWrapper = loadingData?.msg === 'estimation'
    ? <div className={"ResultBox__Loading__BottomWrapper"}>Number of jobs ahead of you: <span
      style={{color: '#ffffff'}}>{loadingData.rank + 1}</span></div>
    : loadingData?.msg === 'process_starts'
      ? <div className={"ResultBox__Loading__BottomWrapper"}>Generation in progress...</div>
      : <></>

  return (<div className={cx("ResultBox")}>
    <div className={"ResultBox__Loading"}>
      <div className={"ResultBox__Loading__TopWrapper"}>
        {status} | {elapsedTime}s
      </div>

      <div className={"ResultBox__Loading__CenterWrapper"}>
        <Loader color={"green"}/>
      </div>

      {bottomWrapper}
    </div>
  </div>)

}

const PromptItem = ({message}) => {
  const [paramsOpened, setParamsOpened] = useState(false);

  const onToggleParams = () => {
    setParamsOpened(!paramsOpened);
  }

  return (
    <div className={cx("PromptContainerRoot")}>
      <div className={"PromptContainer"}>
        <div className={"PromptContainer__Visible"}>
          <div className="PromptContainer__Left">
            <div className={"PromptContainer__PromptLabel"}>Prompt</div>
          </div>
          <div className={"PromptContainer__Right"}>
            {_.map(message.imageUrls, (imageUrl, index) => {
              return (<a key={index} href={imageUrl} target={"_blank"}>
                <img src={imageUrl}/>
              </a>)
            })}
          </div>
        </div>
        {!_.isEmpty(message.params) && <div className={"PromptContainer__BottomWrapper"}>
          <div className={`PromptContainer__ParamsButton ${paramsOpened && "open"}`}
               onClick={onToggleParams}>More parameters <DownChevronIcon/></div>
        </div>}
        {paramsOpened &&
          <div className={"PromptContainer__Params"}>
            <pre>{JSON.stringify(message.params, null, 2)}</pre>
          </div>}
      </div>
    </div>)

}

const ResultItem = ({item}) => {
  return (
    <div className={cx("ResultBox")}>
      <div className={"ResultBox__Image"}>
        <a href={item.image} target={"_blank"}>
          <img src={item.image} alt={"result"}/>
        </a>
      </div>
    </div>
  )
}

const ImageInputInstructions = ({galleryLabel, icon}) => {

  return (<>
    {icon && <div>{icon}</div>}
    <div><span className={"ImageInput__Label--Green"}>Upload {galleryLabel}</span></div>
    <div>or</div>
    <div>drag image here</div>
  </>)
}


const selector = createSelector(
  selectMobileLayout,
  (isMobile) => ({
      isMobile
    }
  ))

let intervalId = null;

export const ShowcaseImageToImageItem = ({model, modelData}) => {

  const dispatch = useDispatch();

  const {isMobile} = useSelector(state => selector(state));
  const [imageUploading, setImageUploading] = useState(false)
  const [loadingData, setLoadingData] = useState(null)
  const [error, setError] = useState(null)
  const [elapsedTime, setElapsedTime] = useState(0)
  const [currentImageUrls, setCurrentImageUrls] = useState({})
  const [messages, setMessages] = useState([])
  const chatboxRef = React.createRef();
  const showcaseUrl = model.urls
  const backgroundRef = useRef(null);
  const layerRef = useRef(null);
  const combineRef = useRef(null);
  const [prompts, setPrompts] = useState({})
  const [rangeInputs, setRangeInputs] = useState({})

  useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, []);

  useEffect(() => {
    setRangeInputs(_.map(modelData.rangeInputs, p => {
      return {
        name: p.name,
        value: p.default
      }
    }));

    setPrompts(_.map(modelData.prompts, p => {
      return {
        name: p.name,
        value: ""
      }
    }));

    const ranges = _.map(modelData.rangeInputs, (param) => {
      const range = document.getElementById(param.name);
      range.addEventListener('input', updateGradient);
      setTimeout(() => {
        updateGradient({target: range})
      }, 100)
      return range;
    });

    return () => {
      ranges.forEach(range => {
        range.removeEventListener('input', updateGradient);
      });
    };
  }, []);

  function updateGradient(e) {
    const range = e.target;
    const value = ((range.value - range.min) / (range.max - range.min)) * 100;
    const color = `linear-gradient(to right, #129978 ${value}%, #3D4463 ${value}%)`;

    // Create a new style element
    const style = document.createElement('style');

    style.textContent = `
    #${range.id}::-moz-range-track {
      background: ${color};
    }
    #${range.id}::-webkit-slider-runnable-track {
      background: ${color};
    }
  `;

    document.head.appendChild(style);
  }


  const onParamChanged = (name, value, needGradientUpdate = false) => {
    const modelParam = modelData.rangeInputs.find(p => p.name === name);
    value = parseFloat(value);

    if (_.isNil(value) || _.isNaN(value)) {
      value = 0;
    } else if (value < modelParam.min) {
      value = modelParam.min
    } else if (value > modelParam.max) {
      value = modelParam.max
    }

    setRangeInputs(rangeInputs.map(param => {
      if (param.name === name) {
        return {
          ...param,
          value: value
        }
      }
      return param;
    }))


    if (needGradientUpdate) {
      setTimeout(() => {
        updateGradient({target: document.getElementById(name)})
      }, 50)
    }
  }

  const onGenerate = async (event) => {
    setError(null)
    event.preventDefault();

    try {
      setMessages([...messages, {
        type: 'prompt',
        imageUrls: _.values(currentImageUrls),
      }, {type: 'loading'}])
      onScrollToBottom()

      startStopwatch()

      const compositeImages = {};

      if (modelData.drawing_enabled) {
        const backgroundFile = dataURLtoFile(backgroundRef.current.toDataURL(), 'background.png', 'application/octet-stream')
        compositeImages.background = await dispatch(AIShowcase.actions.uploadImage(backgroundFile, showcaseUrl))
        const compositeFile = dataURLtoFile(combineRef.current.toDataURL(), 'composite.png', 'application/octet-stream')
        compositeImages.composite = await dispatch(AIShowcase.actions.uploadImage(compositeFile, showcaseUrl))
        const layerFile = dataURLtoFile(layerRef.current.toDataURL(), 'layer_0.png', 'application/octet-stream')
        compositeImages.layer_0 = await dispatch(AIShowcase.actions.uploadImage(layerFile, showcaseUrl))

        const images = ({...currentImageUrls, composite: getUrlFromPath(compositeImages.composite, showcaseUrl)})

        setMessages([...messages, {
          type: 'prompt',
          imageUrls: _.values(images),
          params: _.reduce([...prompts, ...rangeInputs], (obj, param) => {
            obj[param.name] = param.value
            return obj
          }, {})
        }, {type: 'loading'}])
      }


      dispatch(AIShowcase.actions.imageToImagePredict(
        showcaseUrl,
        modelData,
        modelData.drawing_enabled ? compositeImages : currentImageUrls,
        prompts,
        rangeInputs,
        onPredictEvent));
    } catch (e) {
      toast.error(e.message)
      console.error(e)
    }
  };

  const onSelectGalleryImage = async (itemIndex, name) => {
    const gallery = _.find(modelData.galleries, gallery => gallery.name === name)
    const imageUrl = gallery.isLocal ? gallery.paths[itemIndex] : `${showcaseUrl}${gallery.paths[itemIndex]}`


    setCurrentImageUrls({
      ...currentImageUrls,
      [name]: imageUrl
    })
  }

  const onImageSelected = async (file, name) => {
    if (!file) return;

    try {
      setImageUploading(true)
      const imagePath = await dispatch(AIShowcase.actions.uploadImage(file, showcaseUrl))
      const imageUrl = getUrlFromPath(imagePath, showcaseUrl)
      setCurrentImageUrls({
        ...currentImageUrls, [name]: imageUrl
      })
      setImageUploading(false)
    } catch (e) {
      setImageUploading(false)
      toast.error("Failed to upload image")
    }
  }

  const onPredictEvent = (event) => {
    switch (event.msg) {
      case 'estimation':
        setLoadingData(event)
        break;
      case 'process_starts':
        setLoadingData(loadingData => ({...loadingData, msg: 'process_starts'}))
        break;
      case 'process_completed':
        try {
          if (event.success) {
            setLoadingData(null)
            stopInterval()
            setMessages((messages) => [...messages.slice(0, -1), {type: 'result', image: event.output.data[0].url}])
            onScrollToBottom()
          } else {
            onError("Failed to generate")
          }
        } catch (e) {
          onError("Failed to generate: " + e.message)
        }
        break;
      case 'error':
      case 'unexpected_error':
        onError(event.error)
        break;
    }
  }

  const onError = (error) => {
    setLoadingData(null)
    setMessages((messages) => messages.slice(0, -2))
    stopInterval()
    setError(error ?? "Failed to generate. Try again later")
    toast.error(error ?? "Failed to generate. Try again later")
  }

  const startStopwatch = () => {

    if (intervalId) {
      stopInterval()
    }

    const startTime = new Date().getTime()

    intervalId = setInterval(() => {
      const currentTime = new Date().getTime()
      setElapsedTime(((currentTime - startTime) / 1000).toFixed(0))
    }, 1000)

  }

  const stopInterval = () => {
    clearInterval(intervalId)
    intervalId = null
    setElapsedTime(0)
  }

  const onStopGenerating = (event) => {
    event.preventDefault();

    dispatch(UIState.actions.showModal(ModalTypes.CONFIRM, {
      title: "Stop generating",
      message: "Are you sure you want to stop the ongoing generation?",
      onConfirm: onConfirmStopGenerating,
      confirmLabel: "Yes",
      cancelLabel: "No",
      size: 's'
    }))
  }

  const onConfirmStopGenerating = () => {
    stopInterval()
    setLoadingData(null)
    setMessages((messages) => messages.slice(0, -2))
    dispatch(AIShowcase.actions.stopGeneration())
  }

  const onClear = () => {
    setPrompts(_.map(modelData.prompts, p => {
      return {
        name: p.name,
        value: ""
      }
    }));

    setRangeInputs(_.map(modelData.rangeInputs, p => {
      return {
        name: p.name,
        value: p.default
      }
    }));
    setCurrentImageUrls({})
  }

  const onRemoveImage = (name) => {
    setCurrentImageUrls(_.omit(currentImageUrls, name))
  }

  const onScrollToBottom = () => {
    if (!isMobile) {
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
        if (!_.isNil(chatboxRef) && !_.isNil(chatboxRef.current)) {
          chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
        }
      }, 200)
    }
  }

  const onPromptChanged = (value, name) => {
    setPrompts(prompts.map(param => {
      if (param.name === name) {
        return {
          ...param,
          value: value
        }
      }
      return param;
    }))
  }

  return (
    <div className="ShowcaseImageToImageItem">
      <div className="ShowcaseImageToImageItem__container">
        <div className={"ShowcaseImageToImageItem__LeftSide"}>

          <div className={"SidePanel"}>

            <form className="SidePanel__form">

              {modelData.instructions && <div className={"SidePanel__Instructions"}>{modelData.instructions}</div>}

              <div className={"SidePanel__ImageInputs"}>
                {_.map(modelData.galleries, (gallery, index) => {
                  const currentImageUrl = currentImageUrls[gallery.name]

                  return (<>
                    {(modelData.drawing_enabled && currentImageUrl)
                      ? <div className={"DrawingCanvasWrapper"}>
                        <DrawingCanvas key={`canvas-${index}`}
                                       imageUrl={currentImageUrl}
                                       backgroundRef={backgroundRef}
                                       layerRef={layerRef}
                                       combineRef={combineRef}/>
                      </div>
                      : <ImageInput key={`image-input-${index}`}
                                    value={currentImageUrl}
                                    name={gallery.name}
                                    label={gallery.label}
                                    onChange={onImageSelected}
                                    onRemove={onRemoveImage}
                                    formats={"image/jpeg, image/jpg, image/png"}
                                    instructions={<ImageInputInstructions galleryLabel={gallery.label}/>}>
                        <div className={"ImageInput__LeftBadge"}>{gallery.label}</div>
                      </ImageInput>
                    }
                    {isMobile &&
                      <ShowcaseGallery
                        key={`gallery-${index}`}
                        images={gallery.isLocal ? gallery.paths : _.map(gallery.paths, (path) => `${showcaseUrl}${path}`)}
                        title={gallery.galleryLabel}
                        onClick={onSelectGalleryImage}
                        name={gallery.name}/>
                    }
                  </>)
                })}

              </div>

              <div className={"SidePanel__Examples"}>
                {!isMobile && _.map(modelData.galleries, (gallery, index) => {
                  return (<ShowcaseGallery key={`gallery-${index}`}
                                           images={gallery.isLocal ? gallery.paths : _.map(gallery.paths, (path) => `${showcaseUrl}${path}`)}
                                           title={gallery.galleryLabel}
                                           onClick={onSelectGalleryImage}
                                           name={gallery.name}/>)
                })}
              </div>

              {(!_.isEmpty(modelData.prompts) || !_.isEmpty(modelData.rangeInputs)) &&
                <div className={"SidePanel__Prompts"}>
                  {_.map(modelData.prompts, (prompt) => {
                    return (<TextInput key={prompt.name}
                                       name={prompt.name}
                                       placeholder={prompt.label}
                                       value={_.find(prompts, promptValues => promptValues.name === prompt.name)?.value}
                                       onChange={(e) => onPromptChanged(e.target.value, prompt.name)}/>)
                  })}

                  {_.map(modelData.rangeInputs, (param, index) => {
                    return (
                      <div key={param.name} className="ShowcaseSettingsPanel__field">
                        <div className={"ShowcaseSettingsPanel__field--top"}>
                          <Tooltip longText={true}
                                   tooltip={param.desc}>
                            <div className={"label-wrapper"}>
                              <label htmlFor={param.name}>{param.label}</label>
                              <InfoIcon/>
                            </div>
                          </Tooltip>
                          <TextInput max={param.max} min={param.min} className={param.long ? "large" : ""}
                                     type={"number"}
                                     value={rangeInputs?.[index]?.value} name={param.name}
                                     onChange={(e) => onParamChanged(param.name, e.target.value, true)}/>
                        </div>
                        <input type="range" id={param.name} step={param.step} max={param.max} min={param.min}
                               value={rangeInputs?.[index]?.value}
                               onChange={(e) => onParamChanged(param.name, e.target.value)}/>
                      </div>
                    )
                  })}

                </div>}

              <div className={"SidePanel__ActionButtons"}>
                <Button
                  title={"Clear"}
                  color={"grey"}
                  className="SidePanel__ActionButtons__Button SidePanel__ActionButtons__Button"
                  disabled={_.size(currentImageUrls) === 0}
                  onClick={onClear}
                />

                {loadingData
                  ? <Button
                    title={"Stop generating"}
                    onClick={onStopGenerating}
                    className="SidePanel__ActionButtons__Button--Primary"
                    color={"red"}
                  />
                  : <Button
                    title={"Generate"}
                    onClick={onGenerate}
                    loading={imageUploading}
                    icon={imageUploading ? null : <SendIcon/>}
                    className="SidePanel__ActionButtons__Button SidePanel__ActionButtons__Button--Primary"
                    color={"green"}
                    disabled={!_.isNil(loadingData) || _.size(currentImageUrls) < _.size(modelData.galleries)}/>}
              </div>

            </form>

          </div>
        </div>

        <div className={cx("ShowcaseImageToImageItem__RightSide")}>
          {error && <div className={"ErrorMessage"}>{error}</div>}

          <div className={"MessagesWrapper"} ref={chatboxRef}>
            {!_.isEmpty(messages) && messages.map((item, index) => {
              switch (item.type) {
                case 'prompt':
                  return (<PromptItem key={index} message={item}/>)
                case 'loading':
                  return (<LoadingItem key={index} loadingData={loadingData} elapsedTime={elapsedTime}/>)
                case 'result':
                  return (<ResultItem key={index} item={item}/>)
              }
            })}
          </div>

        </div>
      </div>

    </div>
  );
}