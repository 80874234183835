import {compose, createStore, applyMiddleware} from 'redux';
import thunkMiddleware from 'redux-thunk';
import {createLogger} from 'redux-logger';

const logTransformer = action => {
  if (action?.result) {
    action = {
      ...action,
      type: action.type + ":" + action.result
    };
  }
  return action;
}

const logger = createLogger({
  duration: true,
  timestamp: true,
  collapsed: true,
  actionTransformer: logTransformer,
});

export let store = null;

export function createAppStore(reducer, initialState = {}) {
  let middleware = [
    thunkMiddleware,
  ];
  if (process.env.NODE_ENV === 'development') {
    middleware = [...middleware, logger];
  }

  store = createStore(reducer, initialState, applyMiddleware(...middleware));
  return store;
}
