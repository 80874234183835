import _ from 'lodash';
import {post, get, videoServiceAuthHeaders, put, del} from "../../utils/fetch";
import { Hosts, RequestState, Urls } from "../../constants";
import { pushTo } from "../history";
import {mergeUrl} from "../../utils/url";
import {DELETE_STREAM, selectAllStreams} from "./Stream";


// ===========================
// HELPERS
// ===========================


// ===========================
// ACTIONS
// ===========================
export const FETCH_VIDEOS = 'FETCH_VIDEOS';
export const FETCH_VIDEO = 'FETCH_VIDEO';
export const CREATE_VIDEO = 'CREATE_VIDEO';
export const CREATE_VIDEO_UPLOAD_URL = 'CREATE_VIDEO_UPLOAD_URL';
export const UPDATE_VIDEO_NAME = 'UPDATE_VIDEO_NAME';
export const DELETE_VIDEO = 'DELETE_VIDEO';

export const SEARCH_VIDEOS_BY_NAME = 'SEARCH_VIDEOS_BY_NAME';
export const SEARCH_VIDEOS_BY_ID = 'SEARCH_VIDEOS_BY_ID';

// ===========================
// SELECTORS
// ===========================
export const selectAllVideos = (state, serviceAccountId) => {
  return _.filter(Object.values(state.models.videos), (v) => {
    return v.service_account_id === serviceAccountId;
  });
};
export const selectVideo = (state, videoId) => {
  const vid = state.models.videos[videoId];

  if (vid) {
    return {
      ...vid
    };
  }
  return null;

};
export const selectTotalVideoPages = (state) => {
  return state.models.totalVideoPages;
}
// ===========================
// MODEL
// ===========================
const Video = {
  actions: {
    fetchVideos: (serviceAccountId, page = 1, number = 10) => async (dispatch, getState) => {
      let result = await get({
        host: Hosts.API,
        url: `/video/${serviceAccountId}/list`,
        headers: videoServiceAuthHeaders(getState()),
        params: {
          number,
          page
        },
        action: FETCH_VIDEOS,
        dispatch,
      });
      return selectAllVideos(getState());
    },
    fetchVideo: (videoId) => async (dispatch, getState) => {
      let result = await get({
        host: Hosts.API,
        url: `/video/${videoId}`,
        headers: videoServiceAuthHeaders(getState()),
        action: FETCH_VIDEO,
        dispatch,
      });
      return selectVideo(getState(), videoId);
    },
    createVideo: (body) => async (dispatch, getState) => {
      let result = await post({
        host: Hosts.API,
        url: "/video",
        headers: videoServiceAuthHeaders(getState()),
        action: CREATE_VIDEO,
        dispatch,
        body: body
      });
      let video = _.get(result, 'body.videos.0');
      let videoId = _.get(video, 'id');

      pushTo(mergeUrl(Urls.VIDEO_VIEW_VIDEO,{videoId}));

      return selectVideo(getState(), videoId);
    },
    createVideoUploadUrl: (serviceAccountId) => async (dispatch, getState) => {
      let result = await post({
        host: Hosts.API,
        url: "/upload",
        headers: videoServiceAuthHeaders(getState(), serviceAccountId),
        action: CREATE_VIDEO_UPLOAD_URL,
        dispatch
      });

      return _.get(result, 'body.uploads.0');
    },
    updateVideoName: (serviceAccountId, videoId, body) => async (dispatch, getState) => {
      let result = await put({
        host: Hosts.API,
        url: `/video/${videoId}`,
        headers: videoServiceAuthHeaders(getState(), serviceAccountId),
        action: UPDATE_VIDEO_NAME,
        dispatch,
        body: body
      });
      return selectVideo(getState(), videoId);
    },
    searchVideosByName: (serviceAccountId, name) => async (dispatch, getState) => {
      let result = await get({
        host: Hosts.API,
        url: `/video/${serviceAccountId}/search?filename=${name}`,
        headers: videoServiceAuthHeaders(getState(), serviceAccountId),
        action: SEARCH_VIDEOS_BY_NAME,
        dispatch,
      });
      return selectAllVideos(getState());
    },
    searchVideosById: (serviceAccountId, videoId) => async (dispatch, getState) => {
      let result = await get({
        host: Hosts.API,
        url: `/video/${videoId}`,
        headers: videoServiceAuthHeaders(getState(), serviceAccountId),
        action: SEARCH_VIDEOS_BY_ID,
        dispatch,
      });
      return selectAllVideos(getState());
    },
    deleteVideo: (serviceAccountId, videoId) => async (dispatch, getState) => {
      let result = await del({
        host: Hosts.API,
        url: `/video/${videoId}`,
        headers: videoServiceAuthHeaders(getState(), serviceAccountId),
        action: DELETE_VIDEO,
        dispatch,
      });


      pushTo(Urls.VIDEO_VIDEOS);

      return selectAllVideos(getState());
    }
  },
  spec: {
    videos: {},
    totalVideoPages: 0
  },
  modelReducer: (state, type, body, action) => {
    if (action.url && action.result !== RequestState.SUCCESS)
      return state;

    if (type === FETCH_VIDEOS || type === SEARCH_VIDEOS_BY_NAME || type === SEARCH_VIDEOS_BY_ID) {
      return {
        ...state,
        videos: _.keyBy(body.videos, 'id'),
        totalVideoPages: _.get(body, 'pagination.pages')
      }
    }

    if (type === FETCH_VIDEO || type === CREATE_VIDEO || type === UPDATE_VIDEO_NAME) {
      return {
        ...state,
        videos: _.keyBy(body.videos, 'id'),
      }
    }

    return state;
  }
}
export default Video;
