import { UserAvatar } from "./UserAvatar";
import { VerticalDotsIcon } from "./Svg";
import { DropdownMenu, DropdownMenuItem } from "./DropdownMenu";
import { UserRoleIds, UserRoleIds as UserRoles, UserRoleLabels } from "../constants";
import { getUserFullName } from "../store/models/User";
import { Button } from "./Button";
import { SelectInput } from "./form/SelectInput";
import React, { useState } from "react";
import _ from "lodash";

export const MemberItemModes = {
  MANAGE: "manage",
  INVITE: "invite"
}

const RoleMenuItem = ({ role, user, onClick, disabled }) => {

  return (
    <DropdownMenuItem
      disabled={disabled}
      label={UserRoleLabels[role]}
      selected={user.role === role}
      onClick={() => onClick(role)}
    />
  )

}
export const MemberItem = ({
  user, currentUser, mode = MemberItemModes.MANAGE,
  onInvite, onChangeRole, onRemove
}) => {
  const isAdmin = currentUser?.role === UserRoles.ADMIN
  const actionsDisabled = !isAdmin || currentUser?.id === user.id

  const [selectedRole, setSelectedRole] = useState(user?.role ?? UserRoleIds.VIEWER)
  const roleOptions = _.map(UserRoleLabels, (label, key) =>
    ({ label: label, value: key }))

  const changeRole = async (role) => {
    onChangeRole(user, role)
  }

  const remove = () => {
    onRemove(user)
  }

  const invite = () => {
    onInvite(user, selectedRole)
  }

  const onSelectRole = (role) => {
    setSelectedRole(role.value)
  }

  return (
    <div className={"MemberItem"}>
      <div className={"MemberItem__Left"}>
        <div className={"MemberItem__Avatar"}>
          <UserAvatar user={user} />
        </div>
        <div className={"MemberItem__Info"}>
          {getUserFullName(user) && <div className={"MemberItem__Name"}>{getUserFullName(user)}</div>}
          <div className={"MemberItem__Email"}>{user.email}</div>
        </div>
        {mode === MemberItemModes.MANAGE && isAdmin && <div className={"MemberItem__Right"}>
          <DropdownMenu icon={<VerticalDotsIcon />}>
            <DropdownMenu label={"Role"}>
              <RoleMenuItem
                role={UserRoles.ADMIN}
                user={user}
                onClick={changeRole}
                disabled={actionsDisabled}
              />
              <RoleMenuItem
                role={UserRoles.EDITOR}
                user={user}
                onClick={changeRole}
                disabled={actionsDisabled}
              />
              <RoleMenuItem
                role={UserRoles.VIEWER}
                user={user}
                onClick={changeRole}
                disabled={actionsDisabled}
              />
            </DropdownMenu>

            <DropdownMenuItem label={user.disabled ? "Enable" : "Disable"}
              disabled={actionsDisabled}
              onClick={remove} />

          </DropdownMenu>
        </div>}
      </div>
      {mode === MemberItemModes.INVITE && <div className={"MemberItem__Right"}>
        <div className={"NewForm__InputWrapper NewForm__InputWrapper--floating"}>
          <SelectInput
            className={"InviteTeamMembersToProjectModal__InviteRow__RoleSelect"}
            options={roleOptions}
            isDisabled={user?.invited}
            value={{ label: UserRoleLabels[selectedRole], value: selectedRole }}
            onChange={onSelectRole} />
        </div>
        <Button color={"green-outline"}
          title={user?.invited ? "Invited" : "Invite"}
          disabled={user?.invited}
          onClick={() => user?.invited ? null : invite()}>
          Invite
        </Button>
      </div>}
    </div>
  )
}

export const MemberItemsList = ({
  users, currentUser, mode,
  onInvite, onChangeRole, onRemove
}) => {
  return (
    <div className={"MemberItemsList"}>
      {users.map(user => <MemberItem key={user.id ?? user.email}
        user={user}
        currentUser={currentUser}
        mode={mode}
        onRemove={onRemove}
        onChangeRole={onChangeRole}
        onInvite={onInvite} />)}
    </div>
  )
}