import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import App from './App';
import {Provider} from 'react-redux';
import {createAppStore} from "./store/store";
import reducer from './store/reducer';
import {history} from './store/history';
import {BrowserRouter, Router} from 'react-router-dom';

let initialState = window.__STATE__ ?? {};
delete window.__STATE__;
const store = createAppStore(reducer, initialState);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App/>
    </Router>
  </Provider>,
  document.getElementById('root')
);

