import _ from 'lodash';
import React, { useState, useEffect, useContext } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Area } from 'recharts';

const Chart = (props) => {
  const { data, legendName, active } = props;
  return <LineChart width={200} height={45} data={data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }} cursor="pointer">
    <Line type="monotone" dataKey="score" stroke={active ? `#18C99D` : `#8A8FB5`} dot={false} isAnimationActive={false} />
  </LineChart>;
}

export default Chart;
