export const formatNumber = (num, dec, fallback = 0, floor = false, ceil = false) => {
    num = _.isString(num)
      ? parseFloat(num)
      : num;
  
    if (floor) {
      num = Math.floor(num * Math.pow(10, dec)) / Math.pow(10, dec)
    }
  
    if (ceil) {
      num = Math.ceil(num * Math.pow(10, dec)) / Math.pow(10, dec)
    }
  
    let options = {
      minimumFractionDigits: dec ?? 0,
      maximumFractionDigits: dec ?? 0,
    }
    let fm = new Intl.NumberFormat(global.window?.navigator?.language, options);
    return fm.format(num ?? fallback);
  }

export const formatMoney = (num, dec = 2, fallback = 0, floor, ceil) => `$${formatNumber(num, dec, 0, floor, ceil)}`

export const formatTFuel = (num, dec = 1, fallback = 0, floor, ceil) => formatNumber(num, dec, fallback, floor, ceil);
