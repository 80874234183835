import Tooltip from "./Tooltip";
import {getUserColor, getUserFullName} from "../store/models/User";
import {UserAvatar} from "./UserAvatar";

export const UserListRowItem = ({users, displayCount = 5}) => {

  const usersDisplayed = users.slice(0, displayCount)
  const hiddenUsersCount = users.length - displayCount
  const hiddenUsersTooltip = users.slice(displayCount).map(user => `${user.first_name} ${user.last_name ?? ''}`).join(', ')

  return (
    <div className={"UserListRowItem"}>
      {usersDisplayed.map((user, i) => {
        return <Tooltip key={user.id} tooltip={getUserFullName(user)}>
          <UserAvatar user={user} key={user.id} />
        </Tooltip>
      })}

      {hiddenUsersCount > 0 && <Tooltip tooltip={hiddenUsersTooltip}>
        <div className={"UserListRowItem__More"}>{hiddenUsersCount} more</div>
      </Tooltip>}
    </div>
  )
}