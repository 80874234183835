import _ from 'lodash';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Video, {selectVideo} from "../../../store/models/Video";
import {useParams} from "react-router";
import {BackButton, Button} from "../../../components/Button";
import {LiveDemoCardStep} from "../../marketing/LandingTVA";
import {useInterval} from "../../../hooks/useInterval";
import Loader from "../../../components/Loader";
import PageTitleBar from "../../../components/PageTitleBar";
import {Tab, Tabs} from "../../../components/Tabs";
import SyntaxHighlighter from 'react-syntax-highlighter';
import {a11yDark} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {NetworkIdToName, PlayerUri, Urls} from "../../../constants";
import {FaChartBar, FaCheckCircle, FaEdit, FaTimesCircle} from "react-icons/fa";
// import {StudioPlayer} from "theta-video-api-drm-player";
// import 'theta-video-api-drm-player/dist/index.css'
import {mergeUrl} from "../../../utils/url";
import SyntaxHighlighterTheme from "../../../components/SyntaxHighlighterTheme";
import {selectCurrentProject} from "../../../store/models/Project";
import classNames from "classnames";
import {RiDeleteBin2Line} from "react-icons/ri";
import {NavLink} from "react-router-dom";

function VideoPage() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {videoId} = useParams();
  const video = useSelector(state => selectVideo(state, videoId));
  const [selectedTab, setSelectedTab] = useState('embed-player');
  const playbackUrl = video?.use_studio_drm ? video?.dash_playback_uri : video?.playback_uri;
  useEffect(() => {
    (async () => {
      if (videoId) {
        dispatch(Video.actions.fetchVideo(videoId));
      }
    })();
  }, [videoId]);

  useInterval(() => {
    if (videoId && _.isNil(playbackUrl)) {
      dispatch(Video.actions.fetchVideo(videoId));
    }
  }, 1000);


  return (
    <div className={'VideoPage'}>
      <div className={'VideoPage__content'}>
        <PageTitle/>
        {/* <PageTitleBar title={video?.file_name || 'Video'}
          leftContent={<BackButton title={'Videos'}
            href={`/dashboard/video/videos`}
          />}
        /> */}
        {video &&
          <div className={'VideoPage__subtitle'}>{videoId}</div>}

        {video && video.state === "error" &&
          <div className="URLCard URLCard--red">
            <div>{video.error}</div>
          </div>}

        {video && _.isNil(playbackUrl) && video.state !== "error" && !_.isNil(video?.progress) &&
          <div className="URLCard URLCard--green">
            {`Encoding video: ${video?.progress}%`}
          </div>}

        {video && _.isNil(playbackUrl) && video.state !== "error" &&
          <Loader size={'small'}/>}

        {video && !_.isNil(playbackUrl) &&
          <div className="URLCard URLCard--green">
            {(_.isNil(video.use_drm) || !video.use_drm) ? playbackUrl : `${PlayerUri}/video/${videoId}`}
          </div>}

        {video && !_.isNil(playbackUrl) &&
          <div style={{width: '100%'}}>
            {/*<div className={'VideoPage__video-wrapper'}>*/}
            {/*  <StudioPlayer videoId={videoId}*/}
            {/*                params={{autoConnect: false}}*/}
            {/*                walletConnectParams={{*/}
            {/*                  projectId: "13f18a90044df63f7782996fd254a108",*/}
            {/*                  appName: "TVA Dashboard"*/}
            {/*                }}/>*/}
            {/*</div>*/}

            {!_.isNil(video.use_drm) && video.use_drm &&
              <div className={'VideoPage__drm-description'}>
                <div>This video is only available to NFT owners in collection:</div>
                {video.drm_rules && video.drm_rules.map(rule => {
                  return <div>{rule.nft_collection}({NetworkIdToName[rule.chain_id]})</div>
                })}
              </div>}

            <LiveDemoCardStep number={null}
                              title={'Optimize with Theta p2p'}
                              description={'Save up to 60% by integrating the Theta P2P in a few lines of code.'}>
              <Tabs>
                <Tab isSelected={(selectedTab === 'embed-player')}
                     onClick={() => {
                       setSelectedTab('embed-player')
                     }}>
                  Embed Player
                </Tab>
                <Tab isSelected={(selectedTab === 'video.js')}
                     onClick={() => {
                       setSelectedTab('video.js')
                     }}>
                  Video.js
                </Tab>
              </Tabs>

              {
                (selectedTab === 'embed-player') &&
                <React.Fragment>
                  <SyntaxHighlighter language="javascript" style={SyntaxHighlighterTheme} wrapLines={true}
                                     wrapLongLines={true}
                                     customStyle={{
                                       marginBottom: 30,
                                       borderRadius: 8,
                                       backgroundColor: 'rgba(24, 201, 157, 0.1)',
                                       color: '#18C99D',
                                       padding: 20,
                                     }
                                     }>
                    {`
<iframe src=\"${PlayerUri}/video/${videoId}\" 
        border=\"0\" 
        width=\"100%\" 
        height=\"100%\"
        allowfullscreen/>
`}
                  </SyntaxHighlighter>
                </React.Fragment>
              }
              {
                (selectedTab === 'video.js') &&
                <React.Fragment>
                  <div className={'VideoPage__docs-instructions'}>Requirement: Follow the video.js setup instructions
                    first
                  </div>
                  <Button href={'https://docs.thetatoken.org/docs/theta-p2p-javascript-sdk#include-scripts'}
                          target='_blank'
                          color={'grey'}
                          size={'small'}
                          title={'View Setup Docs'}
                          style={{marginTop: 20}}
                  >
                  </Button>
                  <SyntaxHighlighter language="javascript" style={a11yDark} wrapLines={true} wrapLongLines={true}
                                     customStyle={{
                                       marginBottom: 30,
                                       borderRadius: 8,
                                       backgroundColor: '#191D29',
                                       color: '#f8f8f2',
                                       padding: 20
                                     }}>
                    {`
const optionalHlsOpts = null;
const player = window.player = videojs('player-container', {
  techOrder: ["theta_hlsjs", "html5"],
  sources: [{
    src: "${playbackUrl}",
    type: "application/vnd.apple.mpegurl",
    label: "auto"
  }],
  theta_hlsjs: {
    videoId: "${video?.id}",
    userId: "YOUR_AUTHED_USER_ID",
    walletUrl: "wss://api-wallet-service.thetatoken.org/theta/ws",
    onWalletAccessToken: null,
    hlsOpts: null
  }
});
                    `}
                  </SyntaxHighlighter>
                </React.Fragment>
              }
            </LiveDemoCardStep>
          </div>
        }
      </div>
    </div>
  );
}

const PageTitle = props => {
  const dispatch = useDispatch();
  const {videoId} = useParams();

  const project = useSelector(state => selectCurrentProject(state));
  const videoApiId = project?.tva_id
  const video = useSelector(state => selectVideo(state, videoId));
  const [isEditing, setIsEditing] = useState(false);
  const nameRef = useRef();

  const handleEdit = () => {
    setIsEditing(true);
  }
  const handleSubmit = () => {
    dispatch(Video.actions.updateVideoName(videoApiId, videoId, {metadata: {filename: nameRef.current.value}}));
    setIsEditing(false);
  }
  const handleCancel = () => {
    setIsEditing(false);
  }
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }

  const title = isEditing ? <input type='text' ref={nameRef}
                                   defaultValue={video?.metadata?.filename || video?.file_name || 'Video'}
                                   placeholder='Enter your video name'
                                   onKeyDown={handleKeyDown}/> :
    (video?.metadata?.filename || video?.file_name || 'Video');

  const rightContent = isEditing ?
    <>
      <div className='PageTitleBar__right--icon cancel' onClick={handleCancel} title="Cancel"><FaTimesCircle/></div>
      <div className='PageTitleBar__right--icon submit' onClick={handleSubmit} title="Submit"><FaCheckCircle/></div>
      <Button href={`/dashboard/video/metrics?videoId=${videoId}`}
              title={'Metrics'} size={'medium'} color={'transparent'}
              className={'Button--has-icon Button--metric'}
              icon={<FaChartBar/>}
      />
    </> :
    <>
      <div className='PageTitleBar__right--icon edit' onClick={handleEdit}><FaEdit/></div>
      <NavLink
        className={classNames('NavbarLink delete')}
        to={`/dashboard/video/videos/${videoId}/delete`}
        exact
      >
        <RiDeleteBin2Line/>
      </NavLink>
      <Button href={`/dashboard/video/metrics?videoId=${videoId}`}
              title={'Metrics'} size={'medium'} color={'transparent'}
              className={'Button--has-icon Button--metric'}
              icon={<FaChartBar/>}
      />
    </>;


  return <PageTitleBar title={title}
                       leftContent={<BackButton title={'Videos'}
                                                href={mergeUrl(Urls.DASHBOARD_VIDEO)}
                       />}
                       rightContent={rightContent}
  />
}


export default VideoPage;
