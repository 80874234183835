import React, { useState, useCallback, useMemo, useEffect } from 'react';
import cx from 'classnames';
import { useDropzone } from 'react-dropzone';
import useDebounce from '../../hooks/useDebounce';
import { TextInput } from '../form/TextInput';

const KnowledgeBase = ({ className, tab, data, settab, setdata, seterror, error, enableReset = false, addButton, ...props }) => {

  const debouncedHandleTextChange = useDebounce((text) => {
    setdata(d => ({
      ...d,
      text
    }))
  }, 500);

  const handleTextChange = (event) => {
    const text = event.target.value;
    if (enableReset) {
      setdata(d => ({
        ...d,
        text
      }))
    } else {
      debouncedHandleTextChange(text);
    }
  };
  return <div className={cx("knowledge-base", className)}>
    <div className='knowledge-base__tabs'>
      <div className={cx("knowledge-base__tab", { active: tab === 'file' })} onClick={() => settab('file')}>
        File
      </div>
      <div className={cx("knowledge-base__tab", { active: tab === 'text' })} onClick={() => settab('text')}>
        Text
      </div>
      {/* <div className={cx("knowledge-base__tab", { active: tab === 'qa' })} onClick={() => settab('qa')}>
        Q&A
      </div> */}
    </div>
    <div className='knowledge-base__content'>
      <div className='knowledge-base__content--label'>Metadata</div>
      <textarea
        type="text"
        placeholder='Enter additional context or tags for this document...'
        className='CreateRAGChatbotModal__textarea CreateRAGChatbotModal__textarea--metadata'
        onChange={(e) => setdata(d => ({ ...d, metadata: e.target.value }))}
        value={data?.metadata || ''}
      />
      {tab === 'file' && <><FileInput accept=".doc,.pdf,.docx,.txt,.html,.json,.csv" file={data?.file}
        setdata={setdata} seterror={seterror} error={error} />
      </>}
      {tab === 'text' && (enableReset ?
        <textarea type="text" placeholder="Enter document's text here..." className='CreateRAGChatbotModal__textarea'
          onChange={handleTextChange} value={data?.text || ''} /> :
        <textarea type="text" placeholder="Enter document's text here..." className='CreateRAGChatbotModal__textarea'
          onChange={handleTextChange} defaultValue={data?.text} />)}
      {addButton}
    </div>
  </div>
}

const FileInput = props => {
  const { setdata, seterror, error, file } = props

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
    accept: props.accept,
  });
  useEffect(() => {
    if (file) {
      setdata(d => ({ ...d, file }))
    }
  }, [])

  useEffect(() => {
    seterror();
    const newFile = acceptedFiles[0];
    if (!newFile) return;

    setdata(d => ({ ...d, file: newFile }))
  }, [acceptedFiles])

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  return (
    <>
      <div className="container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          {file
            ? <div key={file.path} style={{ color: '#18C99D' }}>
              {file.path}
            </div>
            : <p>Drag & drop or <span className={"choose-file-text"}>Choose a file</span> to upload<br />
              Supported File Types: .pdf, .doc., docx., .txt, .html, .json, .csv
            </p>
          }
        </div>
      </div>
    </>
  )
}

const baseStyle = {
  height: '150px',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderWidth: 2,
  fontWeight: 600,
  fontSize: 15,
  borderRadius: 14,
  cursor: 'pointer',
  borderColor: '#282B3B',
  borderStyle: 'dashed',
  backgroundColor: 'transparent',
  color: '#636B91',
  outline: 'none',
  padding: '0 1rem',
  textAlign: 'center',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#18C99D'
};

const acceptStyle = {
  borderColor: '#18C99D'
};

const rejectStyle = {
  borderColor: '#ff1744'
};
export default KnowledgeBase;

