import React, {useEffect, useState} from 'react';
import cx from 'classnames';
import Modal, {ModalActions, ModalContent, ModalHeader, ModalTitle} from "../components/Modal";
import {Button} from "../components/Button";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import _ from "lodash";
import User, {selectCurrentUserId} from "../store/models/User";
import {createSelector} from "reselect";
import {toast} from "react-toastify";

const selector = createSelector(
  selectCurrentUserId,
  (userId) => {
    return {userId};
  }
)

export const VerifyPhoneNumberModal = ({onRequestClose}) => {
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    formState
  } = useForm();
  const {userId} = useSelector(state => selector(state));

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (_.isEmpty(formState.errors)) {
      setError(null)
    }
  }, [formState]);

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(verifyPhoneNumber)();
  }

  const verifyPhoneNumber = async (body) => {
    const {code} = body
    setLoading(true);
    setError(null);

    try {
      await dispatch(User.actions.verifyPhoneNumber(code))
      dispatch(User.actions.fetchUser(userId));
      toast.success("Your phone number is now verified")
      onRequestClose()
    } catch (e) {
      setLoading(false);
      setError(e.message)
    }
  }

  return (
    <Modal className={cx("VerifyPhoneNumberModal")}
           onRequestClose={onRequestClose}>

      <ModalHeader>
        <ModalTitle>Verify your phone number</ModalTitle>
      </ModalHeader>

      <ModalContent>
        <div className={cx("NewFormError")}>{error && error}</div>

        <form onSubmit={onSubmit} className={"NewForm"}>

          <div className={"NewForm__Row NewForm__Row--last"}>

            <div className={"NewForm__InputTextWrapper"}>
              <div className={"NewForm__InputLabel"}>Code</div>
              <input name="code"
                     type={"text"}
                     placeholder={"Enter your code"}
                     {...register("code", {required: true})} />
            </div>

          </div>

          <ModalActions>
            <Button color={"green"} role={'submit'} loading={loading} title={"Confirm"}/>
            <Button color={"transparent"} onClick={onRequestClose} title={"Cancel"}/>
          </ModalActions>

        </form>

      </ModalContent>

    </Modal>
  );
}