import React, {useState} from 'react';
import AuthCode from "./AuthCode";
import {TextInput} from "./TextInput";


export const CodeInput = React.forwardRef(({className, twoFaRef, ...props}, ref) => {
  //AuthCode Input

  return (
    <AuthCode
      {...props}
      ref={twoFaRef}
      allowedCharacters='numeric'
      containerClassName={"code-input"}
      inputClassName={"character"}
      />
  )

})

export default CodeInput;


export const CodeInputWrapper = ({className, withRecoveryCode, onChange, name, twoFaRef, ...props}) => {
  const [recoveryMode, setRecoveryMode] = useState(false)

  const onSwitchMode = () => {
    onChange(null, name)
    setRecoveryMode(!recoveryMode)
  }

  return <div className={"g-code-wrapper"}>
    {!recoveryMode && <div className="code-label">Enter the verification code on your authentication device</div>}

    {recoveryMode
      ? <TextInput className={"recovery-input NewForm__InputTextWrapper"} placeholder={'Enter a recovery code'} name={name} onChange={onChange} {...props} />
      : <CodeInput {...props} twoFaRef={twoFaRef} name={name} onChange={onChange} />}

    {withRecoveryCode && <div className={"use-recovery"} onClick={onSwitchMode}>
      {recoveryMode
        ? 'Use one-time password'
        : 'Use recovery code'}</div>}
  </div>
}