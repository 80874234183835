import _ from 'lodash';
import {authHeaders, get, post, videoServiceAuthHeaders} from "../../utils/fetch";
import {Hosts, RequestState} from "../../constants";
import {pushTo} from "../history";
import Storage from '../../utils/storage';
import {selectCurrentProject} from "./Project";


// ===========================
// HELPERS
// ===========================


// ===========================
// ACTIONS
// ===========================
export const FETCH_DAPPS = 'FETCH_DAPPS';
export const CREATE_DAPP = 'CREATE_DAPP';
export const UPDATE_DAPP = 'UPDATE_DAPP';
export const ENABLE_VIDEO_API_ACCOUNT = 'ENABLE_VIDEO_API_ACCOUNT';
export const FETCH_VIDEO_API_ACCOUNT = 'FETCH_VIDEO_API_ACCOUNT';
export const FETCH_VIDEO_API_ACCOUNTS = 'FETCH_VIDEO_API_ACCOUNTS';
export const FETCH_SERVICE_ACCOUNT = 'FETCH_SERVICE_ACCOUNT';
export const FETCH_GATEWAY_ACCOUNT = 'FETCH_GATEWAY_ACCOUNT';
export const ENABLE_GATEWAY_ACCOUNT = 'ENABLE_GATEWAY_ACCOUNT';
export const CHECK_PAYMENT_METHOD = 'CHECK_PAYMENT_METHOD';
export const SET_PAYMENT = 'SET_PAYMENT';
export const RESET_PAYMENT = 'RESET_PAYMENT';
export const SET_BALANCE = 'SET_BALANCE';

// ===========================
// SELECTORS
// ===========================
export const selectAllDapps = state => {
  return Object.values(state.models.dapps);
};
export const selectDappVideoAPIAccounts = (state, dappId) => {
  return _.filter(Object.values(state.models.video_api_accounts), (a) => {
    return a.client_id === dappId;
  });
};
export const selectDappVideoAPIAccount = (state, dappId) => {
  return _.first(selectDappVideoAPIAccounts(state, dappId));
};
export const selectServiceAccount = (state, serviceId) => {
  return state.models.service_accounts[serviceId];
};
export const selectServiceAccountIDs = state => {
  let saIDs = [];
  for (let dappID in state.models.dapps) {
    let videoApiAccount = selectDappVideoAPIAccount(state, dappID), saID = _.get(videoApiAccount, 'video_api_id');
    if (!_.isNil(saID)) {
      saIDs.push(saID);
    }
  }
  return saIDs;
};
export const selectDappGatewayAccounts = (state, dappId) => {
  return _.filter(Object.values(state.models.gateway_accounts), (a) => {
    return a.client_id === dappId;
  });
};
export const selectDappGatewayAccount = (state, dappId) => {
  return _.first(selectDappGatewayAccounts(state, dappId));
};
export const selectPaymentRedirect = (state) => {
  return {
    payment_method_set: state.models.payment_method_set,
    payment_url: state.models.payment_url
  }
}
export const selectBalance = (state) => {
  return state.models.balance
}

// ===========================
// MODEL
// ===========================
const Dapp = {
  actions: {
    selectDapp: (dappId) => async (dispatch, getState) => {
      pushTo(`/dashboard/video/${dappId}`);

      dispatch(Dapp.actions.fetchVideoAPIAccount(dappId));
    },
    fetchDApps: (platformUserId) => async (dispatch, getState) => {
      let result = await get({
        host: Hosts.PLATFORM_SERVICE_API,
        url: "/application/list",
        headers: authHeaders(),
        params: {
          user_id: platformUserId,
          number: 1000
        },
        action: FETCH_DAPPS,
        dispatch,
      });

      return selectAllDapps(getState());
    },
    createDApp: (body) => async (dispatch, getState) => {
      let result = await post({
        host: Hosts.PLATFORM_SERVICE_API,
        url: "/application",
        headers: authHeaders(),
        action: CREATE_DAPP,
        dispatch,
        body: {
          ...body,
          category: 'application'
        }
      });
      let dapp = _.get(result, 'body.applications.0');
      let dappId = _.get(dapp, 'id');

      const videoApiAccount = await dispatch(Dapp.actions.enableVideoAPIAccount(dappId));

      pushTo(`/dashboard/video/${dappId}`);

      return {
        dapp: dapp,
        videoApiAccount: videoApiAccount
      };
    },
    updateDApp: (dappId, body) => async (dispatch, getState) => {
      let result = await post({
        host: Hosts.PLATFORM_SERVICE_API,
        url: `/application/${dappId}`,
        headers: authHeaders(),
        action: UPDATE_DAPP,
        dispatch,
        body
      });

      return selectAllDapps(getState());
    },
    fetchVideoAPIAccount: (dappId) => async (dispatch, getState) => {
      let result = await get({
        host: Hosts.PLATFORM_SERVICE_API,
        url: `/video_api_account/${dappId}/list`,
        headers: authHeaders(),
        action: FETCH_VIDEO_API_ACCOUNT,
        dispatch,
      });

      return selectDappVideoAPIAccount(getState(), dappId);
    },
    fetchVideoAPIAccounts: (platformUserId) => async (dispatch, getState) => {
      let hostResult = await post({
        host: Hosts.API,
        url: `/tps_user/checkPaymentMethod`,
        headers: authHeaders(),
        action: CHECK_PAYMENT_METHOD,
        dispatch,
        body: {
          userID: platformUserId,
          returnURL: `${window.location.origin}/dashboard/dapps`
        }
      });
      if (hostResult.status == "success") {
        dispatch({
          type: SET_BALANCE,
          balance: hostResult.balance || 0
        })

        if (hostResult.message == "redirect") {
          // window.location.href = hostResult.url;
          dispatch({
            type: SET_PAYMENT,
            result: RequestState.SUCCESS,
            url: hostResult.url,
          })
        }
        else {
          let result = await get({
            host: Hosts.PLATFORM_SERVICE_API,
            url: `/video_api_account/${platformUserId}/list`,
            headers: authHeaders(),
            params: {
              user_id: platformUserId,
              number: 1000
            },
            action: FETCH_VIDEO_API_ACCOUNTS,
            dispatch,
          });

          dispatch({
            type: RESET_PAYMENT,
            result: RequestState.SUCCESS,
          })

          return selectAllDapps(getState());
        }
      }
    },
    openPaymentPortal: () => async (dispatch, getState) => {
      let platformUserId = Storage.getUserPlatformId();
      let result = await post({
        host: Hosts.API,
        url: `/tps_user/createPortalSession`,
        headers: authHeaders(),
        action: CHECK_PAYMENT_METHOD,
        dispatch,
        body: {
          userID: platformUserId,
          returnURL: window.location.href
        }
      });
      window.location.href = result.url;
    },
    enableVideoAPIAccount: (dappId) => async (dispatch, getState) => {
      let result = await post({
        host: Hosts.PLATFORM_SERVICE_API,
        url: "/video_api_account",
        headers: authHeaders(),
        action: ENABLE_VIDEO_API_ACCOUNT,
        dispatch,
        body: {
          client_id: dappId
        }
      });
      let videoApiAccount = _.get(result, 'body.video_api_accounts.0', null);

      return videoApiAccount;
    },
    fetchGatewayAccount: (dappId) => async (dispatch, getState) => {
      let result = await get({
        host: Hosts.PLATFORM_SERVICE_API,
        url: `/gateway_account/${dappId}/list`,
        headers: authHeaders(),
        action: FETCH_GATEWAY_ACCOUNT,
        dispatch,
      });
      return selectDappGatewayAccount(getState(), dappId);
    },
    enableGatewayAccount: (dappId) => async (dispatch, getState) => {
      let result = await post({
        host: Hosts.PLATFORM_SERVICE_API,
        url: "/gateway_account",
        headers: authHeaders(),
        action: ENABLE_GATEWAY_ACCOUNT,
        dispatch,
        body: {
          client_id: dappId
        }
      });
      let gatewayAccount = _.get(result, 'body.gateway_accounts.0', null);

      return gatewayAccount;
    },
    fetchServiceAccount: () => async (dispatch, getState) => {
      const project = selectCurrentProject(getState());
      const serviceAccountId = project.tva_id;
      let result = await get({
        host: Hosts.API,
        url: `/service_account/${serviceAccountId}`,
        headers: videoServiceAuthHeaders(getState()),
        action: FETCH_SERVICE_ACCOUNT,
        dispatch,
      });
      return selectServiceAccount(getState(), serviceAccountId);
    },
  },
  spec: {
    dapps: {},
    video_api_accounts: {},
    gateway_accounts: {},
    service_accounts: {},
    payment_method_set: false,
    payment_url: '',
    balance: 0
  },
  modelReducer: (state, type, body, action) => {
    if (action.url && action.result !== RequestState.SUCCESS)
      return state;

    switch (type) {
      case FETCH_DAPPS:
      case CREATE_DAPP:
      case UPDATE_DAPP:
        return {
          ...state,
          dapps: _.merge(state.dapps, _.keyBy(body.applications, 'id')),
        }
      case ENABLE_VIDEO_API_ACCOUNT:
      case FETCH_VIDEO_API_ACCOUNT:
      case FETCH_VIDEO_API_ACCOUNTS:
        return {
          ...state,
          video_api_accounts: _.merge(state.video_api_accounts, _.keyBy(body.video_api_accounts, 'id')),
        }
      case CHECK_PAYMENT_METHOD:
        return {
          ...state
        }
      case FETCH_SERVICE_ACCOUNT:
        return {
          ...state,
          service_accounts: _.merge(state.service_accounts, _.keyBy(body.service_accounts, 'id')),
        }
      case ENABLE_GATEWAY_ACCOUNT:
      case FETCH_GATEWAY_ACCOUNT:
        return {
          ...state,
          gateway_accounts: _.merge(state.gateway_accounts, _.keyBy(body.gateway_accounts, 'id')),
        }
      case SET_BALANCE:
        return {
          ...state,
          balance: action.balance
        }
      case SET_PAYMENT:
        return {
          ...state,
          payment_method_set: false,
          payment_url: action.url
        }
      case RESET_PAYMENT:
        return {
          ...state,
          payment_method_set: true,
          payment_url: ''
        }
      default:
        return state;
    }
  }
}
export default Dapp;
