import React, { useState, useRef } from 'react';
import cx from 'classnames';
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../components/Modal";
import { Button } from "../components/Button";
import Select from 'react-select'
import classNames from 'classnames';
import { selectCurrentProjectId } from '../store/models/Project';
import { useSelector, useDispatch } from "react-redux";
import Ai, { } from '../store/models/Ai';
import { selectCurrentOrgId } from '../store/models/Organization';
import Bill, { selectBalanceInfo } from '../store/models/Bill';
import { CreditSelection } from '../components/CreditSelection';
import { toast } from 'react-toastify';
import { TopUpList } from '../constants';
import { findMinOption } from '../utils';

export const AutoRechargeModal = ({ onRequestClose }) => {
  const dispatch = useDispatch()
  const orgId = useSelector(state => selectCurrentOrgId(state));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const balance = useSelector(state => selectBalanceInfo(state, orgId)) || {};
  const { auto_recharge_threshold } = balance;
  const [isOn, setIsOn] = useState(true);
  const [topUp, setTopUp] = useState((Number(balance.top_up_type) / 100));
  const [threshold, setThreshold] = useState(((Number(auto_recharge_threshold) || 1000) / 100).toFixed(2));

  const handleThresholdChange = (e) => {
    let v = Math.min(Math.max(Number(e.target.value), 1), 1000);
    setThreshold(v);
    if (v > topUp) {
      setTopUp(null);
    }
  }

  const onSubmit = async () => {
    if (threshold < 10 || threshold > 1000) {
      setError('The threshold value should be amount $10 and $1000.')
      return;
    }
    const enabled = isOn ? 'TRUE' : "FALSE";
    if (isOn && !topUp) {
      setError('Please select add credit balance amount.')
      return;
    }
    const topUpType = topUp * 100;
    setLoading(true);
    try {
      await dispatch(Bill.actions.updateAutoRechargeSetting(orgId, { threshold: threshold * 100, topUpType, enabled }));
      await new Promise(resolve => {
        setTimeout(() => resolve(), 1000);
      });
      await dispatch(Bill.actions.fetchBalance(orgId));
      await dispatch(Bill.actions.fetchBillHistory(orgId));
      setLoading(false);
      toast.success(`Success! Your auto recharge setting has been ${isOn ? 'enabled' : 'disabled'}.`)
      onRequestClose();
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  }

  return (
    <Modal className={cx("AutoRechargeModal")}
      onRequestClose={onRequestClose}>

      <ModalHeader>
        <ModalTitle>Auto recharge settings</ModalTitle>
      </ModalHeader>

      <ModalContent>
        <div className='AutoRechargeModal__content'>
          <div className='AutoRechargeModal__half-section'>
            <div className='AutoRechargeModal__half-section--title'>
              Would you like to set up automatic recharge?
            </div>
            <div className='AutoRechargeModal__switch--wrap'>
              <label className='AutoRechargeModal__switch'>
                <input className='AutoRechargeModal__switch-input' type="checkbox" checked={isOn} onChange={() => setIsOn(v => !v)} />
                <span className="AutoRechargeModal__switch-slider"></span>
              </label>
              <div className='AutoRechargeModal__switch--label'>
                {isOn ? "Yes, automatically recharge my card when my credit balance falls below a threshold."
                  : "No, do not automatically recharge my card."}
              </div>
            </div>
          </div>
          <div className={classNames('AutoRechargeModal__half-section', { disabled: !isOn })}>
            <div className='AutoRechargeModal__input-section'>
              <div className='AutoRechargeModal__input-title'>
                When credit balance goes below
              </div>
              <div className='AutoRechargeModal__input-wrap'>
                <div className='AutoRechargeModal__input-label'>$</div>
                <input type='number' className='AutoRechargeModal__input' placeholder='0.00' value={threshold}
                  disabled={!isOn} onChange={handleThresholdChange} />
              </div>
              <div className='AutoRechargeModal__input-tooltip'>
                Enter an amount between $10 and $1000
              </div>
            </div>
            <div className='AutoRechargeModal__input-section'>
              <div className={classNames('AutoRechargeModal__input-title', { red: error === 'Please select add credit balance amount.' })}>
                Add credit balance amount
              </div>
              <div className='AutoRechargeModal__input-selection-wrap'>
                <CreditSelection value={topUp} setValue={setTopUp} disabled={!isOn} className={'small'} min={threshold} />
              </div>
            </div>
          </div>
        </div>
        {error && <div className={cx("AutoRechargeModal__error")}>Error: {error}</div>}
      </ModalContent>

      <ModalActions>
        <Button color={"green"} onClick={onSubmit} title={`Save settings`} loading={loading} />
        <Button color={"transparent"} onClick={onRequestClose} title={"Cancel"} disabled={loading} />
      </ModalActions>

    </Modal>
  );
}

export default AutoRechargeModal;
