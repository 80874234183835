import _ from 'lodash';
import React, {useState, useEffect, useContext} from 'react';
import {useForm} from "react-hook-form";
import {BackButton, Button} from "../../components/Button";
import {useDispatch} from "react-redux";
import Dapp from "../../store/models/DApp";
import PageTitleBar from "../../components/PageTitleBar";

function CreateDAppForm({onSubmit, loading, error}) {
  const {register, handleSubmit, watch, formState: {errors}} = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}
          className={'Form CreateDAppForm'}
    >
      <input type="text" placeholder="Enter app name" {...register("name", {required: true, maxLength: 83})} />

      <Button title={'Save'}
              role={'submit'}
              loading={loading}
              style={{
                marginTop: 30,
                marginLeft: 'auto',
                marginRight: 'auto'
              }}
      />

      {
        error &&
        <div className={'FormError'}>{error}</div>
      }

    </form>
  );
}

function CreateDAppPage() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const dapp = await dispatch(Dapp.actions.createDApp(data));
      console.log(dapp);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={'CreateDAppPage'}>
      <div className={'CreateDAppPage__content'}>
        <PageTitleBar title={'New App'}
                      leftContent={<BackButton title={'Apps'}
                                               href={`/dashboard/dapps`}
                      />}
        />
        <CreateDAppForm onSubmit={onSubmit}
                        loading={loading}
                        error={error}
        />

      </div>
    </div>
  );
}

export default CreateDAppPage;
