import _ from 'lodash';
import React, {useEffect, useRef, useState} from 'react';
import { useForm } from "react-hook-form";
import { Button } from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import User from "../../store/models/User";
import PageTitleBar from "../../components/PageTitleBar";
import { Link } from "react-router-dom";
import AppState, { selectProjectInvite } from "../../store/AppState";
import { mergeUrl, useQuery } from "../../utils/url";
import { pushTo } from "../../store/history";
import { Urls } from "../../constants";
import { createSelector } from "reselect";
import Bill from '../../store/models/Bill';
import {CodeInputWrapper} from "../../components/form/CodeInput";

const selector = createSelector(
  selectProjectInvite,
  (projectInvite) => (
    { projectInvite }
  )
);

function LoginForm({ onSubmit, loading, error, require2FA, projectInvite }) {
  const { register, handleSubmit, setValue, formState: { errors }, values } = useForm();
  const twoFaRef = useRef(null)

  console.log('values')
  console.log(values)
  const onSubmitLocal = (e) => {
    e.preventDefault();

    handleSubmit(onSubmit)();
  }

  useEffect(() => {
    if (!_.isEmpty(projectInvite)) {
      setValue('email', projectInvite.email);
    }
  }, [projectInvite]);

  return (
    <form onSubmit={onSubmitLocal}
      className={'Form LoginForm'}
    >
      <div className={"NewForm__Row"}>
        <div className={"NewForm__InputTextWrapper"}>
          <input type="text" placeholder="Email" {...register("email", { required: true, maxLength: 803 })} />
        </div>
      </div>
      <div className={"NewForm__Row"}>
        <div className={"NewForm__InputTextWrapper"}>
          <input type="password" placeholder="Password" {...register("password", { required: true, maxLength: 1000 })} />
        </div>
      </div>
      <div className='reset-password-wrap'>
        <span><Link to={'/request-reset-password'}>Forgot password?</Link></span>
      </div>

      {require2FA &&
        <CodeInputWrapper
          twoFaRef={twoFaRef}
          className="twofa-input"
          name="twoFA"
          disabled={!require2FA}
          value={values?.code}
          withRecoveryCode={true}
          onChange={(value) => setValue('code', value)}/>}

        {/*<input*/}
        {/*  type="text"*/}
        {/*  className="twofa-input"*/}
        {/*  name="twoFA"*/}
        {/*  placeholder="Verification code"*/}
        {/*  {...register("code", { minLengt: 6, maxLength: 6 })}*/}
        {/*/>*/}


      {
        error &&
        <div className={'FormError'}>{error}</div>
      }

      <Button title={'Login'}
        role={'submit'}
        loading={loading}
        className='LoginForm__button'
      />
    </form>
  );
}

function LoginPage() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [require2FA, setRequire2FA] = useState(false);
  const { projectInvite } = useSelector(state => selector(state));
  const { projectId, email, token, userId } = useQuery();

  useEffect(() => {
    if (!_.isEmpty(projectId)) {
      dispatch(AppState.actions.saveProjectInvite(projectId, email, token, userId));
    }
  }, []);

  const onSubmit = async ({ email, password, code }) => {
    setLoading(true);
    try {
      const response = await dispatch(User.actions.login(email, password, code, false));
      if (projectInvite?.token) {
        pushTo(mergeUrl(Urls.ACCEPT_INVITE))
      } else {
        if (_.isNil(response?.body?.users?.[0]?.redirect_project_id)) {
          pushTo(Urls.DASHBOARD);
        } else {
          const project = response?.body?.projects?.[0];
          await dispatch(AppState.actions.selectCurrentOrgAndProject(project.org_id, project.id))
          await dispatch(Bill.actions.fetchBalance(project.org_id))
          pushTo(Urls.DASHBOARD)
        }
      }
    } catch (e) {
      if (e.code === 202) {
        setLoading(false);
        setError(null);
        setRequire2FA(true);
      } else {
        setLoading(false);
        setError(e.message);
      }
    } finally {

    }
  };

  useEffect(() => {
    (async () => {
      console.log('Login page mount...');
    })();
  }, []);

  return (
    <div className={'ServicePage LoginPage'}>
      <div className={'ServicePage__content'}>
        <PageTitleBar title={'Log In'} />
        <LoginForm onSubmit={onSubmit}
          loading={loading}
          error={error}
          projectInvite={projectInvite}
          require2FA={require2FA}
        />
        <div className={'SignUpPage__already-have'}>
          <span>Don't have an account?</span>&nbsp;<Link to={'/sign-up'}>Sign up</Link>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
