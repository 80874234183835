import _ from 'lodash';
import React, {useState, useEffect, useContext, useRef} from 'react';
import {useForm} from "react-hook-form";
import {Button} from "../../components/Button";
import {useDispatch} from "react-redux";
import User from "../../store/models/User";
import PageTitleBar from "../../components/PageTitleBar";
import {getQueryParams} from "../../utils/url";
import {useLocation} from "react-router";
import Loader from "../../components/Loader";
import {toast} from "react-toastify";

function VerifyEmailPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [error, setError] = useState(null);

  useEffect(() => {
    verifyEmail()
  }, []);

  const verifyEmail = async () => {
    try {
      setError(null);
      const queryParams = getQueryParams(location.search)
      await dispatch(User.actions.verifyEmail(queryParams.user_id, queryParams.verification_code));
      toast.success("Your email has been verified");
    } catch (e) {
      setError(e.message)
    }
  }

  if (error) {
    return <div className={'VerifyEmailPage'}>
      <div className={'VerifyEmailPage__content'}>
        <PageTitleBar title={'Error verifying email'}/>
        <div className={'VerifyEmailPage__error'}>{error}</div>
        <Button onClick={verifyEmail} title={"Retry"} />
      </div>
    </div>
  }

  return (
    <div className={'VerifyEmailPage'}>
      <div className={'VerifyEmailPage__content'}>
        <PageTitleBar title={'Verifying Email'}/>
        <Loader size={'small'}/>
      </div>
    </div>
  );
}

export default VerifyEmailPage;
