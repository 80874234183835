
import arrowRight from '../../assets/icons/ArrowRight.svg';
import React from "react";
import cx from "classnames";

const pageLinkUrl = "https://docs.google.com/forms/d/e/1FAIpQLSdBpBcgyLa3DXK4JHUYca0JKZiVWuZux8Bk-BzDAvTwJ_hT1g/viewform?usp=sf_link";
export const LandingWeb3TheatrePage = () => {

  return (
    <div className={"g-landing-web3-theatre"}>

      <div className={"content"}>

        <div className={"header"}>
          <a href={pageLinkUrl} target={"_blank"}>
            <img src={'/images/landing-theatre/theatre-cover.png'} className={"cover"} alt={"Theta Web3 Theatre"}/>
          </a>
          <div className={"slogan"}>Elevate your video content, from on-demand to live, with our hosted web3 platform
            built with your community in mind.
          </div>
        </div>

        <Web3TheatreSection
          title={"Showcase your Video Content"}
          desc={"Theta Theatre empowers you to upload, organize, and elevate your video content for your community. Collaborate with the ThetaDrop Team for monetization and distribution strategies. Built on the trusted foundation of Theta Video API and Theta Edge Node delivery."}
          imageSrc={'/images/landing-theatre/theatre-section-1.png'}
          imageAlt={"cover section 1"}
          position={"left"}
        />

        <Web3TheatreSection
          title={"Security & Curation with Digital Rights for Video"}
          desc={"Our platform can seamlessly authenticate NFTs from Ethereum, Polygon, and Theta blockchains using Theta Network's patented NFT DRM technology. Safeguard your valuable content like never before and explore new frontiers in digital rights management."}
          imageSrc={'/images/landing-theatre/theatre-section-2.png'}
          imageAlt={"cover section 2"}
          position={"right"}
        />

        <Web3TheatreSection
          title={"Tailored to You: Elevate Your Brand"}
          desc={"Customize the branding to match your unique style, and enjoy effortless hosting on ThetaDrop or iframe embed your theatre with few lines of code on your website, ensuring a consistent and engaging user experience."}
          imageSrc={'/images/landing-theatre/theatre-section-3.png'}
          imageAlt={"cover section 3"}
          position={"left"}
        />

        <Web3TheatreSection
          title={"Experiences. Anywhere, Anytime."}
          desc={"Theta Theatre goes where your users go. Access it on the web or on mobile devices. Your audience can enjoy your content from anywhere, making engagement a breeze."}
          imageSrc={'/images/landing-theatre/theatre-section-4.png'}
          imageAlt={"cover section 4"}
          position={"right"}
        />

        <div className={"footer"}>
          <div className={"footer-content"}>Add utility to your community instantly with Theta Theatre.</div>
          <a href={pageLinkUrl} target={"_blank"}>
            <div className={"button"}>
              Get started
              <img src={arrowRight} alt={"arrow right"}/>
            </div>
          </a>

        </div>

      </div>
    </div>
  )
}

const Web3TheatreSection = ({title, desc, imageSrc, imageAlt, position}) => {

  return (
    <div className={cx("section", position)}>
      <div className={"section-content"}>
        <div className={"section-title"}>{title}</div>
        <div className={"section-desc"}>{desc}</div>
      </div>
      <div className={"section-image-wrapper"}>
        <img className={"section-image"} src={imageSrc} alt={imageAlt}/>
      </div>
    </div>
  )
}
