import React from "react";
import { Urls } from "../constants";
import { Button } from "../components/Button";
import { selectCurrentUser } from "../store/models/User";
import { UserRoleIds as UserRoles } from "../constants";
import { mergeUrl } from "../utils/url";
import { useSelector } from "react-redux";
import { WarningIcon } from "./Svg";
import { selectCurrentOrgId } from "../store/models/Organization";
import classNames from "classnames";

export const Overlay = ({ type }) => {
  const user = useSelector(state => selectCurrentUser(state));
  const orgId = useSelector(state => selectCurrentOrgId(state));
  const userIsAdmin = user && user.role === UserRoles.ADMIN;

  const onSendEmail = () => {
    window.open("mailto:support@thetaedgecloud.com" +
      "?subject=Organization%20Re-enable%20Request" +
      "&body=" +
      "%0A%0A%0A" +
      "User%20ID:%20" + user.id +
      "%0A" +
      "Organization%20ID:%20" + orgId +
      "%0A%0A");
  }
  return (
    <div className={classNames('Overlay', { disabled: type === 'disabled' })}>
      {type === 'suspended' ? <div className={'Overlay__wrap'}>
        <div className={'Overlay__title'}>
          <WarningIcon />
          Insufficient Balance
        </div>
        <div className={'Overlay__content'}>{`Your account balance has reached $0, which could result in termination of your streams and deployments. ${userIsAdmin ? 'Please add funds promptly to resume your access to the services.' : "Please contact your organization's admin to add credit to resume your access to the services."}`}</div>
        {userIsAdmin && <Button size={'large'}
          title={'Go To Billing'}
          href={mergeUrl(Urls.SETTINGS_BILLING)}
        />}
      </div> : <div className={'Overlay__wrap'}>
        <div className={'Overlay__title'}>
          <WarningIcon />
          Organization Disabled
        </div>
        <div className={'Overlay__content'}>{`Your organization has been disabled. ${userIsAdmin ? 'Please contract support to learn more' : 'Please contact your organization admin to contact support.'}`}</div>
        {userIsAdmin && <Button size={'large'}
          title={'Contact Support'}
          onClick={onSendEmail}
        />}
      </div>}
    </div>
  )
}