import {AIShowcase} from "../AIShowcase";

let eventSource1 = null;
let eventSource2 = null;
let eventStreamsStopped = false;

export const getUrlFromPath = (path, url) => {
  return `${url}/file=${path}`
}

export const ShowcaseSketchApi = {

  actions: {
    generate2d: (file, url, prompt, negativePrompt, onPredict) => async (dispatch, getState) => {
      const sessionHash = Math.random().toString(36).substring(2, 12);

      gtag('event', `generate_sketch_to_2d`, {
        event_category: "Sketch to 3D",
        event_label: `Generate sketch to 2D`,
        value: {
          prompt, url, negativePrompt
        }
      })

      dispatch(AIShowcase.actions.reportStats({
        "id": sessionHash,
        "name": "generate_sketch_to_2d",
        "category": "Sketch to 3D",
        "label": "Generate sketch to 2D",
        "params": JSON.stringify({prompt, url, negativePrompt}),
      }))

      eventStreamsStopped = false;

      const sketchPath = await dispatch(ShowcaseSketchApi.actions.uploadImage(file, url));

      const sketchPngPath = await dispatch(ShowcaseSketchApi.actions.createEventSource(
        eventSource1,
        sessionHash,
        url,
        0,
        sketchToSketchPngBody(sketchPath, getUrlFromPath(sketchPath, url), file, sessionHash),
        onPredict))

      if (eventStreamsStopped) return;

      const image2dPath = await dispatch(ShowcaseSketchApi.actions.createEventSource(
        eventSource2,
        sessionHash,
        url,
        1,
        sketchPngTo2dBody(sketchPngPath, getUrlFromPath(sketchPngPath, url), file, sessionHash, prompt, negativePrompt),
        onPredict))

      if (eventStreamsStopped) return;

      const image2d2Path = await dispatch(ShowcaseSketchApi.actions.createEventSource(
        eventSource1,
        sessionHash,
        url,
        2,
        image2dToImage2d2Body(image2dPath, getUrlFromPath(image2dPath, url), file, sessionHash),
        onPredict))

      gtag('event', `generate_sketch_to_2d_done`, {
        event_category: "Sketch to 3D",
        event_label: `Generate sketch to 2D done`,
        value: {
          url
        }
      })

      dispatch(AIShowcase.actions.reportStats({
        "id": sessionHash,
        "name": "generate_sketch_to_2d_done",
        "category": "Sketch to 3D",
        "label": "Generate sketch to 2D done",
        "params": JSON.stringify({url}),
      }))

      return image2d2Path;
    },

    generate3d: (imagePath, url, onPredict) => async (dispatch, getState) => {
      eventStreamsStopped = false;
      const sessionHash = Math.random().toString(36).substring(2, 12);

      gtag('event', `generate_2d_to_3d`, {
        event_category: "Sketch to 3D",
        event_label: `Generate 2D to 3D`,
        value: {
          url
        }
      })

      dispatch(AIShowcase.actions.reportStats({
        "id": sessionHash,
        "name": "generate_2d_to_3d",
        "category": "Sketch to 3D",
        "label": "Generate 2D to 3D",
        "params": JSON.stringify({url}),
      }))

      const object3dPath = await dispatch(ShowcaseSketchApi.actions.createEventSource(
        eventSource1,
        sessionHash,
        url,
        4,
        image2dToObjBody(imagePath, getUrlFromPath(imagePath, url), sessionHash),
        onPredict))

      if (eventStreamsStopped) return;

      gtag('event', `generate_2d_to_3d_done`, {
        event_category: "Sketch to 3D",
        event_label: `Generate 2D to 3D done`,
        value: {
          url
        }
      })

      dispatch(AIShowcase.actions.reportStats({
        "id": sessionHash,
        "name": "generate_2d_to_3d_done",
        "category": "Sketch to 3D",
        "label": "Generate 2D to 3D done",
        "params": JSON.stringify({url}),
      }))

      return object3dPath;
    },

    uploadImage: (file, url) => async (dispatch, getState) => {
      const formData = new FormData();
      formData.append('files', file, file.name);

      try {
        const response = await fetch(`${url}/upload`, {
          method: 'POST',
          body: formData
        })
        const json = await response?.json();
        return json?.[0]
      } catch (e) {
        console.log(e)
      }

    },

    createEventSource: (eventSource, sessionHash, url, fnIndex, body, onEvent) => async (dispatch, getState) => {
      return new Promise((resolve, reject) => {

        if (eventSource) {
          eventSource.close()
          eventSource = null
        }

        eventSource = new EventSource(`${url}/queue/join?fn_index=${fnIndex}&session_hash=${sessionHash}`);
        eventSource.onmessage = (e) => {
          const data = JSON.parse(e.data)

          switch (data.msg) {
            case 'process_completed':
              if (data.success) {
                resolve(data.output.data[0].path)
              } else {
                onEvent({msg: 'error', error: 'Failed to generate. Try again later.'})
              }
              eventSource.close()
              break;
            case 'send_data':
              dispatch(ShowcaseSketchApi.actions.queueData(url, body, data.event_id))
              break;
            case 'estimation':
            case 'process_starts':
              onEvent(data)
              break;
            default:
              break;
          }
        };

        eventSource.onerror = (e) => {
          if (e.readyState === EventSource.CONNECTING) {
            return
          }

          onEvent({msg: 'error', error: 'Failed to generate. Try again later.'})
          eventSource.close()
        };
      });
    },

    queueData: (url, body, eventId) => async (dispatch, getState) => {

      const response = await fetch(`${url}/queue/data`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({...body, event_id: eventId}),
      });

      const json = await response?.json();

      if (!response.ok) {
        let errorMessage = json?.detail || 'Failed to generate'
        if (errorMessage.toLowerCase().includes('queue is full')) {
          errorMessage = 'The queue is currently full. Please try again later.'
        }

        throw new Error(errorMessage)
      }
    },

    stopEventStreams: () => async (dispatch, getState) => {
      if (eventSource1) {
        eventSource1.close()
        eventSource1 = null
      }
      if (eventSource2) {
        eventSource2.close()
        eventSource2 = null
      }
      eventStreamsStopped = true;
    },

  }
}

const sketchToSketchPngBody = (sketchPath, sketchUrl, file, sessionHash) => {
  return {
    data: [
      {
        "background": {
          "mime_type": "",
          "orig_name": "background.png",
          "path": sketchPath,
          "size": file.size,
          "url": sketchUrl
        },
        "composite": {
          "mime_type": "",
          "orig_name": "composite.png",
          "path": sketchPath,
          "size": file.size,
          "url": sketchUrl
        },
      }
    ],
    session_hash: sessionHash,
    fn_index: 0,
    trigger_id: 30,
    event_data: null,
  }
}

const sketchPngTo2dBody = (sketchPath, sketchUrl, file, sessionHash, prompt, negativePrompt) => {
  return {
    "data": [
      {
        "mime_type": null,
        "orig_name": "image.png",
        "path": sketchPath,
        "size": null,
        "url": sketchUrl
      },
      "stablediffusionapi/rev-animated-v122-eol",
      "lllyasviel/control_v11p_sd15_lineart",
      512,
      512,
      true,
      1,
      prompt,
      negativePrompt,
      1,
      7.5,
      30,
      "DDIM",
      0,
      "Lineart"
    ],
    "event_data": null,
    "fn_index": 1,
    "session_hash": sessionHash,
    "trigger_id": 30
  }
}

const image2dToImage2d2Body = (image2dPath, image2dUrl, file, sessionHash) => {
  return {
    "data": [
      {
        "mime_type": null,
        "orig_name": "image.png",
        "path": image2dPath,
        "size": null,
        "url": image2dUrl
      },
      true,
      0.85,
    ],
    "event_data": null,
    "fn_index": 2,
    "session_hash": sessionHash,
    "trigger_id": 30
  }
}

const image2dToObjBody = (image2dPath, image2dUrl, sessionHash) => {
  return {
    "data": [
      {
        "mime_type": null,
        "orig_name": "image.png",
        "path": image2dPath,
        "size": null,
        "url": image2dUrl
      },
      256,
    ],
    "event_data": null,
    "fn_index": 4,
    "session_hash": sessionHash,
    "trigger_id": 35
  }
}