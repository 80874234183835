import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {BackButton, Button} from "../../../components/Button";
import PageTitleBar from "../../../components/PageTitleBar";
import Webhook, {selectWebhookRegistration} from '../../../store/models/Webhook';
import {FaCheckCircle, FaEdit, FaTimesCircle} from "react-icons/fa";
import {selectCurrentProject} from "../../../store/models/Project";

function WebhookRegistrationPage() {
  const dispatch = useDispatch();
  const {webhookRegId} = useParams();
  const webhookRegistration = useSelector(state => selectWebhookRegistration(state, webhookRegId));

  useEffect(() => {
    (async () => {
      if (webhookRegId) {
        dispatch(Webhook.actions.fetchWebhookRegistration(webhookRegId));
      }
    })();
  }, [webhookRegId]);


  return (
    <div className={'VideoPage'}>
      <div className={'VideoPage__content'}>
        <PageTitleBar title={webhookRegistration?.name || 'Webhook Registration'}
                      leftContent={<BackButton title={'Webhooks'}
                                               href={`/dashboard/video/webhooks`}
                      />}
                      rightContent={<Button
                        href={`/dashboard/video/webhooks/registrations/${webhookRegId}/delete`}
                        title={'Delete'} size={'medium'} color={'grey'}/>}
        />
        {
          webhookRegistration && <>
            <div className='VideoPage__info'>
              <div className='VideoPage__info--row'>
                <div className={'VideoPage__info--header'}>Webhook URL:</div>
                <div className={'VideoPage__info--content'}>
                  <WebhookRegName/>
                </div>
              </div>
              <div className='VideoPage__info--row event'>
                <div className={'VideoPage__info--header'}>Webhook Events:</div>
                <div className={'VideoPage__info--content event'}>
                  {webhookRegistration.events.length === 0 && <p>All events</p>}
                  {webhookRegistration.events.map(event => <p>{event}</p>)}
                </div>
              </div>
            </div>
          </>
        }
      </div>
    </div>
  );
}

const WebhookRegName = () => {
  const dispatch = useDispatch();
  const {webhookRegId} = useParams();
  const webhookRegistration = useSelector(state => selectWebhookRegistration(state, webhookRegId));
  const [isEditing, setIsEditing] = useState(false);
  const urlRef = useRef();

  const handleEdit = () => {
    setIsEditing(true);
  }
  const handleSubmit = () => {
    dispatch(Webhook.actions.updateWebhookRegistrationUrl(webhookRegId, {url: urlRef.current.value}));
    setIsEditing(false);
  }
  const handleCancel = () => {
    setIsEditing(false);
  }
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }

  return isEditing ? <div className='wbhkReg__url'>
    <input type='text' ref={urlRef}
           defaultValue={webhookRegistration?.url}
           placeholder='Enter your webhook registration url'
           onKeyDown={handleKeyDown}/>
    <div className='wbhkReg__url--icons'>
      <div className='wbhkReg__url--icon cancel' onClick={handleCancel} title="Cancel"><FaTimesCircle/></div>
      <div className='wbhkReg__url--icon submit' onClick={handleSubmit} title="Submit"><FaCheckCircle/></div>
    </div>
  </div> : <div className='wbhkReg__url'>
    {webhookRegistration?.url}
    <div className='wbhkReg__url--icon edit' onClick={handleEdit}><FaEdit/></div>
  </div>;
}


export default WebhookRegistrationPage;
