import {useState} from "react";
import {Urls} from "../../constants";
import {pushTo} from "../../store/history";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {selectMobileLayout} from "../../store/UIState";
import {SelectInput} from "../form/SelectInput";
import _ from "lodash";
import {ShowcaseImageToImageItem} from "./ShowcaseImageToImageItem";
import {ImageToImageModels} from "../../constants/image-to-image-models";

const ModelNames = {
  ART_STYLE_TRANSFER: "Art style transfer",
  BACKGROUND_REMOVAL: "Background removal",
  OBJECT_ERASING: "Object erasing",
  IN_PAINTING: "In painting",
  IMAGE_UPSCALING: "Image upscaling",
}

const getTabName = (path) => {
  switch (path) {
    case Urls.MARKETING_AI_SHOWCASE_BACKGROUND_REMOVAL:
      return ModelNames.BACKGROUND_REMOVAL;
    case Urls.MARKETING_AI_SHOWCASE_OBJECT_ERASING:
      return ModelNames.OBJECT_ERASING;
    case Urls.MARKETING_AI_SHOWCASE_IN_PAINTING:
      return ModelNames.IN_PAINTING;
    case Urls.MARKETING_AI_SHOWCASE_IMAGE_UPSCALING:
      return ModelNames.IMAGE_UPSCALING;
    default:
      return ModelNames.ART_STYLE_TRANSFER;
  }
}

const selector = createSelector(
  (state) => selectMobileLayout(state),
  (isMobile) => ({isMobile}
  ));

export const ShowcaseImageToImage = ({showcase, match}) => {
  const {isMobile} = useSelector(selector);

  const [currentTab, setCurrentTab] = useState(getTabName(match.url))
  const currentModel = _.find(showcase.models, model => (model.name === currentTab))
  const currentModelData = _.find(ImageToImageModels, model => model.label === currentTab)

  const onTabChanged = (model) => {
    switch (model.name) {
      case ModelNames.BACKGROUND_REMOVAL:
        pushTo(Urls.MARKETING_AI_SHOWCASE_BACKGROUND_REMOVAL)
        break;
      case ModelNames.OBJECT_ERASING:
        pushTo(Urls.MARKETING_AI_SHOWCASE_OBJECT_ERASING)
        break;
      case ModelNames.IN_PAINTING:
        pushTo(Urls.MARKETING_AI_SHOWCASE_IN_PAINTING)
        break;
      case ModelNames.IMAGE_UPSCALING:
        pushTo(Urls.MARKETING_AI_SHOWCASE_IMAGE_UPSCALING)
        break;
      default:
        pushTo(Urls.MARKETING_AI_SHOWCASE_ART_STYLE_TRANSFER)
        break;
    }
    setCurrentTab(model.name)
  }

  return (
    <div className={"ShowcaseImageToImage"}>

      <div className={"ShowcaseImageToImage__SelectLabel"}>Image transformation AI model</div>

      <SelectInput
        className={"ShowcaseImageToImage__MobileTabs"}
        value={{label: currentTab, value: currentTab}}
        options={_.map(showcase.models, (model, index) =>
          ({label: model.name, value: model.name}))}
        onChange={(option) => onTabChanged(_.find(showcase.models, model =>
          model.name === option.value))} />


      {currentTab === ModelNames.ART_STYLE_TRANSFER && <ShowcaseImageToImageItem model={currentModel} modelData={currentModelData}/>}
      {currentTab === ModelNames.BACKGROUND_REMOVAL && <ShowcaseImageToImageItem model={currentModel} modelData={currentModelData}/>}
      {currentTab === ModelNames.OBJECT_ERASING && <ShowcaseImageToImageItem model={currentModel} modelData={currentModelData}/>}
      {currentTab === ModelNames.IN_PAINTING && <ShowcaseImageToImageItem model={currentModel} modelData={currentModelData}/>}
      {currentTab === ModelNames.IMAGE_UPSCALING && <ShowcaseImageToImageItem model={currentModel} modelData={currentModelData}/>}
    </div>
  )
}