import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import PageTitleBar from "../../../components/PageTitleBar";
import { Button } from "../../../components/Button";
import { selectCurrentUser, selectCurrentUserId } from "../../../store/models/User";
import { ModalTypes, UserRoleIds, Urls, UserRoleIds as UserRoles, QuotaUsageNameMap } from "../../../constants";
import UIState from "../../../store/UIState";
import Organization, {
  selectCurrentOrg,
  selectCurrentOrgId,
  selectOrganizations,
  selectOrgQuota
} from "../../../store/models/Organization";
import { Tab, Tabs } from "../../../components/Tabs";
import Tooltip from "../../../components/Tooltip";
import { getHardwareDetail, getVmResourcesString } from "../../../utils";
import { replaceTo } from "../../../store/history";
import _ from "lodash";
import { selectMobileLayout } from "../../../store/UIState";
import { SelectInput } from "../../../components/form/SelectInput";
import Project, { selectCurrentProjectId } from "../../../store/models/Project";

const selector = createSelector(
  selectCurrentProjectId,
  selectCurrentUserId,
  selectCurrentUser,
  selectCurrentOrgId,
  selectCurrentOrg,
  (state, orgId) => selectOrgQuota(state, orgId ?? selectCurrentOrgId(state)),
  (currentProjectId, currentUserId, currentUser, currentOrgId, currentOrg, orgQuota) => {
    return {
      currentProjectId,
      currentUserId,
      currentUser,
      currentOrgId,
      currentOrg,
      orgQuota
    }
  }
)


const getQuotaTooltip = (quota) => {
  return (
    <div>
      {quota.description && <div className={"QuotaTooltip__Desc"}>{quota.description}</div>}
      <div
        className={"QuotaTooltip__Info"}>{getVmResourcesString(quota.resources)}</div>
    </div>
  )
}

export const QuotaPage = () => {

  const dispatch = useDispatch();
  const [selectedOrgId, setSelectedOrgId] = useState(null)

  const {
    currentProjectId,
    currentUserId,
    currentUser,
    currentOrgId,
    currentOrg,
    orgQuota
  } = useSelector(state => selector(state, selectedOrgId));

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const isMobile = useSelector(state => selectMobileLayout(state));
  const [usage, setUsage] = useState({});

  useEffect(() => {
    fetchUserOrgs()
  }, []);

  useEffect(() => {
    (async () => {
      if (!_.isEmpty(selectedOrgId)) {
        try {
          await fetchOrgQuota()
          setUsage({});
          let usageRes = await dispatch(Organization.actions.fetchOrgChatbotQuotaUsage(selectedOrgId));
          let volumeRes = await dispatch(Organization.actions.fetchOrgVolumeUsage(selectedOrgId))
          setUsage({
            chat_limit_10_min: _.get(usageRes, 'chat_limit_10_min'),
            chat_limit_daily: _.get(usageRes, 'chat_limit_daily'),
            embedding_token_limit_daily: _.get(usageRes, 'embedding_token_limit_daily'),
            persistent_storage: _.get(volumeRes, 'persistent_storage')
          })
        } catch (e) {
          console.log('Error in fetchData:', e.message);
        }
      }
    })();

  }, [selectedOrgId]);



  useEffect(() => {
    if (!_.isEmpty(currentUser)) {
      if (currentUser.role !== UserRoles.ADMIN && currentUser?.role !== UserRoleIds.EDITOR)
        replaceTo(Urls.SETTINGS_PROFILE);
    }
  }, [currentUser])

  useEffect(() => {
    if (!_.isEmpty(currentOrgId)) {
      setSelectedOrgId(currentOrgId)
    }
  }, [currentOrgId]);
  const fetchUserOrgs = async () => {
    setLoading(true);
    setError(null);
    try {
      await dispatch(Organization.actions.fetchUserOrganizations(currentUserId))
    } catch (e) {
      setError(e.message)
    }
    setLoading(false);
  }

  const fetchOrgQuota = async () => {
    setLoading(true);
    setError(null);
    try {
      await dispatch(Organization.actions.fetchOrgQuota(selectedOrgId))
    } catch (e) {
      setError(e.message)
    }
    setLoading(false);
  }

  const onIncreaseQuota = () => {
    dispatch(UIState.actions.showModal(ModalTypes.INCREASE_QUOTA, { orgId: selectedOrgId }))
  }


  if (error)
    return (<div className={"ServicePage__Error"}>
      {error}
      <Button onClick={fetchOrgQuota()}
        color={"green-outline"}
        title={"Retry"} />
    </div>)

  return (<div className={"QuotaPage"}>
    <PageTitleBar alignTitleLeft={true} title={"Organization Quota"} />

    {isMobile ? (
      <div>
        <div className={'QuotaPage__select--label'}>Organization</div>
        <div className={'QuotaPage__select--label'}>{currentOrg.name}</div>
      </div>
    ) : (
      <Tabs leftAlign={true}>
        <Tab isSelected={true} onClick={() => { }}>
          {currentOrg.name}
        </Tab>
      </Tabs>
    )}


    {loading
      ? <Loader />
      : <>
        <Button onClick={onIncreaseQuota}
          color={"green-outline"}
          title={"Increase quota"} />
        <table>
          <thead>
            <tr>
              <th>Machine Type</th>
              <th>Quota</th>
              <th>Amount Used</th>
              <th>Specs</th>
            </tr>
          </thead>
          <tbody>
            {_.map(orgQuota, (quota, key) => {
              return (<tr key={key}>
                <td><Tooltip tooltip={getQuotaTooltip(quota)}>{quota.name}</Tooltip></td>
                <td>{quota.quota ?? 0}</td>
                <td>{quota.usage ?? 0}</td>
                <td>{getVmResourcesString(quota.resources)}</td>
              </tr>)
            })}
          </tbody>
        </table>

        {Object.keys(usage).length > 0 && <table>
          <thead>
            <tr>
              <th></th>
              <th>Quota</th>
              <th>Amount Used</th>
            </tr>
          </thead>
          <tbody>
            {_.map(Object.keys(usage), (key, i) => {
              const obj = usage[key];
              return (<tr key={i}>
                <td>{QuotaUsageNameMap[key]}</td>
                <td>{obj.quota ?? 0}</td>
                <td>{obj.usage ?? 0}</td>
              </tr>)
            })}
          </tbody>
        </table>}
      </>}
  </div>
  )
}

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#202432',
    borderColor: 'transparent',
    borderRadius: '4px',
    color: '#8A8FB5',
    outline: 'none',
    borderRadius: '0.8rem',
    minHeight: 'none',
    height: '50px',
    boxShadow: 'none',
    ':hover': {
      borderColor: '#18C99D', // Add your hover styles here
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? '#202432' :
      state.isFocused ? '#1b2b32' : '#202432',
    color: state.isDisabled ? '#FFFFFF' :
      state.isFocused ? '#18C99D' :
        state.isSelected ? '#18C99D' : '#8A8FB5',
    fontFamily: 'Gilroy',
    paddingLeft: '10px',
    fontWeight: '500',
    fontSize: '14px',
    paddingTop: '11.5px',
    paddingBottom: '11.5px',
    height: 'auto',
    position: 'relative',
    cursor: state.isDisabled ? 'default' : 'pointer',
    ':hover': {
      backgroundColor: state.isDisabled ? '#202432' : '#1b2b32',
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    borderColor: state.isSelected ? '#18C99D' : 'transparent',
    color: '#8A8FB5',
    fontFamily: 'Gilroy',
    fontWeight: '500',
    fontSize: '14px',
    outline: 'none'
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none'
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#8A8FB5',
    marginRight: '0',
    marginLeft: '4px',
    ':hover': {
      color: '#8A8FB5', // Add your hover styles here
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: '#131722',
    // border: '1px solid #3D4463',
    borderRadius: '4px',
    scrollbarColor: '#3D4463',
    width: '100%',
    height: '4rem',
    zIndex: 30
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: '500px',
  }),
}
