import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {Button} from "../../components/Button";
import {useDispatch, useSelector} from "react-redux";
import User, {selectCurrentUser, selectCurrentUserId} from "../../store/models/User";
import {useIsMountedRef} from '../../hooks/useIsMounted';
import {createSelector} from "reselect";
import cx from "classnames";
import {toast} from "react-toastify";
import PageTitleBar from "../../components/PageTitleBar";
import {replaceTo} from "../../store/history";
import {Urls} from "../../constants";
import {getQueryParams} from "../../utils/url";


const selector = createSelector(
  selectCurrentUserId,
  selectCurrentUser,
  (currentUserId, currentUser) => (
    {currentUserId, currentUser}
  )
);

export const ChangeEmailPage = () => {
  const queryParams = getQueryParams(location.search)
  const {token, user_id: userId} = queryParams;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const isMountedRef = useIsMountedRef();
  const {currentUserId, currentUser} = useSelector(state => selector(state));
  const {register, handleSubmit, watch, setValue, formState: {errors}, getValues} = useForm();

  useEffect(() => {
    if (!_.isEmpty(currentUser)) {
      setValue('email', currentUser.email);
    }
  }, [currentUser]);

  const onSubmit = async () => {
    const {email} = getValues()
    setLoading(true);

    try {
      await dispatch(User.actions.changeEmail(userId, email, token));
      dispatch(User.actions.fetchUser(currentUserId))
      toast.success('Your email has been updated');
      replaceTo(Urls.SETTINGS_SECURITY)
    } catch (e) {
      if (!isMountedRef.current) return;
      setError(e.message);
    } finally {
      if (!isMountedRef.current) return;
      setLoading(false);
    }
  };

  const onCancel = () => {
    replaceTo(Urls.SETTINGS_SECURITY)
  }

  return (
    <div className={'ServiceContainer'}>
      <div className={'ServicePage'}>
        <div className={'ServicePage__content'}>
            <PageTitleBar title={'Change email'} />

            <div className={cx("NewFormError")}>{error && error}</div>



                <div className={"NewForm__InputTextWrapper NewForm__InputTextWrapper--regular"}>
                  <div className={"NewForm__InputLabel"}>Email</div>
                  <input type={"email"}
                         placeholder={"New email"}
                         {...register("email", {required: true, maxLength: 1000})} />

              <Button color={"green"} style={{marginTop: '40px'}} onClick={onSubmit} loading={loading} title={"Update"}/>
              <Button color={"transparent"} onClick={onCancel} title={"Cancel"}/>

            </div>
          </div>
      </div>
    </div>
  );
}