import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from "../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import Dapp from "../../../store/models/DApp";
import { useParams } from "react-router";
import Video, { selectAllVideos, selectTotalVideoPages } from "../../../store/models/Video";
import { Link } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import classNames from "classnames";
import PageTitleBar from "../../../components/PageTitleBar";
import Stream, { selectAllStreams, selectTotalStreamPages } from '../../../store/models/Stream';
import { Pagination } from '../../../components/Pagination';
import { GoSearch } from "react-icons/go";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import Webhook, {
  selectAllWebhookRegistrations,
  selectTotalWebhookRegistrationPages
} from '../../../store/models/Webhook';
import { LivestreamIcon, OverviewIcon, SettingsIcon, VideoIcon, Web3TheatreIcon, WebhookIcon } from "../../../components/Svg";
import { ServiceSidebarItem } from "../../../components/ServiceSidebarItem";
import { mergeUrl } from "../../../utils/url";
import { Urls } from "../../../constants";
import Project, { selectCurrentProject } from "../../../store/models/Project";
import { createSelector } from "reselect";
import { selectCurrentUser, selectCurrentUserId } from "../../../store/models/User";
import { toast } from "react-toastify";
import { selectCurrentOrg } from '../../../store/models/Organization';
import { Overlay } from '../../../components/Overlay';
import LandingTVA from "../../marketing/LandingTVA";
import { LandingWeb3TheatrePage } from '../../marketing/LandingWeb3Theatre';
import { SelectInput } from '../../../components/form/SelectInput';
import { selectMobileLayout } from '../../../store/UIState';
import { pushTo } from '../../../store/history';

const NUM_PER_PAGE = 10;

function DAppVideosPane({ videoApiId, videos }) {
  const dispatch = useDispatch();
  const hasNoVideos = (!_.isNil(videos) && _.isEmpty(videos));
  const totalPages = useSelector(state => selectTotalVideoPages(state));
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = async pageNumber => {
    setCurrentPage(pageNumber);
    try {
      await dispatch(Video.actions.fetchVideos(videoApiId, pageNumber, NUM_PER_PAGE));
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    }
  }

  const getStateText = (video) => {
    if (video.state === 'processing') {
      return `Encoding: ${video?.progress}%`
    } else if (video.state === 'success') {
      return null;
    }

    return video.state;
  };

  return (
    <>
      {!hasNoVideos && <ul className={'ItemsList'}>
        <li className={'ItemsListHeader'}>
          <div className={'ItemsListHeader__name'}>Title</div>
          <div className={'ItemsListHeader__url'}>Video ID</div>
        </li>
        {videos.map(video => {
          const state = getStateText(video);

          return (
            <Link key={video.id} to={mergeUrl(Urls.VIDEO_VIEW_VIDEO, { videoId: video.id })}>
              <li className={classNames('ItemsListItem', `ItemsListItem--${state}`)}>

                <div className={'ItemsListItem__name'}>
                  <VideoIcon className={'ItemsListItem__name__icon'} />
                  {video?.metadata?.filename || video.file_name}
                </div>
                <div className={'ItemsListItem__url'}>
                  {state
                    ? state
                    : video.id}
                </div>
                <FaChevronRight className={'ItemsListItem__caret'} />

              </li>
            </Link>
          );
        })
        }
      </ul>}

      {/* {
        (videos.length > 6) &&
        <Button href={`/dashboard/video/${dapp.id}/videos/create`}
          title={'New Video'}
          style={{
            marginTop: 30,
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block'
          }}
        />
      } */}

      {!hasNoVideos && totalPages &&
        <Pagination
          size={'lg'}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange} />
      }

      {hasNoVideos &&
        <div className={'EmptyState'}>
          <div className={'EmptyState__title'}>No Videos</div>
          <div className={'EmptyState__subtitle'}>Create your first video to get started</div>
          <Button size={'large'}
            title={'New Video'}
            href={mergeUrl(Urls.VIDEO_CREATE_VIDEO)}
          />
        </div>}

    </>
  );
}

function DAppStreamsPane({ videoApiId, streams }) {
  const dispatch = useDispatch();
  const hasNoStreams = (!_.isNil(streams) && _.isEmpty(streams));
  const totalPages = useSelector(state => selectTotalStreamPages(state, NUM_PER_PAGE));
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
    dispatch(Stream.actions.fetchStreams(videoApiId, pageNumber, NUM_PER_PAGE));
  }

  return (
    <>

      {!hasNoStreams && <ul className={'ItemsList'}>
        <li className={'ItemsListHeader'}>
          <div className={'ItemsListHeader__name'}>Title</div>
          <div className={'ItemsListHeader__url'}>Stream URL</div>
        </li>
        {streams.map(stream => {
          const playbackUrl = stream?.use_studio_drm ? stream?.dash_playback_uri : stream?.playback_uri;
          return (
            <Link key={stream.id} to={`/dashboard/video/livestreams/${stream.id}`}>
              <li className={classNames('ItemsListItem', `ItemsListItem--${stream.state}`)}>

                <div className={'ItemsListItem__name'}>
                  <LivestreamIcon className={'ItemsListItem__name__icon'} />
                  {stream.name}
                </div>
                <div className={'ItemsListItem__url'}>
                  {playbackUrl
                    ? playbackUrl
                    : 'offline'}
                </div>
                <FaChevronRight className={'ItemsListItem__caret'} />

              </li>
            </Link>
          );
        })
        }
      </ul>}

      {!hasNoStreams &&
        <Pagination
          size={'lg'}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange} />
      }

      {hasNoStreams &&
        <div className={'EmptyState'}>
          <div className={'EmptyState__title'}>No Streams</div>
          <div className={'EmptyState__subtitle'}>Create your first stream to get started</div>
          <Button size={'large'}
            title={'New Stream'}
            href={`/dashboard/video/livestreams/create`}
          />
        </div>
      }

    </>
  );
}

function DAppWebhooksPane({ videoApiId, webhookRegistrations }) {
  const dispatch = useDispatch();
  const hasNoWebhooks = (!_.isNil(webhookRegistrations) && _.isEmpty(webhookRegistrations));
  const totalPages = useSelector(state => selectTotalWebhookRegistrationPages(state, NUM_PER_PAGE));
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
    dispatch(Webhook.actions.fetchWebhookRegistrations(videoApiId, pageNumber, NUM_PER_PAGE));
  }

  return (<>
    {!hasNoWebhooks && <ul className={'ItemsList'}>
      <li className={'ItemsListHeader'}>
        <div className={'ItemsListHeader__name'}>Title</div>
        <div className={'ItemsListHeader__url'}>Webhook URL</div>
      </li>
      {webhookRegistrations.map(webhookReg => {
        return (
          <Link key={webhookReg.id} to={`/dashboard/video/webhooks/registrations/${webhookReg.id}`}>
            <li className={classNames('ItemsListItem', `ItemsListItem--${webhookReg.state}`)}>

              <div className={'ItemsListItem__name'}>
                <WebhookIcon className={'ItemsListItem__name__icon'} />
                {webhookReg.name}
              </div>
              <div className={'ItemsListItem__url'}>
                {webhookReg.url}
              </div>
              <FaChevronRight className={'ItemsListItem__caret'} />
            </li>
          </Link>
        );
      })
      }
    </ul>}

    {!hasNoWebhooks &&
      <Pagination
        size={'lg'}
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange} />
    }

    {
      hasNoWebhooks &&
      <div className={'EmptyState'}>
        <div className={'EmptyState__title'}>No Webhooks</div>
        <div className={'EmptyState__subtitle'}>Register your first webhook to get started</div>
        <Button size={'large'}
          title={'Register Webhook'}
          href={`/dashboard/video/webhooks/register`}
        />
      </div>
    }

  </>);
}

function DAppSettingsPane({ project }) {
  const dispatch = useDispatch();
  const { tva_id: video_api_id, tva_secret: video_api_secret, gateway_id: partner_id, gateway_key: partner_api_key, gateway_secret: partner_api_secret } = project || {};
  const [isCreating, setIsCreating] = useState(false);
  const onEnableGatewayAccess = async () => {
    setIsCreating(true);
    try {
      await dispatch(Project.actions.enableGatewayAccess(project?.id))
      await dispatch(Project.actions.fetchProject(project?.id))
    } catch (e) {
      toast.error(e.message)
    } finally {
      setIsCreating(false);
    }
  }
  return (
    <div className={'DAppSettingsPane'}
    >
      <div className={'Form'}>
        <div className={'DAppSettingsPaneField'}>
          <div className={'DAppSettingsPaneField__title'}>
            API Key
          </div>
          <input value={video_api_id}
            readOnly />
        </div>
        <div className={'DAppSettingsPaneField'}>
          <div className={'DAppSettingsPaneField__title'}>
            API Secret
          </div>
          <input value={video_api_secret}
            className={"NewForm__InputTextWrapper"}
            readOnly />
        </div>
        {partner_api_key ? <>
          <div className={'DAppSettingsPaneField'}>
            <div className={'DAppSettingsPaneField__title'}>
              Partner Id
            </div>
            <input value={partner_id}
              className={"NewForm__InputTextWrapper"}
              readOnly />
          </div>
          <div className={'DAppSettingsPaneField'}>
            <div className={'DAppSettingsPaneField__title'}>
              Partner API Key
            </div>
            <input value={partner_api_key}
              readOnly />
          </div>
          <div className={'DAppSettingsPaneField'}>
            <div className={'DAppSettingsPaneField__title'}>
              Partner API Secret
            </div>
            <input value={partner_api_secret}
              readOnly />
          </div>
        </> : <>
          <div className='DAppSettingsToolTip'>
            <p>
              Generate the Partner API Key to enable user rewards through micro-payments.
              <a
                href="https://docs.thetatoken.org/docs/theta-p2p-javascript-sdk#3-theta-blockchain-micro-payment-integration-optional"
                target="_blank" rel="noreferrer">
                <AiOutlineQuestionCircle size={16} />
                Learn more
              </a>
            </p>
          </div>
          <Button size={'large'}
            title={isCreating ? 'Generating Partner API Key...' : 'Generate Partner API Key'}
            onClick={onEnableGatewayAccess}
            disabled={isCreating}
          />
        </>}
      </div>

    </div>
  );
}

const selector = createSelector(
  selectCurrentProject,
  selectCurrentUser,
  selectCurrentOrg,
  selectMobileLayout,
  (project, currentUser, currentOrg, isMobile) => ({ project, currentUser, currentOrg, isMobile })
);

function VideoServicesPage() {
  const dispatch = useDispatch();
  const { selectedTab } = useParams();
  const { project, currentUser, currentOrg, isMobile } = useSelector(state => selector(state));
  const videoApiId = project?.tva_id;
  const videos = useSelector(state => selectAllVideos(state, videoApiId));
  const streams = useSelector(state => selectAllStreams(state, videoApiId));
  const userId = useSelector(state => selectCurrentUserId(state));
  const webhookRegistrations = useSelector(state => selectAllWebhookRegistrations(state, videoApiId));
  const searchRef = useRef();
  const [hasTvaAccount, setHasTvaAccount] = useState(true)
  const serviceOptions = getVideoServicesOptions(userId);
  const serviceOption = _.find(serviceOptions, { id: selectedTab });

  useEffect(() => {
    (async () => {
      if (!_.isNil(project)) {
        try {
          await dispatch(Dapp.actions.fetchServiceAccount())
        } catch (e) {
          console.log(e);
        }
      }
    })()
  }, [project]);

  useEffect(() => {
    (async () => {
      try {
        if (videoApiId && selectedTab === 'videos') {
          await dispatch(Video.actions.fetchVideos(videoApiId, 1, NUM_PER_PAGE));
        }
        if (videoApiId && selectedTab === 'livestreams') {
          dispatch(Stream.actions.fetchStreams(videoApiId, 1, NUM_PER_PAGE));
        }
        if (videoApiId && selectedTab === 'webhooks') {
          dispatch(Webhook.actions.fetchWebhookRegistrations(videoApiId, 1, NUM_PER_PAGE));
        }
      } catch (e) {
        console.log(e);
        toast.error(e.message);
      }
    })();
  }, [videoApiId, selectedTab]);

  const handleSearch = async () => {
    let value = searchRef.current.value;

    if (!value) {
      try { 
        await dispatch(Video.actions.fetchVideos(videoApiId, 1, NUM_PER_PAGE));
      } catch (e) {
        console.log(e);
        toast.error(e.message);
      }
      return;
    }

    if (!value.match(/^video_.{26}$/)) {
      dispatch(Video.actions.searchVideosByName(videoApiId, value));
    } else {
      dispatch(Video.actions.searchVideosById(videoApiId, value));
    }
  }

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  }

  const handleSelectChange = (service) => {
    console.log(service);
    pushTo(service.value.link);
  };

  const rightContent = selectedTab === 'livestreams'
    ? <Button href={mergeUrl(Urls.VIDEO_CREATE_LIVESTREAM)}
      title={'New Stream'}
      color={'green-outline'}
      icon={<LivestreamIcon />}
      size={'medium'} />
    : selectedTab === 'videos'
      ? <Button href={mergeUrl(Urls.VIDEO_CREATE_VIDEO)}
        title={'New Video'}
        color={'green-outline'}
        icon={<VideoIcon />}
        size={'medium'} />
      : selectedTab === 'webhooks'
        ? webhookRegistrations && webhookRegistrations.length > 0 &&
        <Button href={mergeUrl(Urls.VIDEO_REGISTER_WEBHOOK)}
          title={'Register Webhook'}
          color={'green-outline'}
          icon={<WebhookIcon />}
          size={'medium'} />
        : <></>;

  const leftContent = selectedTab === 'videos'
    ? <div className='ServicePage__search'>
      <input type='text' placeholder='Search for video name/id' onKeyPress={handleEnterKey} ref={searchRef} />
      <div className='ServicePage__search--button' onClick={handleSearch}><GoSearch /></div>
    </div>
    : null;

  const alignTitleLeft = selectedTab === 'livestreams' || selectedTab === 'webhooks';

  if (!hasTvaAccount) {
    return (
      <div className={'ServiceContainer'}>
        <div className={'ServicePage'}>
          <div className={'ServicePage__content'}>
            <PageTitleBar title={'Video Services'} />
            <div className={'EmptyState'}>
              <div className={'EmptyState__title'}>Your Theta Video Account is not enabled</div>
              <div className={'EmptyState__subtitle'}>Please contact us as contact@thetaedgecloud.com</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (<div className={'ServiceContainer'}>

    {(!userId || (currentOrg && !currentOrg.disabled)) && <>
      <div className='ServiceSidebar'>

        <div className={'ServiceSidebar__title'}>Theta Video Services</div>

        {isMobile && <SelectInput
          options={serviceOptions.map(option => ({
            value: option,
            label: option.title
          }))}
          value={{ label: serviceOption?.title, value: serviceOption }}
          onChange={handleSelectChange}
        />}

        {!isMobile &&
          _.map(serviceOptions, (option) => {
            return (
              <ServiceSidebarItem title={option.title}
                active={selectedTab === option.id}
                icon={option.icon}
                link={option.link} />
            )
          })}
      </div>

      <div className={'ServicePage'}>
        <div className={'ServicePage__content'}>
          {selectedTab !== 'overview' &&
            selectedTab !== 'web3theatre' &&
            <PageTitleBar title={selectedTab}
              rightContent={rightContent}
              leftContent={leftContent}
              alignTitleLeft={!isMobile && alignTitleLeft}
            />}

          {(selectedTab === 'overview') &&
            <LandingTVA />
          }

          {(selectedTab === 'videos') &&
            <DAppVideosPane videoApiId={videoApiId}
              videos={videos}
            />}

          {(selectedTab === 'livestreams') &&
            <DAppStreamsPane videoApiId={videoApiId}
              streams={streams}
            />}

          {(selectedTab === 'web3theatre') &&
            <LandingWeb3TheatrePage />}

          {(selectedTab === 'webhooks') &&
            <DAppWebhooksPane videoApiId={videoApiId}
              webhookRegistrations={webhookRegistrations}
            />}

          {(selectedTab === 'settings') &&
            <DAppSettingsPane project={project}
            />}
        </div>
      </div>
    </>}
    {currentOrg && currentOrg.suspended && <Overlay type={'suspended'} />}
    {currentOrg && currentOrg.disabled && <Overlay type={'disabled'} />}
  </div>
  );
}

export default VideoServicesPage;


const getVideoServicesOptions = (userId) => {
  const options = [
    {
      title: 'Overview',
      id: 'overview',
      icon: <OverviewIcon />,
      link: mergeUrl(Urls.VIDEO_OVERVIEW)
    },
    {
      title: 'Videos',
      id: 'videos',
      icon: <VideoIcon />,
      link: mergeUrl(Urls.VIDEO_VIDEOS)
    },
    {
      title: 'Livestreams',
      id: 'livestreams',
      icon: <LivestreamIcon />,
      link: mergeUrl(Urls.VIDEO_LIVESTREAMS)
    },
    {
      title: 'Web3 Theatre',
      id: 'web3theatre',
      icon: <Web3TheatreIcon />,
      link: mergeUrl(Urls.VIDEO_WEB3THEATRE)
    },
    {
      title: 'Webhooks',
      id: 'webhooks',
      icon: <WebhookIcon />,
      link: mergeUrl(Urls.VIDEO_WEBHOOKS)
    }

  ]

  if (userId) {
    options.push({
      title: 'Settings',
      id: 'settings',
      icon: <SettingsIcon />,
      link: mergeUrl(Urls.VIDEO_SETTINGS)
    })
  }

  return options;
}