import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { BackArrowIcon, CopyIcon } from "../../../components/Svg";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import {
  Hosts, Urls
} from "../../../constants";
import { mergeUrl } from "../../../utils/url";
import { AiTabs, AiTab } from '../../../components/AiTabs';
import Ai, { getRagChatbotIframeUrl, selectRagChatbot } from '../../../store/models/Ai';
import cx from 'classnames';
import Loader from '../../../components/Loader';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Button } from '../../../components/Button';
import { toast } from 'react-toastify';
import { KnowledgeTab } from '../../../components/rag-chatbot/KnowledgeTab';
import { RagSettingsTab } from '../../../components/rag-chatbot/RagSettingsTab';
import { SelectInput } from '../../../components/form/SelectInput';
import { selectMobileLayout } from '../../../store/UIState';
import { selectCurrentProjectId } from '../../../store/models/Project';
import { replaceTo } from '../../../store/history';


function RagChatbotDetailPage() {
  const dispatch = useDispatch();
  const { projectId, id } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [selectedSubTab, setSelectedSubTab] = useState('playground');
  const [accessToken, setAccessToken] = useState();

  const chatbot = useSelector(state => selectRagChatbot(state, projectId, id));
  const isMobile = useSelector(state => selectMobileLayout(state));
  const stateProjectId = useSelector(state => selectCurrentProjectId(state));

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [projectId, id]);

  useEffect(() => {
    if (stateProjectId !== projectId) {
      replaceTo(mergeUrl(Urls.AI_RAG_CHATBOT, { projectId }));
    }
  }, [stateProjectId]);

  const fetchData = async () => {
    setLoading(true);
    if (error) setError();
    try {
      await dispatch(Ai.actions.fetchRagChatbot(projectId, id));
      let res = await dispatch(Ai.actions.fetchRagAccessToken(projectId, id));
      setAccessToken(res);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }

  const tabOptions = [
    { label: 'Playground', value: 'playground' },
    { label: 'Integration', value: 'integration' },
    { label: 'Settings', value: 'settings' },
    { label: 'Knowledge base', value: 'knowledge-base' }
  ];

  return (<div className={'AiServicePage AiServicePage--with-back-button'}>
    <Link to={mergeUrl(Urls.AI_RAG_CHATBOT, { projectId })}>
      <div className={'AiServicePage__back-button rag-chatbot'}>
        <BackArrowIcon />
        <div className={'AiServicePage__back-button--label'}>
          RAG Chatbot
        </div>
      </div>
    </Link>
    {!loading && chatbot && <div className={'AiServicePage__header detail'}>
      <div className={'AiServicePage__header--title'}>{chatbot.name || 'RAG Chatbot'}</div>
    </div>}
    {!loading && chatbot && <div className={'AiServicePage__content new-deployment'}>
      {isMobile ? (
        <SelectInput
          value={{ label: tabOptions.find(t => t.value === selectedSubTab).label, value: selectedSubTab }}
          options={tabOptions}
          styles={selectStyles}
          onChange={(option) => setSelectedSubTab(option.value)}
        />
      ) : (
        <AiTabs>
          <AiTab isSelected={(selectedSubTab === 'playground')}
            onClick={() => setSelectedSubTab('playground')}>
            Playground
          </AiTab>
          <AiTab isSelected={(selectedSubTab === 'integration')}
            onClick={() => setSelectedSubTab('integration')}>
            Integration
          </AiTab>
          <AiTab isSelected={(selectedSubTab === 'settings')}
            onClick={() => setSelectedSubTab('settings')}>
            Settings
          </AiTab>
          <AiTab isSelected={(selectedSubTab === 'knowledge-base')}
            onClick={() => setSelectedSubTab('knowledge-base')}>
            Knowledge base
          </AiTab>
        </AiTabs>
      )}
      {
        (selectedSubTab === 'playground') && chatbot &&
        <PlaygroundTab chatbot={chatbot} accessToken={accessToken} />
      }
      {
        (selectedSubTab === 'integration') && chatbot &&
        <IntegrationTab chatbot={chatbot} accessToken={accessToken} />
      }
      {
        (selectedSubTab === 'settings') && chatbot &&
        <div className='AiServicePage__tab-content'>
          <RagSettingsTab chatbot={chatbot} projectId={projectId} accessToken={accessToken} setAccessToken={setAccessToken} />
        </div>
      }
      {
        (selectedSubTab === 'knowledge-base') && chatbot &&
        <KnowledgeTab chatbot={chatbot} />
      }
    </div>}
    {loading && <div className={'EmptyState'}>
      <Loader size='large' color='grey' />
    </div>}
    {!loading && error && !chatbot && <div className={"AiServicePage__error"}>
      {error}
      <Button onClick={fetchData}
        color={"green-outline"}
        title={"Retry"} />
    </div>}
  </div >
  );
}

const PlaygroundTab = (props) => {
  const { chatbot, accessToken } = props;

  const handleThemeChange = (event) => {
    setTheme(event.target.value);
  };

  const iframeUrl = useMemo(() =>
    getRagChatbotIframeUrl(chatbot, accessToken), [chatbot.llm_endpoint, accessToken]);

  const onCopy = () => {
    navigator.clipboard.writeText(iframeUrl)
      .then(() => {
        toast.success('Chatbot URL Copied!')
      })
      .catch((err) => {
        console.error('Failed to copy:', err);
      });
  }
  return <div className='AiServicePage__tab-content'>
    <div className='RagChatbotDetailPage playground'>
      <div className='RagChatbotDetailPage__row'>

        <div className='RagChatbotDetailPage__input-wrap'>
          <div className='RagChatbotDetailPage__input-label-row'>
            <div className='RagChatbotDetailPage__input-label'>
              Chatbot URL <div className='RagChatbotDetailPage__input-label--icon' onClick={onCopy}><CopyIcon /></div>
            </div>
            <Button href={iframeUrl} target='_blank' title={"Open"} size={'small'} color={'green-outline'} />
          </div>

          <div className='RagChatbotDetailPage__input-div'>
            <div className='RagChatbotDetailPage__input-div--info RagChatbotDetailPage__input-div--one-line'>{iframeUrl}</div>
          </div>
        </div>
      </div>
      <div className='RagChatbotDetailPage__playground'>
        <iframe src={iframeUrl} width='100%' height='100%' />
      </div>
    </div>
  </div >
}



const IntegrationTab = (props) => {
  const { chatbot, accessToken } = props;
  const [tab, setTab] = useState('curl');

  return (
    <div className='AiServicePage__tab-content'>
      <div className='RagChatbotDetailPage integration'>
        <div className='RagChatbotDetailPage__tabs'>
          <div className={cx("RagChatbotDetailPage__tab", { active: tab === 'curl' })} onClick={() => setTab('curl')}>
            cURL
          </div>
          {/* <div className={cx("RagChatbotDetailPage__tab", { active: tab === 'js' })} onClick={() => setTab('js')}>
            JavaScript
          </div>
          <div className={cx("RagChatbotDetailPage__tab", { active: tab === 'python' })} onClick={() => setTab('python')}>
            Python
          </div> */}
          <div className={cx("RagChatbotDetailPage__tab", { active: tab === 'iframe' })} onClick={() => setTab('iframe')}>
            iFrame
          </div>
        </div>
        <CodeIntegration tab={tab} chatbot={chatbot} accessToken={accessToken} />
      </div>
    </div>
  );
}

const CodeIntegration = ({ tab, chatbot, accessToken }) => {
  const { model, llm_endpoint, max_tokens, id, temperature } = chatbot;
  const link = (llm_endpoint || '').split('/v1')[0];
  const iframeUrl = getRagChatbotIframeUrl(chatbot, accessToken);

  const [copied, setCopied] = useState(false);
  const [code, setCode] = useState(`curl -X POST ${Hosts.CHATBOT_API}/chatbot/${id}/chat/completions \\
  -H 'Content-Type: application/json' \\
  -H "Authorization: Bearer ${accessToken}" \\
  -d '{
    "messages": [
        {"role": "system", "content": "You are a helpful assistant."},
        {"role": "user", "content": "Hello, how are you?"}
    ],
  }'`);
  useEffect(() => {
    const curlCode = `curl -X POST ${Hosts.CHATBOT_API}/chatbot/${id}/chat/completions \\
    -H 'Content-Type: application/json' \\
    -H "Authorization: Bearer ${accessToken}" \\
    -d '{
      "messages": [
          {"role": "system", "content": "You are a helpful assistant."},
          {"role": "user", "content": "Hello, how are you?"}
      ],
      "max_tokens": ${max_tokens || 100},
      "temperature": ${temperature || 0.7},
      "stream": true
    }'`;
    const jsCode = `import OpenAI from "openai";

// Set OpenAI's API key to use vLLM's API server.
const openai_api_key = "YOUR_OPENAI_API_KEY"
const openai_api_base = "${link}/v1"

const openai = new OpenAI(
  api_key=openai_api_key,
  base_url=openai_api_base,
);

async function main() {
  const completion = await openai.completions.create({
    "model": "${model}",
    "messages": [
      {"role": "system", "content": "You are a helpful assistant."},
      {"role": "user", "content": "Hello, how are you?"}
    ],
    "max_tokens": ${max_tokens || 100}
  });
    
  // console.log(completion);
}
main();`;

    const pythonCode = `from openai import OpenAI

# Set OpenAI's API key to use vLLM's API server.
openai_api_key = "YOUR_OPENAI_API_KEY"
openai_api_base = "${link}/v1"

client = OpenAI(
  api_key=openai_api_key,
  base_url=openai_api_base,
)

chat_response = client.chat.completions.create({
  "model": "${model}",
  "messages": [
    {"role": "system", "content": "You are a helpful assistant."},
    {"role": "user", "content": "Hello, how are you?"}
  ],
  "max_tokens": ${max_tokens || 100}
})
print("Chat response:", chat_response)`;

    const iframeCode = `<iframe src="${iframeUrl}"></iframe>`;

    switch (tab) {
      case 'curl':
        setCode(curlCode);
        break;
      case 'js':
        setCode(jsCode);
        break;
      case 'python':
        setCode(pythonCode);
        break;
      case 'iframe':
        setCode(iframeCode);
        break;
      default:
        setCode();
        break;
    }
  }, [tab, chatbot]);

  function getLanguage(tab) {
    switch (tab) {
      case 'curl':
        return 'javascript';
      case 'js':
        return 'javascript';
      case 'python':
        return 'python';
      case 'iframe':
        return 'html';
      default:
        return 'javascript';
    }
  }

  function getTitle(tab) {
    switch (tab) {
      case 'curl':
        return 'cURL code';
      case 'js':
        return 'JavaScript code';
      case 'python':
        return 'Python code';
      case 'iframe':
        return 'iFrame embed';
      default:
        return 'javascript';
    }
  }

  const handleCopy = () => {
    if (copied) return;
    navigator.clipboard.writeText(code)
      .then(() => {
        setCopied(true);
        toast.success('Code Copied!')
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => {
        console.error('Failed to copy:', err);
      });
  }

  return <div className='AiServicePage__tab-content'>
    <div className='RagChatbotDetailPage integration'>
      <div className='RagChatbotDetailPage__code-content'>
        <div className='RagChatbotDetailPage__code-content--title'>{getTitle(tab)}</div>
        <div className='RagChatbotDetailPage__code-content--code'>
          <SyntaxHighlighter
            lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
            language={getLanguage(tab)}
            style={vscDarkPlus}>
            {code}
          </SyntaxHighlighter>
        </div>
        <Button onClick={handleCopy} title={copied ? "Code Copied!" : "Copy Code"} color={'green-outline'} />

      </div>
    </div>
  </div>
}
export default RagChatbotDetailPage;


const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#202432',
    borderColor: 'transparent',
    borderRadius: '4px',
    color: '#8A8FB5',
    outline: 'none',
    borderRadius: '0.8rem',
    minHeight: 'none',
    height: '50px',
    boxShadow: 'none',
    ':hover': {
      borderColor: '#18C99D', // Add your hover styles here
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? '#202432' :
      state.isFocused ? '#1b2b32' : '#202432',
    color: state.isDisabled ? '#FFFFFF' :
      state.isFocused ? '#18C99D' :
        state.isSelected ? '#18C99D' : '#8A8FB5',
    fontFamily: 'Gilroy',
    paddingLeft: '10px',
    fontWeight: '500',
    fontSize: '14px',
    paddingTop: '11.5px',
    paddingBottom: '11.5px',
    height: 'auto',
    position: 'relative',
    cursor: state.isDisabled ? 'default' : 'pointer',
    ':hover': {
      backgroundColor: state.isDisabled ? '#202432' : '#1b2b32',
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    borderColor: state.isSelected ? '#18C99D' : 'transparent',
    color: '#8A8FB5',
    fontFamily: 'Gilroy',
    fontWeight: '500',
    fontSize: '14px',
    outline: 'none'
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none'
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#8A8FB5',
    marginRight: '0',
    marginLeft: '4px',
    ':hover': {
      color: '#8A8FB5', // Add your hover styles here
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: '#131722',
    // border: '1px solid #3D4463',
    borderRadius: '4px',
    scrollbarColor: '#3D4463',
    width: '100%',
    height: '4rem',
    zIndex: 30
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: '500px',
  }),
}
