import _ from 'lodash';
import { mergeUrl } from './url';

import { RequestState, Hosts, SessionErrorCodes } from '../constants';
import Storage from '../utils/storage';
import { TYPE } from '../constants';

import { selectCurrentProject } from "../store/models/Project";

const { REQUEST, SUCCESS, FAIL, NOT_AUTHORIZED } = RequestState;


// ===========================
// HELPERS
// ===========================

export function fetchOptions(method = 'get', body, headers = {}, mode = 'cors') {
  const isFormData = body instanceof FormData;
  return {
    method,
    headers: {
      'X-Platform': 'web',
      ...((body && !isFormData) ? { 'Content-Type': 'application/json' } : {}),
      ...headers,
    },
    body: isFormData ? body : JSON.stringify(body),
    credentials: 'include',
    mode,
  };
}

export function restUrl(url, params, host) {
  if (url.indexOf("http") !== -1) {
    return mergeUrl(url, params);
  }
  return mergeUrl(host + url, params);
}

export function isErrorResponse(code, json) {
  return Boolean(
    !json ||
    (code && code != 200 && code != 204) ||
    (json.code && json.code != 200 && json.code != 204) ||
    json.status === "ERROR" ||
    json.status === "FAIL");
}

export function authHeaders(id, token) {
  id = id ?? Storage.getUserId();
  token = token ?? Storage.getUserToken();
  if (id && token) {
    return {
      'x-auth-id': id,
      'x-auth-token': token,
    }
  }
}

export function videoServiceAuthHeaders(state, serviceAccountId) {
  const project = selectCurrentProject(state)

  if (project) {
    return {
      'x-tva-sa-id': project.tva_id,
      'x-tva-sa-secret': project.tva_secret
    }
  }
}

export function convertToFormData(data) {
  const formData = new FormData();

  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }

  return formData;
}


// ===========================
// FETCH FUNCTION
// ===========================
async function _fetch(args) {
  let {
    url, params, method, dispatch, type = TYPE.API,
    action, body, headers, host = Hosts.API, mode, data
  } = args;

  if (method === 'post') {
    console.log('body:', body)
  }

  url = restUrl(url, params, host);
  let options = fetchOptions(method, body, headers, mode);

  // Disaptch Request
  if (dispatch && action)
    dispatch(request(action, url, params, body, data));

  // Do Fetch
  let response;
  try {
    response = await fetch(url, options);
  } catch (err) { // NETWORK ERROR
    if (typeof err === 'string') {
      console.log("URL is unreachable: ", url, err);
      return Promise.reject({ message: err });
    }
    console.log(err);
    return Promise.reject(err);
  }

  let json = {}
  try {
    if (response.status !== 204) {
      json = await response?.json();
    }
  } catch (e) { // JSON PARSE ERROR
    console.log('err: ' + e?.message)
  }

  response.headers.forEach(function (val, key) {
    if (key === 'x-auth-session-error-code') {
      console.log('x-auth-session-error-code ' + val)
      switch (parseInt(val)) {
        case SessionErrorCodes.ACCOUNT_DISABLED:
        case SessionErrorCodes.INVALID_SESSION_TYPE:
        case SessionErrorCodes.INVALID_TOKEN:
        case SessionErrorCodes.SESSION_NOT_FOUND:
        case SessionErrorCodes.TOKEN_EXPIRED:
          // dispatch(User.actions.invalidSessionLogout());
          break;
      }
    }
  });

  if (response.headers.has('x-tva-sa-error')) {
    if (response.headers.get('x-tva-sa-error') === 'service account disabled' && action === 'FETCH_SERVICE_ACCOUNT') {
      let newJson = {
        status: "success",
        headers: headers,
        body: {
          service_accounts: [{
            id: headers['x-tva-sa-id'],
            secret: headers['x-tva-sa-secret'],
            disabled: true,
            disabledMsg: response.headers.get('x-tva-sa-error-message')
          }]
        }
      }
      dispatch(receive(action, newJson, url, params, data));
      return Promise.resolve(json);
    }
  }

  // RESPONSE ERRORS
  if (isErrorResponse(response.status, json)) {
    console.log("API ERROR", json, response,);
    let err = {
      message: json.message || json.status || 'Unknown Error',
      code: json.code || response.status || 'Unknown Code',
    }

    // if (err.code === 401) {
    //   dispatch(UIState.actions.showErrorToast("You are not authorized. Please login and try again."))
    //   dispatch(User.actions.logout());
    // }

    if (dispatch && action)
      dispatch(error(action, err, url, params, data));

    return Promise.reject({ ...err, action });
  }

  if (dispatch && action)
    dispatch(receive(action, json, url, params, data));

  json.headers = headers;
  return Promise.resolve(json);
}



// ===========================
// REQUEST TYPES
// ===========================
export const get = (options) => _fetch({ ...options, method: 'get' });
export const post = (options) => _fetch({ ...options, method: 'post' });
export const put = (options) => _fetch({ ...options, method: 'put' });
export const del = (options) => _fetch({ ...options, method: 'delete' });

// ===========================
// RESPONSE TYPES
// ===========================
export function request(action, url, params, body, data = null) {
  return {
    type: action,
    url,
    params,
    body,
    result: REQUEST,
    fetchAt: Date.now(),
    data,
  };
}

export function receive(action, json, url, params, data = null) {
  return {
    type: action,
    json,
    url,
    params,
    result: SUCCESS,
    receivedAt: Date.now(),
    data
  }
}

export function error(action, error, url, params, data) {
  return {
    type: action,
    ...error,
    url,
    params,
    result: FAIL,
    receivedAt: Date.now(),
    data,
  }
}

