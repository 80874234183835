import _ from 'lodash';
import React, { useState, useEffect, useContext } from 'react';
import {
  AreaChart, Line, CartesianGrid, XAxis, YAxis, Tooltip,
  Legend, Area, BarChart, Bar
} from 'recharts';
import CustomTooltip from './CustomTooltip';

// const Chart = (props) => {
export function HourChart(props) {
  let { data, legendName } = props;
  return <AreaChart width={750} height={255} data={data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
    <defs>
      <linearGradient id="colorScore">
        <stop offset="5%" stopColor="##18C99D" stopOpacity={0.8} />
        <stop offset="95%" stopColor="#18C99D" stopOpacity={1} />
      </linearGradient>
    </defs>
    <Line type="monotone" dataKey="score" stroke="#18C99D" dot={false} />
    <XAxis dataKey="hour" />
    <YAxis />
    <Tooltip contentStyle={{ 'backgroundColor': '#0C0F1A', 'border': 'none' }} />
    <Area name={legendName} type="monotone" dataKey="score" stroke="#18C99D" fillOpacity={0.3} fill="url(#colorScore)" />
    <Legend verticalAlign="bottom" height={36} />
  </AreaChart>;
}

export function DailyChart(props) {
  let { data, legendName, width, height } = props;
  // console.log(`jlog151 data ${JSON.stringify(data)}, legendName ${legendName}`);
  const newData = divideResults(data, legendName);

  // console.log(`jlog152 data ${JSON.stringify(newData)}, legendName ${legendName}`);

  return <AreaChart width={width || 750} height={height || 255} data={newData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
    <defs>
      <linearGradient id="colorScore">
        <stop offset="5%" stopColor="##18C99D" stopOpacity={0.8} />
        <stop offset="95%" stopColor="#18C99D" stopOpacity={1} />
      </linearGradient>
    </defs>
    <Line type="monotone" dataKey="result" stroke="#18C99D" dot={false} />
    <XAxis dataKey="date" />
    <YAxis />
    <Tooltip content={<CustomTooltip legendName={legendName} />} contentStyle={{ 'backgroundColor': '#0C0F1A', 'border': 'none' }} />
    {/* <Tooltip contentStyle={{ 'backgroundColor': '#0C0F1A', 'border': 'none' }} /> */}
    <Area name={legendName} type="monotone" dataKey="result" stroke="#18C99D" fillOpacity={0.3} fill="url(#colorScore)" />
    <Legend verticalAlign="bottom" height={36} />
  </AreaChart>;
}

export function StakedBarChart(props) {
  let { data, legendName, width, height, dataKeys, dataColors } = props;
  // console.log(`jlog151 data ${JSON.stringify(data)}, legendName ${legendName}`);
  const newData = divideResults(data, legendName);

  // console.log(`jlog152 data ${JSON.stringify(newData)}, legendName ${legendName}`);

  return <BarChart width={width || 750} height={height || 255} data={newData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
    <defs>
      <linearGradient id="colorScore">
        <stop offset="5%" stopColor="##18C99D" stopOpacity={0.8} />
        <stop offset="95%" stopColor="#18C99D" stopOpacity={1} />
      </linearGradient>
    </defs>
    <XAxis dataKey="name" />
    <YAxis tickFormatter={(v) => {
      if (legendName === 'Usage') return `$${v}`;
      return v;
    }} />
    <Tooltip cursor={{ fill: '#24283B' }}
      content={<CustomTooltip legendName={legendName} />}
      contentStyle={{ 'backgroundColor': '#0C0F1A', 'border': 'none' }} />
    {dataKeys.map((k, i) => {
      return <Bar dataKey={k} stackId="a" fill={dataColors[i]} key={i} />
    })}
    <Legend verticalAlign="bottom" height={36} />
  </BarChart>;
}
//TODO: depending on data itself and change unit
const divideResults = (data, type) => {
  // _.get(metric, `${getCap(activeInfo.bar)}`) || data
  if (type == 'URL Hits') {
    return data.map(item => {
      return {
        result: Number((item.result / 1000).toFixed(2)),
        date: item.date
      };
    })
  }
  if (type == 'URL Volume') {
    return data.map(item => {
      return {
        result: Number((item.result / 1000000000).toFixed(2)),
        date: item.date
      };
    })
  }
  return data;
}

// export default Chart;
