import React, { useState, useRef } from 'react';
import cx from 'classnames';
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../components/Modal";
import { Button } from "../components/Button";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentOrgId } from '../store/models/Organization';
import { CreditSelection } from '../components/CreditSelection';
import Bill from '../store/models/Bill';
import { toast } from 'react-toastify';


export const AddUsdcCreditModal = ({ onRequestClose, couponOnly = false, coupon }) => {
  const dispatch = useDispatch()
  const orgId = useSelector(state => selectCurrentOrgId(state));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [credit, setCredit] = useState(20);


  const onSubmit = async () => {
    setError(null);
    setLoading(true);
    try {
      const currentUrl = window.location.protocol + '//' + window.location.host + location.pathname + location.search;
      let url = await dispatch(Bill.actions.fetchStripeUrlForUsdc(orgId, currentUrl, `${parseFloat(credit) * 100}`));
      window.location.href = url;
      onRequestClose();
    } catch (e) {
      toast.error(`Error: ${e.message}`);
      setError(e.message);
    } finally {
      setLoading(false);
    }
    return;
  }

  return (
    <Modal className={cx("AddCreditModal")}
      onRequestClose={onRequestClose}>

      <ModalHeader>
        <ModalTitle>{couponOnly ? "Apply Coupon" : "Add Credit"}</ModalTitle>
      </ModalHeader>

      <ModalContent>
        <div className='AddCreditModal__content'>

            <div className='AddCreditModal__input-section'>
              <div className='AddCreditModal__input-selection-wrap'>
                <CreditSelection value={credit} setValue={setCredit} />
              </div>
            
          </div>
        </div>
        {error && <div className={cx("AddCreditModal__error")}>Error: {error}</div>}
      </ModalContent>

      <ModalActions>
        <Button color={"green"} onClick={onSubmit} title={`Confirm`} loading={loading} />
        <Button color={"transparent"} onClick={onRequestClose} title={"Cancel"} />
      </ModalActions>

    </Modal>
  );
}

export default AddUsdcCreditModal;
