import { useDispatch } from 'react-redux';
import Organization from '../store/models/Organization';
import _ from 'lodash';
import Project from '../store/models/Project';
import AppState from '../store/AppState';
import Bill from '../store/models/Bill';

const useSelectDefaultProject = () => {
  const dispatch = useDispatch();

  const fetchDefaultProject = async () => {
    let orgRes = await dispatch(Organization.actions.fetchUserOrganizations());
    let orgId = _.get(orgRes, '0.id');
    let projectRes = await dispatch(Project.actions.fetchUserProjectsInOrg(orgId, {
      expand: 'user_ids'
    }))
    let projectId = _.get(projectRes, '0.id');
    await dispatch(AppState.actions.selectCurrentOrgAndProject(orgId, projectId))
    await dispatch(Bill.actions.fetchBalance(orgId));
  };

  return { fetchDefaultProject };
};

export default useSelectDefaultProject;
