import React, { useRef, useEffect } from 'react';
import Select from 'react-select';

const CustomSelect = (props) => {
  const { selectRef, defaultValue } = props;
  useEffect(() => {
    const handleMenuOpen = () => {
      if (selectRef && selectRef.current) {
        const menu = selectRef.current.getMenuListRef();
        if (menu) {
          const selectedOption = menu.querySelector('.react-select__option--is-selected');
          if (selectedOption) {
            selectedOption.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
          }
        }
      }
    };

    if (selectRef && selectRef.current) {
      handleMenuOpen();
    }
  }, [defaultValue]);

  return (
    <Select
      ref={selectRef}
      classNamePrefix={'VMSelect'}
      onMenuOpen={() => {
        setTimeout(() => {
          const selectedEl = document.getElementsByClassName("VMSelect__option--is-selected")[0];
          if (selectedEl) {
            selectedEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 0);
      }}
      {...props}
    />
  );
};

export default CustomSelect;
