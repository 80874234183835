import React from 'react';
import {selectCurrentUserId} from "../../store/models/User";
import {useDispatch, useSelector} from "react-redux";
import {selectPaymentRedirect} from "../../store/models/DApp";
import {Button} from "../../components/Button";
import {PricingCard} from "../marketing/LandingTVA";
import {BsInfoCircle} from 'react-icons/bs';

const NUM_PER_PAGE = 10;
export const MinimumFundingAmount = 1; //$1 USD

function SubscribePage(props) {
  const dispatch = useDispatch();
  const userId = useSelector(state => selectCurrentUserId(state));
  const paymentInfo = useSelector(state => selectPaymentRedirect(state));

  return (
    <div className={'SubscribePage'}>
      <div className={'PricingSection'}>
        <div className={'PricingSection__title'}>
          Theta Video API Pricing
        </div>
        <div className={'PricingSection__subtitle'}>
          Subscribe to enable video ingest, storage, and delivery on your account. Pay as you go.
        </div>
        <div className={'PricingSection__cards'}>
          <PricingCard title={'Video ingest'}
                       price={`$0.015/min`}
                       priceSubtitle={'(based on video file length)'}
                       product={null}
                       savings={null}
                       hideCTA={true}
          />
          <PricingCard title={'Video transcode'}
                       price={`$0.015/min`}
                       priceSubtitle={'(based on compute time)'}
                       product={null}
                       savings={null}
                       hideCTA={true}
          />
          <PricingCard title={'Video delivery'}
                       price={`$0.024/GB`}
                       priceSubtitle={'(based on video data delivered)'}
                       product={null}
                       savings={null}
                       hideCTA={true}
          />
        </div>
        <Button title={'Subscribe'}
                color={'green'}
                size={'large'}
                style={{
                  width: 340,
                  marginTop: 30
                }}
                href={paymentInfo.payment_url}
        />
        {/* <div className={'PricingSection__asterisks'}>
          $100.00 due today and monthly. Then billed monthly based on usage. The $100 flat rate charge will be applied to your account as credit for future invoices. Each month, any existing credit will be used first before your credit card is charged again for the monthly fee.
        </div> */}
        <div className={'PricingSection__asterisks'}>
          <BsInfoCircle className='PricingSection__asterisks--icon'/>Here is how your account billing works.
          <div className={'PricingSection__asterisks--text'}>
            1. Upon signing up, your account is charged $100 and you are allocated a credit.
          </div>
          <div>
            2. As you use our video/streaming services, the costs are deducted from this $100 credit.
          </div>
          <div>
            3. Each month, your credits will be refreshed up to $100 (based on previous month’s usage) and you will be
            charged accordingly.*
          </div>
          <div className={'PricingSection__asterisks--text'}>
            *If your credits remain above $100, you will NOT be charged the following month.
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscribePage;
