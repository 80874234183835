import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import AiStorage, { selectStorage, selectDeploymentsUsingVolume, convertSizeLabel, getFormateStorageSize } from '../../../../store/models/AiStorage';
import { Button } from '../../../../components/Button';
import { toast } from 'react-toastify';
import { createSelector } from 'reselect';
import { selectCurrentProject } from '../../../../store/models/Project';
import { selectCurrentUser } from '../../../../store/models/User';
import { selectCurrentOrg } from '../../../../store/models/Organization';
import Loader from '../../../../components/Loader';
import { BackArrowIcon } from "../../../../components/Svg";
import { Urls } from "../../../../constants";
import { mergeUrl } from "../../../../utils/url";
import dayjs from 'dayjs';
import { getVmResourcesString } from '../../../../utils';
import Ai, { selectAllVMs } from '../../../../store/models/Ai';
import Tooltip from '../../../../components/Tooltip';

const selector = createSelector(
  selectCurrentProject,
  selectCurrentUser,
  selectCurrentOrg,
  (state, storageId) => selectStorage(state, storageId),
  (state, storageId) => selectDeploymentsUsingVolume(state, storageId),
  selectAllVMs,
  (project, currentUser, currentOrg, storage, deploymentsUsingVolume, vms) => (
    { project, currentUser, currentOrg, storage, deploymentsUsingVolume, vms })
);

const StorageDetailsPage = () => {
  const { id: storageId } = useParams();
  const dispatch = useDispatch();
  const { project, currentUser, currentOrg, storage, deploymentsUsingVolume, vms } = useSelector(state => selector(state, storageId));

  useEffect(() => {
    if (!storage) {
      dispatch(AiStorage.actions.fetchStorage(project.id, storageId));
    }
    dispatch(AiStorage.actions.getDeploymentsUsingVolume(project.id, storageId));
  }, [project.id]);

  const formatDate = (date) => {
    return dayjs(date).format('MMMM DD, YYYY HH:mm:ss');
  };

  const formatSize = (size) => {
    if (size >= 1024) {
      return `${size / 1024} TB`;
    }
    return `${size} GB`;
  };

  if (!storage) {
    return <Loader />;
  }

  return (
    <div className={'AiServicePage'}>
      <Link to={mergeUrl(Urls.AI_STORAGE, { projectId: project.id })}>
        <div className={'AiServicePage__back-button'}>
          <BackArrowIcon />
        </div>
      </Link>
      <div className={'AiServicePage__header detail'}>
        <div className={'AiServicePage__header--title'}>{storage.Name}</div>
      </div>
      
      <div className={'AiServicePage__header--subtitle'}>Details</div>

      <div className="StorageDetailsPage__horizontal-border"></div>

      <div className={'AiServicePage__content storage'}>
        <div className='StorageDetailsPage__details'>
          <div className='StorageDetailsPage__details--row'>
            <div className='StorageDetailsPage__details--section-title'>
              BASIC
            </div>
          </div>

          <div className='StorageDetailsPage__details--row'>
            <div className='StorageDetailsPage__details--title'>
              Name
            </div>
            <div className='StorageDetailsPage__details--value'>
              {storage.DisplayName}
            </div>
          </div>
          {/* <div className='StorageDetailsPage__details--row'> */}
            {/* <div className='StorageDetailsPage__details--title'>
              Status
            </div> */}
            {/* <div className='StorageDetailsPage__details--value status'> */}
              {/* Active */}
            {/* </div> */}
          {/* </div> */}
          <div className='StorageDetailsPage__details--row'>
            <div className='StorageDetailsPage__details--title'>
            Volume Key
            </div>
            <div className='StorageDetailsPage__details--value'>
              {storage.Suffix}
            </div>
          </div>
          <div className='StorageDetailsPage__details--row'>
            <div className='StorageDetailsPage__details--title'>
              Created
            </div>
            <div className='StorageDetailsPage__details--value'>
              {formatDate(storage.createdAt)}
            </div>
          </div>
          <div className='StorageDetailsPage__details--row'>
            <div className='StorageDetailsPage__details--title'>
              Region
            </div>
            <div className='StorageDetailsPage__details--value'>
              {storage.Region}
            </div>
          </div>
          <div className='StorageDetailsPage__details--row'>
            <div className='StorageDetailsPage__details--title'>
              Size
            </div>
            <div className='StorageDetailsPage__details--value'>
              {getFormateStorageSize(storage)}
            </div>
          </div>
          {/* <div className='StorageDetailsPage__details--row'>
            <div className='StorageDetailsPage__details--title'>
              Cost
            </div>
            <div className='StorageDetailsPage__details--value'>
              - per hour
            </div>
          </div> */}

          <div className='StorageDetailsPage__details--row section'>
            <div className='StorageDetailsPage__details--section-title'>
              ATTACHED TO
            </div>
          </div>

          <div className='StorageDetailsPage__attached-nodes'>
            <div className='StorageDetailsPage__attached-nodes--header'>
              <div className='StorageDetailsPage__attached-nodes--cell'>GPU Node Name</div>
              <div className='StorageDetailsPage__attached-nodes--cell'>ID</div>
              <div className='StorageDetailsPage__attached-nodes--cell'>Mounted at</div>
              <div className='StorageDetailsPage__attached-nodes--cell'>Machine type</div>
            </div>
            {deploymentsUsingVolume && deploymentsUsingVolume.length > 0 ? (
              deploymentsUsingVolume.map((node) => {
                const deploymentVolume = _.find(node.Volumes, volume => volume.volumeId === storage.Suffix);
                return (
                  <div key={node.ID} className='StorageDetailsPage__attached-nodes--row'>
                    <div className='StorageDetailsPage__attached-nodes--cell'>{node.Annotations.nickname}</div>
                    <div className='StorageDetailsPage__attached-nodes--cell'>{node.Name}</div>
                    <div className='StorageDetailsPage__attached-nodes--cell'>{deploymentVolume?.mountPath || '-'}</div>
                    <div className='StorageDetailsPage__attached-nodes--cell'>
                    <Tooltip tooltip={<div className={"AiServicePage__tooltip"}>
                      {getVmResourcesString(vms?.[node.MachineType]?.resources)}
                    </div>}>{vms?.[node.MachineType]?.name || '-'}</Tooltip>
                    </div>
                  </div>
              )
            })
            ) : (
              <div className='StorageDetailsPage__attached-nodes--row empty'>
                <div className='StorageDetailsPage__attached-nodes--cell' colSpan="4">No deployments are currently using this volume.</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StorageDetailsPage;
