import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { Button } from "../components/Button";
import { useDispatch, useSelector } from "react-redux";
import User from "../store/models/User";
import { Urls } from "../constants";
import { Link } from "react-router-dom";
import { useIsMountedRef } from '../hooks/useIsMounted';
import AppState, { selectProjectInvite } from "../store/AppState";
import { createSelector } from "reselect";
import { pushTo } from "../store/history";
import { mergeUrl, useQuery } from "../utils/url";
import Modal, { ModalContent, ModalHeader, ModalTitle } from "../components/Modal";
import UIState from '../store/UIState';
import { updateAiProjectId } from '../utils';
import { useHistory } from "react-router";

const selector = createSelector(
  selectProjectInvite,
  (projectInvite) => (
    { projectInvite }
  )
);

function SignUpForm({ onSubmit, loading, error, projectInvite }) {

  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
  const recaptchaRef = useRef(null);
  const isMountedRef = useIsMountedRef();
  const onSubmitLocal = (e) => {
    e.preventDefault();

    if (!isMountedRef.current) return;
    handleSubmit(onSubmit)();
    // recaptchaRef.current.reset();
  }

  useEffect(() => {
    if (!_.isEmpty(projectInvite)) {
      setValue('email', projectInvite.email);
    }
  }, [projectInvite]);

  // const onVerifyCaptcha = (token) => {
  //   console.log('onVerifyCaptcha :: token == ' + token);

  //   setValue('captchaToken', token);
  // };

  // useEffect(() => {
  //   register('captchaToken', { required: true });
  // });

  return (
    <form onSubmit={onSubmitLocal}
      className={'Form SignUpForm'}
    >
      <div className={"NewForm__Row"}>
        <div className={"NewForm__InputTextWrapper"}>
          <input type="text" placeholder="Email" {...register("email", { required: true, maxLength: 803 })} />
        </div>
      </div>
      <div className={"NewForm__Row"}>
        <div className={"NewForm__InputTextWrapper"}>
          <input type="password" placeholder="Password" {...register("password", { required: true, maxLength: 1000 })} />
        </div>
      </div>
      {/*<input type="text" placeholder="First Name" {...register("firstname", {required: true, maxLength: 83})} />*/}
      {/*<input type="text" placeholder="Last Name" {...register("lastname", {required: true, maxLength: 83})} />*/}
      {/*<input type="number" placeholder="Phone Number" {...register("phoneNumber", {required: true, maxLength: 1000})} />*/}

      {/* <Reaptcha sitekey={RecaptchaV2SiteKey}
        onVerify={onVerifyCaptcha}
        ref={recaptchaRef}
      /> */}
      {/*
      <div className='FormTip'>
        We have temporarily disabled account registrations. If you're interested in creating an account, please contact us at
        <a target="_blank" href="mailto:support@thetalabs.org"> support@thetalabs.org</a>.
      </div> */}

      {
        error &&
        <div className={'FormError'}>{error}</div>
      }

      <Button title={'Sign Up'}
        role={'submit'}
        loading={loading}
      // disabled={true} //disabled signup feature
      />
    </form>
  );
}

function SignUpModal({ onRequestClose, ...props }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const isMountedRef = useIsMountedRef();
  const { projectInvite } = useSelector(state => selector(state));
  const { projectId, email, token, userId } = useQuery();

  useEffect(() => {
    if (!_.isEmpty(projectId)) {
      dispatch(AppState.actions.saveProjectInvite(projectId, email, token, userId));
    }
  }, []);

  const onSubmit = async ({ email, password }) => {
    setLoading(true);
    try {
      await dispatch(User.actions.signup(email, password, email.split('@')[0]));
      if (projectInvite?.token) {
        pushTo(mergeUrl(Urls.ACCEPT_INVITE))
      } else {
        pushTo(Urls.DASHBOARD);
      }
      dispatch(UIState.actions.hideAllModals())
    } catch (e) {
      if (!isMountedRef.current) return;
      setError(e.message);
      setLoading(false);
    }
  };

  return (


    <Modal className={"SignUpModal"} onRequestClose={onRequestClose}>
      <ModalHeader>
        <ModalTitle align="row">Sign Up</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <SignUpForm onSubmit={onSubmit}
          loading={loading}
          error={error}
          projectInvite={projectInvite}
        />
        <div className={'SignUpPage__already-have'}>
          <span>Already have an account?</span>
          &nbsp;
          <Link to={'/login'} onClick={() => dispatch(UIState.actions.hideAllModals())}>Login</Link>
        </div>
      </ModalContent>
    </Modal>
  );
}

export default SignUpModal;
