import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import React, {useEffect, useState} from "react";
import Loader from "../../../components/Loader";
import PageTitleBar from "../../../components/PageTitleBar";
import {Button} from "../../../components/Button";
import {selectCurrentUser, selectCurrentUserId} from "../../../store/models/User";
import {ProjectIcon} from "../../../components/Svg";
import {ModalTypes, Urls, UserRoleIds} from "../../../constants";
import UIState from "../../../store/UIState";
import Project, {selectOrgProjects, selectUsersForProjectsInOrg} from "../../../store/models/Project";
import Organization, {selectCurrentOrgId, selectOrganizations} from "../../../store/models/Organization";
import _ from "lodash";
import classNames from "classnames";
import dayjs from "dayjs";
import {UserListRowItem} from "../../../components/UserListRowItem";
import {pushTo} from "../../../store/history";
import {mergeUrl} from "../../../utils/url";
import {Tab, Tabs} from "../../../components/Tabs";
import {selectMobileLayout} from "../../../store/UIState";
import {SelectInput} from "../../../components/form/SelectInput";

const selector = createSelector(
  selectCurrentUserId,
  selectCurrentUser,
  selectCurrentOrgId,
  selectOrganizations,
  (state, orgId) => selectOrgProjects(state, orgId ?? selectCurrentOrgId(state)),
  (state, orgId) => selectUsersForProjectsInOrg(state, orgId ?? selectCurrentOrgId(state)),
  (currentUserId, currentUser, currentOrgId, organizations, projects, users) => {
    return {
      currentUserId,
      currentUser,
      currentOrgId,
      organizations,
      projects,
      users
    }
  }
)

export const ProjectsPage = () => {

  const dispatch = useDispatch();
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const {currentUserId, currentUser, currentOrgId, organizations, projects, users} = useSelector(state => selector(state, selectedOrgId));

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const currentUserIsAdmin = currentUser?.role === UserRoleIds.ADMIN;

  const isMobile = useSelector(state => selectMobileLayout(state));

  useEffect(() => {
    fetchUserOrgs();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(selectedOrgId)) {
      fetchProjectsInOrg();
    }
  }, [selectedOrgId]);

  useEffect(() => {
    if (!_.isEmpty(currentOrgId)) {
      setSelectedOrgId(currentOrgId);
    }
  }, [currentOrgId]);

  const fetchUserOrgs = async () => {
    setLoading(true);
    setError(null);
    try {
      await dispatch(Organization.actions.fetchUserOrganizations(currentUserId));
    } catch (e) {
      setError(e.message);
    }
    setLoading(false);
  }

  const fetchProjectsInOrg = async () => {
    setLoading(true);
    setError(null);
    try {
      await dispatch(Project.actions.fetchUserProjectsInOrg(selectedOrgId, {expand: 'user_ids'}));
    } catch (e) {
      setError(e.message);
    }
    setLoading(false);
  }

  const onNewProject = () => {
    dispatch(UIState.actions.showModal(ModalTypes.CREATE_PROJECT, {orgId: selectedOrgId}));
  }

  const onSelectProject = (project) => {
    pushTo(mergeUrl(Urls.SETTINGS_PROJECT, {projectId: project.id}));
  }

  const rightButton = currentUserIsAdmin
    ? <Button title={'Create Project'}
              color={"green-outline"}
              onClick={onNewProject}/>
    : null;

  const tabOptions = organizations.map(org => ({
    label: org.name,
    value: org.id
  }));

  if (error)
    return (
      <div className={"ServicePage__Error"}>
        {error}
        <Button onClick={fetchProjectsInOrg()}
                color={"green-outline"}
                title={"Retry"}/>
      </div>
    );

  return (
    <div className={"ProjectsPage"}>
      <PageTitleBar alignTitleLeft={true} title={"Projects"} rightContent={rightButton}/>

      {isMobile ? <div>
        <div className={'ProjectsPage__select--label'}>Organization</div>
        <SelectInput
          value={{ label: organizations.find(org => org.id === selectedOrgId)?.name, value: selectedOrgId }}
          options={tabOptions}
          styles={selectStyles}
          onChange={(option) => setSelectedOrgId(option.value)}
        />

        </div> : (
        <Tabs leftAlign={true}>
          {_.map(organizations, org => (
            <Tab key={org.id}
                isSelected={(selectedOrgId === org.id)}
                onClick={() => setSelectedOrgId(org.id)}>
              {org.name}
            </Tab>
          ))}
        </Tabs>
      )}

      {loading ? (
        <Loader />
      ) : (
        <ul className={'ItemsList'}>
          {projects.map(project => {
            const projectUsers = _.filter(users, user => project?.user_ids?.includes(user.id));
            return (
              <li key={project.id}
                  onClick={() => onSelectProject(project)}
                  className={classNames('ItemsListItem')}>
                <div className={'ItemsListItem__name ItemsListItem__name--l ItemsListItem__33'}>
                  <ProjectIcon className={'ItemsListItem__name__icon'} /> {project.name}
                </div>
                <div className={'ItemsListItem__url ItemsListItem__33'}>
                  Created {dayjs(project.create_time).format('MMM D, YYYY')}
                </div>
                <div className={'ItemsListItem__users ItemsListItem__33'}>
                  <UserListRowItem users={projectUsers} />
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#202432',
    borderColor: 'transparent',
    borderRadius: '4px',
    color: '#8A8FB5',
    outline: 'none',
    borderRadius: '0.8rem',
    minHeight: 'none',
    height: '50px',
    boxShadow: 'none',
    ':hover': {
      borderColor: '#18C99D', // Add your hover styles here
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? '#202432' :
      state.isFocused ? '#1b2b32' : '#202432',
    color: state.isDisabled ? '#FFFFFF' :
      state.isFocused ? '#18C99D' :
        state.isSelected ? '#18C99D' : '#8A8FB5',
    fontFamily: 'Gilroy',
    paddingLeft: '10px',
    fontWeight: '500',
    fontSize: '14px',
    paddingTop: '11.5px',
    paddingBottom: '11.5px',
    height: 'auto',
    position: 'relative',
    cursor: state.isDisabled ? 'default' : 'pointer',
    ':hover': {
      backgroundColor: state.isDisabled ? '#202432' : '#1b2b32',
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    borderColor: state.isSelected ? '#18C99D' : 'transparent',
    color: '#8A8FB5',
    fontFamily: 'Gilroy',
    fontWeight: '500',
    fontSize: '14px',
    outline: 'none'
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none'
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#8A8FB5',
    marginRight: '0',
    marginLeft: '4px',
    ':hover': {
      color: '#8A8FB5', // Add your hover styles here
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: '#131722',
    // border: '1px solid #3D4463',
    borderRadius: '4px',
    scrollbarColor: '#3D4463',
    width: '100%',
    height: '4rem',
    zIndex: 30
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: '500px',
  }),
}
