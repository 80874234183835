import React from 'react';
import cx from 'classnames';
import Modal, {ModalActions, ModalContent, ModalHeader, ModalTitle} from "../components/Modal";
import {Button} from "../components/Button";
import {useSelector} from "react-redux";
import {selectCurrentOrgId} from "../store/models/Organization";
import {selectCurrentUserId} from "../store/models/User";
import {selectCurrentProjectId} from "../store/models/Project";
import {createSelector} from "reselect";

const selector = createSelector(
  selectCurrentOrgId,
  selectCurrentUserId,
  selectCurrentProjectId,
  (organizationId, currentUserId, projectId) => ({
    organizationId,
    currentUserId,
    projectId
  })
)

export const IncreaseQuotaModal = ({onRequestClose, orgId}) => {

  const {organizationId, currentUserId, projectId} = useSelector(state => selector(state));
  const onSendEmail = () => {
    window.open("mailto:support@thetaedgecloud.com" +
      "?subject=Increase%20Quota%20Request" +
      "&body=" +
      "%0A%0A%0A" +
      "User%20ID:%20" + currentUserId +
      "%0A" +
      "Organization%20ID:%20" + orgId +
      "%0A%0A");
  }

  return (
    <Modal className={cx("IncreaseQuotaModal s")}
           onRequestClose={onRequestClose}>

      <ModalHeader>
        <ModalTitle>Contact us</ModalTitle>
      </ModalHeader>

      <ModalContent>

        <div className={"short-content"}>In order to increase your quota, send us an email.</div>

        <ModalActions>
          <Button color={"green"} onClick={onSendEmail} title={"Contact support"}/>
          <Button color={"transparent"} onClick={onRequestClose} title={"Close"}/>
        </ModalActions>

      </ModalContent>

    </Modal>);
}