import React from 'react';
import _ from 'lodash';
import cx from 'classnames';


// import {didPropChange} from 'utils/props';
import Scrollbars from "react-custom-scrollbars-2";


// ===========================
// PARTIALS
// ===========================
export const VerticalScrolbar = p => <div className={cx('scrollbar', 'vertical')} {...p} />;

// SAFE CENTER - fixes issue with overflow containers and centering content, use only if you want the conetnt to be centered
export const ScrollbarContainer = React.forwardRef(({children, safeCenter,...props}, ref) => (
  <div 
    ref={ref} 
    className={cx("g-scrollbar-container", { 'safe-center': safeCenter })} 
    {...props}>
    { safeCenter 
      ? <div className="safe-center-container">
          {children}
        </div>
      : children}
  </div>));


// ===========================
// COMPONENT
// ===========================
class CustomScrollbars extends React.Component {

  componentDidMount() {
    this.refs.scrollbars.scrollTop = this.refs.scrollbars.scrollHeight;
  }

  componentDidUpdate(prev) {
    // if (didPropChange(prev, this.props, 'children')) {
    //   let {stickToBottom} = this.props;
    //   if (stickToBottom) {
    //     const {scrollTop, scrollHeight, clientHeight} = this.refs.scrollbars.getValues();
    //     let previousStateEmpty = prev.children.props.children.length === 0
    //     let isBottomPosition = Math.round(scrollTop + clientHeight) >= scrollHeight - 150
    //
    //     if (previousStateEmpty || isBottomPosition) {
    //       let s = this.refs.scrollbars;
    //       s.container.firstChild.scrollTop = s.getScrollHeight();
    //     }
    //   }
    // }
  }

  render() {
    let {className, stickToBottom, safeCenter, ...props} = this.props;
    return (
      <Scrollbars
        ref="scrollbars"
        className={cx("g-scrollbars", className)}
        renderTrackVertical={VerticalScrolbar}
        renderView={(props) => <ScrollbarContainer safeCenter={safeCenter} {...props}/>}
        autoHide
        autoHideTimeout={1000}

        {...props} />);
  }
}

export default CustomScrollbars;