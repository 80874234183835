import { Urls } from "../../constants"

export const AiShowcasesTermsPage = () => {

    return (<div className="AiShowcasesTermsPage">
        <h1>THETA EDGECLOUD - TERMS OF USE</h1>
        <div className="lastUpdated">Last Updated: 09/04/24</div>
        <p>The user-facing dashboard located at thetaedgecloud.com (“Theta EdgeCloud”, or the “Site”) is owned and operated by Theta Labs, Inc. (&quot;Theta”, &quot;we&quot;, “our” or &quot;us&quot;) and is being made available to you subject to these terms of use (these “Terms”). Certain features of the Site may be subject to additional guidelines, terms or rules, which will be posted on the Site in connection with such features. All such additional terms, guidelines and rules are incorporated by reference into these Terms. These Terms contain very important information regarding your rights and obligations, as well as conditions, limitations and exclusions that might apply to you. Please read them carefully.</p>
        <p>The Site provides users and creators (“Users” or “you”) with the opportunity to upload and generate content, including images, models, videos, and 3D renderings (“Generated Content”) through various third-party generative AI models and software of your choosing (“Third Party AI Models”) (“AI Showcase”). Before you visit the Site or use the AI Showcase (collectively, the “Service”), you must agree to these Terms and any terms and conditions incorporated herein by reference. THESE TERMS SET FORTH THE LEGALLY BINDING TERMS AND CONDITIONS THAT GOVERN YOUR USE OF THE SERVICE. BY ACCESSING OR USING THE SERVICE, YOU AGREE THAT YOU HAVE READ, UNDERSTOOD AND ACCEPT THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT). <strong>YOU MAY NOT ACCESS OR USE THE SERVICE OR ACCEPT THE TERMS IF YOU ARE NOT AT LEAST 18 YEARS OLD.</strong> IF YOU DO NOT AGREE WITH ALL OF THE PROVISIONS OF THESE TERMS, DO NOT ACCESS AND/OR USE THE SERVICE.</p>
        <p>Any changes to these Terms will be in effect as of the “Last Updated” date referred to at the top of this page. You should review these Terms before visiting the Site or using the AI Showcase, or purchasing any product or using any services that are available through this Site. Your continued visitation to the Site or use of the AI Showcase or Generated Content (as defined below) after the “Last Updated” date will constitute your acceptance of and agreement to such changes.</p>
        <p><strong>ANY USE OF OUTSIDE OF THE AI SHOWCASE OR SERVICE WILL BE ENTIRELY AT YOUR RISK. WE DO NOT CONTROL OR ENDORSE THE USE OF GENERATED CONTENT OUTSIDE OF THE AI SHOWCASE. BY VISITING THE SITE OR USING THE APP AI SHOWCASE OR ANY PART OF THEM, OR BY CLICKING “I ACCEPT” BELOW OR INDICATING YOUR ACCEPTANCE IN AN ADJOINING BOX, YOU ARE CONFIRMING THAT YOU UNDERSTAND AND AGREE TO BE BOUND BY ALL OF THESE TERMS, INCLUDING THE ARBITRATION PROVISION.</strong></p>
        <h2>1. ELIGIBILITY</h2>
        <p>The AI Showcase is not intended for children under 18. You must be at least 18 years old to use the Service. By using the Service, you represent and warrant to Theta that: (a) you are at least 18 years old; (b) you have the right, authority and capacity to enter into these Terms (on behalf of yourself or the entity that you represent); (c) you are not prohibited from accessing or using the site or the Service or any of the content, merchandise, products or services available in connection with the Service; and (d) your registration and your use of the Service is in compliance with any and all applicable laws and regulations. </p>
        <p>We do not knowingly collect information from or direct any of our content specifically to children under the age of 18.  If we learn or have reason to suspect that you are a user who is under the age of 18, we will unfortunately have to close your account or revoke your access.  Other countries may have different minimum age limits, and if you are below the minimum age for providing consent for data collection in your country, you may not use the Service.</p>
        <h2>2. ACCOUNTS</h2>
        <p>2.1 <em>Account Creation</em>. In order to use certain features of the Site, you may be asked to register for an account (&quot;Account&quot;) and provide certain information about yourself as prompted by the account registration form. <strong>You may not directly or indirectly register (or attempt to register) for more than one account to use the Site, whether using the same or a different name or other identifier, without our prior written consent</strong>. You represent and warrant that: (i) all required registration information you submit is truthful and accurate; (ii) you will maintain the accuracy of such information; (iii) you have not previously registered or attempted to register for an account on the Site using the same or a different name or other identifier; and (iv) you have not previously been suspended or removed from the Site. You may submit a customer service request to disable your Account at any time, for any reason, by following the instructions on the Site. <strong>Theta may suspend or terminate your Account at any time in accordance with Section 5, including for violation of this Section 2.1.</strong></p>
        <p>2.2  <em>Account Responsibility</em>. You are responsible for maintaining the confidentiality of your Account login information and are fully responsible for all activities that occur under your Account. You agree to immediately notify Theta of any unauthorized use, or suspected unauthorized use, of your Account or any other breach of security. Theta cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements. If you become aware of any unauthorized use of your password or of your account with us, you agree to notify us immediately at vip_support@thetalabs.org.</p>
        <p>2.3  <em>Account Configuration</em>. To most easily use the Service, you should first install a web browser (such as the Google Chrome web browser).  </p>
        <h2>3.  FEES AND PAYMENTS</h2>
        <p>3.1  To the extent that any features or access to the Services are subject to subscription or usage-based fees (“Fees”), you will be required to pay the applicable fees prior to being able to use or access such Services. You may pay the Fees using your credit card or other authorized payment method then available on the Services. By submitting your payment information to us, you authorize us to charge your credit card or other payment method then available in accordance with these Terms. You represent that you will not use any credit card or other form of payment unless you have all necessary authorization to do so. We will not be liable in the event that another person acting without your permission uses your credit card or other means of payment to pay for any Services; however, you may report any unauthorized use to us, and we will use reasonable measures in our control to prevent future unauthorized use of your credit card or other payment method.</p>
        <h2>4. OWNERSHIP, LICENSE, AND USE RESTRICTIONS</h2>
        <p><strong>You acknowledge and agree that Theta (or, as applicable, its licensors) owns all legal right, title and interest in and to the Site and the AI Showcase, and all intellectual property rights therein. You further acknowledge and agree that Theta is not the creator of the Generated Content created through the AI Showcase. The rights that you have in and to the Site, the AI Showcase and the User Content are limited to those described in this Section. Theta (or, as applicable, our partners and Users) reserves all rights in and to the Site, the AI Showcase and the User Content not expressly granted to you in this Section.</strong></p>
        <p>4.1  <em>Use of the Site</em>. All use of the Site and the AI Showcase must be lawful, and you agree to notify us if you learn of any misuse. Subject to your continued compliance with these Terms, the Theta grants you a personal, non-exclusive, revocable, non-transferable, limited license to use the Site and the AI Showcase solely as permitted by these Terms.</p>
        <p>By visiting the Site or using the AI Showcase, you agree not to:</p>
        <p>1) License, sell, transfer, assign, distribute, host or otherwise commercially exploit the Site or the AI Showcase.<br />2) Modify, prepare derivative works of, disassemble, decompile or reverse engineer any part of the Site or the AI Showcase and any content made available through the Site or the AI Showcase.<br />3) Access the Site or the AI Showcase in order to build a similar or competitive website, product, application or service without our express prior written consent.<br />4) Attempt to gain unauthorized access to another user’s Account, another user’s activities on the AI Showcase without permission, the hardware or software a user uses in connection with the Site, or to the AI Showcase itself.<br />5) Upload, transmit or otherwise distribute to or through the AI Showcase any computer viruses, worms or other software intended to harm or interfere with the intended operation of any computer system or data.<br />6) Use the Site or the AI Showcase in any manner that could interfere with, disrupt, negatively affect or prevent other users of the Site or the AI Showcase from using the Site or the AI Showcase or that could damage, disable, overburden or impair the functionality of the Site or the AI Showcase in any manner.<br />7) Intentionally interfere with or negate any content available on or through the Site or the AI Showcase.<br />8) Use any scrapers, crawlers, bots or other automated program to access, query or search the Site or the AI Showcase.<br />9) Attempt to circumvent any access restrictions, content-related limitations or security procedures that we use.<br />10) Use the Site or the AI Showcase to violate any law or infringe our rights, or the rights of any other person or entity.</p>
        <p>4.2  <em>User Content</em>. The AI Showcase and other features of the Site may allow users to submit,  upload, publish, share, or otherwise transmit (“Post”) content to the Service, including photo, text, data, video, or audio (including sound or voice or musical recordings embodied in a video or audio clip) and any other works of authorship or other works (“User Content”). You retain all rights, including copyright or other proprietary rights, that you may hold in any User Content that you Post to the Service, subject to the licenses granted herein. </p>
        <p>4.3  <em>User Content Representations and Warranties</em>. By agreeing to these Terms, you agree to comply with all applicable federal, state, and local laws and regulations concerning your use of the Service. You also represent and warrant that you are the owner, or that you have authorization to grant rights in all elements of any User Content that you Post to the Service. Theta hereby disclaims any and all liability in connection with User Content. You understand that you are solely responsible for your activities while using the Service, including any User Content and any consequences of Posting your User Content, and all license terms related to User Content. You represent and warrant that:</p>
        <p>1) you are the owner of the User Content, or have the necessary rights, licenses, consents and permissions required to Post or otherwise use the User Content in any manner contemplated by the Service, these Terms and applicable law;<br />2) your User Content, and use or Posting of your User Content as allowed by these Terms does not and will not: (i) infringe, misappropriate, violate or otherwise breach any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity or other proprietary or contract right; (ii) slander, defame, libel or invade the right of privacy, publicity, or other proprietary rights of any other person or third party; (iii) promote illegal or violent activities, including, but not limited to: child abuse and exploitation, rape and domestic abuse, illegal substances or product sales requiring sales/permits (e.g., pharmaceuticals, narcotics, firearms), fraud or deception, brutal fights, graphic injuries, or human death, or graphic violence or bestiality; (iv) depict or intend to depict real individuals or minors in a mature or sexual context; (v) depict or intend to depict photorealistic minors, regardless of context; (vi) depict “Loli”, “lilicon”, “shota”, or “shotacon”; (vii) depict the excrement of “scat” or fecal matter; (vii) be falsely labeled as having been created or endorsed by another creator, or impersonate another creator on the Service; or (viii) cause Theta to violate any law or regulation or require Theta to obtain any further licenses from or pay any royalties, fees or compensation or other amounts or provide any attribution to any third parties; and<br />3) your User content could not be deemed by a reasonable person to be objectionable, profane, indecent, harassing, threatening, embarrassing, hateful or otherwise inappropriate. </p>
        <p>4.4   <em>Third-Party Services and Licenses</em>. Our Service may include third party software, products, output, or services (“Third Party Services”) and some parts of our Services, like our browse feature, may include output from those services (“Third Party Output”). Third Party Services and Third Party Output are subject to their own terms (“Third Party Licenses”), and we are not responsible for them. You agree to comply with these Third Party Licenses to the extent required to use our Service. For more information and to access the Terms of the applicable Third Party Licenses, please refer to the links that are displayed in the description of each Third Party AI Model or other applicable Third Party Services we make available on the Site:</p>
        <p>4.5  <em>Limited Rights in Generated Content</em>. As between you and Theta, and to the extent permitted by applicable law, you own the Generated Content. We hereby assign to you all our right, title, and interest, if any, in and to Generated Content, subject to any rights to the Generated Content that may be retained by the Third Party Services as set forth in the Third Party Licenses. You acknowledge that due to the nature of our Services and artificial intelligence generally, outputs may not be unique and other users may receive similar output from our Services. Our Assignment does not extend to other users’ or third parties’ output. </p>
        <p>4.6  <em>Restrictions on Generated Content</em>.  You agree that you may not, nor permit any third party to do or attempt to do any of the following without Theta’s express prior written consent in each case: (i) use the Generated Content in connection with images, videos or other forms of media that depict hatred, intolerance, violence, cruelty or anything else that could reasonably be found to constitute hate speech or otherwise infringe upon the rights of others; (ii) use the Generated Content for an illegal purpose or in connection with any illegal activity; or (iii) represent that the Generated Content was human generated when it was not. To the extent that any Generated Content contains the intellectual property rights of any third party (e.g., licensed intellectual property from a celebrity, athlete or other public figure) (“Third-Party IP”), you acknowledge and agree as follows: (x) that you will not have the right to use such Third Party IP in any way except as incorporated in the Generated Content, and subject to the restrictions contained herein; (y) that, depending on the nature of the license granted from the owner of the Third Party IP, Theta may need to pass through additional restrictions on your ability to use the Generated Content; and (z) to the extent that Theta informs you of such additional restrictions in writing (email is permissible), you will be responsible for complying with all such restrictions from the date that you receive the notice, and that failure to do so will be deemed a breach of these Terms. The restrictions herein will survive the expiration or termination of these Terms.  </p>
        <p>4.7  <em>Theta’s Rights</em>.  Theta reserves the right (but not the obligation) in our sole discretion to (1) monitor the Service for violations of these Terms; (2) take appropriate legal action against anyone who uses or accesses the Service in a manner that we believe violates the law or these Terms, including without limitation, reporting such user to law enforcement authorities; (3) deny access to the Service or any features of the Service to anyone who violates these Terms or who we believe interferes with the ability of others to enjoy our Service or infringes the rights of others; and (4) otherwise manage the Service in a manner designed to protect our rights and property and to facilitate the proper functioning of the Site.</p>
        <h2>5. TERMINATION</h2>
        <p>5.1 <em>Termination by User</em>. You may terminate these Terms at any time by submitting a request to disable your Account and/or discontinuing your access to and use of the Service. <strong>If you disable your Account, or otherwise terminate these Terms, you will not receive any refunds for any purchases that you might make on or through the Service.</strong></p>
        <p>5.2 <em>Termination by Theta</em>. You agree that we, in our sole discretion and for any or no reason, may terminate these Terms and suspend and/or terminate your Account and/or revoke your access to the Service without the provision of prior notice. You agree that any suspension or termination of your access to the Service may be without prior notice, and that we will not be liable to you or to any third party for any such suspension or termination.</p>
        <p>5.3  <em>Other Remedies Available</em>. Our right to suspend your access to the Service or terminate your Account or these Terms will be in addition to any other remedies we may have at law or in equity. <strong>Once your Account is suspended under either 5.1 or 5.2 above, you will not receive any refunds for any purchases that you might make on or through the Service.</strong></p>
        <p>5.4  <em>Referral to Governmental Authority</em>. We have the right, without provision of prior notice, to take appropriate legal action, including, without limitation, referral to law enforcement or regulatory authority, or notifying the harmed party of any illegal or unauthorized use of the Site or the Service. Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone using the Site or the Service.</p>
        <p>5.5 <em>Effect of Termination</em>. Upon any termination or expiration of these Terms, whether by you or us, you may no longer have access to the Site, the AI Showcase or your Account, and you acknowledge that we will have no obligation to maintain any information related to your use of the Site, the AI Showcase or your Account in our databases or to forward any such information to you or to any third party. Sections 3 through 16 will survive the termination or expiration of these Terms for any reason.</p>
        <p><strong>YOU WAIVE AND HOLD US AND OUR PARENT, SUBSIDIARIES, AFFILIATES AND OUR AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, ASSIGNEES AND SUCCESSORS HARMLESS FROM ANY AND ALL CLAIMS RESULTING FROM ANY ACTION TAKEN BY US AND ANY OF THE FOREGOING PARTIES RELATING TO ANY INVESTIGATIONS BY EITHER US OR SUCH PARTIES OR BY LAW ENFORCEMENT AUTHORITIES.</strong></p>
        <h2>6. DISCLAIMERS</h2>
        <p>We reserve the right to modify, suspend or discontinue the Site or the AI Showcase at any time, with or without notice to you. We will not be liable if, for any reason, all or any part of the Site or the AI Showcase is unavailable for any period. From time to time, we may restrict access to or use of some or all of the Site or the AI Showcase to users generally or to you individually, for any or no reason.</p>
        <p>THE SITE, THE AI SHOWCASE, AND GENERATED CONTENT ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OR REPRESENTATIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. Any warranties with respect to services associated with Generated Content will come from the provider of such  services.</p>
        <p>THETA MAKES NO WARRANTIES OR REPRESENTATION THAT THE SERVICE (OR ANY THIRD PARTY SERVICE INCORPORATED HEREIN) WILL (A) MEET YOUR REQUIREMENTS; (B) BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE BASIS; OR (C) BE ACCURATE, RELIABLE, COMPLETE, LEGAL, SECURE OR SAFE. FURTHER, THETA AND ITS LICENSORS MAKE NO WARRANTIES OR REPRESENTATIONS WITH RESPECT TO THE GENERATED CONTENT, INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS ARISING FROM: (I) SERVER FAILURE OR DATA LOSS; OR (V) ANY UNAUTHORIZED THIRD PARTY ACTIVITIES, INCLUDING WITHOUT LIMITATION THE USE OF VIRUSES, PHISHING, BRUTEFORCING OR OTHER MEANS OF ATTACK AGAINST THE SITE OR GENERATED CONTENT.  FOR THE AVOIDANCE OF DOUBT, THETA WILL NOT BE LIABLE FOR ANY LOSS OF ANY KIND ARISING FROM OR RELATING TO ANY ACTION TAKEN BY YOU IN CONNECTION WITH THE SERVICE OR GENERATED CONTENT.</p>
        <p>WHILE THETA ATTEMPTS TO MAKE YOUR ACCESS TO AND USE OF THE SITE AND THE AI SHOWCASE SECURE, THETA CANNOT AND DOES NOT REPRESENT OR WARRANT THAT THE SITE, AI SHOWCASE OR ANY GENERATED CONTENT ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WE CANNOT GUARANTEE THE SECURITY OF ANY DATA THAT YOU DISCLOSE ONLINE. BY USING THE SITE OR THE SERVICE, YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING OVER THE INTERNET AND YOU AGREE THAT YOU WILL NOT HOLD US RESPONSIBLE FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR GROSS NEGLIGENCE.</p>
        <p>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
        <h2>7. LIMITATION OF LIABILITY</h2>
        <p><strong>YOU UNDERSTAND AND AGREE THAT WE, OUR PARENT, SUBSIDIARIES, AFFILIATES AND LICENSORS WILL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES WHICH YOU MAY INCUR, HOWSOEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION, ANY LOSS OF PROFITS (WHETHER INCURRED DIRECTLY OR INDIRECTLY), LOSS OF GOODWILL OR BUSINESS REPUTATION, LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, DIMINUTION OF VALUE OR ANY OTHER INTANGIBLE LOSS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</strong></p>
        <p><strong>YOU AGREE THAT OUR TOTAL, AGGREGATE LIABILITY TO YOU OR ANY THIRD PARTY FOR ANY AND ALL CLAIMS ARISING OUT OF OR RELATING TO THESE TERMS OR YOUR ACCESS TO OR USE OF (OR YOUR INABILITY TO ACCESS OR USE) ANY PORTION OF THE SITE OR THE SERVICE, WHETHER IN CONTRACT, TORT, STRICT LIABILITY OR ANY OTHER LEGAL THEORY, IS LIMITED TO ONE HUNDRED U.S. DOLLARS ($100).</strong></p>
        <p><strong>YOU ACKNOWLEDGE AND AGREE THAT WE HAVE MADE THE SITE, THE AI SHOWCASE AND THE GENERATED CONTENT AVAILABLE TO YOU AND ENTERED INTO THESE TERMS IN RELIANCE UPON THE REPRESENTATIONS AND WARRANTIES, DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN, WHICH REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN US AND YOU AND FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN US AND YOU. WE WOULD NOT BE ABLE TO PROVIDE THE SERVICE TO YOU WITHOUT THESE LIMITATIONS.</strong></p>
        <h2>8. ASSUMPTION OF RISK</h2>
        <p>Artificial intelligence and machine learning are rapidly evolving fields of study. Given the probabilistic nature of machine learning, use of our Services may, in some situations, result in Generated Content that does not accurately reflect real people, places, or facts. By using the Service and any Generated Content, you acknowledge and agree to the following terms:</p>
        <p>8.1 <em>Inherent Risks with Generated Content</em>. When you use our Service and create or use any Generated content, you understand and agree:</p>
        <p>1) Generated Content may not always be accurate. You should not rely on Generated Content from our Services as a sole source of truth or factual information, or as a substitute for professional advice.<br />2) You must evaluate Generated Content for accuracy and appropriateness for your use case, including using human review as appropriate, before using or sharing Generated Content from the Services.<br />3) You must not use any Generated Content relating to a person for any purpose that could have a legal or material impact on that person, such as making credit, educational, employment, housing, insurance, legal, medical, or other important decisions about them.<br />4) Our Services may provide incomplete, incorrect, or offensive Generated Content that does not represent Theta’s views. If Generated Content references any third party products or services, it doesn’t mean the third party endorses or is affiliated with Theta.</p>
        <p>YOU ACCEPT AND AGREE THAT ANY USE OF GENERATED CONTENT FROM OUR SERVICE IS AT YOUR SOLE RISK AND YOU WILL NOT RELY ON GENERATED CONTENT AS A SOLE SOURCE OF TRUTH OR FACTUAL INFORMATION.</p>
        <p>8.2  <em>Software Risks</em>. Upgrades to or the Service or Third Party Services may have unintended, adverse effects on all services available on the Theta EdgeCloud ecosystem.  Use of the Services may involve other technical risks, including, but not limited to, the risk of hardware, software and Internet connections, the risk of malicious software introduction, and the risk that third parties may obtain unauthorized access to information stored within our servers. You accept and acknowledge that we will not be responsible for any communication failures, disruptions, errors, distortions or delays you may experience when using the Service or Site, however caused.</p>
        <h2>9. INDEMNIFICATION</h2>
        <p>You agree to indemnify and hold Theta (and its affiliates and its and their officers, employees, and agents) harmless, including costs and attorneys’ fees, from any loss, claim or demand made by any third party due to or arising out of (a) your use of the Site, the AI Showcase, User Content, and your Generated Content, (b) your violation of these Terms, (c) your violation of any Third-Party Licenses, and (d) your violation of applicable laws or regulations. Theta reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate with our defense of these claims. You agree not to settle any matter without the prior written consent of Theta. Theta will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.</p>
        <h2>10. EXTERNAL SITES</h2>
        <p>The Site and the AI Showcase may include hyperlinks to other websites or resources (collectively, the “External Sites”), which are provided solely as a convenience to our users. We have no control over any External Sites. You acknowledge and agree that we are not responsible for the availability of any External Sites, and that we do not endorse any advertising, products or other materials on or made available from or through any External Sites. Furthermore, you acknowledge and agree that we are not liable for any loss or damage which may be incurred as a result of the availability or unavailability of the External Sites, or as a result of any reliance placed by you upon the completeness, accuracy or existence of any advertising, products or other materials on, or made available from, any External Sites.</p>
        <h2>11. FORCE MAJEURE</h2>
        <p><em>Force Majeure Events</em>. We will not be liable or responsible to the you, nor be deemed to have defaulted under or breached these Terms, for any failure or delay in fulfilling or performing our obligations under any of these Terms, when and to the extent such failure or delay is caused by or results from the following force majeure events (“Force Majeure Event(s)”): (a) acts of God; (b) flood, fire, earthquake, epidemics, pandemics, including the 2020 novel coronavirus pandemic (COVID-19), tsunami, explosion; (c) war, invasion, hostilities (whether war is declared or not), terrorist threats or acts, riot or other civil unrest; (d) government order, law, or action; (e) embargoes or blockades in effect on or after the date of this agreement; (f) strikes, labor stoppages or slowdowns or other industrial disturbances; (g) shortage of adequate or suitable Internet connectivity, telecommunication breakdown or shortage of adequate power or electricity; and (h) other similar events beyond our control).</p>
        <h2>12. CHANGES TO THE SITE AND SERVICE</h2>
        <p>We are constantly innovating the Site and the Service to help provide the best possible experience. You acknowledge and agree that the form and nature of the Site and the Service, and any part of them, may change from time to time without prior notice to you, and that we may add new features and change any part of the Site or the Service at any time with or without notice.</p>
        <h2>14. PRIVACY POLICY</h2>
        <p>Our <a href={Urls.MARKETING_AI_SHOWCASE_PRIVACY_POLICY} target="_blank">Privacy Policy</a> describes the ways we collect, use, store and disclose your personal information, and is hereby incorporated by this reference into these Terms. You agree to the collection, use, storage and disclosure of your personal information in accordance with our <a href="https://www.google.com/url?q=https://www.thetadrop.com/privacy\&amp;sa=D\&amp;source=editors\&amp;ust=1645137743699410\&amp;usg=AOvVaw25KaFnnn3HqACWPA2q16Ot">Privacy Policy</a>.</p>
        <h2>15. DISPUTE RESOLUTION; BINDING ARBITRATION</h2>
        <p><strong>YOU ARE AGREEING TO FORSAKE ANY RIGHTS TO LITIGATE CLAIMS IN A COURT. OTHER RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT MAY ALSO BE UNAVAILABLE OR MAY BE LIMITED IN ARBITRATION.</strong></p>
        <p><strong>YOU HEREBY EXPRESSLY FORSAKE YOUR RIGHT TO HAVE A TRIAL BY JURY.</strong></p>
        <p><strong>YOU HEREBY EXPRESSLY FORSAKE YOUR RIGHT TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS IN ANY LAWSUIT, INCLUDING, BUT NOT LIMITED TO, CLASS ACTION LAWSUITS INVOLVING ANY SUCH DISPUTE.</strong></p>
        <p>15.1  <em>Binding Arbitration</em>.  You agree to arbitrate any dispute arising from these Terms or your use of the Service or Generated Content, except that you and Theta are not required to arbitrate any dispute in which either party seeks equitable and other relief for the alleged unlawful use of copyrights, trademarks, trade names, logos, trade secrets, patents or other intellectual property. <strong>ARBITRATION PREVENTS YOU FROM SUING IN COURT OR FROM HAVING A JURY TRIAL</strong>. Each party agrees that it will notify the other in writing of any dispute within thirty (30) days of when it arises. Notice to Theta shall be sent to: Theta Labs, Inc., Attn: Legal, 2910 Stevens Creek Blvd., Ste. 200, San Jose, CA 95128. Each party further agrees: to attempt informal resolution prior to any demand for arbitration; that any arbitration will be conducted confidentially by a single arbitrator in accordance with the rules of JAMS; and that the state or federal courts in Santa Clara County, California have exclusive jurisdiction over any appeals of an arbitration award and over any suit between the parties not subject to arbitration. Other than class procedures and remedies discussed below, the arbitrator has the authority to grant any remedy that would otherwise be available in court. Any dispute between the parties will be governed by this Agreement and the laws of the State of California and applicable United States law, without giving effect to any conflict of laws principles that may provide for the application of the law of another jurisdiction. Whether the dispute is heard in arbitration or in court, neither party will commence against the other a class action, class arbitration or other representative action or proceeding.</p>
        <p>15.2  <em>Jurisdiction and Residence</em>. If you are a resident in any jurisdiction in which the provision in the section above is found to be unenforceable, then any disputes, claims or causes of action arising out of or in connection with these Terms will be governed by and construed under the laws of your jurisdiction of residence, and shall be resolved by competent civil courts within your jurisdiction of residence.</p>
        <p>15.3  <em>Arbitration Fees</em>. Each party will cover its own fees and costs associated with the arbitration proceedings; however, if the arbitrator finds that you cannot afford to pay the fees and costs reasonably associated with the arbitration proceedings, we will pay them for you.</p>
        <p>15.4  <em>Award Enforcement</em>. The award of the arbitrator will be final and binding, and any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.</p>
        <p>15.5  <em>Our Equitable Remedies</em>. Notwithstanding the foregoing, we may seek and obtain injunctive relief in any jurisdiction in any court of competent jurisdiction, and you agree that these Terms are specifically enforceable by us through injunctive relief and other equitable remedies without proof of monetary damages.</p>
        <h2>16. GENERAL</h2>
        <p>16.1  <em>Entire Agreement</em>. These Terms and our <a href={Urls.MARKETING_AI_SHOWCASE_PRIVACY_POLICY} target="_blank">Privacy Policy</a> constitute the entire legal agreement between you and us and will be deemed to be the final and integrated agreement between you and us, and govern your access to and use of the Service, and completely replace any prior or contemporaneous agreements between you and us related to your access to or use of the Service, whether oral or written.</p>
        <p>16.2  <em>No Third-Party Beneficiaries</em>. These Terms do not and are not intended to confer any rights or remedies upon any person or entity other than you.</p>
        <p>16.3  <em>Interpretation</em>. The language in these Terms will be interpreted as to its fair meaning, and not strictly for or against any party.</p>
        <p>16.4  <em>Severability</em>. Should any part of these Terms be held invalid, illegal, void or unenforceable, that portion will be deemed severed from these Terms and will not affect the validity or enforceability of the remaining provisions of these Terms.</p>
        <p>16.5  <em>No Waivers</em>. Our failure or delay to exercise or enforce any right or provision of these Terms will not constitute or be deemed a waiver of future exercise or enforcement of such right or provision. The waiver of any right or provision of these Terms will be effective only if in writing and signed for and on behalf of us by a duly authorized representative.</p>
        <p>16.6  <em>Governing Law</em>. All matters arising out of or relating to these Terms will be governed by and construed in accordance with the laws of the state of California and the federal laws of the United States applicable therein without giving effect to any choice or conflict of law provision or rule (whether of the state of California or any other jurisdiction).</p>
        <p>16.7  <em>Venue</em>. Subject to Section 16 of these Terms, any legal action or proceeding arising under these Terms will be brought exclusively in the state courts located in Santa Clara County and we and you irrevocably consent and submit to the personal jurisdiction and venue there.</p>
        <p>16.8  <em>Notices</em>. We may provide you with any notices (including, without limitation those regarding changes to these Terms) by email or postings on the Service. By providing us with your email address, you consent to our using the email address to send you any notices. Notices sent by email will be effective when we send the email, and notices we provide by posting will be effective upon posting. It is your responsibility to keep your email address current.</p>
        <p>16.9  <em>Assignment</em>. You may not assign any of your rights or obligations under these Terms, whether by operation of law or otherwise, without our prior written consent. We may assign our rights and obligations under these Terms in our sole discretion to an affiliate, or in connection with an acquisition, sale or merger.</p>
        <p>***</p>

    </div>)
}
