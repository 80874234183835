import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { ServingIcon, EllipsisIcon } from "../../../components/Svg";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Urls } from "../../../constants";
import { mergeUrl } from "../../../utils/url";
import Ai, { selectAllDeployments, selectAllVMs } from '../../../store/models/Ai';
import { getHardwareDetail, getStatus, age } from '../../../utils';
import UIState from '../../../store/UIState';
import { ModalTypes } from '../../../constants';
import { ThetaIcon, CloudIcon, DataCenterIcon, DistributedNodeIcon } from '../../../components/Svg';
import { selectCurrentProjectId } from '../../../store/models/Project';

function GPUMarketingPage() {
  const dispatch = useDispatch();
  const projectId = useSelector(state => selectCurrentProjectId(state));

  const deployments = useSelector(state => selectAllDeployments(state, projectId));
  const vms = useSelector(state => selectAllVMs(state));
  console.log('deployments:', deployments)
  const [activePopup, setActivePopup] = useState(-1);

  useEffect(() => {

  }, []);

  return (<div className={'AiServicePage'}>
    <div className={'AiServicePage__header'}>
      <div className={'AiServicePage__header--title'}>GPU Marketplace</div>
    </div>
    <div className={'AiServicePage__content'}>
      <div className={'AiServicePage__cells'}>
        <div className={'AiServicePage__cell'}>
          <ThetaIcon />
          <div className={'AiServicePage__cell--text'}>Theta Edge Nodes</div>
        </div>
        <div className={'AiServicePage__cell'}>
          <CloudIcon />
          <div className={'AiServicePage__cell--text'}>Cloud Partners</div>
        </div>
        <div className={'AiServicePage__cell'}>
          <DataCenterIcon />
          <div className={'AiServicePage__cell--text'}>Data Center Partners</div>
        </div>
        <div className={'AiServicePage__cell'}>
          <DistributedNodeIcon />
          <div className={'AiServicePage__cell--text'}>Distributed Node Partners</div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default GPUMarketingPage;
