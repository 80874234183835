import _ from 'lodash';
import { authHeaders, del, get, post, put } from "../../utils/fetch";
import { Hosts, RequestState } from "../../constants";

// ===========================
// ACTIONS
// ===========================
export const FETCH_WALLET_MAPPING = 'FETCH_WALLET_MAPPING';
export const FETCH_TFUEL_PRICE = 'FETCH_TFUEL_PRICE';

// ===========================
// SELECTORS
// ===========================
export const selectWalletMapping = (state, orgId) => state.models.walletMappings?.[orgId];
export const selectTfuelPrice = (state) => state.models.tfuelPrice;

// ===========================
// MODEL
// ===========================
const Wallet = {
  actions: {
    fetchWalletMapping: (orgId) => async (dispatch, getState) => {
      let result = await get({
        host: Hosts.EDGE_CLOUD_API,
        url: `/wallet_mapping/:orgId`,
        params: { orgId },
        action: FETCH_WALLET_MAPPING,
        dispatch,
      });
      return _.get(result, 'body.wallet_mappings.0');
    },
    fetchTfuelPrice: () => async (dispatch, getState) => {
      let result = await get({
        host: Hosts.EDGE_CLOUD_API,
        url: '/tfuel_price',
        action: FETCH_TFUEL_PRICE,
        dispatch,
      });
    }
  },
  spec: {
    walletMappings: {},
    tfuelPrice: null,
  },
  modelReducer: (state, type, body, action) => {
    if (action.url && action.result !== RequestState.SUCCESS)
      return state;

    if (!_.isEmpty(body?.wallet_mappings)) {
      state = {
        ...state,
        walletMappings: {
          ...state.walletMappings,
          ..._.keyBy(body.wallet_mappings, 'org_id')
        },
      }
    }

    switch (type) {
      case FETCH_TFUEL_PRICE:
        state = {
          ...state,
          tfuelPrice: body,
        }
        break;
    }

    return state;
  }
}
export default Wallet;
