import React from 'react';

import Modal, {ModalActions, ModalContent, ModalHeader, ModalSubtitle, ModalTitle,} from "../components/Modal";
import {Button} from "../components/Button";
import User from "../store/models/User";
import {useDispatch} from "react-redux";

// ===========================
// COMPONENT
// ===========================

export const LogoutModal = ({onRequestClose}) => {

  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(User.actions.logout())
    onRequestClose();
  }

  const onCancelClick = () => {
    onRequestClose();
  }

  return (
    <Modal className="LogoutModal s" onRequestClose={onCancelClick}>
      <ModalHeader>
        <ModalTitle>Logout</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <div className={"short-content"}>Are you sure you want to logout?</div>


        <ModalActions>
          <Button color={"red"} role={'submit'} onClick={onLogout} title={"Logout"}/>
          <Button color={"transparent"} onClick={onCancelClick} title={"Cancel"}/>
        </ModalActions>
      </ModalContent>

    </Modal>
  );
}

export default LogoutModal;
