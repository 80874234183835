import React, { useState } from 'react';
import { FormProvider, useForm } from "react-hook-form";
import { BackButton, Button } from "../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import PageTitleBar from "../../../components/PageTitleBar";
import Webhook from '../../../store/models/Webhook';
import { selectCurrentUserId } from '../../../store/models/User';
import UIState from '../../../store/UIState';
import { ModalTypes } from '../../../constants';

function RegisterWebhookForm({ onSubmit, loading }) {
  const formMethods = useForm();
  const { register, handleSubmit, watch, formState: { errors } } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}
        className={'Form CreateVideoForm'}
      >

        <div className='CreateVideoForm__wh_title'>Webhook Name</div>
        <input type="text" placeholder="Enter webhook name" {...register("name", { required: true })} />

        <div className='CreateVideoForm__wh_title'>Endpoint URL</div>
        <input type="text" placeholder="Enter webhook endpoint URL" {...register("url", { required: true })} />

        <Button title={'Register'}
          role={'submit'}
          loading={loading}
          style={{
            marginTop: 30,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      </form>
    </FormProvider>
  );
}

const RegisterWebhookPage = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(null);
  const [warning, setWarning] = useState(null);
  const [error, setError] = useState(null);
  const userId = useSelector(state => selectCurrentUserId(state));


  const onSubmit = async (data) => {
    if (_.isNil(userId)) {
      dispatch(UIState.actions.showModal(ModalTypes.SIGNUP));
      return;
    }

    let webhookData = data;
    webhookData.events = [];

    setLoading(true);
    setError(null);

    try {
      setState('Registering Webhook...');
      setWarning(null);

      const webhookReg = await dispatch(Webhook.actions.registerWebhook(webhookData));
    } catch (e) {
      setLoading(false);
      setState(null);
      setError(e.message || 'Ooops');
    }
  };

  return (
    <div className={'CreateVideoPage'}>
      <div className={'CreateVideoPage__content'}>
        <PageTitleBar title={'Register Webhook'}
          leftContent={<BackButton title={'Webhooks'}
            href={`/dashboard/video/webhooks`} />}
        />
        <div className={'CreateVideoPage__content--subtitle'}>
          Listen to Theta Video API events
        </div>
        <RegisterWebhookForm onSubmit={onSubmit}
          loading={loading}
        />
        {
          state &&
          <div className={'CreateVideoPage__state'}>
            {state}
          </div>
        }
        {
          warning &&
          <div className={'CreateVideoPage__warning'}>
            {warning}
          </div>
        }
        {
          error &&
          <div className={'CreateVideoPage__error'}>
            {error}
          </div>
        }
      </div>
    </div>
  );
}
export default RegisterWebhookPage;