import React from 'react';
import cx from 'classnames';

import Scrollbars from './Scrollbars'
import { CloseIcon } from "./Svg";

export const ModalHeader = ({ className, ...props }) => (
  <div className={cx("modal-header", className)} {...props}></div>
)

export const ModalContent = ({ className, ...props }) => (
  <div className={cx("modal-content", className)} {...props}></div>
)

export const ModalError = ({ className, ...props }) => (
  <div className={cx("modal-error", className)} {...props}></div>
)

export const ModalContentStretch = ({ className, ...props }) => (
  <div className={cx("modal-content stretch", className)} {...props}></div>
)

export const ModalContentFill = ({ className, ...props }) => (
  <div className={cx("modal-content fill-height", className)} {...props}></div>
)

export const ModalTitle = ({ className, align, ...props }) => (
  <div className={cx("modal-title", className, align)} {...props}></div>
)

export const ModalSubtitle = ({ className, ...props }) => (
  <div className={cx("modal-subtitle", className)} {...props}></div>
)

export const ModalSection = ({ className, align, size, label, children, ...props }) => (
  <div className={cx("modal-section", className, align, size)} {...props}>
    {label &&
      <span className="modal-section-label">{label}</span>}
    {children}
  </div>
)

export const ModalSectionTitle = ({ className, ...props }) => (
  <div className={cx("modal-section-title", className)} {...props}></div>
)

export const ModalSectionSubtitle = ({ className, ...props }) => (
  <div className={cx("modal-section-subtitle", className)} {...props}></div>
)

export const ModalActions = ({ className, direction = 'row', ...props }) => (
  <div className={cx("modal-actions", className, direction)} {...props}></div>
)

export const ModalActionHelper = ({ className, color, ...props }) => (
  <div className={cx("action-helper", className, color)} {...props}></div>
)



const Modal = ({ children, className, size, onRequestClose, onClick, disableClickToClose = false, ...props }) => (
  <Scrollbars className="modal-scrollbars" onClick={!disableClickToClose ? onRequestClose : null}>
    <div className={cx("modal", className, size)} onClick={e => onClick ? onClick() : e.stopPropagation()}>
      {children}

      {!disableClickToClose && <a className="modal-close-button" onClick={onRequestClose}>
        <CloseIcon />
      </a>}
    </div>
  </Scrollbars>
);
export default Modal;

export const ModalSmall = ({ ...props }) => <Modal {...props} size="s" />
export const ModalFull = ({ ...props }) => <Modal {...props} size="full" />


