import classNames from "classnames";
import React from "react";
import { TopUpList } from "../constants";

export function CreditSelection(props) {
  const { value, setValue, disabled, className, min = 0 } = props;
  const handleOnClick = (v) => {
    if (disabled || v < min) return;
    setValue(v);
  }
  return <div className={classNames("CreditSelection", { disabled: disabled }, className)}>
    {TopUpList.map((v, i) => {
      return <div className="CreditSelection__option-wrap" key={i}>
        <div onClick={() => handleOnClick(v)}
          className={classNames("CreditSelection__option", { selected: v === value && !disabled, disabled: v < min })}>
          ${v}
        </div>
      </div>
    })}
  </div >
}