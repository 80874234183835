import React from "react";
import { AiIcon, DocumentsIcon, RenderingIcon, SettingsIcon, VideoIcon } from "./Svg";
import { Link, useHistory } from "react-router-dom";
import Tooltip from "./Tooltip";
import cx from "classnames";
import { useLocation } from "react-router";
import { selectCurrentProjectId } from "../store/models/Project";
import { useDispatch, useSelector } from "react-redux";
import { mergeUrl } from "../utils/url";
import { ModalTypes, Urls, UserRoleIds as UserRoles } from "../constants";
import { createSelector } from "reselect";
import UIState from "../store/UIState";
import { selectBalance } from "../store/models/Bill";
import { selectCurrentOrgId } from "../store/models/Organization";
import { selectCurrentUser, selectCurrentUserId } from "../store/models/User";

const sideMenuSelector = createSelector(
  selectCurrentProjectId,
  selectCurrentOrgId,
  selectCurrentUser,
  selectCurrentUserId,
  (currentProjectId, orgId, user, userId) => (
    { currentProjectId, orgId, user, userId }
  )
)

export const AppSideMenu = () => {
  const dispatch = useDispatch();
  const location = useLocation()
  const { currentProjectId, orgId, user, userId } = useSelector(state => sideMenuSelector(state));
  const selected = location.pathname.toString().split('/')?.[2]
  const projectId = currentProjectId || 'service';
  const balance = useSelector(state => selectBalance(state, orgId));
  const userIsAdmin = user && user.role === UserRoles.ADMIN;
  const history = useHistory();


  const onOpenSelectProjectModal = () => {
    dispatch(UIState.actions.showModal(ModalTypes.SELECT_PROJECT));
  }

  const handleSettingClick = () => {
    if (_.isNil(userId)) {
      dispatch(UIState.actions.showModal(ModalTypes.SIGNUP));
    } else {
      history.push(Urls.DASHBOARD_SETTINGS)
    }
  }

  return (
    <div className={'AppSideMenu'}>
      <AppSideMenuItem to={mergeUrl(Urls.AI_MODEL_EXPLORER, { projectId })}
        name={'AI Services'}
        icon={<AiIcon />}
        onOpenSelectProjectModal={onOpenSelectProjectModal}
        currentProjectId={currentProjectId}
        userId={userId}
        selected={selected === ('ai')}
      />
      <AppSideMenuItem to={userId ? Urls.DASHBOARD_VIDEO : Urls.VIDEO_OVERVIEW}
        name={'Video Services'}
        icon={<VideoIcon />}
        onOpenSelectProjectModal={onOpenSelectProjectModal}
        currentProjectId={currentProjectId}
        userId={userId}
        selected={selected === ('video')}
      />
      <AppSideMenuItem to={mergeUrl(Urls.DASHBOARD_RENDERING, { projectId })}
        name={'Rendering/Gaming Services'}
        icon={<RenderingIcon />}
        onOpenSelectProjectModal={onOpenSelectProjectModal}
        currentProjectId={currentProjectId}
        userId={userId}
        selected={selected === ('rendering')}
      />

      <div className={'separator'} />

      <a href={'https://docs.thetatoken.org/docs/theta-edgecloud-overview'} target={"_blank"}>
        <AppSideMenuSubItemContent name={'Docs'}
          icon={<DocumentsIcon />}
          selected={selected === ('documents')}
        />
      </a>
      <Link to={mergeUrl(balance <= 0 && userIsAdmin ? Urls.SETTINGS_BILLING : Urls.DASHBOARD_SETTINGS)}>
        {!_.isNil(userId) && <AppSideMenuSubItemContent name={'Settings'}
          icon={<SettingsIcon />}
          selected={selected === ('settings')}
          onClick={handleSettingClick}
        />}
      </Link>
    </div>
  )
}

const AppSideMenuItem = ({ to, name, icon, selected, currentProjectId, onOpenSelectProjectModal, userId }) => {

  if (!currentProjectId && !_.isNil(userId)) return (
    <Tooltip tooltip={name} placement={'right'}>
      <div className={cx('AppSideMenu__Item', { active: selected })}
        onClick={onOpenSelectProjectModal}>
        <div className={cx("AppSideMenu__Item__icon-wrapper", { active: selected })}>{icon}</div>
      </div>
    </Tooltip>
  )

  return (
    <Link to={to}>
      <Tooltip tooltip={name} placement={'right'}>
        <div className={cx('AppSideMenu__Item', { active: selected })}>
          <div className={cx("AppSideMenu__Item__icon-wrapper", { active: selected })}>{icon}</div>
        </div>
      </Tooltip>
    </Link>
  )
}

const AppSideMenuSubItemContent = ({ name, icon, selected, onClick }) => {
  return (
    <Tooltip tooltip={name} placement={'right'}>
      <div className={cx('AppSideMenu__SubItem', { active: selected })} onClick={onClick}>
        <div className={cx("AppSideMenu__SubItem__icon-wrapper")}>{icon}</div>
      </div>
    </Tooltip>
  )
}