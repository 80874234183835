const CustomTooltip = (props) => {
  const { active, payload, label, legendName } = props;
  if (active && payload && payload.length) {
    const unit = getUnit(legendName)
    const isUsage = legendName === 'Usage';
    const isTokenUsage = legendName === 'Token Usage';
    const total = isUsage ? payload.reduce((s, c) => s += c.value, 0) : 0;
    return isUsage ? <div className="custom-tooltip">
      <p className="custom-tooltip__label">{`${label} | Total:$${Number(total).toFixed(2)}`}</p>
      {payload.map((p, i) => (
        <p key={i} className="custom-tooltip__value" style={{ color: p.color }}>{p.dataKey}: {isUsage ? '$' : ''}{p.value} {unit}</p>
      ))}
    </div> : isTokenUsage ? <div className="custom-tooltip">
      <p className="custom-tooltip__label">{`${label}`}</p>
      {payload.map((p, i) => (
        <p key={i} className="custom-tooltip__value" style={{ color: p.color }}>{p.dataKey}: {p.value} {unit}</p>
      ))}
    </div> : <div className="custom-tooltip">
      <p className="custom-tooltip__label">{`${label}`}</p>
      <p className="custom-tooltip__value">{legendName}: {isUsage ? '$' : ''}{payload[0].value} {unit}</p>
    </div>
  }

  return null;
};

const getUnit = (legendName) => {
  switch (legendName) {
    case 'URL Hits':
      return 'K hits';
    case 'URL Volume':
      return 'GB';
    case 'URL Viewers':
      return ''
    default:
      return '';
  }
}

export default CustomTooltip;