import {CloseIcon} from "../Svg";
import React, {useState} from "react";
import {toast} from "react-toastify";

export const ImageInput = ({children, onChange, value, name, onRemove, instructions, formats, ...props}) => {

  const [dragging, setDragging] = useState(false);

  const onImageSelected = (e) => {
    setDragging(false)
    onChange(e.target.files[0], e.target.name);
  }

  const onRemoveClick = () => {
    onRemove(name);
  }

  return (
    <div className={"ImageInput"}>
      {value ?
        <>
          <img className={"ImageInput__ImageUploaded"}
               src={value}/>
          <div className={"ImageInput__ImageUploaded__Close"} onClick={onRemoveClick}><CloseIcon/></div>
        </>
        : <>
          <div
            className={`ImageInput__Label ${dragging ? "ImageInput__Label--dragover" : ""}`}
            onClick={() => document.querySelector(`input[name="${name}"]`).click()}
            onDragOver={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDragEnter={() => setDragging(true)}
            onDragLeave={() => setDragging(false)}
            onDrop={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                const file = e.dataTransfer.files[0];
                if (formats && formats.indexOf(file.type) === -1
                  || file.type.indexOf("image/") === -1) {
                  toast.error("Invalid file type");
                } else {
                  onChange(file, name);
                }
              }
            }}
          >
            {instructions
              ? instructions
              : <>
              Drag & drop or <span
              className={"ImageInput__Label--Green"}>Choose a file</span> to upload
            </>}
          </div>
          <input type="file" name={name} accept={formats ? formats : "image/*"} onChange={onImageSelected}/></>}

      {children}
    </div>
  )
}