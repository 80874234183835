import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {selectCurrentOrgId} from "../../../store/models/Organization";
import React, {useEffect, useState} from "react";
import Loader from "../../../components/Loader";
import PageTitleBar from "../../../components/PageTitleBar";
import {Button} from "../../../components/Button";
import User, {getUserFullName, selectCurrentUser, selectUsersInOrg} from "../../../store/models/User";
import {InviteIcon} from "../../../components/Svg";
import {MemberItemsList} from "../../../components/MemberItem";
import {ModalTypes, UserRoleIds} from "../../../constants";
import UIState from "../../../store/UIState";
import {toast} from "react-toastify";

const selector = createSelector(
  selectCurrentOrgId,
  selectCurrentUser,
  (state) => selectUsersInOrg(state),
  (currentOrgId, currentUser, users) => {
    return {
      currentOrgId,
      currentUser,
      users,
      activeUsers: users.filter(u => !u.disabled),
      disabledUsers: users.filter(u => u.disabled)
    }
  }
)

export const TeamMembersPage = () => {

  const dispatch = useDispatch();
  const {currentOrgId, currentUser, users, activeUsers, disabledUsers} = useSelector(state => selector(state));

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const currentUserIsAdmin = currentUser?.role === UserRoleIds.ADMIN;

  useEffect(() => {
    fetchTeamMembers()
  }, []);

  const fetchTeamMembers = async () => {
    setLoading(true);
    setError(null);
    try {
      const orgsResponse = await dispatch(User.actions.fetchOrganizationUsers());
    } catch (e) {
      setError(e.message)
    }
    setLoading(false);
  }

  const onInviteUser = () => {
    dispatch(UIState.actions.showModal(ModalTypes.INVITE_USER))
  }

  const onChangeUserRole = async (user, role) => {
    try {
      await dispatch(User.actions.changeUserRoleInOrg(user.id, role))
      toast.success('Success! User role has been changed')
    } catch (e) {
      toast.error(`Error changing user role: ${e.message}`)
    }
  }

  // const onRemoveUser = (user) => {
  //   dispatch(UIState.actions.showModal(ModalTypes.CONFIRM, {
  //     title: `Confirm removing user`,
  //     message: `Do you want to remove ${getUserFullName(user)} from your organization?`,
  //     onConfirm: () => onConfirmRemoveUser(user)
  //   }))
  // }

  const onRemoveUser = async (user) => {
    try {
      if (user.disabled) {
        await dispatch(User.actions.enableUserInOrg(user.id))
        toast.success('User has been enabled')
      } else {
        await dispatch(User.actions.removeUserFromOrg(user.id))
        toast.success('User has been disabled')
      }
    } catch (e) {
      toast.error(`Error removing user: ${e.message}`)
    }
  }

  const inviteUserBtn = currentUserIsAdmin ? <Button
    title={"Invite"}
    onClick={onInviteUser}
    icon={<InviteIcon/>}
    color={"green-outline"}
    size={"medium"}
  /> : null;

  if (loading)
    return <Loader/>

  if (error)
    return (<div className={"ServicePage__Error"}>
      {error}
      <Button onClick={fetchTeamMembers}
              color={"green-outline"}
              title={"Retry"}/>
    </div>)

  return (<div className={"TeamMembersPage"}>
    <PageTitleBar alignTitleLeft={true} rightContent={inviteUserBtn} title={"Team members"}/>

    <div className={"TeamMembersPage__MembersCount"}>{`Members (${activeUsers.length})`}</div>

    <div className={"TeamMembers__List"}>
      <MemberItemsList users={activeUsers}
                       currentUser={currentUser}
                       onChangeRole={onChangeUserRole}
                       onRemove={onRemoveUser}
      />
    </div>

    {currentUserIsAdmin && disabledUsers.length > 0 && <>
    <div className={"TeamMembersPage__MembersCount"}>{`Disabled (${disabledUsers.length})`}</div>

    <div className={"TeamMembers__List"}>
      <MemberItemsList users={disabledUsers}
                       currentUser={currentUser}
                       onChangeRole={onChangeUserRole}
                       onRemove={onRemoveUser}
      />
    </div>
    </>}

  </div>)
}
