import _ from 'lodash';
import {loadStripe} from "@stripe/stripe-js";

import {get, post, videoServiceAuthHeaders} from "../../utils/fetch";
import {Hosts, RequestState, StripePublicKey} from "../../constants";
import {selectServiceAccountIDs} from "./DApp";
import {selectCurrentUserId} from "./User"
// import {FETCH_USER_TFUEL} from "./Wallet"; //TODO: get tfuel from blockchain


// ===========================
// HELPERS
// ===========================


// ===========================
// ACTIONS
// ===========================
const FETCH_DROP_SOURCE_TOP_UP = "FETCH_DROP_SOURCE_TOP_UP"

// ===========================
// SELECTORS
// ===========================
export const selectTopUpsByBillID = (state, billID) => _.filter(state.models?.topUps, topUp => topUp?.bill_id === billID);

export const selectTopUp = (state, topUpID) => {
  const topUp = state.models.topUps[topUpID];
  if (topUp) {
    return {
      ...topUp
    };
  }
  return null;
};

// ===========================
// MODEL
// ===========================
const TopUp = {
  actions: {
    createTopUpViaStripe: (quantity, billID) => async (dispatch, getState) => {
      let serviceAccountIds = selectServiceAccountIDs(getState());
      for (let serviceAccountId of serviceAccountIds) {
        try {
          let header = videoServiceAuthHeaders(getState(), serviceAccountId)
          let result = await post({
            host: Hosts.API,
            url: "/top_up",
            headers: header,
            // headers: authHeaders(),
            body: {
              source: 'stripe',
              quantity: quantity,
              redirect_url: `${window.location.origin}/dashboard`,
              userID: selectCurrentUserId(getState()),
              billID: billID,
            },
          });
          const topUp = result.body.top_ups[0];
          const {stripe_checkout_session_id} = topUp;
          await dispatch(TopUp.actions.openStripeCheckout(stripe_checkout_session_id))
          return result;
        } catch (err) {
          console.log(`failed to create stripe topup for bill ${billID}, error is ${err.message}`);
        }
      }
    },
    fetchTopUp: (topUpId) => async dispatch => {
      let result = await get({
        host: Hosts.API,
        url: `/top_up/${topUpId}`,
        headers: authHeaders(),
      });
      const topUp = result.body.top_ups[0];
      return topUp;
    },
    fetchDropSourceTopUp: (sourceId) => async dispatch =>
      get({
        url: '/top_up/list',
        params: {
          drop_source_id: sourceId,
        },
        headers: authHeaders(),
        dispatch,
        action: FETCH_DROP_SOURCE_TOP_UP,
      }),
    openStripeCheckout: (stripeCheckoutSessionId) => async dispatch => {
      // Ensure Stripe is loaded
      const stripePromise = loadStripe(StripePublicKey);
      const stripe = await stripePromise;
      const result = await stripe.redirectToCheckout({
        sessionId: stripeCheckoutSessionId,
      });
      console.log("stripe response:", result);
      return result;
    }
  },
  spec: {
    topUps: {},
  },
  modelReducer: (state, type, body, action) => {
    if (action.url && action.result !== RequestState.SUCCESS)
      return state;

    if (type === FETCH_DROP_SOURCE_TOP_UP) {
      state = {
        ...state,
        topUps: _.keyBy(body.top_ups, 'id'),
      }
    }

    return state;
  }
}

export default TopUp;
