import classNames from "classnames";
import { Link } from "react-router-dom";

export const AiTab = ({ href, isSelected, children, onClick }) => {
  return (
    <li className={classNames('AiTab', {
      'AiTab--selected': isSelected
    })} onClick={onClick}>
      {children}
    </li>
  )
}

export const AiTabs = ({ children }) => {
  return (
    <ul className={'AiTabs'}>
      {children}
    </ul>
  )
}
