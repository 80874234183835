export const Step = ({title, number}) => {
  return (
    <div className={'Step'}>
      <div className={'Step__number'}>
        {number}
      </div>
      <div className={'Step__title'}>
        {title}
      </div>
    </div>
  )
}
