import dayjs from "dayjs";

export const formatDuration = (date) => {
    const now = dayjs();
    const created = dayjs(date);
    const durationInSeconds = now.diff(created, 'second');
  
    if (durationInSeconds < 60) {
      return 'Less than a minute';
    } else if (durationInSeconds < 3600) {
      const minutes = Math.floor(durationInSeconds / 60);
      return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
    } else if (durationInSeconds < 86400) {
      const hours = Math.floor(durationInSeconds / 3600);
      return `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
    } else if (durationInSeconds < 2592000) {
      const days = Math.floor(durationInSeconds / 86400);
      return `${days} ${days === 1 ? 'day' : 'days'}`;
    } else if (durationInSeconds < 31536000) {
      const months = Math.floor(durationInSeconds / 2592000);
      return `${months} ${months === 1 ? 'month' : 'months'}`;
    } else {
      const years = Math.floor(durationInSeconds / 31536000);
      return `${years} ${years === 1 ? 'year' : 'years'}`;
    }
  };
  
  