import React, {useEffect, useState} from 'react';
import cx from 'classnames';
import Modal from "../components/Modal";
import Loader from "../components/Loader";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {AIShowcase} from "../store/models/AIShowcase";
import {createSelector} from "reselect";
import {selectMobileLayout} from "../store/UIState";
import {Button} from "../components/Button";
import {toast} from "react-toastify";
import Tooltip from "../components/Tooltip";
import {DownloadAltIcon, DownloadIcon, InfoIcon} from "../components/Svg";

const ImageItem = ({image, index}) => {
  const [showPrompt, setShowPrompt] = useState(false)
  const imageUrl = `https://user-assets.thetadrop.com/user_446i201m6304j1ts23d48cwuyxq/coll_nw0uccycbmswvb3f97ffdd4f7b1/${image.id}.png`
  let description = image.description.replace(/<[^>]*>?/gm, '');
  description = description.replace(/&nbsp;/g, ' ').replace(/\s\s+/g, ' ');

  const onShowPrompt = () => {
    setShowPrompt(!showPrompt)
  }

  const onCopyPrompt = () => {
    navigator.clipboard.writeText(image.params)
    toast.success("Copied to clipboard")
    setShowPrompt(false)
  }

  return (
    <>
    <div className={cx("ImageItem")}>
      <img src={imageUrl}/>

      <div className={cx("ImageItem__InfoWrapper")}>
        {showPrompt ? <Tooltip tooltip={"Copy"}><div className={"ImageItem__Params"} onClick={onCopyPrompt}>{image.params}</div></Tooltip>
        : <>
        <div className={"ImageItem__InfoWrapper__Title"}>{image.name}</div>
        <div className={"ImageItem__InfoWrapper__Author"}>by {image.creator}</div>
        <div className={"ImageItem__InfoWrapper__Description"}>{description}</div>
        </>}
        <div className={"ImageItem__InfoWrapper__Buttons"}>
          <Tooltip tooltip={`${showPrompt ? 'Hide' : 'Show'} prompt and parameters`}><div onClick={onShowPrompt}><InfoIcon/></div></Tooltip>
          <Tooltip tooltip={"Open image"}><div onClick={() => window.open(imageUrl, "_blank")}><DownloadAltIcon/></div></Tooltip>
          </div>
      </div>
    </div>

    </>
  )
}

const ImagesColumn = ({images, columnNumber}) => {
  return (
    <div className={cx("ImagesColumn")}>
      {images.map((image, index) => <ImageItem image={image} index={index + columnNumber} key={index}/>)}
    </div>
  )
}

const selector = createSelector(
  (state) => selectMobileLayout(state),
  (isMobile) => ({isMobile}
  ));

export const ShowcaseTextToImageDiscoverModal = ({onRequestClose}) => {

  const dispatch = useDispatch();
  const {isMobile} = useSelector(state => selector(state));
  const [rawData, setRawData] = useState(null)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    fetchData()
  }, []);

  useEffect(() => {
    if (rawData) formatData()
  }, [rawData, isMobile]);

  const formatData = () => {
    //distribute items from the rawData array in 4 sub arrays, filling arrays one item at a time
    const arraysCount = isMobile ? 2 : 4
    let data = []
    for (let i = 0; i < rawData.length; i++) {
      if (!data[i % arraysCount]) data[i % arraysCount] = []
      data[i % arraysCount].push(rawData[i])
    }
    setData(data)
  }

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      let response = await dispatch(AIShowcase.actions.fetchExploreTextToImage())
      setRawData(response)
    } catch (e) {
      setError(e.message)
    }
    setLoading(false);
  }

  return (
    <Modal className={cx("ShowcaseTextToImageDiscoverModal")}
           size={"xl"}
           onRequestClose={onRequestClose}>

      <div className={cx("DiscoverHeader")}>
        <div className={"DiscoverHeader__Title"}>Community Feed</div>
        <div className={"DiscoverHeader__Description"}>Explore what the community has created.</div>
      </div>

      {loading && <Loader/>}
      {error && <div>{error}</div>}
      {data && <div className={"ColumnsWrapper"}>
        {data.map((imagesColumn, index) => <ImagesColumn images={imagesColumn} columnNumber={index} key={index}/>)}
      </div>
      }
    </Modal>
  );
}