import React from 'react';
import cx from 'classnames';

const ELLIPSE = '&#8230;'

export const Pagination = props => {
  const { size = 'lg', maxButtons = 5, currentPage, totalPages, onPageChange, disabled } = props;
  const handlePrevious = () => {
    const previous = currentPage - 1;
    if (previous > 0) {
      handleOnPage(previous);
    }
  }

  const handleNext = () => {
    const next = currentPage + 1;
    if (next <= totalPages) {
      handleOnPage(next);
    }
  }

  const handleOnPage = (pageNumber) => {
    onPageChange(pageNumber);
  }

  const renderPaginationItems = (disabled) => {
    const pageButtons = [];
    const items = totalPages;
    const activePage = currentPage;
    let startPage;
    let endPage;

    if (maxButtons && maxButtons < items) {
      startPage = Math.max(
        Math.min(
          activePage - Math.floor(maxButtons / 2, 10),
          items - maxButtons + 1,
        ),
        1,
      );
      endPage = startPage + maxButtons - 1;
    } else {
      startPage = 1;
      endPage = items;
    }

    for (let page = startPage; page <= endPage; ++page) {
      pageButtons.push(
        <PaginationLink
          key={page}
          onClick={() => handleOnPage(page)}
          active={page === activePage}
          className="number"
          disabled={disabled}>
          {page}
        </PaginationLink>);
    }
    if (startPage > 1) {
      pageButtons.unshift(<Ellipse key="e0" />);
    }
    if (endPage < items) {
      pageButtons.push(<Ellipse key="e1" />);
    }

    return pageButtons;
  }

  return (
    <div className={cx('pagination', size, { disabled })}>
      <PaginationFirst onClick={() => handleOnPage(1)} disabled={disabled} className="icon first" />
      <PaginationPrev onClick={handlePrevious} disabled={disabled} className="icon prev" />
      {renderPaginationItems(disabled)}
      <PaginationNext onClick={handleNext} disabled={disabled} className="icon next" />
      <PaginationLast onClick={() => handleOnPage(totalPages)} disabled={disabled} className="icon last" />
    </div>);
}

const PaginationLink = props => {
  let { active, onClick, children, className, ...params } = props;
  return (
    <button
      className={cx("btn t", { active: active }, className)}
      onClick={onClick}
      {...params}>{children}</button>);
}
const PaginationFirst = props => <PaginationLink active={false} className="first" {...props}><i /></PaginationLink>
const PaginationPrev = props => <PaginationLink active={false} className="prev" {...props}><i /></PaginationLink>
const PaginationNext = props => <PaginationLink active={false} className="next" {...props}><i /></PaginationLink>
const PaginationLast = props => <PaginationLink active={false} className="last" {...props}><i /></PaginationLink>
const Ellipse = props => <div className="ellipse" {...props}>&#8230;</div>

