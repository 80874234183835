import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import { Urls, UserRoleIds } from '../constants';


import Scrollbars from '../components/Scrollbars';
import Overlay from '../components/MenuOverlay';

import {
  CloseIcon,
} from "./Svg";
import dayjs from "dayjs";
import { selectCurrentUser, selectCurrentUserId } from '../store/models/User';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { mergeUrl } from '../utils/url';
import { selectBalance } from '../store/models/Bill';
import { selectCurrentOrgId } from '../store/models/Organization';
import { selectCurrentProjectId } from '../store/models/Project';
import { OrgAndProjectDropdown } from './OrgAndProjectDropdowns';


const CURRENT_YEAR = dayjs().get('year')

export const SideMenuItem = ({ children, className, disabled, to, partnerTo, url, isCurrent, ...props }) => {
  const currentPath = window.location.pathname;
  const current = isCurrent || (to && currentPath === to);
  if (url)
    return <a href={url} className={cx("g-side-menu-item", className, { disabled })} {...props}>
      {children}
      {current && <div className='border' />}
    </a>
  return <Link className={cx("g-side-menu-item", className, { disabled, current })} to={to} {...props} >
    {children}
    {current && <div className='border' />}
  </Link>
}

export const SideMenu = ({ children, className, open, onClose, ...props }) => {
  let onOverlayClick = () => onClose()
  return (<>
    <div className={cx("g-side-menu", className, { open })} onClick={onOverlayClick}>
      <Scrollbars>
        {children}
      </Scrollbars>
    </div>
    {open &&
      <Overlay onClick={onOverlayClick} />}
  </>);
}
export default SideMenu;


const selector = createSelector(
  (state) => selectCurrentUserId(state),
  (state) => selectCurrentUser(state),
  (state) => selectCurrentProjectId(state),
  (state) => selectCurrentOrgId(state),
  (state) => selectBalance(state, selectCurrentOrgId(state)),
  (userId, user, projectId, currentOrgId, balance) => ({ userId, user, projectId, currentOrgId, balance })
);

// ===========================
// TYPES
// ===========================

export const MobileSideMenu = (props) => {
  const { userId, user, projectId, currentOrgId, balance } = useSelector((state) => selector(state))
  const userIsAdmin = user && user.role === UserRoleIds.ADMIN;
  const currentPath = window.location.pathname;

  return (
    <SideMenu className="partner" open={false}{...props}>
      <div className="sidemenu-wrap">
        <div className="sidemenu-items">
          <div className="sidemenu-header">
            <div>
              <CloseIcon color="sub" className="close-icon" />
            </div>

            <div>
              <img src={"/images/edgecloud-logo-mobile.svg"}
                alt={'theta-video-api-logo'}
                className={'Navbar__logo Navbar__logo--mobile'}
              />
            </div>

          </div>

          {currentOrgId && !_.isNil(userId) &&
          <OrgAndProjectDropdown />}

          <SideMenuItem to={Urls.HOME}>Home</SideMenuItem>
          <SideMenuItem to={mergeUrl(Urls.AI_MODEL_EXPLORER, { projectId })} isCurrent={currentPath.includes('/ai/')}>AI Services</SideMenuItem>
          <SideMenuItem to={Urls.DASHBOARD_VIDEO} isCurrent={currentPath.includes('/video/')}>Video Services</SideMenuItem>
          <SideMenuItem to={mergeUrl(Urls.DASHBOARD_RENDERING, { projectId })} isCurrent={currentPath.includes('/rendering/')}>Rendering/Gaming Services</SideMenuItem>
          <SideMenuItem url={'https://docs.thetatoken.org/docs/theta-edgecloud-overview'} target={"_blank"}>Docs</SideMenuItem>
          {!_.isNil(userId) &&
            <SideMenuItem to={mergeUrl(balance <= 0 && userIsAdmin ? Urls.SETTINGS_BILLING : Urls.DASHBOARD_SETTINGS)} isCurrent={currentPath.includes('/settings/')}>
              Settings</SideMenuItem>}
          <SideMenuItem to={Urls.MARKETING_AI_SHOWCASES} isCurrent={currentPath.includes('/ai-showcase')}>AI Showcases</SideMenuItem>
        </div>

        <div className="bottom-section">
          <div className="copyright">Copyright © {CURRENT_YEAR} Theta Labs, Inc All rights reserved.</div>
        </div>
      </div>
    </SideMenu>)
}