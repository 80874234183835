import { Link } from "react-router-dom";
import classNames from "classnames";
import React from "react";

export const ServiceSidebarSubItem = ({ title, active, link, suffixIcon }) => {
  return (
    <Link to={link}>
      <div
        className={classNames('ServiceSidebarSub__item', { 'ServiceSidebarSub__item--active': active })}>
        <span>{title}</span>
        {suffixIcon && <div className="ServiceSidebarSub__item--suffix">{suffixIcon}</div>}
      </div>
    </Link>
  )
}